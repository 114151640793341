import {APIClient} from "../helpers/api_helper"

const api = new APIClient();

export function getPermissions(role) {
    return api.get(`/spa/permissions/data`, (role ? {role} : {}))
}

export function updatePermission(data) {
    return api.post(`/spa/permissions/update`, data)
}

export function selectAllPermissions(role) {
    return api.post(`/spa/permissions/select-all`, {role})
}

export function unselectAllPermissions(role) {
    return api.post(`/spa/permissions/unselect-all`, {role})
}
