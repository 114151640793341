import {useContext, useEffect, useRef, useState} from "react"
import {ModalContext} from "../../../contexts"
import {toast} from "react-toastify"
import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import {addDocument} from "../../../api/customers";
import Select from "react-select";
import {selectListData} from "../../../api/general";

const UploadDocument = ({customerId, updateTable}) => {
	const {closeModal} = useContext(ModalContext)
	const unloadedFile= useRef('')
	const [selectedType, setSelectedType] = useState('')
	const [documentTypes, setDocumentTypes] = useState([])

	const submitHandler = (e) => {
		e.preventDefault()
		const btn = e.target.querySelector('[type="submit"]')
		btn.disabled = true

		const data = new FormData()
		data.append('user_id', customerId)
		data.append('type', selectedType.value)

		let i = 0;
		for (let file of unloadedFile.current) {
			data.append(`attachments[${i}]`, file, file.name)
			i++
		}

		addDocument(data).then((r) => {
      closeModal()

			if (r.success) {
        toast.info(r.message);
				btn.disabled = false
				updateTable()
			} else {
          toast.error(r.message);
				btn.disabled = false
			}
		}).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      } else {
        toast.error(r.message ? r.message : r)
      }
		}).finally(() => {
      btn.disabled = false
    })
	}

	useEffect(() => {
		selectListData('document_types').then(r => setDocumentTypes(r))
	}, [])

	return (
			<>
				<Form onSubmit={submitHandler}>
					<Row>
						<Col xs={12} className='mb-3'>
							<Label className="form-label mb-0">Document type</Label>
							<Select
									placeholder="Choose..."
									value={selectedType}
									onChange={(selected) => {
										setSelectedType(selected)
									}}
									options={documentTypes}
							/>
						</Col>
						<Col xs={12}>
							<Label className="form-label mb-0">Files</Label>
							<Input
									type='file'
									multiple={true}
									placeholder="Upload document..."
									onChange={(e) => {
										unloadedFile.current = e.target.files
									}}
							>
							</Input>
						</Col>
						<Col xs={12} className="hstack justify-content-end">
							<Button type="submit" color="primary" className="btn ms-auto mt-4">Submit</Button>
						</Col>
					</Row>
				</Form>
			</>
	)
}

export default UploadDocument
