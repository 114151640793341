import React, {useEffect, useState} from 'react'
import {Table} from "reactstrap"
import classnames from "classnames"
import CustomCheckbox from "../Form/CustomCheckbox"

const PermissionsTable = (props) => {
	const {
		data,
		entity,
		checkPermissionHandler,
		protectedRolesPermissions,
		directPermissions,
		overlay
	} = props
	const [isLoading, setIsLoading] = useState(true)

	// Accept array of grouped permissions and return input template for each of them
	const groupTemplate = data => {
		return data.map(([key, permission], idx) => {
			let namedId = `${permission.group}_${permission.role_name}_${permission.entity}_${permission.id}`
			let checkedStatus = !!permission.checked
			let disabledPermission = !!protectedRolesPermissions
			let directPermission = !!directPermissions?.find(i => permission.id === i.id)

      return (
        <React.Fragment key={idx}>
          <CustomCheckbox
            namedId={namedId}
            updateTable={data}
            label={permission.permission_name.replace(/_+/g, ' ').capitalize()}
            tooltip={permission.description}
            checked={checkedStatus || directPermission}
            disabled={checkedStatus && disabledPermission}
            onUpdate={e => checkPermissionHandler(e, permission.id, entity)}
          />
        </React.Fragment>
      )
		})
	}

	useEffect(() => {
    if (Object.keys(data).length) {
			setIsLoading(false)
		}
	}, [data, directPermissions]);

  return (
    <React.Fragment>
      <Table className={classnames('table-borderless table-striped-columns', {'overlayTable': overlay})}>
        <thead>
          <tr>
            <th className="col-1" scope="col"></th>
            <th className="col-2 border" scope="col">View</th>
            <th className="col-2 border" scope="col">Create</th>
            <th className="col-2 border" scope="col">Edit</th>
            <th className="col-2 border" scope="col">Delete</th>
          </tr>
        </thead>
        <tbody>
        {(Object.keys(data).length > 0 && !isLoading) ? Object.entries(data).map(([role, entity], idx) => {
					return (
						<tr className="border" key={idx}>
							<td>{role.capitalize()}</td>
							<td className="border">{groupTemplate(Object.entries(entity?.view || []))}</td>
							<td className="border">{groupTemplate(Object.entries(entity?.create || []))}</td>
							<td className="border">{groupTemplate(Object.entries(entity?.edit || []))}</td>
							<td className="border">{groupTemplate(Object.entries(entity?.delete || []))}</td>
						</tr>
					)
        }) : (
	        <tr>
		        <td className="text-center fs-16 fw-bold" colSpan={5}>{isLoading ? 'Loading...' : 'No data'}</td>
	        </tr>
        )}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default PermissionsTable
