import React, {useContext, useEffect, useState} from "react"
import {Accordion, AccordionItem, Button, Card, CardBody, Col, Collapse, Container, Row} from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import {ModalContext} from "../../../contexts"
import {deletePage, getPages} from "../../../api/pages"
import {toast} from "react-toastify"
import {Link} from "react-router-dom"
import EditPage from "./Modals/EditPage"
import AddPage from "./Modals/AddPage"
import EditTab from "./Modals/EditTab"
import {useUserCan} from "../../../Components/Hooks/UserHooks"

const Pages = () => {
  const {openModal} = useContext(ModalContext)
  const [pageData, setPageData] = useState([])
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [activeToggleTab, setActiveToggleTab] = useState(null)

  // Permissions
  const canManagePages = useUserCan('manage_pages')

  const getPagesData = () => {
    getPages().then(r => {
      setPageData(r)
    })
  }

  const toggleAccordion = (idx) => {
    setActiveToggleTab((prev) => (prev === idx ? null : idx))
  }

  const openEditTabModal = (uuid, name, page_name, page_id) => {
    const blocksData = pageData.find(i => i.id === page_id)
      ?.tabs.find(i => i.uuid === uuid)?.blocks

    openModal({
      title: `Page: "${page_name}"`,
      content: <EditTab
        uuid={uuid}
        name={name}
        page_id={page_id}
        data={blocksData}
        updateData={getPagesData}
      />,
      size: 'lg',
      backdrop: 'static'
    })
  }

  const deletePageHandler = (name, id) => {
    if (confirm(`You really want to remove page "${name}"?`)) {
      deletePage(id).then(r => {
        toast.success(r.message)
        getPagesData()
      })
    }
  }

  const openEditPageModal = (page) => {
    openModal({
      title: `Edit "${page.name}" page`,
      content: <EditPage page={page} updateData={getPagesData}/>,
      size: 'lg'
    })
  }

  const openAddPageModalHandler = () => {
    openModal({
      title: 'Add new page',
      content: <AddPage updateData={getPagesData}/>,
      size: 'lg'
    })
  }

  useEffect(() => {
    getPagesData()
  }, [])

  document.title = 'Pages | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="col-12">
              <BreadCrumb title="Crypto" pageTitle="Pages"/>

              <Card>
                <CardBody>

                  {canManagePages &&
                  <div className="d-flex justify-content-end mt-1 mb-3">
                    <Button color="primary" className="btn" onClick={openAddPageModalHandler}>
                      Add Page
                    </Button>
                  </div>}
                  {(pageData && pageData.length) ?
                    <Accordion
                      open='false'
                      id="default-accordion-example"
                      toggle={() => setIsAccordionOpen(!isAccordionOpen)}
                      flush
                    >
                      {(pageData && pageData.length) ?
                        pageData.map((page, idx) => {
                          const tabId = `${page.slug}_${idx + 1}`

                          return (
                            <AccordionItem key={idx}>
                              <h2
                                className="accordion-header"
                                id={`heading_${idx}`}
                              >
                                <button
                                  className={`accordion-button ${activeToggleTab !== tabId ? 'collapsed' : ''}`}
                                  type="button"
                                  onClick={() => toggleAccordion(tabId)}
                                  style={{cursor: "pointer"}}
                                >
                                  {canManagePages &&
                                    <span className="me-3">
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      deletePageHandler(page.name, page.id)
                                    }}
                                    className="link-danger fs-15 me-3"
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </Link>
                                  <Link
                                    to="#"
                                    className="link-muted fs-15"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      openEditPageModal(page)
                                    }}
                                  >
                                    <i className="ri-settings-4-line "></i>
                                  </Link>
                                </span>}
                                  | {page.name}
                                </button>
                              </h2>

                              <Collapse
                                isOpen={activeToggleTab === tabId}
                                className="accordion-collapse"
                                id="collapseOne"
                              >
                                <div className="accordion-body">
                                  {(page.tabs && page.tabs?.length) ?
                                    page.tabs.map((tab, idx) => {
                                      return (
                                        <div key={idx} className="border-bottom w-100 d-flex p-2 justify-content-between">
                                        <span className="fs-14">
                                          Tab name:  <span className="text-primary">{tab.title}</span>
                                        </span>
                                          {canManagePages &&
                                          <span>
                                          <Link
                                            to="#"
                                            className="link-muted fs-15"
                                            onClick={() => openEditTabModal(tab.uuid, tab.title, page.name, page.id)}
                                          >
                                            <i className="ri-settings-4-line "></i>
                                          </Link>
                                        </span>}
                                        </div>
                                      )
                                    }) : ''
                                  }
                                </div>
                              </Collapse>
                            </AccordionItem>
                          )
                        }) : ''
                      }

                    </Accordion> :
                    <div className='text-center fs-20 font-weight-bold mb-3 border-top pt-4'>
                      No data
                    </div>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Pages
