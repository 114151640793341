import React, {useEffect, useReducer} from 'react'
import CountUp from "react-countup"
import {BsCalendarWeek, BsCalendarEvent, BsCalendarCheck, BsCalendarCheckFill, BsCurrencyExchange} from "react-icons/bs"
import {getIncome} from "../api/widget"

function incomeWidgetReducer(widget, action) {
  switch (action.type) {
    case 'change_amounts': {
      return widget.map(obj => {
        if (obj.name === action.name) {
          return {
            ...obj,
            counter: action.sum,
            count: action.count,
            syncCount: action.syncCount
          }
        }
        return obj
      })
    }

    default: {
      return widget
    }
  }
}

const crmWidgets = [
  {
    name: 'this_month',
    label: "This Month",
    badge: "ri-arrow-up-circle-line text-success",
    icon: <BsCalendarCheck className="display-6 text-muted"/>,
    counter: 0,
    decimals: 0,
    separator: ",",
    prefix: "$",
    count: 0,
    syncCount: 0
  },
  {
    name: 'this_week',
    label: "This Week",
    badge: "ri-arrow-up-circle-line text-success",
    icon: <BsCalendarWeek className="display-6 text-muted"/>,
    counter: 0,
    decimals: 0,
    separator: ",",
    prefix: "$",
    count: 0,
    syncCount: 0
  },
  {
    name: 'today',
    label: "Today",
    badge: "ri-arrow-up-circle-line text-success",
    icon: <BsCalendarEvent className="display-6 text-muted"/>,
    counter: 0,
    decimals: 0,
    separator: ",",
    prefix: "$",
    count: 0,
    syncCount: 0
  },
  {
    name: 'prev_month',
    label: "Previous month",
    badge: "ri-arrow-up-circle-line text-success",
    icon: <BsCalendarCheckFill className="display-6 text-muted"/>,
    counter: 0,
    decimals: 0,
    separator: ",",
    prefix: "$",
    count: 0,
    syncCount: 0
  },
  {
    name: 'this_year',
    label: "This Year",
    badge: "ri-arrow-up-circle-line text-success",
    icon: <BsCurrencyExchange className="display-6 text-muted"/>,
    counter: 0,
    decimals: 0,
    separator: ",",
    prefix: "$",
    count: 0,
    syncCount: 0
  },
]

const IncomeWidget = () => {
  const [widgetRows, widgetRowsReducer] = useReducer(incomeWidgetReducer, crmWidgets);

  useEffect(() => {
    getIncome().then((r) => {
      Object.entries(r).forEach(([name, data]) => {
        if (Array.isArray(data)) {
          widgetRowsReducer({
            type: 'change_amounts',
            name: name,
            sum: data.find(i => (i.tr_type === 'sync') || ((i.tr_type === 'no_sync')))?.sum || 0,
            count: data.find(i => i.tr_type === 'no_sync')?.count || 0,
            syncCount: data.find(i => i.tr_type === 'sync')?.count || 0,
          })
        }
      })
    })
  }, [])

  return (
    <React.Fragment>
      <div className="col-12">
        <div className="card crm-widget">
          <div className="card-body p-0">
            <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
              {widgetRows && widgetRows.map((widget, index) => (
                <div className="col" key={index}>
                  <div className="px-3" style={{paddingBottom: '15px', paddingTop: '15px'}}>
                    <h5 className="text-muted text-uppercase fs-13">{widget.label}<i
                      className={widget.badge + " fs-18 float-end align-middle"}></i></h5>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        {widget.icon}
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h2 className="mb-0">
                          <span className="counter-value" data-target="197">
                            <CountUp
                              start={0}
                              prefix={widget.prefix}
                              separator={widget.separator}
                              end={widget.counter}
                              decimals={widget.decimals}
                              duration={1}/>
                          </span>
                        </h2>
                      </div>
                    </div>
                    <div>
                      <h5 className="text-muted text-uppercase pt-2 fs-11">
                        {'Count: ' + widget.count}{' | Sync count: ' + widget.syncCount}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default IncomeWidget
