import React from 'react'
import {Card, CardBody} from "reactstrap"
import CustomerSecuritiesTable from "../../Secutities/Securities/CustomerSecuritiesTable"

const Securities = ({activeTab, customerId, methodsRef}) => {

  return (
    <Card>
      <CardBody>
        {activeTab === 'securities'
        ? <CustomerSecuritiesTable customerId={customerId} methodsRef={methodsRef} /> :
        ''
        }
      </CardBody>
    </Card>
  )
}

export default Securities
