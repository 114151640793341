import {createSlice} from "@reduxjs/toolkit"

let defaultCustomersColumnsSetting
// First part of key depends on title of table title (lowercase)
let storageName = 'customers_columns_setting'

try {
  defaultCustomersColumnsSetting = JSON.parse(localStorage.getItem(storageName) ?? {})
} catch (err) {
  defaultCustomersColumnsSetting = {}
}

const initialState = {
  columnsSetting: defaultCustomersColumnsSetting,
}

const CustomersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setColumnsSetting(state, action) {
      const data = {...state.columnsSetting}
      data[action.payload.id] = {...action.payload.params}

      state.columnsSetting = data
      localStorage.setItem(storageName, JSON.stringify({...data}))
    }
  }
})

export default CustomersSlice.reducer
export const {setColumnsSetting} = CustomersSlice.actions
