import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export const cryptoRates = () => {
    return api.get('/spa/static-data', {name: 'crypto_rates'})
}

export const getIncome = () => {
    return api.get('/spa/widgets/income-widget', {})
}

export const getLastTransactions = () => {
    return api.get('/spa/widgets/last-transactions', {})
}

export const getLastTickets = () => {
    return api.get('/spa/widgets/last-tickets', {})
}
