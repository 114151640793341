import {Col} from "reactstrap"
import Select, {components} from "react-select"
import {useEffect, useImperativeHandle, useState} from "react"
import {selectListData} from "../../../api/general"
import {insertFilterData} from "../../../slices/table-filters/reducer";
import {useDispatch} from "react-redux";

const SelectInput = (props) => {
  const {
    methodsRef,
    fieldData,
    rowValues,
    withoutPlaceholder = false,
    filterStorageKey
  } = props
  const {type, name, uuid} = fieldData
  const [selectedValue, setSelectedValue] = useState([])
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [fieldName, setFieldName] = useState('')
  const [activeOperator, setActiveOperator] = useState(null)
  const dispatch = useDispatch()

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} innerProps={{ ...props.innerProps, id: `${filterStorageKey}_onOpen_${name}` }} />
    )
  }

  const mostUsageOptionsList = [
    {label: 'Today', value: 'today'},
    {label: 'Yesterday', value: 'yesterday'},
    {label: 'This week', value: 'this week'},
    {label: 'Previous week', value: 'previous week'},
    {label: 'This month', value: 'this month'},
    {label: 'Previous month', value: 'previous month'}
  ]

  const changeOperator = () => {
    if (!selectedValue || !selectedValue?.length) return

    let currentOperatorIndex = fieldData?.operators.indexOf(activeOperator)
    let newOperator

    if(typeof fieldData?.operators[currentOperatorIndex + 1] === 'undefined') {
      newOperator = fieldData?.operators[0]
    } else {
      newOperator = fieldData?.operators[currentOperatorIndex + 1]
    }

    setActiveOperator(newOperator)
    addToActiveFilters(rowValues, newOperator)
  }

  const isMulti = () => {
    return !((type === 'date') || (type === 'single_select'))
  }

  const placeExistingValue = () => {
    if (rowValues && rowValues.length && typeof rowValues !== 'string') {
      setSelectedValue(type !== 'single_select' ? rowValues : rowValues[0])
    } else if (rowValues && typeof rowValues === 'string') {
      setSelectedValue(mostUsageOptionsList.find(i => i.value === rowValues))
    } else {
      setSelectedValue(rowValues ? rowValues : [])
    }
  }

  const getOptions = () => {
    if (fieldData?.yes_or_no) {
      setOptions([{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}])
    } else if (type === 'date') {
      setOptions(mostUsageOptionsList)
    } else if (fieldName !== fieldData.name) {
      setLoading(true)
      selectListData(
        fieldData?.name === 'status' ? 'transaction_statuses' :
          fieldData?.name === 'type' ? 'transfer_types' :
            fieldData?.name === 'teams' ? 'departments_teams'
            : fieldData?.name
      ).then((res) => {
        setOptions(res)
        setLoading(false)
        setFieldName(fieldData.name)
      })
    }
  }

  const addToActiveFilters = (value, operator = false) => {
    dispatch(insertFilterData({
      value: value,
      type: type,
      name: name,
      uuid: uuid,
      filterStorageKey: filterStorageKey,
      operator: operator ? operator : activeOperator
    }))
  }

  useImperativeHandle(methodsRef, () => ({
    changeOperator: changeOperator
  }))

  useEffect(() => {
    placeExistingValue()
  }, [options]);

  useEffect(() => {
    if (rowValues) {
      if (options?.length) {
        placeExistingValue()
      } else {
        getOptions()
      }
    } else {
      setSelectedValue([])
    }
  }, [rowValues])

  useEffect(() => {
    setActiveOperator(fieldData?.operators[0])
  }, [fieldData])

  return (
    <Col className="col">
      <Select
        id={`${filterStorageKey}_${name}`}
        placeholder={!withoutPlaceholder ? 'Select the value' : ''}
        // isClearable={type !== 'single_select'}
        isClearable
        value={selectedValue}
        isMulti={isMulti()}
        onMenuOpen={getOptions}
        onChange={(selected) => {
          if (selected === null) {
            addToActiveFilters([])
            setSelectedValue([])
          } else {
            addToActiveFilters(selected)
            setSelectedValue(selected)
          }
        }}
        options={options}
        isLoading={loading}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
      />
    </Col>
  )
}

export default SelectInput
