import {Button, Col, Form, FormGroup, Label, Row} from "reactstrap"
import React, {useContext, useState} from "react"
import {editCommentTicket} from "../../../api/tickets"
import {toast} from "react-toastify"
import {ModalContext} from "../../../contexts"

const EditTicketComment = ({id, message, updateTable}) => {
  const [comment, setComment] = useState(message)
  const {closeModal} = useContext(ModalContext)

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    editCommentTicket(id, data).then(r => {
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(message)
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12}>
            <FormGroup className="mb-1">
              <Label htmlFor="ticketComment" className="form-label">Message</Label>
              <textarea
                name="comment"
                className="form-control"
                id="ticketComment"
                rows="8"
                placeholder="Enter your message"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col xs={12} className="hstack justify-content-end">
            <Button type="submit" color="primary" className="btn ms-auto mt-4">Submit</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default EditTicketComment
