import classnames from "classnames";
import SelectInput from "../FilterFields/SelectInput";
import React, {useRef} from "react";

const SelectFilterContainer = ({item, activeFilterData, filterStorageKey, canChangeOperator = true}) => {
  const methodsRef = useRef()

  return (
    <div key={item.uuid} className="me-2 user-select-none">
      <label
        className="custom-label text-muted cursor-pointer"
        onClick={() => methodsRef.current.changeOperator()}
      >
        {item.title}
        {canChangeOperator &&
          <span className={classnames('ms-1 fs-11 fw-bold text-primary', {
            'b-block': activeFilterData,
            'b-none': activeFilterData
          })}> {activeFilterData?.operator ?? ''}</span>
        }
      </label>
      <SelectInput
        methodsRef={methodsRef}
        fieldData={item}
        rowValues={activeFilterData?.value}
        withoutPlaceholder={true}
        filterStorageKey={filterStorageKey}
      />
    </div>
  )
}

export default SelectFilterContainer
