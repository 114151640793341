import {createSlice} from "@reduxjs/toolkit"

let defaultTransactionsColumnsSetting
// First part of key depends on title of table title (lowercase)
let storageName = 'transactions_columns_setting'

try {
  defaultTransactionsColumnsSetting = JSON.parse(localStorage.getItem(storageName) ?? {})
} catch (err) {
  defaultTransactionsColumnsSetting = {}
}

const initialState = {
  columnsSetting: defaultTransactionsColumnsSetting,
}

const TransactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setColumnsSetting(state, action) {
      const data = {...state.columnsSetting}
      data[action.payload.id] = {...action.payload.params}

      state.columnsSetting = data
      localStorage.setItem(storageName, JSON.stringify({...data}))
    }
  }
})

export default TransactionsSlice.reducer
export const {setColumnsSetting} = TransactionsSlice.actions
