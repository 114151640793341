import React, {useEffect, useMemo, useState} from 'react'
import {Container} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import BaseTable from "../../Components/Tables/BaseTable";
// import {getAnalytics} from "../../api/analytics"
import {filtersList} from "../../api/general"

const filterStorageKey = 'analytics_filter'
const tableRowsPerPage = 'analytics_rows_per_page'

const Analytics = () => {
  const [filters, setFilters] = useState([])

  const columns = useMemo(() => [
    {
      id: "count",
      name: <span className='font-weight-bold fs-14'>Count</span>,
      selector: row => row.count,
      reorder: true,
      sortable: true,
      minWidth: "90px",
      maxWidth: "300px",
      omit: false
    },
    {
      id: "department_name",
      name: <span className='font-weight-bold fs-14'>Department name</span>,
      selector: row => row.department_name,
      reorder: true,
      sortable: true,
      minWidth: "90px",
      maxWidth: "300px",
      omit: false
    },
    {
      id: "status",
      name: <span className='font-weight-bold fs-14'>Status</span>,
      selector: row => row.status,
      sortable: true,
      reorder: true,
      minWidth: "90px",
      maxWidth: "300px",
      omit: false
    },
    {
      id: "source",
      name: <span className='font-weight-bold fs-14'>Source</span>,
      selector: row => row.source ?? '-',
      reorder: true,
      sortable: true,
      minWidth: "90px",
      maxWidth: "300px",
      omit: false
    },
    {
      id: "country",
      name: <span className='font-weight-bold fs-14'>Country</span>,
      selector: row => row.country ?? '-',
      sortable: true,
      reorder: true,
      minWidth: "120px",
      maxWidth: "300px",
      omit: false
    },
    {
      id: "employee_name",
      name: <span className='font-weight-bold fs-14'>Agent</span>,
      selector: row => row.employee_name ?? '-',
      sortable: true,
      reorder: true,
      minWidth: "120px",
      maxWidth: "300px",
      omit: false
    },
  ])

  useEffect(() => {
    filtersList(filterStorageKey).then(r => setFilters(r))
    // getAnalytics().then(r => console.log(r))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Analytics"/>

          <BaseTable
            columns={columns}
            title="Analytics"
            url="/spa/analytics/data"
            filterFields={filters}
            // actions={contextActions}
            selectedRowsHandler={() => {}}
            // methodsRef={baseTableMethodsRef}
            // columnsSetting={columnsSetting}
            // columnsSettingHandler={setColumnsSetting}
            // storageName={storageName}
            tableRowsPerPage={tableRowsPerPage}
            // clearingSelectedRowsList={clearingSelectedRowsList}
            filterStorageKey={filterStorageKey}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Analytics
