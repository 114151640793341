import React from 'react'
import {prettyDate} from "../../../helpers/utils"

const DatesDetailsFields = (props) => {
  const {invoiceDate, dueDate, termsDays} = props

  return (
    <>
      <tr>
        <td><strong>Invoicing date:</strong></td>
        <td className="text-end">{prettyDate(invoiceDate, false)}</td>
      </tr>
      <tr>
        <td><strong>Due date:</strong></td>
        <td className="text-end">{prettyDate(dueDate, false)}</td>
      </tr>
      <tr>
        <td><strong>Terms:</strong></td>
        <td className="text-end">
          {termsDays === 1 ? `${termsDays} day` : `${termsDays} days`}
        </td>
      </tr>
    </>
  )
}

export default DatesDetailsFields
