import React, {useEffect, useState, useRef, useCallback} from "react"
import {
  Container,
  Button,
  Input,
  Row,
  Col,
  AccordionItem,
  Collapse,
  Accordion,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  PaginationLink,
  PaginationItem,
  Pagination
} from "reactstrap"
import {Link} from "react-router-dom"
import SimpleBar from "simplebar-react"
import classnames from "classnames"
import {
  closeTicket,
  commentTicket,
  deleteCommentTicket,
  editCommentTicket,
  getTicket,
  getTickets
} from "../../api/tickets"
import {copyToClipboard, prettyDate} from "../../helpers/utils"
import {toast} from "react-toastify"
import Select from "react-select"
import {selectListData} from "../../api/general"
import {useUserCan} from "../../Components/Hooks/UserHooks"
import PersonalInfo from './Components/PersonalInfo'
import FeatherIcon from "feather-icons-react"
import '../../assets/scss/pages/componentsBg/_chat-page-background.scss'

//Import Icons & avatar
import default_avatar from "../../assets/images/users/default_avatar.png"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import {useSelector} from "react-redux"

const Chat = () => {
  const userChatShow = useRef()
  const unreadTickets = useSelector(s => s.Tickets.unreadTickets)
  const [messageBox, setMessageBox] = useState(null)
  const [singleTicket, setSingleTicket] = useState(false)
  const [ticketsData, setTicketsData] = useState([])
  const [customerProfile, setCustomerProfile] = useState({})
  const [isMouseOnItem, setIsMouseOnItem] = useState('')
  const [activeToggleTab, setActiveToggleTab] = useState(null)
  const [isFilterForm, setIsFilterForm] = useState(false)
  const [fastRepliesOptions, setFastRepliesOptions] = useState([])
  const [selectedReply, setSelectedReply] = useState()
  const [replyTemplate, setReplyTemplate] = useState([])
  const [ticketsStatusOptions, setTicketsStatusOptions] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('')
  const [comment, setComment] = useState('')
  const [isEditForm, setIsEditForm] = useState(false)
  const [editedMessageId, setEditedMessageId] = useState('')
  const [isMouseOnAccItem, setIsMouseOnAccItem] = useState('')
  const [isInfoDetails, setIsInfoDetails] = useState(false)
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [filterTicketId, setFilterTicketId] = useState('')
  const [filterUserId, setFilterUserId] = useState('')
  const [data, setData] = useState({})

  //Permissions
  const canAnswerTicket = useUserCan('answer_ticket')
  const canCloseTicket = useUserCan('close_ticket')
  const canEditTicketComment = useUserCan('edit_ticket_comment')
  const canDeleteTicketComment = useUserCan('delete_ticket_comment')
  const canViewCustomer = useUserCan('view_customer')

  const [currentPage, setCurrentPage] = useState(1)

  // const { hash } = useLocation()

  const getTicketsData = (data) => {
    if (!data) data = {}

    getTickets(data).then(r => {
      setTicketsData(r.data)
      setCurrentPage(r.current_page)
      setData(r)

      if (data?.ticket_id) {
        openTicketHandler(data.ticket_id)
        if (r.data[0]?.id) {
          toggleAccordion(r.data[0].id)
        }
      } else if (data?.customer_id && r.data.length) {
        toggleAccordion(r.data[0].id)
      }
    })
  }

  const paginatePageHandler = (page) => {
    let formData = new FormData(document.querySelector('#ticket_chats_filter_form'))
    let data = Object.fromEntries(formData.entries())

    getTicketsData({...data, page: page})
  }

  const openTicketHandler = async (id) => {
    try {
      const response = await getTicket(id)
      if (Object.keys(response).length) {
        setSingleTicket(response)
      } else {
        setSingleTicket(false)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const commentTicketHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let data = new FormData(e.target)

    commentTicket(singleTicket.id, data).then(r => {
      btn.disabled = false

      if (r.success) {
        toast.success(r.message)
        getTicketsData()
        openTicketHandler(singleTicket.id)
        setSelectedReply('')
        setReplyTemplate('')
      } else {
        toast.error(r.message)
      }
    }).catch(e => {
      btn.disabled = false
      toast.error(e)
    })
  }

  const editTicketCommentHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    editCommentTicket(editedMessageId, data).then(r => {
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(r.message)
        openTicketHandler(singleTicket.id)
        setEditedMessageId('')
      } else {
        toast.error(message)
      }
    })
  }

  const deleteTicketCommentHandler = (id) => {
    if (confirm('Do you want to delete this comment?')) {
      deleteCommentTicket(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          openTicketHandler(singleTicket.id)
        } else {
          toast.error(r.message)
        }
      }).catch(e => {
        toast.error(e)
      })
    }
  }

  const closeTicketHandler = (e) => {
    e.target.disabled = true

    if (confirm("Do you really want to close this ticket?")) {
      closeTicket(singleTicket.id).then(r => {
        if (r.success) {
          toast.success(r.message)
          getTicketsData()
          openTicketHandler(singleTicket.id)
          setSelectedReply('')
          setReplyTemplate('')
        } else {
          toast.error(r.message)
        }
      }).catch(e => {
        toast.error(e)
      })
    }
  }

  const submitFilterHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    getTicketsData(data)
  }

  const resetFilter = () => {
    setSelectedFilter('')
    setFilterUserId('')
    setFilterTicketId('')
  }

  const toggleAccordion = (idx) => {
    setActiveToggleTab((prev) => (prev === idx ? null : idx))
  }

  const userChatOpen = () => {
    if (window.innerWidth < 992) {
      userChatShow.current.classList.add("user-chat-show")
    }
  }

  const backToUserChat = () => {
    userChatShow.current.classList.remove("user-chat-show")
  }

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  }

  const toggleSettings = () => {
    setSettingsMenu(!settingsMenu)
  }

  const toggleFilterForm = () => {
    setIsFilterForm(!isFilterForm)
  }

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }, [messageBox])

  useEffect(() => {
    if (singleTicket) scrollToBottom()
  }, [singleTicket, scrollToBottom])

  useEffect(() => {
    getTicketsData()

    selectListData('ticket_comments_template').then(r => {
      setFastRepliesOptions(r)
    })
    selectListData('ticket_status').then(r => {
      setTicketsStatusOptions(r)
    })
  }, [])

  // useEffect(() => {
  //   let hashTicketId = Number(hash.slice(1))
  //
  //   if (hashTicketId) {
  //     openTicketHandler(hashTicketId)
  //   }
  // }, [hash])

  useEffect(() => {
    if (ticketsData.find(i => unreadTickets.includes(i.id))) {
      getTicketsData()
    }

    if (singleTicket) {
      openTicketHandler(singleTicket.id)
    }
  }, [unreadTickets])

  document.title = 'Chat | '  + import.meta.env.VITE_APP_NAME
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div className="chat-leftsidebar">

              {/*// Filters fields START*/}
              <div className="px-2 col pt-2 mb-3 d-flex justify-content-center align-items-center flex-column">
                <div className="d-flex align-items-center justify-content-between w-100 my-3 ">
                  <div>
                    <h5 className="mb-0">Chats</h5>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-ghost-secondary btn-icon"
                      onClick={() => getTicketsData()}
                    >
                      <FeatherIcon icon="refresh-cw" className="icon-sm rotate"/>
                    </button>
                  </div>
                </div>
                <Accordion
                  className="custom-accordionwithicon w-100 accordion-secondary"
                  id="accordionWithicon"
                  toggle={toggleFilterForm}
                  open="false"
                >
                  <AccordionItem>
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classnames("accordion-button", {collapsed: !isFilterForm})} type="button"
                        onClick={toggleFilterForm} style={{cursor: "pointer"}}>
                        <i className="ri-search-2-line me-2"></i> Filters
                      </button>
                    </h2>
                    <Collapse isOpen={isFilterForm} className="accordion-collapse" id="collapseOne">
                      <form onSubmit={submitFilterHandler} id="ticket_chats_filter_form">
                        <div className="accordion-body">
                          <div className="form-group mb-2 w-100">
                            <Input
                              name="ticket_id"
                              placeholder="Filter by Ticket ID"
                              autoComplete="off"
                              value={filterTicketId}
                              onChange={(e) => setFilterTicketId(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-2 w-100">
                            <Input
                              name="customer_id"
                              placeholder="Filter by Customer ID"
                              autoComplete="off"
                              value={filterUserId}
                              onChange={(e) => setFilterUserId(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-3 w-100">
                            <Select
                              placeholder="Filter by Status"
                              value={selectedFilter}
                              name="ticket_status"
                              onChange={(selected) => setSelectedFilter(selected)}
                              options={ticketsStatusOptions}
                            />
                          </div>
                          <div className="w-100 d-flex justify-content-center">
                            <Button className="btn btn-sm me-3 w-25" onClick={resetFilter}>
                              Reset
                            </Button>
                            <Button type="submit" color="primary" className="btn btn-sm w-25">
                              Submit
                            </Button>
                          </div>
                        </div>
                      </form>
                    </Collapse>
                  </AccordionItem>
                </Accordion>

              </div>
              {/*// Filters fields END*/}

              <div className="pt-3 border-top">
                <SimpleBar
                  className={classnames('', {
                    'chat-room-list': !isFilterForm,
                    'chat-room-list-with-filter': isFilterForm
                  })}
                  style={{margin: "-16px 0px 0px"}}
                >
                  <div className="chat-message-list">
                    <ul
                      className="list-unstyled chat-list chat-user-list users-list"
                      id="userList"
                    >
                      {(ticketsData && ticketsData.length > 0) && ticketsData.map((user, idx) => {
                        let numberOfNewTickets =  user.tickets
                          .filter(ticket => ticket.status === "1" || ticket.status === "2")

                        return (
                          <Accordion
                            flush
                            key={user.id}
                            id="default-accordion-example"
                            open="false"
                            toggle={() => toggleAccordion(user.id)}
                          >
                            <AccordionItem>
                              <div
                                className={classnames("accordion-header ps-0 ms-0", {
                                  "border-primary border-start border-3": isMouseOnAccItem === String(user.id)
                                })}
                                id={`heading-${user.id}`}
                                style={{transition: '0.1s'}}
                                onMouseEnter={() => setIsMouseOnAccItem(String(user.id))}
                                onMouseLeave={() => setIsMouseOnAccItem('')}
                              >
                                <button
                                  className={classnames("accordion-button p-2", {collapsed: activeToggleTab !== user.id})}
                                  type="button"
                                  onClick={() => {
                                    toggleAccordion(user.id)
                                  }}
                                  style={{cursor: "pointer"}}
                                >
                                  <div className="position-relative pe-2">
                                    <span className="bg-transparent text-reset">
                                      <img
                                        src={
                                          user.profile.avatar_url ?
                                            user.profile.avatar_url :
                                            default_avatar
                                        }
                                        className="avatar-xs rounded-circle"
                                        alt=""
                                      />
                                    </span>
                                    {user.is_online ?
                                      <span
                                        className="position-absolute top-10 start-90 translate-middle badge bg-success me-1 p-1">
                                    <span className="visually-hidden"></span></span>
                                      : null
                                    }
                                  </div>
                                  <div className="chat-sidebar-list-width d-flex">
                                    <span className={classnames('text-truncate me-1 fw-normal', {
                                      'fw-semibold': numberOfNewTickets.length > 0
                                    })}>
                                      {user.name} #{user.id}
                                    </span>
                                    {numberOfNewTickets.length > 0 &&
                                    <span className="badge ms-auto text-bg-info">
                                        {String(numberOfNewTickets.length)}
                                      </span>
                                    }
                                  </div>
                                </button>
                              </div>

                              <Collapse isOpen={activeToggleTab === user.id} className="accordion-collapse"
                                        id={`collapse-${user.id}`}>
                                <ul
                                  className="list-unstyled chat-list chat-user-list users-list"
                                  id="userList"
                                >
                                  {user.tickets.map((item, idx) => (
                                    <li key={idx}>
                                      <div
                                        className={classnames(
                                          `flex-grow-1 align-items-center d-flex justify-content-between
                                          overflow-hidden fs-12 py-1 cursor-pointer
                                          text-${item.status_color === 'danger' ? 'muted' : 'dark'}`,
                                          {
                                            'bg-light': singleTicket?.id === item.id,
                                            'bg-soft-info': isMouseOnItem === String(idx)
                                          })}
                                        style={{transition: '0.15s'}}
                                        onMouseEnter={() => setIsMouseOnItem(String(idx))}
                                        onMouseLeave={() => setIsMouseOnItem('')}
                                        onClick={() => {
                                          openTicketHandler(item.id)
                                          // window.location.hash = item.id
                                          userChatOpen()
                                          setCustomerProfile(user)
                                        }}
                                      >
                                      <span
                                        className="ps-4 pe-1 text-truncate"

                                      >
                                        #{item.id} | {item.subject}
                                      </span>
                                        <small className="fs-10 pe-2">
                                        <span
                                          className={`badge rounded-pill badge-soft-${item.status === '1' ?
                                            'warning' : item.status_color
                                          }`}
                                        >
                                          {item.status === '1' ? 'Waiting' :
                                            (item.status === '3' ? 'Answered' : (
                                              item.status === '4'? 'Closed' : 'NEW'
                                            ))
                                          }
                                        </span>
                                        </small>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </Collapse>

                            </AccordionItem>
                          </Accordion>
                        )
                      })}
                    </ul>
                  </div>
                </SimpleBar>
                {(ticketsData && ticketsData?.length > 0) &&
                  <Pagination
                    size="sm"
                    className="pagination-separated pt-3 absolut-pagination-list border-top"
                  >
                    <PaginationItem disabled={data.current_page === data.from}>
                      <PaginationLink onClick={() => paginatePageHandler(1)} to="#">
                        <span className="text-nowrap">|&lt;</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={data.current_page === data.from}>
                      <PaginationLink onClick={() => paginatePageHandler(data.current_page - 1)} to="#">
                        &lt;
                      </PaginationLink>
                    </PaginationItem>
                    {(data && data.links.length > 0) &&
                      (data.links.slice(1, -1) || []).map((link, idx) => {

                        const pageNumber = Number(link.label)
                        const isWithinRange = Math.abs(pageNumber - data.current_page) <= 2
                        const shouldRender = data.links.length <= 5 || isWithinRange

                        if (shouldRender) {
                          return (
                            <PaginationItem active={data.current_page === pageNumber} key={idx}>
                              <PaginationLink onClick={() => paginatePageHandler(pageNumber)} to="#">
                                {pageNumber}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        }

                        return null
                      })}
                    <PaginationItem disabled={data.current_page === data.last_page}>
                      <PaginationLink onClick={() => paginatePageHandler(data.current_page + 1)} to="#">
                        &gt;
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={data.current_page === data.last_page}>
                      <PaginationLink onClick={() => paginatePageHandler(data.last_page)} to="#">
                        <span className="text-nowrap">&gt;|</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                }
              </div>
              {/*// Customers list END*/}

            </div>

            <div className="user-chat bg-chat w-100 overflow-hidden" ref={userChatShow}>
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <div className="position-relative">

                    {/*Top Bar START*/}
                    <div className="px-3 py-2 user-chat-topbar">
                      {singleTicket ?
                        <Row className="align-items-center">
                          <Col sm={8} xs={8}>
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 d-lg-none me-3">
                                <Link
                                  to="#"
                                  onClick={backToUserChat}
                                  className="user-chat-remove fs-18 p-1"
                                >
                                  <i className="ri-arrow-left-s-line align-bottom"></i>
                                </Link>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <div className="d-flex align-items-center">
                                  {canViewCustomer ?
                                  <img
                                    src={
                                      customerProfile?.profile?.avatar_url ?
                                        customerProfile?.profile.avatar_url :
                                        default_avatar
                                    }
                                    className="avatar-sm rounded-circle cursor-pointer"
                                    alt="Image"
                                    onClick={() => toggleInfo()}
                                  /> :
                                    <img
                                      src={
                                        customerProfile?.profile?.avatar_url ?
                                          customerProfile?.profile.avatar_url :
                                          default_avatar
                                      }
                                      className="avatar-sm rounded-circle"
                                      alt="Image"
                                    />
                                  }
                                  <div className="flex-grow-1 ms-2 overflow-hidden text-nowrap">
                                    <h4 className="text-truncate mb-0 text-reset fs-16 username">
                                      {canViewCustomer ?
                                        <>
                                          <Link to={`/customers/show/${singleTicket?.customer_id}`} >
                                            {singleTicket?.customer.name} #{singleTicket?.customer_id}
                                          </Link>
                                          <Link to={`/customers/show/${singleTicket?.customer_id}`} target="_blank" className="ms-1">
                                            <i className="ri-share-box-line text-muted fs-12"></i>
                                          </Link> </>
                                      :
                                        <>{singleTicket?.customer.name} #{singleTicket?.customer_id}</>
                                      }
                                    </h4>
                                    <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                      <small>
                                        {customerProfile?.is_online ? 'Online' : 'Offline'}
                                      </small>
                                    </p>
                                    <div className="text-nowrap overflow-hidden fs-14 mb-0 text-truncate">
                                      <span>{singleTicket.message}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm={4} xs={4}>
                            <ul className="list-inline user-chat-nav text-end me-3 mb-0">
                              {canViewCustomer &&
                                <li className="list-inline-item d-none d-lg-inline-block me-2 m-0">
                                  <button
                                    type="button"
                                    className="btn btn-ghost-secondary btn-icon"
                                    onClick={() => toggleInfo()}
                                  >
                                    <FeatherIcon icon="info" className="icon-sm"/>
                                  </button>
                                </li>
                              }
                              <li className="list-inline-item mb-0">
                                <Dropdown isOpen={settingsMenu} toggle={toggleSettings}>
                                  <DropdownToggle className="btn btn-ghost-secondary btn-icon" tag="button">
                                    <FeatherIcon
                                      icon="more-vertical"
                                      className="icon-sm"
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => openTicketHandler(singleTicket.id)}>
                                      <i className="ri-refresh-line text-muted me-2 align-bottom"/>
                                      Refresh
                                    </DropdownItem>
                                    {(singleTicket.status !== '4' && canCloseTicket) &&
                                      <DropdownItem onClick={closeTicketHandler}>
                                        <i className="ri-close-circle-line text-muted me-2 align-bottom"/>
                                        Close ticket
                                      </DropdownItem>
                                    }
                                  </DropdownMenu>
                                </Dropdown>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                        :
                        <Row className="align-items-center">
                          <Col>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0 d-lg-none me-3">
                                  <Link
                                      to="#"
                                      onClick={backToUserChat}
                                      className="user-chat-remove fs-18 p-1"
                                    >
                                      <i className="ri-arrow-left-s-line align-bottom"></i>
                                    </Link>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h4 className="mb-0 text-center">
                                      Select a ticket
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      }
                    </div>
                    {/*Top Bar END*/}

                    {/*// ScrollBar OPEN CHAT section START //*/}
                    <div className="position-relative" id="users-chat">
                      <PerfectScrollbar
                        className="p-3 p-lg-4 chat-conversation"
                        id="chat-conversation"
                        containerRef={ref => setMessageBox(ref)}
                      >
                        <div id="elmLoader"></div>
                        {!singleTicket &&
                          <div className="position-relative h-75">
                            <div className="absolut-chat-preview">
                              <h2 className="text-muted text-nowrap">Open the client from the list</h2>
                              <h4 className="text-muted lh-lg mb-0 pb-0">in the left panel and the required ticket</h4>
                              <h4 className="text-muted">to start the chat.</h4>
                            </div>
                          </div>
                        }
                        <ul
                          className="list-unstyled chat-conversation-list"
                          id="users-conversation"
                        >
                          {singleTicket &&
                            <div className="d-flex justify-content-center mb-3">
                              <span>Opened {prettyDate(singleTicket.created_at)}</span>
                            </div>
                          }
                          {singleTicket &&
                            <li className="chat-list left">
                              <div className="conversation-list">
                                <div className="chat-avatar">
                                  <img
                                    src={
                                      customerProfile?.profile?.avatar_url ?
                                      customerProfile.profile.avatar_url :
                                      default_avatar
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="user-chat-content">
                                  <div className="ctext-wrap">
                                    <div className="ctext-wrap-content spa keep-lines">
                                      <p className="mb-0 ctext-content">
                                        {singleTicket?.message}
                                      </p>
                                    </div>
                                    <UncontrolledDropdown className="align-self-start message-box-drop">
                                      <DropdownToggle
                                        href="#"
                                        className="btn nav-btn"
                                        tag="a"
                                      >
                                        <i className="ri-more-2-fill"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          href="#"
                                          onClick={() => copyToClipboard(singleTicket?.message)}
                                        >
                                          <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                                          Copy
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>
                                  <div className="conversation-name">
                                    {canViewCustomer ?
                                    <small className="text-muted">
                                      <Link to={`/customers/show/${singleTicket?.customer_id}`}>
                                        {singleTicket?.customer?.name}
                                      </Link>
                                      <Link to={`/customers/show/${singleTicket?.customer_id}`} target="_blank" className="ms-1">
                                        <i className="ri-share-box-line text-muted fs-12"></i>
                                      </Link>
                                    </small> :
                                      <small className="text-muted">
                                        {singleTicket?.customer?.name}
                                      </small>
                                    }
                                    <small className="text-muted time">
                                      {prettyDate(singleTicket.created_at)}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </li>
                          }
                          {singleTicket?.comments &&
                            singleTicket.comments.map((message, idx) => (
                              <li
                                className={
                                  message.user_id === singleTicket.customer_id
                                    ? "chat-list left"
                                    : "chat-list right"
                                }
                                key={idx}
                              >
                                <div className="conversation-list">
                                  <div className="chat-avatar">
                                    <img
                                      src={message.user_id === singleTicket.customer_id ?
                                          (customerProfile?.profile.avatar_url ?
                                          customerProfile.profile.avatar_url :
                                          default_avatar) :
                                        default_avatar
                                      }
                                      alt=""
                                    />
                                  </div>

                                  <div className="user-chat-content">
                                    <div className="ctext-wrap">
                                      <div className="ctext-wrap-content">
                                        {editedMessageId !== message.id &&
                                          <p className="mb-0 ctext-content keep-lines">
                                            {message.message}
                                          </p>
                                        }
                                        {(isEditForm && message.user_id !== singleTicket.customer_id &&
                                            message.id === editedMessageId && singleTicket.status !== '4') &&
                                          <form onSubmit={editTicketCommentHandler}>
                                            <textarea
                                              name="comment"
                                              className="form-control bg-soft-success border-soft-dark"
                                              id="ticketComment"
                                              rows="10"
                                              cols="80"
                                              placeholder="Enter your message"
                                              value={comment}
                                              onChange={(e) => setComment(e.target.value)}
                                            />
                                            <div className="pt-2 d-flex justify-content-end">
                                              <button
                                                className="link-success fs-16 btn btn-sm p-0 m-0"
                                                type="submit"
                                              >
                                                <i className="ri-checkbox-circle-line"></i>
                                              </button>
                                              <button
                                                className="link-danger fs-16 ms-3 btn btn-sm p-0 m-0"
                                                onClick={() => {
                                                  setEditedMessageId('')
                                                  setIsEditForm(false)
                                                }}
                                              >
                                                <i className="ri-close-circle-line"></i>
                                              </button>
                                            </div>
                                          </form>
                                        }
                                      </div>

                                        <UncontrolledDropdown className="align-self-start message-box-drop">
                                          <DropdownToggle
                                            href="#"
                                            className="btn nav-btn"
                                            tag="a"
                                          >
                                            <i className="ri-more-2-fill"></i>
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem
                                              href="#"
                                              onClick={() => copyToClipboard(message.message)}
                                            >
                                              <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                                              Copy
                                            </DropdownItem>
                                            {(message.user_id !== singleTicket.customer_id &&
                                                editedMessageId !== message.id && singleTicket.status !== '4') &&
                                              <>
                                                {canEditTicketComment &&
                                                  <DropdownItem
                                                    href="#"
                                                    onClick={() => {
                                                      setComment(message.message)
                                                      setEditedMessageId(message.id)
                                                      setIsEditForm(true)
                                                    }}
                                                  >
                                                    <i className="ri-edit-2-line me-2 text-muted align-bottom"></i>
                                                    Edit
                                                  </DropdownItem>
                                                }
                                                {canDeleteTicketComment &&
                                                  <DropdownItem
                                                    href="#"
                                                    onClick={() => deleteTicketCommentHandler(message.id)}
                                                  >
                                                    <i className="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>
                                                    Delete
                                                  </DropdownItem>
                                                }
                                              </>
                                            }
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                    <div>

                                    </div>
                                    <div className="conversation-name">
                                      {message.user_id !== singleTicket.customer_id ?
                                        <small className="text-muted">
                                          {message?.user?.name ? message?.user?.name : 'Support Team'}
                                        </small> :
                                        <small className="text-muted">
                                          <Link to={`/customers/show/${singleTicket?.customer_id}`}>
                                            {singleTicket?.customer?.name}
                                          </Link>
                                          <Link to={`/customers/show/${singleTicket?.customer_id}`} target="_blank"
                                                className="ms-1">
                                            <i className="ri-share-box-line text-muted fs-12"></i>
                                          </Link>
                                        </small>
                                      }
                                      <small className="text-muted">
                                        {prettyDate(message.created_at)}
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        {singleTicket?.status === '4' &&
                          <div className="d-flex justify-content-center mt-3">
                          <span>Closed {prettyDate(singleTicket.updated_at)}</span>
                          </div>
                        }
                      </PerfectScrollbar>
                    </div>
                    {/*// ScrollBar OPEN CHAT END //*/}

                    {/*// Comment ticket form START //*/}
                    {singleTicket &&
                      <div className="chat-input-section p-3 p-lg-4">
                        <form id="chatinput-form" onSubmit={commentTicketHandler}>
                          <Row className="g-0 align-items-center d-flex">
                            <Col>
                              <textarea
                                id="ticketMessage"
                                name="comment"
                                rows="5"
                                disabled={singleTicket.status === '4' || !canAnswerTicket}
                                className="form-control bg-light border-light"
                                placeholder={singleTicket.status === '4' ?
                                  "This ticket is closed. You can no longer post messages here." : (
                                    !canAnswerTicket ? "You don't have permission to answer a ticket" :
                                      "Type your message..."
                                  )
                                }
                                value={selectedReply}
                                onChange={(e) => setSelectedReply(e.target.value)}
                              />
                              <div className="mt-3">
                                <Select
                                  name="transactions_comments"
                                  menuPlacement="top"
                                  options={(singleTicket.status !== '4' && canAnswerTicket) ? fastRepliesOptions : []}
                                  value={replyTemplate}
                                  onChange={selected => {
                                    setSelectedReply(selected.data)
                                    setReplyTemplate(selected)
                                  }}
                                  placeholder={singleTicket.status === '4' ?
                                    "" :
                                    "Fast Answer..."
                                  }
                                />
                              </div>
                            </Col>
                            <Col className='col-auto'>
                              <div className="chat-input-links ms-2">
                                <div className="links-list-item">
                                  <Button
                                    type="submit"
                                    disabled={singleTicket.status === '4'}
                                    color="success"
                                    className="chat-send waves-effect waves-light"
                                  >
                                    <i className="ri-send-plane-2-fill align-bottom"></i>
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    }
                    {/*// Comment ticket form END //*/}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <PersonalInfo
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(!isInfoDetails)}
        currentUser={customerProfile && customerProfile}
        userAvatar={customerProfile?.profile?.avatar_url}
      />
    </React.Fragment>
  )
}

export default Chat
