import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import InvoiceTable from "./InvoiceTable"

const Invoices = () => {
  document.title = 'Invoices | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="col-12">
              <BreadCrumb title="Crypto" pageTitle="Invoices"/>
              <InvoiceTable withFilters={true}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Invoices
