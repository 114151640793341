import {Col, Form, Input, Label, Row, Table} from "reactstrap"
import React, {useState} from "react"
import {autoSendingPinCode, deleteSchedulePinCode, disablePinCode, schedulePinCode} from "../../../api/employees"
import {toast} from "react-toastify"
import {Link} from "react-router-dom"
import {prettyDate} from "../../../helpers/utils"

const SchedulePinCodeTab = (props) => {
const {
  id,
  disabledDates,
  updateTable,
  isPinCodeDisabled,
  isPinSendingDisabled
} = props

  const submitHandler = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData.entries())
    data.employee_id = id

    schedulePinCode(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
      } else {
        toast.error(r.message)
      }
    })
  }

  const deleteDisabledDateHandler = (disabledDate) => {
    const data = {
      employee_id: id,
      date: disabledDate,
    }

    if (confirm('Do you want to delete this date?')) {
      deleteSchedulePinCode(data).then(r => {
        if (r.success) {
          toast.success(r.message)
          updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const isDisablePinCodeHandler = isDisabled => {
    const data = {
      employee_id: id,
      is_disabled: isDisabled
    }

    disablePinCode(data).then(r => {
     if (r.success) {
       updateTable()
       toast.success(r.message)
     } else {
       toast.error(r.message)
     }
    })
  }

  const autoSendingPinCodeHandler = isEnabled => {
    const data = {
      employee_id: id,
      auto_pin_code_sending: isEnabled
    }

    autoSendingPinCode(data).then(r => {
      if (r.success) {
        updateTable()
        toast.success(r.message)
      } else {
        toast.error(r.message)
      }
    })
  }

  return (
    <>
      <Row>
        <Col xl={12}>
          <div className="d-flex mb-3">
            <div className="form-check form-switch">
              <Label htmlFor="is_disabled_checkbox">Disable Pin Code</Label>
              <Input
                type="checkbox"
                role="switch"
                checked={isPinCodeDisabled}
                id="is_disabled_checkbox"
                onChange={e => {
                  isDisablePinCodeHandler(e.target.checked)
                }}
              />
            </div>
            <div className="form-check form-switch mx-3">
              <Label htmlFor="is_enabled_email_sending">Enable auto send PIN code to Email</Label>
              <Input
                type="checkbox"
                role="switch"
                checked={isPinSendingDisabled}
                id="is_enabled_email_sending"
                onChange={e => {
                  autoSendingPinCodeHandler(e.target.checked)
                }}
              />
            </div>
          </div>
        </Col>
        <Col xl={6}>
          <Form onSubmit={submitHandler}>
            <div className="mb-3">
              <Label htmlFor="shedule_date_for_pin_code" className="form-label">Select Date</Label>
              <Input
                name="schedule_date"
                type="date"
                className="form-control"
                id="shedule_date_for_pin_code"
              />
            </div>
            <div className="text-start mt-3 mb-3">
              <button type="submit" className="btn btn-success">Submit</button>
            </div>
          </Form>
        </Col>
        <Col xl={6}>
          <Table className="table-responsive table-sm">
            <thead>
              <tr>
                <th className="ps-2">#</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {disabledDates?.length > 0 ?
              disabledDates.map((disabledDate, idx) =>
                <tr key={idx}>
                  <td className="ps-2">{++idx}</td>
                  <td>{prettyDate(disabledDate, false)}</td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      <Link to="#"
                            onClick={() => {deleteDisabledDateHandler(disabledDate)}}
                            className="link-danger fs-15"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ) :
              <tr>
                <td colSpan={3} className="text-center pt-3">No data</td>
              </tr>
            }
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default SchedulePinCodeTab
