import React, {useState, useEffect} from 'react'
import {Button, Form, Input, Label} from "reactstrap"
import Select from "react-select"
import {ModalContext} from "../../../../contexts"
import {useContext} from "react"
import {
  calculateTransactionPrice,
  createTransaction,
  getAllAssets,
  // getTransactionsSides,
  getTransactionsStatuses
} from "../../../../api/transactions"
import {toast} from "react-toastify"
import DatePickerWithParse from "../../../../Components/Common/DatePickerWithParse"
import moment from 'moment-timezone'
import {timeZone} from "../../../../helpers/utils"
import {Tooltip} from "react-tooltip"

const DepositTransactionModal = ({id, transactionTableMethodsRef}) => {
  const {closeModal} = useContext(ModalContext)
  const [assetsOptions, setAssetsOptions] = useState([])
  const [selectedAsset, setSelectedAsset] = useState('')
  const [transactionStatusesOptions, setTransactionStatusesOptions] = useState([])
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState('')
  const [amount, setAmount] = useState('')
  const [assetUsdPrice, setAssetUsdPrice] = useState('')

  const getAssetsData = () => {
    getAllAssets().then(r => {
      setAssetsOptions(r.map(i => ({...i, label: i.code + ' / ' + i.label})))

      if (r.length) {
        let firstAsset = {...r[0], label: r[0]?.code + ' / ' + r[0]?.label}
        setSelectedAsset(firstAsset)
      }
    })
  }

  const getTransactionStatusesData = () => {
    getTransactionsStatuses().then(r => {
      setTransactionStatusesOptions(r)

      if (r.length) {
        setSelectedTransactionStatus(r[2] || r[0])
      }
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    data.date = new Date(moment.tz(data.date, timeZone).format()).toUTCString()

    if (data.amount === '') {
      toast.error('Enter amount')
      btn.disabled = false
    } else {
      createTransaction(data)
        .then(r => {
          closeModal()
          let message = r?.message || 'Empty response message.'

          if (r.success) {
            toast.success(message)
            btn.disabled = false
            transactionTableMethodsRef?.current?.updateTable()
          } else {
            toast.error(message)
            btn.disabled = false
          }
        })
        .catch(r => {
          btn.disabled = false

          if (r.errors) {
            Object.entries(r.errors).forEach(entry => {
              const [key, value] = entry
              value.forEach(i => toast.error(i))
            })
          } else {
            toast.error(r)
          }
        })
    }
  }

  const calculateHandler = e => {
    let formEl = e.target.closest('form')

    let formData = new FormData(formEl)
    let data = Object.fromEntries(formData.entries())

    calculateTransactionPrice(data).then(r => {
      if (r.success) {
        setAmount(r.amount)
        setAssetUsdPrice(r.usd_price)
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  useEffect(() => {
    // getTransactionSidesData()
    getAssetsData()
    getTransactionStatusesData()
  }, [])

  return (
    <React.Fragment>
      <Form onSubmit={submitHandler}>
        <div className="mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Asset</Label>
          <Select
            name="asset_id"
            placeholder="Choose asset"
            options={assetsOptions}
            value={selectedAsset}
            onChange={selected => setSelectedAsset(selected)}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Amount</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="0.00000000"
            name="amount"
            autoComplete="off"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Status</Label>
          <Select
            name="status"
            placeholder="Status"
            options={transactionStatusesOptions}
            value={selectedTransactionStatus}
            onChange={selected => setSelectedTransactionStatus(selected)}
          />
        </div>
        <div className="mb-3">
          <Label htmlFor="inputDate" className="form-label mb-0">Date</Label>
          <DatePickerWithParse/>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Amount in USD</Label>
          <Input type="text" className="form-control" placeholder="0.00" name="amount_in_usd"/>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Asset USD Price</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="0.00"
            name="usd_amount"
            value={assetUsdPrice}
            onChange={e => setAssetUsdPrice(e.target.value)}
          />
        </div>
        <div className="mb-4 border-bottom">
          <div className="d-flex">
            <div className="col-6 form-check form-switch mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                name="is_real_check"
                role="switch"
                id="check-box"
              />
              <Label className="form-check-label" for="check-box">Is real transaction</Label>
            </div>
            <div className="col-6 form-check form-switch mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                name="is_sync_check"
                role="switch"
                id="check-box"
              />
              <Label className="form-check-label" for="check-box">Is sync transaction</Label>
            </div>
          </div>
          <div className="col-6 form-check form-switch mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              name="is_hidden_transaction"
              role="switch"
              id="check-box"
            />
            <Label className="form-check-label" for="check-box">Hidden</Label>
          </div>
        </div>
        <div className="hstack justify-content-between">
          <div className="d-flex align-items-center">
            <Button type="button" color="success" className="btn" onClick={calculateHandler}>
              Calculate
            </Button>
            <div className="text-muted m-0 p-0">
              <i
                className="ri-question-line align-middle fs-20 ms-1"
                style={{cursor: 'help'}}
                data-tooltip-id={'calculate'}
                data-tooltip-content={
                  'Enter the "Amount in USD" and click "Calculate" to convert it to cryptocurrency at the current rate. ' +
                  'You can manually enter the "Asset USD Price" or select the rate by date (the date will be ignored if ' +
                  'the price is entered manually).'
                }
              ></i>
              <Tooltip id={'calculate'} style={{maxWidth: '300px'}}/>
            </div>
          </div>
          <div>
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" color="primary" className="btn">Submit</Button>
          </div>
        </div>
        <Input type="hidden" name="customer_id" value={id}></Input>
      </Form>
    </React.Fragment>
  )
}

export default DepositTransactionModal
