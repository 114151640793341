import React, {useContext, useEffect, useState} from 'react'
import {ModalContext} from "../../../../../contexts"
import {Button, Input, Label} from "reactstrap"
import {changeExchangeFee, getFee} from "../../../../../api/fees"
import {toast} from "react-toastify"

const ExchangeFeesConfigModal = ({customer_id, updateProfile}) => {
  const {closeModal} = useContext(ModalContext)
  const [feeValue, setFeeValue] = useState('')
  const [customerFee, setCustomerFee] = useState(null)

  const submitHandler = (e, defaultValue = null) => {
    e.preventDefault()
    e.target.disabled = true

    let value = feeValue
    if (defaultValue && String(defaultValue) === 'setDefault') {
      value = ''
    }

    changeExchangeFee(value, customer_id).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateProfile()
        closeModal()
      } else {
        toast.error(r.message)
      }
    }).finally(() => {
      e.target.disabled = false
    })
  }

  const setDefaultFee = (e) => {
    if (confirm('Do you really want to set the "Exchange fee" to the default value?')) {
      submitHandler(e, 'setDefault')
    }
  }

  useEffect(() => {
    if (customer_id) {
      getFee(customer_id).then(r => {
        setCustomerFee(r)
      })
    }
  }, [customer_id])

  useEffect(() => {
    if (customerFee) {
      setFeeValue(customerFee['exchange_fee'])
    }
  }, [customerFee])

  return (
    <>
      <div>
        <Label className="mb-0 form-label">Fee value</Label>
        <div className='d-flex'>
          <div className="form-group w-100">
            <Input
              className="form-control"
              type="text"
              value={feeValue}
              onChange={(e) => setFeeValue(e.target.value)}
              autoComplete="off"
              placeholder='4.0%'
            />
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3">
          <Button
            type="button"
            className="btn btn-warning text-nowrap"
            onClick={(e) => {
              setDefaultFee(e)
            }}
          >
            Set default
          </Button>
          <Button type="button" className="btn btn-success" onClick={(e) => submitHandler(e)}>
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default ExchangeFeesConfigModal
