import React, {useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Card, CardBody, Col, Container, Row, Button, Input, Label, Form} from 'reactstrap'
import ParticlesAuth from "../ParticlesAuth"

import {checkPinCode} from "../../../api/auth"

const PinCode = () => {
  const pinCodeForm = useRef(null)
  const navigator = useNavigate()
  const brandLogosUrl = import.meta.env.VITE_APP_URL + '/storage/brand_logos/'
  const logoHeight = import.meta.env.VITE_THEME_LOGO_SIZE || 70
  const logoLightUrl = brandLogosUrl + 'logo-light.png'

  document.title = import.meta.env.VITE_APP_NAME + ' | Pin Code'

  const getInputElement = (index) => {
    return document.getElementById('digit' + index + '-input')
  }

  const moveToNext = (index) => {
    if (getInputElement(index).value.length === 1) {
      if (index !== 4) {
        getInputElement(index + 1).focus()
      } else {
        getInputElement(index).blur()
        // Submit code
        submitCode()
      }
    }
  }

  const submitCode = () => {
    const formData = new FormData(pinCodeForm.current)
    const pinCode = Object.values(Object.fromEntries(formData.entries())).join('')

    checkPinCode(pinCode).then(r => {
      if (r.success) {
        // localStorage.setItem('lockScreen', '')
        navigator('/dashboard')
      }
    })
  }

  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link to="/dashboard" className="d-inline-block auth-logo">
                        {
                          import.meta.env.VITE_APP_NAME === 'Crypto Clasic' ?
                            <img className="bg-light rounded-circle" src={logoLightUrl} alt="" height={logoHeight}/> :
                            <img src={logoLightUrl} alt="" height={logoHeight}/>
                        }
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="mt-4">
                    <CardBody className="p-4">
                      <div className="mb-4">
                        <div className="avatar-lg mx-auto">
                          <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                            <i className="ri-mail-line"></i>
                          </div>
                        </div>
                      </div>

                      <div className="p-2 mt-4">
                        <div className="text-muted text-center mb-4 mx-lg-3">
                          <h4>Enter your PIN code</h4>
                          <p>Please enter the 4 digit code sent to your email</p>
                        </div>

                        <Form innerRef={pinCodeForm}>
                          <Row>
                            <Col className="col-3">
                              <div className="mb-3">
                                <Label htmlFor="digit1-input" className="visually-hidden">Digit 1</Label>
                                <Input
                                  name="digit_1"
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit1-input"
                                  onKeyUp={() => moveToNext(1)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <Label htmlFor="digit2-input" className="visually-hidden">Digit 2</Label>
                                <Input
                                  name="digit_2"
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit2-input"
                                  onKeyUp={() => moveToNext(2)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <Label htmlFor="digit3-input" className="visually-hidden">Digit 3</Label>
                                <Input
                                  name="digit_3"
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit3-input"
                                  onKeyUp={() => moveToNext(3)}
                                />
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <Label htmlFor="digit4-input" className="visually-hidden">Digit 4</Label>
                                <Input
                                  name="digit_4"
                                  type="text"
                                  className="form-control form-control-lg bg-light border-light text-center"
                                  maxLength="1"
                                  id="digit4-input"
                                  onKeyUp={() => moveToNext(4)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                        <div className="mt-3">
                          <Button color="success" onClick={submitCode} className="w-100">Confirm</Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  {/*<div className="mt-4 text-center">
                    <p className="mb-0">Didn't receive a code ? <Link to="/auth-pass-reset-basic" className="fw-semibold text-primary text-decoration-underline">Resend</Link> </p>
                  </div>*/}
                </Col>
              </Row>
            </Container>
          </div>
        </ParticlesAuth >

      </div>
    </React.Fragment >
  )
}

export default PinCode
