import {Button, Form, FormGroup, Input, Label} from "reactstrap"
import {useContext, useState} from "react"
import {ModalContext} from "../../../contexts"
import {toast} from "react-toastify"
import {updateAsset} from "../../../api/settings";

const EditAsset = ({ address, id, updateTable }) => {
  const {closeModal} = useContext(ModalContext)
  const [addressInput, setAddressInput] = useState(address)

  const addressChangeHandler = (e) => {
    setAddressInput(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const data = {
      asset_id: id,
      default_address: addressInput,
    }

    updateAsset(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Default Address</Label>
          <Input
            placeholder='Address'
            autoComplete="off"
            value={addressInput}
            onChange={addressChangeHandler}
          />
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default EditAsset
