import React, {useContext, useMemo, useRef, useState} from "react"
import BaseTable from "../../../../Components/Tables/BaseTable"
import btc from "../../../../assets/images/svg/crypto-icons/btc.svg"
import eth from "../../../../assets/images/svg/crypto-icons/eth.svg"
import ltc from "../../../../assets/images/svg/crypto-icons/ltc.svg"
import dash from "../../../../assets/images/svg/crypto-icons/dash.svg"
import usdt from "../../../../assets/images/svg/crypto-icons/usdt.svg"
import cdn from "../../../../assets/images/svg/crypto-icons/cdn.svg"
import usd from "../../../../assets/images/svg/crypto-icons/usd.svg"
import eur from "../../../../assets/images/svg/crypto-icons/eur.svg"
import gbp from "../../../../assets/images/svg/crypto-icons/gbp.svg"
import xrp from "../../../../assets/images/svg/crypto-icons/xrp.svg"
import ada from "../../../../assets/images/svg/crypto-icons/ada.svg"
import doge from "../../../../assets/images/svg/crypto-icons/doge.svg"
import tusd from "../../../../assets/images/svg/crypto-icons/tusd.svg"
import {Link} from "react-router-dom"
import {deleteP2POrder} from "../../../../api/p2p-orders"
import {toast} from "react-toastify"
import {ModalContext} from "../../../../contexts"
import EditP2POrderModal from "../Modals/EditP2POrderModal"
import {cryptoIconsMapper, isTwoPrecision} from "../../../../helpers/utils"

const P2POrdersTable = ({customerId}) => {
  const {openModal} = useContext(ModalContext)
  const baseTableMethodsRef = useRef(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowIds, setSelectedRowIds] = useState([])

  const selectedRowsHandler = (row) => {
    setSelectedRows(row)
    setSelectedRowIds(row.map((row) => row.id))
  }

  const editP2POrderHandler = (id) => {
    openModal({
      title: 'Edit P2P Order',
      content: <EditP2POrderModal p2pOrderId={id} updateTable={updateTable}/>,
      size: 'lg',
    })
  }

  const deleteP2POrderHandler = (id) => {
    if (confirm('Do you really want to delete this order?')) {
      deleteP2POrder(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>Order ID</span>,
        selector: row => <a href='#' onClick={() => editP2POrderHandler(row.id)}># {row.id}</a>,
        sortable: true,
        minWidth: '120px',
        maxWidth: '300px',
        omit: false,
      },
      {
        id: "transfer_id",
        name: <span className='font-weight-bold fs-14'>Transfer ID</span>,
        selector: row => row.transfer_id ? row.transfer_id : "-",
        sortable: true,
        minWidth: '120px',
        maxWidth: '300px',
        omit: false,
      },
      {
        id: "sell_asset",
        name: <span className="font-weight-bold fs-14">Sell Asset</span>,
        selector: row => (
          <div className="d-flex align-items-center">
            <div className="avatar-xxs me-2">
              <img
                src={cryptoIconsMapper[row.sell_asset.currency.code]}
                className="img-fluid"
                alt={row.sell_asset.currency.name}
              />
            </div>
            <span>{row.sell_asset.currency.code}</span>
          </div>
        ),
        minWidth: "140px",
        reorder: true,
        omit: false
      },
      {
        id: "buy_asset",
        name: <span className="font-weight-bold fs-14">Buy Asset</span>,
        selector: row => (
          <div className="d-flex align-items-center">
            <div className="avatar-xxs me-2">
              <img
                src={cryptoIconsMapper[row.buy_asset.currency.code]}
                className="img-fluid"
                alt={row.buy_asset.currency.name}
              />
            </div>
            <span>{row.buy_asset.currency.code}</span>
          </div>
        ),
        minWidth: "140px",
        reorder: true,
        omit: false
      },
      {
        id: "status",
        name: <span className='font-weight-bold fs-14'>Status</span>,
        selector: row => row.order_status,
        sortable: true,
        minWidth: '120px',
        maxWidth: '300px',
        omit: false,
      },
      {
        id: "sell_price",
        name: <span className='font-weight-bold fs-14'>Sell Price</span>,
        selector: row => isTwoPrecision(row.sell_asset.currency.code, row.sell_price),
        sortable: true,
        minWidth: '120px',
        maxWidth: '300px',
        omit: false,
      },
      {
        id: "buy_price",
        name: <span className='font-weight-bold fs-14'>Buy Price</span>,
        selector: row => isTwoPrecision(row.buy_asset.currency.code, row.buy_price),
        sortable: true,
        minWidth: '120px',
        maxWidth: '300px',
        omit: false,
      },
      {
        id: "actions",
        name: <span className="font-weight-bold fs-14">Actions</span>,
        selector: row => (
          <>
            <div className="hstack gap-3 flex-wrap">
              <Link
                onClick={() => editP2POrderHandler(row.id)}
                to="#"
                className="link-success fs-15">
                <i className="ri-edit-2-line"></i>
              </Link>
              <Link
                onClick={() => deleteP2POrderHandler(row.id)}
                to="#"
                className="link-danger fs-15">
                <i className="ri-delete-bin-line"></i>
              </Link>
            </div>
          </>
        ),
        omit: false,
        reorder: true,
      },
    ], [])

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  return (
    <BaseTable
      columns={columns}
      title="P2POrders"
      url="/spa/p2p-orders/data"
      filterFields={[]}
      selectedRowsHandler={selectedRowsHandler}
      methodsRef={baseTableMethodsRef}
      additionalRequest={customerId ? {customer_id: customerId} : null}
    />
  )
}

export default P2POrdersTable
