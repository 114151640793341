import React from "react"
import { Card, CardBody } from "reactstrap"
import moment from "moment-timezone"
import {prettyDate, timeZone} from "../../helpers/utils"

function UpcomingEvents({event, color}) {
	return (
    <Card className="mb-3">
      <CardBody>
        <div className="d-flex mb-3">
          <div className="flex-grow-1">
            <i className={"mdi mdi-checkbox-blank-circle me-2 text-" + color}></i>
            <span className="fw-medium">
              {/*{moment.tz(moment.tz(event.start, 'UTC')*/}
              {/*  .format(), timeZone ? timeZone : 'UTC')*/}
              {/*  .format('YYYY-MM-DD')} to {moment.tz(moment.tz(event.end, 'UTC')*/}
              {/*  .format(), timeZone ? timeZone : 'UTC')*/}
              {/*  .format('YYYY-MM-DD')*/}
              {/*}*/}
              {prettyDate(event.start, false)} to {prettyDate(event.end, false)}
            </span>
          </div>
          <div className="flex-shrink-0">
            <small className="badge badge-soft-primary ms-auto">
              {/*{dateRange}*/}
              {moment.tz(moment.tz(event.start, 'UTC').format(), timeZone)
                .format('HH:mm')} - {moment.tz(moment.tz(event.end, 'UTC')
                .format(), timeZone).format('HH:mm')
              }
            </small>
          </div>
        </div>
        <h6 className="card-title fs-16">{event.title}</h6>
        <p className="text-muted text-truncate-two-lines mb-0">
          {event.description}
        </p>
      </CardBody>
    </Card>
  )
}

export default UpcomingEvents
