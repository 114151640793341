import React, {useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import {Button, Offcanvas, OffcanvasBody} from "reactstrap"
import BG from "../../../assets/images/bg-image.webp"
import defaultAvatar from "../../../assets/images/users/user-dummy-img.jpg"
import {copyToClipboard, prettyDate} from "../../../helpers/utils"
import { capitalize } from "lodash"
import {selectListData} from "../../../api/general"
import classnames from "classnames"

const PersonalInfo = (props) => {
  const {
    show,
    onCloseClick,
    currentUser,
    userAvatar
  } = props
  const navigate = useNavigate()
  const [countryList, setCountryList] = useState([])
  const [country, setCountry] = useState([])

  const getCustomerCountry = () => {
    if (currentUser) {
      setCountry(countryList.filter(i => i.value === currentUser?.profile?.country))
    }
  }

  useEffect(() => {
    selectListData('countries').then(r => {
      setCountryList(r)
    })
  }, [])

  useEffect(() => {
    getCustomerCountry()
  }, [countryList, currentUser])

  return (
    <Offcanvas
      isOpen={show}
      direction="end"
      className="offcanvas-end border-0"
      toggle={onCloseClick}
    >
      <OffcanvasBody className="offcanvas-body profile-offcanvas p-0">
        <div className="team-cover">
          <img src={BG} alt="#" className="img-fluid" />
        </div>
        <div className="p-1 pb-4 pt-0">
          <div className="team-settings">
            <div className="row g-0">
              <div className="col">
                <div className="btn nav-btn">
                  <Button
                    onClick={onCloseClick}
                    color=""
                    className="btn-close btn-close-white"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 text-center">
          <img
            src={userAvatar ? userAvatar : defaultAvatar}
            alt="avatar"
            className="avatar-lg img-thumbnail rounded-circle mx-auto profile-img"
          />
          <div className="mt-3">
            <h5 className="fs-16 mb-1">
              <Link
                to="#"
                className="link-primary username"
                onClick={() => copyToClipboard(currentUser.name)}
              >
                {currentUser?.name}
              </Link>
            </h5>
            <p className="text-muted">
              <i className={
                `ri-checkbox-blank-circle-fill me-1 align-bottom text-${
                  currentUser?.is_online ? 'success' : 'muted'
                }`
              }></i>
              {currentUser?.is_online ? 'Online' : 'Offline'}
              <span> | {capitalize(String(currentUser?.profile?.verification_status))}</span>
            </p>
          </div>
        </div>
        <div className="border-top border-top-dashed p-3">
          <h5 className="fs-15 mb-3">Personal Details</h5>
          {currentUser &&
            <>
              <div className="mb-3">
                <p className="text-muted text-uppercase fw-medium fs-12 mb-1">
                  Phone
                </p>
                <h6 className={classnames("", {
                  "cursor-pointer text-primary": currentUser?.profile?.phone
                })}
                onClick={() => {
                  currentUser?.profile?.phone &&
                  copyToClipboard(currentUser.profile.phone)
                }}
                >
                  {currentUser?.profile?.phone ? currentUser?.profile?.phone : 'No information'}
                </h6>
              </div>
              <div className="mb-3">
                <p className="text-muted text-uppercase fw-medium fs-12 mb-1">
                  Email
                </p>
                <h6 className={classnames("", {
                  "cursor-pointer text-primary": currentUser.email
                })}
                    onClick={() => {
                      currentUser.email &&
                      copyToClipboard(currentUser.email)
                    }}
                >
                  {currentUser.email}
                </h6>
              </div>
              <div className="mb-3">
                <p className="text-muted text-uppercase fw-medium fs-12 mb-1">
                  Country
                </p>
                <h6 className="mb-0">
                  {country ? country[0]?.label : 'No information'}
                </h6>
              </div>

              <div className={classnames("mb-3", {
                "border-bottom border-bottom-dashed": currentUser && currentUser?.comments?.length > 0
              })}>
                <p className="text-muted text-uppercase fw-medium fs-12 mb-2">
                  Last 3 comments
                </p>
                {(currentUser && currentUser?.comments?.length > 0) ? (
                  <h6 className="mb-0">
                    {(currentUser?.comments || []).map((comment, idx) => (
                      <div className="d-flex flex-column border-top border-top-dashed px-1" key={idx}>
                        <h6 className="fs-14 mt-1">{comment.message}</h6>
                        <small className="text-muted mb-1">
                          {prettyDate(comment.created_at)}
                        </small>
                      </div>
                    ))}
                  </h6>
                ) : "This customer has no comments yet"}
              </div>
            </>
          }
        </div>

        <div className="border-top border-top-dashed p-3">

          <div className="vstack gap-2">
            <div className="text-center mt-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate(`/customers/show/${currentUser.id}`)}
              >
                Go to profile{" "}
                <i className="ri-arrow-right-fill align-bottom ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default PersonalInfo;
