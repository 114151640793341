import React, {useEffect, useState} from 'react'
import {
  Button,
  Card,
  CardBody,
  Offcanvas,
  OffcanvasBody,
} from "reactstrap"
import {getEmployeeEvents} from "../../../../api/calendar"
import moment from "moment-timezone"
import {Link} from "react-router-dom"
import SimpleBar from "simplebar-react"
import {Tooltip} from "react-tooltip"
import {capitalize} from "lodash"
import "../../../../assets/scss/pages/componentsBg/_employee-profile-offcanvas-bg.scss"
import {prettyDate} from "../../../../helpers/utils"

const CalendarEventsCanvas = ({show, onCloseClick, id}) => {
  const [eventsData, setEventsData] = useState([])

  const badgeColorMap = {
    "phone call" : "success",
    "other" : "primary",
    "email" : "info",
    "meeting" : "warning",
  }

  const iconsMap = {
    "phone call" : "mdi mdi-phone-outgoing",
    "other" : "mdi mdi-file-alert",
    "email" : "mdi mdi-email-fast",
    "meeting" : "mdi mdi-handshake",
  }

  // const currentDate = new Date()
  // const tomorrowDate = new Date()
  // tomorrowDate.setDate(currentDate.getDate() + 1)
  // tomorrowDate.setHours(0, 0, 0, 0)

  const upcomingEvents = eventsData
    .filter(event => moment.tz(event.start, 'UTC') >= moment.tz(new Date(), 'UTC'))

  const getCalendarEventsList = () => {
    getEmployeeEvents(id).then(r => {
      setEventsData(r)
    })
  }

  useEffect(() => {
    getCalendarEventsList()
  }, [])

  return (
    <Offcanvas
      isOpen={show}
      direction="end"
      toggle={onCloseClick}
      id="offcanvasRight"
      className="border-0"
    >
      <OffcanvasBody className="p-0 overflow-scroll bg-offcanvas">
        <div className="p-1 py-3 border-2 border-bottom">
          <div className="team-settings">
            <div className="row g-0">
              <div className="col d-flex justify-content-between">
                <div className="text-white fs-16 ps-3 d-flex align-items-center">Calendar events</div>
                <div className="btn nav-btn">
                  <Button
                    onClick={onCloseClick}
                    color=""
                    className="btn-close btn-close-white"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SimpleBar className="events-offcanvas-scroll-height">
          <div className="acitivity-timeline p-3">
            {(upcomingEvents && upcomingEvents.length > 0) ?
              upcomingEvents
                .sort((a, b) => moment.tz(a.start, 'UTC') - moment.tz(b.start, 'UTC'))
                .map((event, idx) => (
                <div className="acitivity-item pb-3 d-flex" key={idx}>
                  {(() => {
                    const classNameColor = badgeColorMap[event.category]
                    const iconName = iconsMap[event.category]
                    return (
                      <div className="flex-shrink-0">
                        <div className="avatar-xs acitivity-avatar rounded-circle">
                          <div
                            className={`avatar-title rounded-circle bg-soft-${classNameColor} text-${classNameColor}`}
                            data-tooltip-id={String(idx)}
                            data-tooltip-content={capitalize(event.category)}
                          >
                            <i className={`fs-18 ${iconName}`}></i>
                            <Tooltip id={String(idx)}/>
                          </div>
                        </div>
                      </div>
                    )
                  })()}
                  <div className="flex-grow-1 ms-3">
                    <Card className="m-0 p-0">
                      <CardBody>
                        {event &&
                          <div className="event-details mb-0">
                            <div className="d-flex mb-1">
                              <div className="flex-grow-1 d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                  <i className=" ri-profile-line text-muted fs-16"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <h6
                                    className="d-block fw-semibold mb-0"
                                    id="event-title-tag"
                                  >
                                    {event.title}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="flex-grow-1 d-flex align-items-center">
                                <div className="flex-shrink-0 me-3">
                                  <i className="ri-calendar-event-line text-muted fs-16"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <h6
                                    className="d-block mb-0"
                                    id="event-start-date-tag"
                                  >
                                    {event ?
                                      prettyDate(event.start, true, true) :
                                      ""
                                    }
                                  </h6>
                                </div>
                              </div>
                            </div>
                            {event.description &&
                              <div className="d-flex mt-1">
                                <div className="flex-shrink-0 me-3">
                                  <i className="ri-discuss-line text-muted fs-16"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p
                                    className="d-block text-muted mb-0"
                                    id="event-description-tag"
                                  >
                                    {event ? event.description : "No Description"}
                                  </p>
                                </div>
                              </div>
                            }
                            {event?.users?.length > 0 &&
                              <div className="d-flex mt-3">
                                <div className="flex-shrink-0 me-3">
                                  <i className="ri-user-line text-muted fs-16"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p
                                    className="d-block text-muted mb-0"
                                    id="event-description-tag"
                                  >
                                    {event.users.map((user, idx) => {
                                      return <span key={idx} className="hstack justify-content-start">
                                        <Link to={`/customers/show/${user.id}`}>#{user.id} {user.name}</Link>
                                        <Link to={`/customers/show/${user.id}`} target="_blank" className="ms-1">
                                      <i className="ri-share-box-line text-muted fs-12"></i>
                                    </Link>
                                  </span>
                                    })}
                                  </p>
                                </div>
                              </div>
                            }
                            {event?.employee &&
                              <div className="d-flex mt-1">
                                <div className="flex-shrink-0 me-3">
                                  <i className="ri-user-2-line text-muted fs-16"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p
                                    className="d-block text-muted mb-0"
                                    id="event-description-tag"
                                  >
                                  <span className="hstack justify-content-start">
                                    <Link to={`/employees/show/${event.employee.id}`}>
                                      #{event.employee.id} {event.employee.name}
                                    </Link>
                                    <Link to={`/employees/show/${event.employee.id}`} target="_blank" className="ms-1">
                                      <i className="ri-share-box-line text-muted fs-12"></i>
                                    </Link>
                                  </span>
                                  </p>
                                </div>
                              </div>
                            }
                          </div>
                        }
                      </CardBody>
                    </Card>
                  </div>
                </div>
              )) :
              <div className="card fs-16 py-3 text-center">
                The employee currently has no upcoming events
              </div>
            }
          </div>
        </SimpleBar>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default CalendarEventsCanvas
