import React, {useEffect, useMemo, useRef, useState} from 'react'
import BaseTable from "../../Components/Tables/BaseTable"
import {Link} from "react-router-dom";
import {prettyDate} from "../../helpers/utils";
import {exportData} from "../../api/import";

const ImportTable = () => {
  const baseTableMethodsRef = useRef(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowIds, setSelectedRowIds] = useState([])

  const selectedRowsHandler = (row) => {
    setSelectedRows(row)
    setSelectedRowIds(row.map((row) => row.id))
  }

  const exportHandler = (id, filename) => {
    if (confirm('Do you want to download this file?')) {
      exportData(id)
        .then(r => {
          const url = window.URL.createObjectURL(new Blob([r]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename + '-import.csv')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          updateTable()
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }

  const columns = useMemo(() => [
    {
      id: 'id',
      name: <span className='font-weight-bold fs-14'>Import ID</span>,
      selector: row => <span>{row.id}</span>,
      omit: false
    },
    {
      id: 'file_name',
      name: <span className='font-weight-bold fs-14'>File name</span>,
      selector: row => row.file_name,
      omit: false
    },
    {
      id: 'imported_by',
      name: <span className='font-weight-bold fs-14'>Was imported by</span>,
      selector: row => <Link to={`/employees/show/${row.user_id}`}>ID: #{row.user_id}</Link>,
      omit: false
    },
    {
      id: 'failed_counts',
      name: <span className='font-weight-bold fs-14'>Failed counts</span>,
      selector: row => row.failed_count ? row.failed_count : '-',
      omit: false
    },
    {
      id: 'import_date',
      name: <span className='font-weight-bold fs-14'>Downloaded date</span>,
      selector: row => prettyDate(row.created_at),
      omit: false
    },
    {
      id: "actions",
      name: <span className='font-weight-bold fs-14'>Actions</span>,
      selector: row => (
        <>
          <div className="hstack gap-3 flex-wrap">
            <Link to="#" onClick={() => {exportHandler(row.id, row.file_name)}} className="link-success fs-15">
              <i className="ri-download-line"></i>
            </Link>
          </div>
        </>
      ),
      omit: false
    }
  ], [])

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  return (
    <BaseTable
      columns={columns}
      filterFields={[]}
      title="Imports"
      selectedRowsHandler={selectedRowsHandler}
      methodsRef={baseTableMethodsRef}
      url="/spa/import/data"
    />
  )
}

export default ImportTable
