import Select from "react-select"
import React, {useEffect, useState} from "react"
import {Input, Label} from "reactstrap";

function CustomInput({label, value, attrs}) {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (value) {
      setInputValue(value)
    }
  }, [value])

  return (
    <div className="form-group mb-3">
      <Label className="form-label mb-0">{label}</Label>
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        {...attrs}
        required={true}
        autoComplete="off"
      />
    </div>
  )
}

export default CustomInput
