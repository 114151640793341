import React, {useContext, useEffect, useState} from 'react'
import {Button, Form, Input, Label} from "reactstrap"
import {ModalContext} from "../../../contexts"
import Select from "react-select"
import {createVoipAccount} from "../../../api/settings";
import {toast} from "react-toastify";

const AddProviderAccount = ({providersList, updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [options, setOptions] = useState(null)
  const [accountsFields, setAccountFields] = useState([])

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    createVoipAccount(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        if (typeof r.errors === 'object') {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        } else {
          toast.error(r.message)
        }
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  useEffect(() => {
    if (providersList?.length > 0) {
      setOptions(providersList)
    }
  }, [providersList])

  useEffect(() => {
    let fields = []

    if (selectedProvider?.settings && selectedProvider?.settings?.account_fields.length > 0) {
      fields = selectedProvider.settings.account_fields.map((item, idx) => {
        return <div className="form-group border-0 mb-3" key={idx}>
          <Label className="form-label mb-0">{item.title}</Label>
          <Input
            type={item.type}
            name={item.name}
            placeholder={item.placeholder}
            autoComplete="false"
            minLength='2'
          />
        </div>
      })
    }

    setAccountFields(fields)
  }, [selectedProvider])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group border-0 mb-3">
          <Label className="form-label mb-0">Select Provider<sup className="text-danger">*</sup></Label>
          <Select
            name='provider_id'
            placeholder="Select provider"
            required
            value={selectedProvider}
            options={options}
            onChange={selected => setSelectedProvider(selected)}
          />
        </div>
        {accountsFields && accountsFields}
        <div className="d-flex justify-content-between mt-4">
          <Button type="btn" color="warning" className="btn" onClick={closeModal}>Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default AddProviderAccount
