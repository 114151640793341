import React, {useEffect, useState, useContext} from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent, Table,
  TabPane
} from 'reactstrap'
import classnames from "classnames"
import {Link, useParams} from "react-router-dom"
import PersonalDetails from "./PersonalDetails"
import ChangePasswordForm from "./ChangePasswordForm"
import {getProfile} from "../../../api/employees"
import {capitalize} from "lodash"
import PermissionsTab from "./PermissionsTab"
import SchedulePinCodeTab from "./SchedulePinCodeTab"
import {prettyDate} from "../../../helpers/utils"
import {useUserHasRole} from "../../../Components/Hooks/UserHooks"
import CalendarEventsCanvas from "./Components/CalendarEventsCanvas"
import {ModalContext} from "../../../contexts"
import VoipEditModal from './Components/VoipEditModal'

//import images
import profileBg from '../../../assets/images/galaxy/img-3.png'
import defaultAvatar from '../../../assets/images/users/default_avatar.png'

const EmployeeProfile = () => {
  const [activeTab, setActiveTab] = useState("personal_details")
  const {id} = useParams()
  const {openModal} = useContext(ModalContext)
  const [profileData, setProfileData] = useState({})
  const [disabledDates, setDisabledDates] = useState()
  const [isPinCodeDisabled, setIsPinCodeDisabled] = useState()
  const [isPinCodeSendingDisabled, setIsPinCodeSendingDisabled] = useState()
  const [isInfoDetails, setIsInfoDetails] = useState(false)
  const [voipAccounts, setVoipAccounts] = useState([])

  // For VOIP
  /*const extensionList = [
    {label: '_Cfr4'},
    {label: 'GFR-98'},
    {label: 'arG_ti02'},
    {label: 'arG_256'},
  ]*/

  // Permissions
  const superAdminRolePermissions = useUserHasRole(['super_admin'])

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const toggleCalendarEvents = () => {
    setIsInfoDetails(!isInfoDetails)
  }

  const openVoipEditModalHandler = () => {
    openModal({
      title: "Edit VoIP",
      content: <VoipEditModal employeeId={id} voipAccount={voipAccounts} updateProfile={getProfileData}/>
    })
  }

  const getProfileData = () => {
    getProfile(id).then(r => {
      setProfileData(r)
      setDisabledDates(r.disabled_dates)
      setIsPinCodeDisabled(r.user.profile.settings?.is_pin_code_disabled)
      setIsPinCodeSendingDisabled(r.user.profile.settings?.is_auto_sending_enabled)
      setVoipAccounts(r.voip_accounts)
    })
  }

  useEffect(() => {
    getProfileData()
  }, [])

  document.title = 'Employee Profile | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              <img src={profileBg} className="profile-wid-img" alt=""/>
            </div>
          </div>
          <Row>
            <Col xxl={3} xl={4}>
              <Card className="mt-n5">
                <CardBody>
                  <div className="text-center mb-4 mt-4">
                    <div className="position-absolute top-0 end-0 mt-3 me-3">
                      <Link
                        to="#"
                        className="badge bg-light text-primary d-flex align-items-center fs-12"
                        onClick={toggleCalendarEvents}
                      >
                        <i className="mdi mdi-calendar-account-outline pe-1 fs-14 align-bottom"></i>
                        Calendar events
                      </Link>
                    </div>
                    <div className="profile-user position-relative mt-1 d-inline-block mx-auto">
                      <img
                        src={defaultAvatar}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                      {/*<div className="avatar-xs p-0 rounded-circle profile-photo-edit">*/}
                      {/*  <Input*/}
                      {/*    name="employee_avatar_img"*/}
                      {/*    id="profile-img-file-input"*/}
                      {/*    type="file"*/}
                      {/*    className="profile-img-file-input"*/}
                      {/*    onChange={changeAvatarHandler}*/}
                      {/*  />*/}
                      {/*  <Label*/}
                      {/*    htmlFor="profile-img-file-input"*/}
                      {/*    className="profile-photo-edit avatar-xs"*/}
                      {/*  >*/}
                      {/*    <span className="avatar-title rounded-circle bg-light text-body">*/}
                      {/*      <i className="ri-camera-fill"></i>*/}
                      {/*    </span>*/}
                      {/*  </Label>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                  <div className="table-responsive overflow-visible">
                    <Table className="table-borderless mb-0">
                      <tbody>
                      <tr>
                        <th className="ps-0 text-nowrap" scope="row">Full Name :</th>
                        <td className="text-muted">
                            <span>
                              {profileData.user?.name}
                            </span>
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-nowrap align-middle" scope="row">Role :</th>
                        <td className="text-muted">
                            <span>
                              {capitalize(profileData.role)}
                            </span>
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-nowrap" scope="row">Departments :</th>
                        <td className="text-muted">
                          {profileData.departments?.length > 0 &&
                            profileData.departments.map((item, idx) => (
                              <React.Fragment key={idx}>
                                    <span>
                                      ID {item.id} | {item.name}
                                    </span> <br/>
                              </React.Fragment>
                            ))
                          }
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-nowrap" scope="row">Teams :</th>
                        <td className="text-muted">
                          {profileData.teams?.length > 0 &&
                            profileData.teams.map((item, idx) => (
                              <React.Fragment key={idx}>
                                    <span>
                                      ID {item.id} | {item.name}
                                    </span> <br/>
                              </React.Fragment>
                            ))
                          }
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              {/*   VOIP   */}
              {import.meta.env.VITE_VOIP_ENABLED && (
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <h5 className="fw-semibold mb-0">VoIP</h5>
                      <Link
                        to="#"
                        className="badge bg-light text-primary d-flex align-items-center fs-12"
                        onClick={openVoipEditModalHandler}
                      >
                        <i className="ri-settings-3-line pe-1 fs-14 align-bottom"></i>
                        Edit
                      </Link>
                    </div>
                    <div className="table-responsive overflow-visible">
                      <Table className="table-borderless mb-0">
                        <tbody>
                        <tr className="p-1">
                          <th className="ps-0 text-nowrap" scope="row">Accounts :</th>
                          <td className="text-muted">
                            {voipAccounts?.length > 0 ? voipAccounts.map((item, idx) =>
                                <span key={idx} className="badge badge-soft-primary me-1 fs-10">{item.name}</span>
                              ) :
                              <span>No data</span>
                            }
                          </td>
                        </tr>
                        {/*<tr>
                          <th className="ps-0 text-nowrap" scope="row">Extensions :</th>
                          <td className="tex">
                            {extensionList.map((item, idx) => (
                              <span key={idx} className="badge badge-soft-primary me-1 fs-10">
                              {item.label}
                            </span>
                            ))}
                          </td>
                        </tr>*/}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              )}

              {/*   Create and last update dates  */}
              <Card>
                <CardBody>
                  <div className="table-responsive overflow-visible">
                    <Table className="table-borderless mb-0">
                      <tbody>
                      <tr className="p-1">
                        <th className="ps-0 text-nowrap" scope="row">Created at :</th>
                        <td className="text-muted">
                              <span>
                                {prettyDate(profileData.user?.created_at)}
                              </span>
                        </td>
                      </tr>
                      <tr>
                        <th className="ps-0 text-nowrap" scope="row">Last updated :</th>
                        <td className="text-muted">
                              <span>
                                {prettyDate(profileData.user?.updated_at)}
                              </span>
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9} xl={8}>
              <Card className="mt-xl-n5">

                <CardHeader>
                  <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                       role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "personal_details"})}
                        type="button"
                        to="#"
                        onClick={() => {
                          tabChange("personal_details")
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Personal Details
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({active: activeTab === "change_password"})}
                        type="button"
                        to="#"
                        onClick={() => {
                          tabChange("change_password")
                        }}
                      >
                        <i className="far fa-user"></i>
                        Change Password
                      </NavLink>
                    </NavItem>

                    {(superAdminRolePermissions && profileData.role !== 'affiliate') &&
                      <NavItem>
                        <NavLink
                          className={classnames({active: activeTab === "permissions"})}
                          type="button"
                          to="#"
                          onClick={() => {
                            tabChange("permissions")
                          }}
                        >
                          <i className="far fa-envelope"></i>
                          Permissions
                        </NavLink>
                      </NavItem>
                    }

                    {profileData.role === 'agent' &&
                      <NavItem>
                        <NavLink
                          className={classnames({active: activeTab === "schedule_pin_code"})}
                          type="button"
                          to="#"
                          onClick={() => {
                            tabChange("schedule_pin_code")
                          }}
                        >
                          <i className="far fa-envelope"></i>
                          Schedule pin code
                        </NavLink>
                      </NavItem>
                    }

                  </Nav>
                </CardHeader>

                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="personal_details">
                      <PersonalDetails id={id} response={profileData} updateProfile={getProfileData}/>
                    </TabPane>

                    <TabPane tabId="change_password">
                      <ChangePasswordForm id={id}/>
                    </TabPane>

                    <TabPane tabId="permissions">
                      <PermissionsTab
                        id={id}
                        activeTab={activeTab}
                      />
                    </TabPane>

                    {profileData.role === 'agent' &&
                      <TabPane tabId="schedule_pin_code">
                        <SchedulePinCodeTab
                          id={id}
                          disabledDates={disabledDates}
                          updateTable={getProfileData}
                          isPinCodeDisabled={isPinCodeDisabled}
                          isPinSendingDisabled={isPinCodeSendingDisabled}
                        />
                      </TabPane>}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <CalendarEventsCanvas
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(!isInfoDetails)}
        id={id}
      />
    </React.Fragment>
  )
}

export default EmployeeProfile
