import React, {useContext, useState} from 'react'
import {Button, Form, Input} from "reactstrap"
import {ModalContext} from "../../../contexts"
import {updateVoipAccount} from "../../../api/settings"
import {toast} from "react-toastify"

const EditProviderAccount = ({account, updateTable}) => {
  const [editAccount, setEditAccount] = useState(account)
  const {closeModal} = useContext(ModalContext)

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    data = {...data, provider_id: account.provider_id}

    updateVoipAccount(account.value, data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group border-0">
          <Input
            type='input'
            name='name'
            value={editAccount.label}
            onChange={(e) => setEditAccount({...editAccount, label: e.target.value})}
          />
        </div>
        <div className="d-flex justify-content-between mt-4">
          <Button type="btn" color="warning" className="btn" onClick={closeModal}>Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default EditProviderAccount
