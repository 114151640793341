import {Button, Col, Form, FormGroup, Input, Label} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../../../contexts"
import {createStatusGroup} from "../../../../../api/settings"
import {toast} from "react-toastify"
import classnames from "classnames"
import {Tooltip} from "react-tooltip";

const Add = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [isFieldVisible, setIsFieldVisible] = useState(false)
  const [addedFields, setAddedFields] = useState([])
  const [checked, setChecked] = useState()
  const [switchChecked, setSwitchChecked] = useState()

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())
    data.fields = addedFields.map(i => i.name)

    createStatusGroup(data).then(r => {
      closeModal()
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(error => {
            toast.error(error)
          })
        })
      }
    })
  }

  const addFieldHandler = () => {
    if (!isFieldVisible) {
      setAddedFields([...addedFields, {name: ''}])
    }
  }

  const fieldsChangeHandler = (index, value) => {
    const updatedFields = [...addedFields]
    updatedFields[index]['name'] = value
    setAddedFields(updatedFields)
  }

  const removeFieldHandler = (index) => {
    const updatedFields = addedFields.filter((_, i) => i !== index)
    setAddedFields(updatedFields)
  }

  useEffect(() => {
    addFieldHandler()
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Enter Group Name</Label>
          <Input name="statuses_group_name" placeholder="Statuses group name" tabIndex={1}/>
        </div>
        <div className="d-flex flex-column">
          {addedFields.map((field, idx) => (
            <div className="d-flex align-items-center justify-content-between" key={idx}>
              {idx === 0 ? null : (
                isNaN(field.id) ? (
                  <div className="mb-3 me-3">
                    <Button
                      type="button"
                      onClick={() => removeFieldHandler(idx, field.id)}
                      className="btn btn-danger"
                    >
                      X
                    </Button>
                  </div>
                ) : null
              )}
              <div className="w-100">
                <FormGroup>
                  <Input
                    value={field.name}
                    onChange={e => fieldsChangeHandler(idx, e.target.value)}
                    placeholder={idx === 0 ? "Default status name" : "Status name"}
                    tabIndex={idx + 2}
                  />
                </FormGroup>
              </div>
            </div>
          ))}
        </div>
        <div>
          <hr className="w-100 px-0 mt-0"/>
          <Button type="button" color="primary" className="btn mb-3" onClick={addFieldHandler}>Add Field</Button>
        </div>
        {/*<div className="form-check form-switch form-switch-left form-switch-sm mt-3 mb-3">
          <Input
            className="form-check-input"
            name="is_default"
            type="checkbox"
          />
          <Label className="form-check-label text-muted">
            Default
          </Label>
        </div>*/}
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default Add
