import {Button, Form, Label} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {createAsset, getAssets} from "../../../api/settings"
import {toast} from "react-toastify"
import Select from "react-select";

const AddAssets = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [assets, setAssets] = useState([])

  const getAssetsData = () => {
    getAssets().then((r) => {
      setAssets(r)
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())
    let {currency_id} = data

    createAsset({currency_id}).then(res => {
      closeModal()
      let message = res?.message || 'Empty response message.'

      if (res.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  useEffect(() => {
    getAssetsData()
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Add asset</Label>
          <Select
            name="currency_id"
            placeholder="Add asset"
            required
            options={assets}
          >
          </Select>
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default AddAssets
