import React, {useContext} from "react";
import {ModalContext} from "../../../../../contexts"
import {Button, Table} from "reactstrap"

const Show = ({statusGroup}) => {
  const {closeModal} = useContext(ModalContext)
  const {name, statuses, is_default} = statusGroup

  return (
    <>
      <div className="d-flex justify-content-center mt-3 mb-3">
        <h3 className="text-info font-weight-bold">Status Group Name: {name}</h3>
      </div>
      <div className="table-responsive">
        <Table className="table-borderless table-nowrap">
          <thead>
          <tr className="border-bottom">
            <th className="col-1" scope="col">ID</th>
            <th className="col-11" scope="col">STATUS NAME</th>
          </tr>
          </thead>
          <tbody>
          {statuses.length ?
            statuses.map((status, idx) => {
              return (
                <tr className="border-bottom" key={idx}>
                  <td>{status.id}</td>
                  <td>{(status.name).capitalize()}</td>
                </tr>
              )
            })
            :
            (<tr>
              <td colSpan={3} className='text-center'>No Data</td>
            </tr>)}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-center mt-3 mb-3">
        <h3 className="text-info font-weight-bold">Default: {is_default === 1 ? 'Yes' : 'No'}</h3>
      </div>
      <div className="hstack justify-content-center">
        <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
      </div>

    </>
  )
}

export default Show
