import {Button, Form, Input, Label} from "reactstrap"
import {useContext, useState} from "react"
import {ModalContext} from "../../../contexts"
import {editRecipient} from "../../../api/settings"
import {toast} from "react-toastify"

const EditRecipient = ({ name, id, settings, onSave }) => {
  const {closeModal} = useContext(ModalContext)
  const [recipientName, setRecipientName] = useState(name)
  const [recipientSettings, setRecipientSettings] = useState(settings)

  const recipientNameChangeHandler = (e) => {
    setRecipientName(e.target.value)
  }

  const recipientSettingsChangeHandler = (e) => {
    setRecipientSettings(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const data = {
      recipient_id: id,
      recipient_name: recipientName,
      recipient_settings: recipientSettings,
    }

    editRecipient(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        onSave()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Name</Label>
          <Input
            placeholder="Name"
            autoComplete="off"
            required={true}
            value={recipientName}
            onChange={recipientNameChangeHandler}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Settings</Label>
          <Input
            placeholder="Settings"
            autoComplete="off"
            type="textarea"
            rows="4"
            required={true}
            value={JSON.stringify(recipientSettings)}
            onChange={recipientSettingsChangeHandler}
          />
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default EditRecipient
