import { createSlice } from "@reduxjs/toolkit"

import { getEvents, addNewEvent, updateEvent, deleteEvent, getCategories, getUpcomingEvent ,resetCalendar} from './thunk'

export const initialState = {
  events: [],
  categories: [],
  upcomingEvents: [],
  error: {}
}

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GetEvents
    builder.addCase(getEvents.fulfilled, (state, action) => {
      state.events = action.payload ?? []
    })
    builder.addCase(getEvents.rejected, (state, action) => {
      state.error = action.payload.error || null
    })
    // AddNewEvent
    builder.addCase(addNewEvent.fulfilled, (state, action) => {
      state.events = action.payload
    })
    builder.addCase(addNewEvent.rejected, (state, action) => {
      state.error = action.payload.error || null
    })
    // UpdateEvent
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      state.events = action.payload
    })
    builder.addCase(updateEvent.rejected, (state, action) => {
      state.error = action.payload.error || null
    })
    // DeleteEvent
    builder.addCase(deleteEvent.fulfilled, (state, action) => {
      state.events = action.payload
    })
    builder.addCase(deleteEvent.rejected, (state, action) => {
      state.error = action.payload.error || null
    })
    // GetCategories
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload
    })
    builder.addCase(getCategories.rejected, (state, action) => {
      state.error = action.payload.error || null
    })
    // GetUpcomingEvents
    builder.addCase(getUpcomingEvent.fulfilled, (state, action) => {
      state.upcommingevents = action.payload
    })
    builder.addCase(getUpcomingEvent.rejected, (state, action) => {
      state.error = action.payload.error || null
    })
    // ResetCalendar
    builder.addCase(resetCalendar.fulfilled, (state, action) => {
      let flag = action.payload.flag
      let value = action.payload.value
      let flags = {}
      flags[flag] = value

      // state.flags = action.payload
    })
    builder.addCase(resetCalendar.rejected, (state, action) => {
      state.error = action.payload.error || null
    })

  },
})

export default calendarSlice.reducer
