import React, {useEffect, useState} from 'react'
import {Button, Col, Dropdown, DropdownMenu, DropdownToggle, Row} from 'reactstrap'
import {Link} from 'react-router-dom'
import SimpleBar from "simplebar-react"
import {readAllNotification, toggleNotification} from "../../api/notifications"
import classnames from "classnames"
import {Tooltip} from "react-tooltip"
import {useDispatch, useSelector} from "react-redux"
import {getNotifications} from "../../slices/notification/thunk"
import {setNotifications} from "../../slices/notification/reducer"
import {toast} from "react-toastify";

const NotificationDropdown = () => {
  const dispatch = useDispatch()
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false)
  const notifications = useSelector(s => s.Notification.notifications)
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)

  const getNotificationsData = () => {
    getNotifications().then(r => {
      dispatch(setNotifications(r))
    })
  }

  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown)
  }

  const markAsRead = ids => {
    toggleNotification(ids).then(r => {
      if (r.success) {
        dispatch(setNotifications(r))
      } else {
        toast.error(r.message)
      }
    })
  }

  const markAllAsRead = () => {
    readAllNotification().then(r => {
      if (r.success) {
        dispatch(setNotifications(r))
      }
    })
  }

  useEffect(() => {
    setUnreadNotificationsCount(notifications.reduce((a, v) => (v?.read_at ? a : ++a), 0))
  }, [notifications])

  // Notifications data
  useEffect(() => {
    getNotificationsData()
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className='bx bx-bell fs-22'></i>
          {unreadNotificationsCount > 0 &&
            <>
              <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                {unreadNotificationsCount} <span className="visually-hidden">unread messages</span>
              </span>
            </>
          }
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <Link to="/notifications"><h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6></Link>
                </Col>
                <div className="col-auto dropdown-tabs">
                  <Button onClick={markAllAsRead} className="btn-sm btn-info">Mark all as read</Button>
                </div>
              </Row>
            </div>
          </div>
          <SimpleBar style={{maxHeight: "300px"}} className="pe-2">
            {notifications.length > 0 ?
              notifications.map((notification, idx) =>
                <div
                  className={classnames(
                    'text-reset',
                    'notification-item',
                    'd-block',
                    'dropdown-item',
                    'position-relative',
                    {active: !!notification.read_at}
                  )}
                  key={idx}
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      {
                        notification.data.icon ?
                          <>
                            <span
                              className={`avatar-title bg-soft-${notification.data.color ? notification.data.color : 'danger'}
                            text-${notification.data.color ? notification.data.color : 'danger'} rounded-circle fs-16`}>
                              <i className={`${notification.data.icon}`}></i>
                            </span>
                          </> :
                          <span className="avatar-title bg-soft-danger text-danger rounded-circle fs-16">
                            <i className='bx bx-message-square-dots'></i>
                          </span>
                      }
                    </div>
                    <div className="flex-1">
                      <Link to="#" className="stretched-link">
                        <h6 className="mt-0 mb-1 fs-13 lh-base">
                          {notification.data.title}
                        </h6>
                        <div className="fs-13 text-muted">
                          <p className="mb-2">{notification.data?.sub_title}</p>
                        </div>
                      </Link>
                      <p className="mb-0 fs-10 fw-medium text-uppercase text-muted">
                        <span><i className="mdi mdi-clock-outline"></i> {notification.past_time}</span>
                      </p>
                    </div>
                    <div className="px-2 fs-15">
                      <div className="form-check notification-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={!!notification.read_at}
                          onChange={() => markAsRead([notification.id])}
                          data-tooltip-id="mark_as_read"
                          data-tooltip-content="Mark as read"
                        />
                        <label className="form-check-label"></label>
                        <Tooltip id="mark_as_read"/>
                      </div>
                    </div>
                  </div>
                </div>
              ) : <div className="fw-bold text-center my-3">All notifications is read!</div>}
            <div className="my-3 text-center">
              <Link to="/notifications" type="button" className="btn btn-soft-success waves-effect waves-light">
                View All Notifications <i className="ri-arrow-right-line align-middle"></i>
              </Link>
            </div>
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown
