import {APIClient} from "../helpers/api_helper"

const api = new APIClient

export const getTicket = (id) => {
    return api.get(`/spa/tickets/show/${id}`, {})
}

export const getTickets = (data = {page: 1}) => {
  return api.get(`/spa/tickets/chat-data/`, data)
}

export const commentTicket = (ticketId, data) => {
    return api.create(`/spa/tickets/comment/create/${ticketId}`, data)
}

export const closeTicket = (id) => {
    return api.create(`/spa/tickets/close/${id}`)
}

export const editCommentTicket = (id, data) => {
    return api.post(`/spa/tickets/comment/edit/${id}`, data)
}

export function deleteCommentTicket(id) {
    return api.post(`/spa/tickets/comment/delete/${id}`, {})
}

export function navUnreadTicket(data) {
  return api.get(`/spa/tickets/navigate/`, data)
}
