import {Button, Col, Form, Label, Row} from "reactstrap"
import Select from "react-select"
import React, {useContext, useEffect, useState} from "react"
import {selectListData} from "../../../api/general"
import {ModalContext} from "../../../contexts"
import {updateInvoiceStatus} from "../../../api/invoices"
import {toast} from "react-toastify"

const EditInvoice = ({id, status, updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [statusOptions, setStatusOptions] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let data = {
      invoice_id: id,
      status: selectedStatus.value,
    }

    updateInvoiceStatus(data).then((r) => {
        let message = r?.message || 'Empty response message.'

        if (r.success) {
          toast.success(r.message)
          closeModal()
          updateTable()
          btn.disabled = false
        } else {
          toast.error(message)
          btn.disabled = false
        }
      })
  }

  useEffect(() => {
    selectListData('invoice_status').then(r => {
      setStatusOptions(r)
      setSelectedStatus(r.find(s => status === s.value))
    })
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12}>
            <Label className="form-label mb-0">Edit Status</Label>
            <Select
              placeholder="Choose..."
              options={statusOptions}
              value={selectedStatus}
              onChange={(selected) => setSelectedStatus(selected)}
            />
          </Col>
          <Col xs={12} className="hstack justify-content-end">
            <Button type="submit" color="primary" className="btn ms-auto mt-4">Submit</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default EditInvoice
