import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import React, {useContext, useEffect, useReducer} from "react"
import {ModalContext} from "../../../../contexts"
import {editProfile} from "../../../../api/customers"
import {toast} from "react-toastify"
import {selectListData} from "../../../../api/general"
import FormSelect from "./Components/FormSelect"
import {useUserCan} from "../../../../Components/Hooks/UserHooks"

function fieldsReducer(fields, action) {
  switch (action.type) {
    case 'fill_options': {
      return fields.map(i => {
        if (i.name === action.name) {
          return {...i, attrs: {...i.attrs, options: action.options}}
        }
        return i
      })
    }
    case 'fill_value': {
      return fields.map(i => {
        if (i.name === action.name) {
          return {
            ...i,
            value: i.attrs.options.find(i => i.label === action.value),
          }
        }
        return i
      })
    }
    default: {
      return fields
    }
  }
}

const fields = [
  {name: 'first_name', label: 'First Name', type: 'text'},
  {name: 'last_name', label: 'Last Name', type: 'text'},
  {name: 'email', label: 'Email', type: 'text'},
  {name: 'phone', label: 'Phone', type: 'text'},
  {name: 'country', label: 'Country', type: 'select'},
  {name: 'state', label: 'Canton', type: 'text'},
  {name: 'city', label: 'City', type: 'text'},
  {name: 'address', label: 'Address', type: 'text'},
  {name: 'postal_code', label: 'Postal code', type: 'text'},
  {name: 'source', label: 'Source', type: 'text'},
  {name: 'details', label: 'Details', type: 'textarea'}
]

const EditProfile = ({profileData, updateProfile}) => {
  const {closeModal} = useContext(ModalContext)
  const [formFields, formFieldsReducer] = useReducer(fieldsReducer, fields)

  // Permissions
  const canChangeSource = useUserCan('change_source')

  const generateInput = (field, idx) => {
    switch (field.type) {
      case 'textarea': {
        return <Col xs={12} className="mb-3" key={idx}>
          <Label className="form-label mb-0">{field.label}</Label>
          <textarea
            rows={4}
            className="form-control"
            name={field.name}
            placeholder={field.label}
            defaultValue={profileData[field.name]}
            {...field.attrs}
          >
          </textarea>
        </Col>
      }
      case 'select': {
        return <Col md={6} className="mb-3" key={idx}>
          <Label className="form-label mb-0">{field.label}</Label>
          <FormSelect
            name={field.name}
            label={field.label}
            value={field.value}
            extraAttrs={field.attrs}
          />
        </Col>
      }
      default: {
        return <Col md={6} key={idx}>
          <div className="form-group mb-3">
            <Label className={`form-label mb-0 ${(!canChangeSource && (field.name === 'source')) && 'd-none'}`}>
              {field.label}
            </Label>
            <Input
              type={field.type}
              className={`form-control ${(!canChangeSource && (field.name === 'source')) && 'd-none'}`}
              name={field.name}
              placeholder={field.label}
              autoComplete="off"
              defaultValue={profileData[field.name]}
            />
          </div>
        </Col>
      }
    }
  }

  const submitHandler = e => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData.entries())

    editProfile(profileData.id, data).then(r => {
      if (r.success) {
        btn.disabled = false
        closeModal()
        updateProfile()
        toast.success(r.message)
      } else {
        btn.disabled = false

        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        }
      }
    })
  }

  useEffect(() => {
    selectListData('countries').then(r => {
      formFieldsReducer({
        type: 'fill_options',
        name: 'country',
        options: r
      })

      formFieldsReducer({
        type: 'fill_value',
        name: 'country',
        value: profileData.customerCountry
      })
    })
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          {profileData && formFields.map((field, idx) => {
            return generateInput(field, idx)
          })
          }
        </Row>
        <Row>
          <div>
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" color="primary" className="btn">Submit</Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export default EditProfile
