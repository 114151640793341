import React, {useContext, useEffect, useState} from 'react'
import {ModalContext} from "../../../../contexts"
import {Button, Col, Row, Table} from "reactstrap"
import _, {capitalize} from "lodash"
import {cryptoIconsMapper, prettyDate} from "../../../../helpers/utils"
// import Select from "react-select"
import {selectListData} from "../../../../api/general"
import TransactionBadgeIcon from "../../../../Components/Common/TransactionBadgeIcon";

const SecurityDetails = ({item}) => {
  const {closeModal} = useContext(ModalContext)
  const [itemData, setItemData] = useState([])
  // const [selectedStatus, setSelectedStatus] = useState(null)
  // const [statusOptions, setStatusOptions] = useState([])
  const [isLogoUrlValid, setIsLogoUrlValid] = useState(true)
  const [assetList, setAssetList] = useState([])
  const [assetCode, setAssetCode] = useState('')
  const [pnl, setPnl] = useState(0)
  const [pnlPct, setPnlPct] = useState(0)
  const [dailyChangePct, setDailyChangePct] = useState(0)

  useEffect(() => {
    // selectListData('securities_statuses_list').then(r => {
    //   setStatusOptions(r)
    // })

    selectListData('all_assets').then(r => {
      setAssetList(r)
    })
  }, [])

  useEffect(() => {
    if (!_.isEmpty(assetList)) {
      setAssetCode(assetList.find(i => i.value === item.security?.asset_id)?.code || '')
    }
  }, [assetList])

  useEffect(() => {
    let pnl =
      _.subtract(_.multiply(item.quantity, item.security?.price), Number(-item.transfers_sum)).toFixed(2)

    setPnlPct(_.multiply(_.divide(Number(pnl), Number(-item.transfers_sum)), 100))
    setPnl(Number(pnl))
    setDailyChangePct(Number(item.security?.data?.session?.change_percent))
  }, [item])

  return (
    <div className="d-flex flex-column border-top pt-3">
      <Row className='d-flex align-items-stretch'>
        <Col className='col-12 col-xl-6 details_column'>
          <h4>Security details:</h4>
          <Table responsive className="table table-striped">
            <tbody>
            <tr>
              <td className="text-nowrap fw-bold py-2">Name:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                <div className="d-flex align-items-center">
                  <div className="avatar-xxs me-2">
                    {(isLogoUrlValid && item.security?.logo) ?
                      <img
                        src={item.security?.logo}
                        className="img-fluid rounded-circle h-100"
                        alt='#logo'
                        onError={() => {
                          setIsLogoUrlValid(false)
                        }}
                      />
                      :
                      <div className="avatar-title bg-soft-primary rounded-circle text-primary">
                        {_.capitalize(item.security?.name?.slice(0, 1))}
                      </div>
                    }
                  </div>
                  <div className="text-truncate">{item.security?.name}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-nowrap fw-bold py-2">Current price:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                {Number(item.security?.price).toFixed(2)} {assetCode}
              </td>
            </tr>
            <tr>
              <td className="text-nowrap fw-bold py-2">Quantity:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                {item.quantity}
              </td>
            </tr>
            <tr>
              <td className="text-nowrap fw-bold py-2">Type:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                {capitalize(item.security.type)}
              </td>
            </tr>
            {item.security?.data?.ticker ?
              <tr>
                <td className="text-nowrap fw-bold py-2">Ticker:</td>
                <td className="text-nowrap text-truncate fw-semibold py-2">
                  {item.security?.data?.ticker}
                </td>
              </tr> : null
            }
            {item.security?.type !== 'bond' ?
              <tr>
                <td className="text-nowrap fw-bold py-2">Daily change (%):</td>
                <td className="text-nowrap text-truncate fw-semibold py-2">
                <span
                  className={`badge badge-soft-${dailyChangePct > 0 ? 'success' : (dailyChangePct < 0 ? 'danger' : 'primary')} fs-12`}>
                  {dailyChangePct > 0 ? '+' : ''}{dailyChangePct}%
                </span>
                </td>
              </tr> :
              null
            }
            <tr>
              <td className="text-nowrap fw-bold py-2">Total investments:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                {Number(-item.transfers_sum).toFixed(2)} {assetCode}
              </td>
            </tr>
            <tr>
              <td className="text-nowrap fw-bold py-2">PNL:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                <span className={`badge badge-soft-${pnl > 0 ? 'success' : (pnl < 0 ? 'danger' : 'primary')} fs-12`}>
                  {pnl > 0 ? '+' : ''}{pnl} {assetCode}
                </span>
              </td>
            </tr>
            <tr>
              <td className="text-nowrap fw-bold py-2">PNL (%):</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                <span className={`badge badge-soft-${Number(pnlPct) > 0 ? 'success' : (Number(pnlPct) < 0 ? 'danger' : 'primary')} fs-12`}>
                  {Number(pnlPct) > 0 ? '+' : ''}{Number(pnlPct).toFixed(2)}%
                </span>
              </td>
            </tr>
            <tr>
              <td className="text-nowrap fw-bold py-2">Equity:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
              {_.multiply(item.quantity, item.security?.price).toFixed(2)} {assetCode}
              </td>
            </tr>
            <tr>
              <td className="text-nowrap fw-bold py-2">Created at:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                {prettyDate(item.created_at)}
              </td>
            </tr>
            <tr>
              <td className="text-nowrap fw-bold py-2">Lust update:</td>
              <td className="text-nowrap text-truncate fw-semibold py-2">
                {prettyDate(item.updated_at)}
              </td>
            </tr>
            <tr className="text-nowrap">
            </tr>

            </tbody>
          </Table>
        </Col>
        <Col className='col-12 col-xl-6' style={{overflowY: 'auto'}}>
          <h4>Transactions list:</h4>
          <Table responsive className="table table-striped">
            <thead>
              <tr className="fw-bolder fs-12">
                <td>ID</td>
                <td>Quantity</td>
                <td>Type</td>
                <td className='text-nowrap'>Base Asset</td>
                <td className='text-nowrap'>Amount</td>
                <td className='text-nowrap'>USD Amount</td>
                <td className='text-nowrap'>Created at</td>
              </tr>
            </thead>
            <tbody>
            {!_.isEmpty(item.transfers) ?
              item.transfers.map((item, idx) => {
                return (
                  <tr className='text-semibold' key={idx}>
                    <td className='fw-semibold py-2'>#{item.id}</td>
                    <td className='fw-semibold py-2'>{item.meta?.quantity}</td>
                    <td className='py-2'>
                      <TransactionBadgeIcon
                        transactionId={item.id}
                        transactionType={item.type}
                      />
                    </td>
                    <td className='py-2'>
                      <div className="d-flex align-items-center">
                        <div className="avatar-xxs me-2">
                          <img
                            src={cryptoIconsMapper[item?.transactions[0]?.asset?.currency?.code]}
                            className="img-fluid"
                            alt={item?.transactions[0]?.asset?.currency?.code}
                          />
                        </div>
                        <span className='fw-semibold'>{item?.transactions[0]?.asset?.currency?.code}</span>
                      </div>
                    </td>
                    <td className='py-2 fw-semibold'>{item?.transactions[0]?.amount || '-'}</td>
                    <td className='py-2 fw-semibold'>${item?.transactions[0]?.usd_amount || '-'}</td>
                    <td className='py-2 fw-semibold'>{prettyDate(item?.created_at)}</td>
                  </tr>
                )
              })
              :
              <tr className='text-semibold'>
                <td colSpan={6} className='fw-semibold fs-14 text-center'>No data</td>
              </tr>
            }
            </tbody>
          </Table>
        </Col>
      </Row>

      {/*<div className="form-group mb-4 d-flex align-items-center">*/}
      {/*  <span className="mx-3 fw-bold">Status</span>*/}
      {/*  <Select*/}
      {/*    className="w-100"*/}
      {/*    placeholder="Choose..."*/}
      {/*    name="status"*/}
      {/*    value={selectedStatus}*/}
      {/*    onChange={selected => setSelectedStatus(selected)}*/}
      {/*    options={statusOptions}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className="d-flex w-100 justify-content-between">
        <Button type="button" className="btn btn-warning" onClick={closeModal}>Close</Button>
        {/*  <Button type="button" className="btn btn-success" onClick={() => {*/}
        {/*  }}>Save</Button>*/}
      </div>
    </div>
  )
  }

  export default SecurityDetails
