import React from 'react'
import {Table} from "reactstrap";

const ItemsDetailsRowsTable = (props) => {
  const {invoiceData} = props

  return (
    <>
      <Table responsive>
        <thead>
        <tr className="table-active border-bottom">
          <th className="col-6">ITEM</th>
          <th className="col-2 text-center">QUANTITY</th>
          <th className="col-2 text-center">RATE</th>
          <th className="col-2 text-center">AMOUNT</th>
        </tr>
        </thead>
        <tbody>
        {(invoiceData?.items || []).map((row, idx) => (
          <tr key={idx} className="border-bottom">
            <td className="fw-medium">{row.product_description}</td>
            <td className="text-center">{row.quantity}</td>
            <td className="text-center">
              {Number(row.rate).toFixed(2)} {invoiceData.formatted_code}
            </td>
            <td className="text-center table-active">
              {Number(row.amount).toFixed(2)} {invoiceData.formatted_code}
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </>
  )
}

export default ItemsDetailsRowsTable
