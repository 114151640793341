import {createSlice} from "@reduxjs/toolkit"

let defaultInvoicesColumnsSetting
// First part of key depends on title of table title (lowercase)
let storageName = 'invoices_columns_setting'

try {
  defaultInvoicesColumnsSetting = JSON.parse(localStorage.getItem(storageName) ?? {})
} catch (err) {
  defaultInvoicesColumnsSetting = {}
}

const initialState = {
  columnsSetting: defaultInvoicesColumnsSetting,
}

const InvoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setColumnsSetting(state, action) {
      const data = {...state.columnsSetting}
      data[action.payload.id] = {...action.payload.params}

      state.columnsSetting = data
      localStorage.setItem(storageName, JSON.stringify({...data}))
    }
  }
})

export default InvoicesSlice.reducer
export const {setColumnsSetting} = InvoicesSlice.actions
