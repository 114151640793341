import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export function createEmployee(data) {
  return api.post('/spa/create-user', data)
}

export function getProfile(id) {
  return api.get(`/spa/employee/profile/${id}`, {})
}

export function updateEmployee(data) {
  return api.post('/spa/employee/update', data)
}

export function changePassword(data) {
  return api.post('/spa/employee/change-password', data)
}

export function schedulePinCode(data) {
  return api.post('/spa/employee/pin-code/schedule/set', data)
}

export function deleteSchedulePinCode(data) {
  return api.post('/spa/employee/pin-code/schedule/delete', data)
}

export function disablePinCode(data) {
  return api.post('/spa/employee/pin-code/disable', data)
}

export function autoSendingPinCode(data) {
  return api.post('/spa/employee/pin-code/auto-sending', data)
}

export function deleteEmployee(id) {
  return api.post('/spa/employee/delete', {employee_id: id})
}

export function setPinCodes(data) {
  return api.post('/spa/pin-code/set', {employee_ids: data})
}

export function revokePinCodes(data) {
  return api.post('/spa/pin-code/revoke', {employee_ids: data})
}

export function sendToEmails(data) {
  return api.post('/spa/pin-code/send', {employee_ids: data})
}

export function setPinCode(id) {
  return api.post('/spa/employee/pin-code/set', {employee_id: id})
}

export function revokePinCode(id) {
  return api.post('/spa/employee/pin-code/revoke', {employee_id: id})
}

export function updatePermission(data) {
  return api.post('/spa/employee/update-permission', data)
}

export function selectAllDirectPermissions(data) {
  return api.post('/spa/employee/select-all-permissions', data)
}

export function unselectAllDirectPermissions(data) {
  return api.post('/spa/employee/unselect-all-permissions', data)
}

export function attachVoipAccounts(data) {
  return api.post(`/spa/employee/attach-voip-accounts`, data)
}
