import BaseTable from "../../../Components/Tables/BaseTable"
import React, {useContext, useImperativeHandle, useMemo, useRef, useState} from "react"
import {prettyDate} from "../../../helpers/utils"
import {Link} from "react-router-dom"
import {deletePostback, editPostback} from "../../../api/postbacks"
import {toast} from "react-toastify";
import {ModalContext} from "../../../contexts";
import EditPostback from "./Modals/EditPostback";
import {useUserCan} from "../../../Components/Hooks/UserHooks";

const PostbacksTable = ({methodsRef}) => {
  const {openModal} = useContext(ModalContext)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const baseTableMethodsRef = useRef(null)

  // Permissions
  const canEditPostback = useUserCan('edit_postback')
  const canDeletePostback = useUserCan('delete_postback')

  useImperativeHandle(methodsRef, () => ({
    updateTable() {
      baseTableMethodsRef.current.updateTable()
    }
  }))

  const selectedRowsHandler = (rows) => {
    setSelectedRows(rows)
    setSelectedRowIds(rows.map((row) => row.id))
  }

  const editPostbackHandler = (id) => {
    openModal({
      title: "Edit Postback",
      content: <EditPostback id={id} updateTable={baseTableMethodsRef.current.updateTable()}/>
    })
  }

  const deletePostbackHandler = (id) => {
    if (confirm('Do you really want to delete this postback?')) {
      deletePostback(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          baseTableMethodsRef.current.updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>Id</span>,
        selector: row => row.id,
        width: "190px",
        omit: false
      },
      {
        id: "name",
        name: <span className='font-weight-bold fs-14'>Name</span>,
        selector: row => row.name,
        width: "190px",
        omit: false
      },
      {
        id: "type",
        name: <span className='font-weight-bold fs-14'>Type</span>,
        selector: row => row.formatted_type,
        width: "190px",
        omit: false
      },
      {
        id: "url",
        name: <span className='font-weight-bold fs-14'>URL</span>,
        selector: row => row.url,
        width: "300px",
        omit: false
      },
      {
        id: "created_at",
        name: <span className='font-weight-bold fs-14'>Created At</span>,
        selector: row => (prettyDate(row.created_at)),
        width: "190px",
        omit: false
      },
      {
        id: "actions",
        name: <span className='font-weight-bold fs-14'>Actions</span>,
        selector: row => (
          <>
            <div className="hstack gap-3 flex-wrap">
              {canEditPostback &&
              <Link to="#" className="link-success fs-15" onClick={() => {
                editPostbackHandler(row.id)
              }}>
                <i className="ri-edit-2-line"></i>
              </Link>}
              {canDeletePostback &&
              <Link to="#" className="link-danger fs-15" onClick={() => {
                deletePostbackHandler(row.id)
              }}>
                <i className="ri-delete-bin-line"></i>
              </Link>}
            </div>
          </>
        ),
        minWidth: '75px',
        maxWidth: '300px',
        omit: false,
      }
    ], [])

  return (
    <BaseTable
      columns={columns}
      title="Postbacks"
      url="spa/postbacks/data"
      filterFields={[]}
      selectedRowsHandler={selectedRowsHandler}
      methodsRef={baseTableMethodsRef}
    />
  )
}

export default PostbacksTable
