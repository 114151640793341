import {DropdownItem, Input, Label} from "reactstrap"
import React from "react"

const ColumnSettingsDropdownItem = ({column, columnsSetting, toggleColumnForTable}) => {

  return (
    <DropdownItem className="column_setting_input">
      <Input
        id={`checkbox_${column.id}`}
        type="checkbox"
        name={column.id}
        defaultChecked={columnsSetting[column.id]?.isVisible !== false}
        onChange={toggleColumnForTable}
      />
      <Label htmlFor={`checkbox_${column.id}`} className="mb-0 w-100">&nbsp;{column.name}</Label>
    </DropdownItem>
  )
}

export default ColumnSettingsDropdownItem
