import {Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import Select from "react-select"
import {editTeam, getTeam} from "../../../api/settings"
import {toast} from "react-toastify"
import {selectListData} from "../../../api/general";

const EditTeam = ({ id, updateTable }) => {
  const { closeModal } = useContext(ModalContext)
  const [loading, setLoading] = useState(true)
  const [teamName, setTeamName] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [statusGroupsOptions, setStatusGroupsOptions] = useState([])
  const [selectedStatusGroup, setSelectedStatusGroup] = useState('')

  const getTeamData = id => {
    getTeam(id).then(r => {
      setTeamName(r.name)
      setSelectedDepartment(departmentOptions.find(i => i.value === r.department_id))
      setSelectedStatusGroup(statusGroupsOptions.find(i => i.value === r.customer_status_group_id))
      setLoading(false)
    })
  }

  const teamsChangeHandler = (e) => {
    setTeamName(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const data = {
      id: id,
      name: teamName,
      department_id: selectedDepartment.value,
      customer_status_group_id: selectedStatusGroup?.value,
    }

    editTeam(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  useEffect(() => {
    selectListData('departments').then((r) => {
      setDepartmentOptions(r)
    })

    selectListData('customer_status_groups').then(r => {
      setStatusGroupsOptions(r)
    })
  }, [])

  useEffect(() => {
    if (departmentOptions.length) {
      getTeamData(id)
    }
  }, [departmentOptions, statusGroupsOptions])

  return (
    <>
      {loading ?
        <div className="hstack justify-content-center">
          <Spinner color="primary">Loading...</Spinner>
        </div>
        :
        <Form onSubmit={submitHandler}>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Team name</Label>
            <Input
              placeholder='Default Address'
              value={teamName}
              onChange={teamsChangeHandler}
            />
          </div>
          <FormGroup className="form-floating mb-3">
            <Select
              placeholder="Department"
              value={selectedDepartment}
              options={departmentOptions}
              onChange={selected => setSelectedDepartment(selected)}
            />
          </FormGroup>
          <FormGroup className="form-floating mb-3">
            <Select
              placeholder="Customer Status Group"
              value={selectedStatusGroup}
              options={statusGroupsOptions}
              isClearable={true}
              onChange={selected => setSelectedStatusGroup(selected)}
            />
          </FormGroup>
          <div className="hstack justify-content-end">
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" color="primary" className="btn">Save</Button>
          </div>
        </Form>
      }
    </>
  )
}

export default EditTeam
