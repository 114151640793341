import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export function getPages() {
  return api.get('/spa/pages/data', {})
}

export function createPage(data) {
  return api.post('/spa/pages/create', data)
}

export function updatePage(id, data) {
  return api.post(`/spa/pages/update/${id}`, data)
}

export function deletePage(id) {
  return api.post(`/spa/pages/delete/${id}`, {})
}
