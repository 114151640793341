import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../../contexts"
import {Button, Col, Form, Label, Row, Table} from "reactstrap"
import {getP2POrder, preview, updateP2POrder} from "../../../../api/p2p-orders"
import {selectListData} from "../../../../api/general"
import Select from "react-select"
import {cryptoIconsMapper, isTwoPrecision} from "../../../../helpers/utils"
import {toast} from "react-toastify"
import {Link} from "react-router-dom"
import _ from "lodash"

const EditP2POrderModal = ({p2pOrderId, updateTable}) => {

  const {closeModal} = useContext(ModalContext)
  const [order, setOrder] = useState(null)
  const [orderDetails, setOrderDetails] = useState('')
  const [statusOptions, setStatusOptions] = useState([])
  const [status, setStatus] = useState('')
  const [previewData, setPreviewData] = useState({})

  const getData = () => {
    getP2POrder(p2pOrderId).then(r => {
      setOrder(r)
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const data = {
      status: status.value,
      order_details: orderDetails,
    }

    let isConfirm = status.value === 40

    if (isConfirm) {
      if (!confirm(`Will be created deposit transaction for the order #${order.id}`)) {
        return
      }
    }

    updateP2POrder(data, order.id).then(r => {
      closeModal()
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(r.message)
        getData()
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  const previewHandler = (data) => {
    preview(data).then(r => {
      setPreviewData(r.data?.order_details || {})
    })
  }

  useEffect(() => {
    if (p2pOrderId) {
      getData()
    }

    selectListData('p2p_orders_status_list').then(r => {
      setStatusOptions(r)
    })
  }, [])

  useEffect(() => {
    if (order) {
      setOrderDetails(order?.meta?.order_details_raw)
      setStatus(statusOptions.find(i => i.value === order.status))
    }
  }, [order])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          {order &&
            <Col xs={12}>
              <div className="table-responsive">
                <Table className="table-striped align-middle">
                  <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Sell Price</th>
                    <th scope="col">Buy Price</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr className="align-middle">
                    <td className="fw-medium">#{order.id}</td>
                    <td className="fw-medium">{order.transfer_id ? `#${order.transfer_id}` : "-"}</td>
                    <td className="fw-medium">
                      <div className="d-flex align-items-center">
                        <div className="avatar-xxs me-2">
                          <img
                            src={cryptoIconsMapper[order.sell_asset.currency.code]}
                            className="img-fluid"
                            alt={order.sell_asset.currency.name}
                          />
                        </div>
                        <div>{isTwoPrecision(order.sell_asset.currency.code, order.sell_price)} {order.sell_asset.currency.name}</div>
                      </div>
                    </td>
                    <td className="fw-medium">
                      <div className="d-flex align-items-center">
                        <div className="avatar-xxs me-2">
                          <img
                            src={cryptoIconsMapper[order.buy_asset.currency.code]}
                            className="img-fluid"
                            alt={order.buy_asset.currency.name}
                          />
                        </div>
                        <div>{isTwoPrecision(order.buy_asset.currency.code, order.buy_price)} {order.buy_asset.currency.name}</div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          }

          {/* User info table BEGIN */}
          <Col className="col-6 table-responsive d-none d-lg-block">
            <Table className="table-striped align-middle">
              <tbody>
              {order && order.meta?.profile_info &&
                Object.entries(order.meta.profile_info).map(([key, val], idx) => (
                  idx % 2 === 0 ? (
                    <tr key={idx}>
                      <td>{_.startCase(_.toLower(key))}:</td>
                      <td>{val}</td>
                    </tr>
                  ) : null
                ))
              }
              </tbody>
            </Table>
          </Col>
          <Col className="col-6 table-responsive d-none d-lg-block">
            <Table className="table-striped align-middle">
              <tbody>
              {order && order.meta?.profile_info &&
                Object.entries(order.meta.profile_info).map(([key, val], idx) => (
                  idx % 2 !== 0 ? (
                    <tr key={idx}>
                      <td>{_.startCase(_.toLower(key))}:</td>
                      <td>{val}</td>
                    </tr>
                  ) : null
                ))
              }
              </tbody>
            </Table>
          </Col>

          <Col className="table-responsive d-block d-lg-none">
            <Table className="table-striped align-middle">
              <tbody>
              {(order && order.meta?.profile_info) ?
                Object.entries(order.meta.profile_info).map(([key, val], idx) => {
                  return (
                    <tr key={idx}>
                      <td>{_.startCase(_.toLower(key))}:</td>
                      <td>{val}</td>
                    </tr>
                  )
                })
                : null
              }
              </tbody>
            </Table>
          </Col>
          {/* User info table END */}

          <Col xs={12}>
            <hr className="my-2"/>
          </Col>
          <Col xs={12} lg={6}>
            <div className="form-group mb-3">
              <div className="d-flex justify-content-between">
                <Label htmlFor="message-text" className="col-form-label pb-0">Order Details:</Label>
                <Link
                  to='#'
                  onClick={() => previewHandler(orderDetails)}
                  color="primary"
                  className="mb-0 d-flex align-items-end"
                >
                  <span className="font-weight-bolder fs-14">Preview</span>
                </Link>
              </div>
              <textarea
                className="form-control "
                value={orderDetails}
                rows={8}
                onChange={(e) => {
                  setOrderDetails(e.target.value)
                }}
              />
            </div>
          </Col>

          {Object.keys(previewData)?.length ?
            <Col className="col-12 col-lg-6 d-flex flex-column justify-content-start mt-2 mt-lg-4 mb-3 mb-lg-0">
              {Object.entries(previewData).map(([key, val], idx) => {
                return (
                  <div key={idx} className="mb-1 text-nowrap text-truncate">
                    <span className="font-weight-bold">{key}:</span>
                    <span className="text-muted ms-2">{val}</span>
                  </div>
                )
              })}
            </Col> : null
          }

          <Col className="col-12">
            <div className="form-group">
              <Label className="form-label mb-0">Status</Label>
              <Select
                placeholder="Choose..."
                value={status}
                onChange={(selected) => {
                  setStatus(selected)
                }}
                options={statusOptions}
              />
            </div>
          </Col>

          <Col xs={12} className="hstack justify-content-end">
            <Button type="submit" color="primary" className="btn ms-auto mt-4">Save</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default EditP2POrderModal
