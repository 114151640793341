import {Card, CardBody, Table} from "reactstrap"
import React, {useEffect, useState} from "react"
import {deleteCreditCard} from "../../../api/creditCards"
import {toast} from "react-toastify"
import {Link} from "react-router-dom"
import {copyToClipboard} from "../../../helpers/utils"
import {useUserCan} from "../../../Components/Hooks/UserHooks"

const CreditCards = ({creditCards, activeTab, updateTable, customerId}) => {
  const [creditCardsData, setCreditCardsData] = useState([])

  // Permissions
  const canDeleteCreditCard = useUserCan('delete_credit_card')

  const getCreditCardsData = () => {
    if (creditCards.length) {
      setCreditCardsData(creditCards)
    }
  }

  const deleteCreditCardHandler = (id) => {
    if (confirm('Are you sure to delete Credit Card?')) {
      deleteCreditCard(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          getCreditCardsData()
          updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  useEffect(() => {
    if (activeTab === 'credit_cards') {
      getCreditCardsData()
    }
  }, [activeTab, creditCards, customerId])

  return (
    <Card>
      <CardBody>
        <div className="overflow-scroll">
          <h5 className="card-title mb-3">Credit Cards</h5>
          <table className="table table-sm table-responsive overflow-auto">
            <thead>
            <tr>
              <th>Id</th>
              <th className="text-nowrap">Card Number</th>
              <th className="text-center text-nowrap">Expiration Month</th>
              <th className="text-center text-nowrap">Expiration Year</th>
              <th className="text-end">Actions</th>
            </tr>
            </thead>
            <tbody>
            {creditCardsData.length > 0 ?
              creditCardsData.map((card, idx) => {
                return (
                  <tr key={idx}>
                    <td>{card.id}</td>
                    <td className="text-nowrap">{card.card_number}</td>
                    <td className="text-center text-nowrap">{card.expiration_month}</td>
                    <td className="text-center text-nowrap">{card.expiration_year}</td>
                    <td>
                      <div className="hstack gap-3 justify-content-end">
                        <Link
                          to="#"
                          className="btn copy-address py-0"
                          title="Copy"
                          onClick={() => copyToClipboard(card.card_number)}
                        >
                          <i className="ri-file-copy-2-line"></i>
                        </Link>
                        {canDeleteCreditCard &&
                          <Link
                            to="#"
                            onClick={() => {deleteCreditCardHandler(card.id)}}
                            className="link-danger fs-15">
                            <i className="ri-delete-bin-line"></i>
                          </Link>}
                      </div>
                    </td>
                  </tr>
                )
              }) :
              <tr>
                <td colSpan={5} className='text-center'>No data</td>
              </tr>
            }
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  )
}

export default CreditCards
