import React, {useEffect, useState} from "react"
import {Label} from "reactstrap"
import {getAssignedCustomers} from "../../api/calendar"
import AsyncSelect from "react-select/async"

function LazyLoadSelect({label, customOnChange, customValue}) {
  const [selectedItems, setSelectedItems] = useState(customValue ?? [])

  const loadOptions = async (inputValue) => {
    await new Promise(resolve => setTimeout(resolve, 300));

    let data = await getAssignedCustomers(1, inputValue)

    return data.map(item => ({
      value: item.value,
      label: item.label
    }))
  }

  useEffect(() => {
    customOnChange(selectedItems)
  }, [selectedItems])

  return (
    <div className="form-group mb-3">
      <Label className="form-label mb-0">{label}</Label>
      <AsyncSelect
        placeholder="Select from list or type ID"
        autoComplete="off"
        isMulti
        loadOptions={loadOptions}
        defaultOptions
        menuPlacement={"top"}
        value={selectedItems}
        onChange={(selected) => {
          setSelectedItems(selected || [])
        }}
      />
    </div>
  )
}

export default LazyLoadSelect
