import {APIClient} from "../helpers/api_helper";

const api = new APIClient()

export function selectListData(name) {
	return api.get('/spa/select-list-data', {name: name})
}

export function selectListDataWithParam(name, params) {
  return api.get('/spa/select-list-data', {name: name, ...params})
}

export function staticData(name) {
	return api.get('/spa/static-data', {name: name})
}

export function assetsData() {
	return api.get('/spa/select-list-data', {name: 'crypto_assets_codes'})
}

export function filtersList(name) {
  return api.get('/spa/filters-data', {name: name})
}
