import {Card, CardBody} from "reactstrap"
import React from "react"
import P2POrdersTable from "./Tables/P2POrdersTable"

const P2POrders = ({activeTab, customerId, methodsRef}) => {

  return (
    <Card>
      <CardBody>
        {activeTab === 'p2p_orders'
          ? <P2POrdersTable customerId={customerId} methodsRef={methodsRef}/> :
          ''
        }
      </CardBody>
    </Card>
  )
}

export default P2POrders
