import {APIClient} from "../helpers/api_helper"

const api = new APIClient();

export function createSecurity(data) {
  return api.post('/spa/securities/create', data)
}

export function updateSecurity(security, data) {
  return api.post(`/spa/securities/update/${security}`, data)
}

export function disableOrEnabledSecurity(security) {
  return api.post(`/spa/securities/disable_or_enable/${security}`, {})
}

export function deleteSecurity(security) {
  return api.post(`/spa/securities/delete/${security}`, {})
}

export function getMeta(type) {
  return api.post(`/spa/securities/get-security-meta`, {type: type})
}
