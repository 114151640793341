import React, {useEffect, useState} from 'react'
import {getFee} from "../../../../api/fees"
import {Table} from "reactstrap"
import {capitalize, isObject} from "lodash"
import {getCustomerFees} from "../../../../api/customers"
import classnames from "classnames"

const feeTypeTitle = {
  'fiat_fees': 'Bank Fees',
  'crypto_fees': 'Crypto Fees',
  'exchange_fee': 'Exchange Fee'
}

const FeesConfigTable = ({customerId, profileData}) => {
  const [modifiedFees, setModifiedFees] = useState([])
  const [customerFees, setCustomerFees] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const buildTableHeaders = (val) => {
    let headerData = Object.keys(val)

    if (!headerData.includes('name')) {
      headerData = ['name', ...headerData]
    }

    return headerData
  }

  const checkValue = (fee_type_key, val) => {
    const modifiedValue = (modifiedFees[fee_type_key]) ? modifiedFees[fee_type_key] : null

    return (
      <td className={classnames('py-1', {'bg-primary text-white': modifiedValue})} key={fee_type_key + '_idx'}>
        {val}
      </td>
    )
  }

  useEffect(() => {
    if (customerId) {
      getFee(customerId).then(r => {
        setModifiedFees(r)
      })

      getCustomerFees(customerId).then(r => {
        setCustomerFees(r.data || null)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }, [customerId, profileData])

  return (
    <>
      {(customerFees && Object.keys(customerFees)?.length) ?
        Object.entries(customerFees).map(([fee_type_key, val], idx) => {
          const isFeeValuesList = Array.isArray(val) ? val.length > 0 : Object.keys(val).length > 0

          return (
            isFeeValuesList ?
              <div key={idx}>
                <div className="fw-medium fs-14">{feeTypeTitle[fee_type_key]}</div>
                <Table responsive bordered className="table-striped rounded-2 mb-3" key={idx}>
                  <thead>
                  <tr>
                    {Object.entries(val).map(([header_key, val], idx) => {
                      let headerData = buildTableHeaders(val)

                      return (
                        <React.Fragment key={idx}>
                          {idx === Number(0) ?
                            headerData.map((field, idx) => {

                              return (
                                <th className="py-1" width={String(field) === 'name' ? 200 : ''} key={idx}>
                                  {capitalize(field)}
                                </th>
                              )
                            }) : null}
                        </React.Fragment>
                      )
                    })}
                  </tr>
                  </thead>
                  <tbody>
                  {Object.entries(val).map(([body_key, val], idx) => {
                    let headerData = buildTableHeaders(val)
                    let bodyData = val

                    if (!val.name) {
                      bodyData = {name: capitalize(body_key), ...bodyData}
                    }

                    return (
                      <tr key={idx}>
                        {Object.entries(bodyData).map(([body_data_key, val], idx) => {
                          const modifiedValue =
                            (modifiedFees[fee_type_key] && modifiedFees[fee_type_key][body_key]) ?
                              modifiedFees[fee_type_key][body_key][body_data_key]
                              : null

                          return (
                            headerData.includes(body_data_key) ?
                              <td className={classnames('py-1', {'bg-primary text-white': modifiedValue})} key={idx}>
                                {(body_data_key === 'limit' && (Number(val) === 0 || String(val) === '0')) ? 'Unlimited' : val}
                              </td> : null
                          )
                        })}
                      </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </div> :
              <div key={idx}>
                <div className="fw-medium fs-14">{feeTypeTitle[fee_type_key]}</div>
                <Table responsive bordered className="table-striped rounded-2 mb-3" key={idx}>
                  <thead>
                  <tr>
                    <th className="py-1" width={200}>Name</th>
                    <th className="py-1">Fee</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td className="py-1">Current</td>
                    {checkValue(fee_type_key, val)}
                  </tr>
                  </tbody>
                </Table>
              </div>
          )
        }) :
        <div className="w-100 text-center">
          {isLoading ? 'Loading...' : 'This user has no fees configurations'}
        </div>
      }
    </>
  )
}

export default FeesConfigTable
