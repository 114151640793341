import React, {useContext, useEffect, useState} from 'react'
import {Button, Card, CardBody, Input, Label} from "reactstrap"
import {toast} from "react-toastify"
import {updatePage} from "../../../../api/pages"
import {ModalContext} from "../../../../contexts"

const EditPage = ({page, updateData}) => {
  const {closeModal} = useContext(ModalContext)
  const [pageName, setPageName] = useState('')
  const [tabsList, setTabsList] = useState([])
  const [pageSlug, setPageSlug] = useState('')
  const [isVisible, setIsVisible] = useState(0)

  useEffect(() => {
    if (page) {
      setPageName(page.name)
      setTabsList(page.tabs)
      setPageSlug(page.slug)
      setIsVisible(page.visible)
    }
  }, [page])

  const handleTitleChange = (uuid, newTitle) => {
    const updatedTabsList = tabsList.map((tab, idx) =>
      tab.uuid === uuid ? { ...tab, title: newTitle } : tab
    )

    setTabsList(updatedTabsList)
  }

  const deleteTabHandler = (uuid) => {
    const updatedTabsList = tabsList.filter(i => i.uuid !== uuid)

    setTabsList(updatedTabsList)
  }

  const addTabHandler = () => {
    const uuid = Date.now()

    setTabsList([...tabsList, {
      id: `general_tab_${uuid}`,
      title: '',
      uuid: uuid
    }])
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const data = {
      page_title: pageName,
      slug: pageSlug,
      tabs: tabsList,
      visible: isVisible
    }

    updatePage(page.id, data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateData()
        closeModal()
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  return (
    <>
      <div className="form-group">
        <Label className="form-label mb-0">Page title</Label>
        <Input
          type="text"
          name="page_title"
          placeholder="Page title"
          autoComplete="off"
          required
          value={pageName}
          onChange={(e) => setPageName(e.target.value)}
        />
      </div>

      <div className="form-group mt-2">
        <Label className="form-label mb-0">Slug</Label>
        <Input
          type="text"
          name="slug"
          placeholder="Slug"
          autoComplete="off"
          required
          value={pageSlug}
          onChange={(e) => setPageSlug(e.target.value)}
        />
      </div>

      <Card className="border-top-0 p-0">
        <CardBody className="px-2 pb-2">
          {(tabsList && tabsList?.length) ?
            tabsList.map((tab, idx) => (
              <React.Fragment key={idx}>
                <div className="form-group w-100 d-flex align-items-center mb-3">
                  <Label className="form-label mb-0 mt-1 text-nowrap me-3">Tab name:</Label>
                  <Input
                    type="text"
                    name="tab_title"
                    placeholder="Tab name"
                    autoComplete="off"
                    value={tab.title}
                    onChange={(e) => handleTitleChange(tab.uuid, e.target.value)}
                  />

                  <button
                    type="button"
                    className="btn btn-danger px-3 btn-icon waves-effect waves-light ms-3"
                    onClick={() => deleteTabHandler(tab.uuid)}
                  >
                    <i className="mdi mdi-archive-remove-outline fs-20"></i>
                  </button>
                </div>
              </React.Fragment>
            )) : ''
          }
        </CardBody>
      </Card>

      <div className="d-flex justify-content-between align-items-start">
        <div className="form-check form-switch form-switch-left form-switch-sm">
          <Input
            className="form-check-input"
            name="visible"
            type="checkbox"
            checked={isVisible === 1}
            onChange={e => setIsVisible(e.target.checked ? 1 : 0)}
          />
          <Label className="form-check-label text-muted" for="hidden_from_customer">
            Page visible
          </Label>
        </div>
        <button
          type="button"
          className="btn btn-primary px-3 btn-icon waves-effect waves-light ms-3"
          onClick={addTabHandler}
        >
          <i className="mdi mdi-layers-plus fs-20"></i>
        </button>
      </div>

      <div className="mt-3 d-flex justify-content-end">
        <Button type="button" className="btn btn-warning me-3 py-1" onClick={closeModal}>Cancel</Button>
        <Button
          type="button"
          onClick={(e) => submitHandler(e)}
          className="btn btn-success py-1"
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default EditPage
