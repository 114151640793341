import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Dropdown, DropdownMenu, DropdownToggle, Form} from 'reactstrap'

//import Components
import FullScreenDropdown from '../Components/Common/FullScreenDropdown'
import NotificationDropdown from '../Components/Common/NotificationDropdown'
import ProfileDropdown from '../Components/Common/ProfileDropdown'
import LightDark from '../Components/Common/LightDark'
import DocumentsDropdown from "../Components/Common/DocumentsDropdown"
import CalendarDropdown from "../Components/Common/CalendarDropdown"

import {changeSidebarVisibility} from '../slices/thunks'
import {useSelector, useDispatch} from "react-redux"
import moment from "moment-timezone"
import {timeZone} from "../helpers/utils"

const Header = ({onChangeLayoutMode, layoutModeType, headerClass}) => {
  const dispatch = useDispatch()
  const [currentTime, setCurrentTime] = useState(moment.tz(new Date(), timeZone))

  const logoHeight = import.meta.env.VITE_THEME_LOGO_SIZE || 70
  const logoSmHeight = import.meta.env.VITE_THEME_LOGO_SIZE_SM || 30
  const brandLogosUrl = import.meta.env.VITE_APP_URL + '/storage/brand_logos/'
  const logoLightUrl = brandLogosUrl + 'logo-light.png'
  const logoLightSmUrl = brandLogosUrl + 'logo-light-sm.png'
  const logoDarkUrl = brandLogosUrl + 'logo-dark.png'
  const logoDarkSmUrl = brandLogosUrl + 'logo-dark-sm.png'

  const {sidebarVisibilityType} = useSelector(state => ({
    sidebarVisibilityType: state.Layout.sidebarVisibilitytype
  }))

  const [search, setSearch] = useState(false)
  const toggleSearch = () => {
    setSearch(!search)
  }

  const gmtHours = moment().tz(timeZone).utcOffset() / 60

  const toggleMenuBtn = () => {
    let windowSize = document.documentElement.clientWidth
    dispatch(changeSidebarVisibility("show"))

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle('open')

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === "horizontal") {
      document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu")
    }

    //For collapse vertical and semibox menu
    if (sidebarVisibilityType === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ?
          document.documentElement.setAttribute('data-sidebar-size', '') :
          document.documentElement.setAttribute('data-sidebar-size', 'sm')
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ?
          document.documentElement.setAttribute('data-sidebar-size', 'sm') :
          document.documentElement.setAttribute('data-sidebar-size', 'lg')
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable')
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
      document.body.classList.contains('twocolumn-panel') ?
        document.body.classList.remove('twocolumn-panel') :
        document.body.classList.add('twocolumn-panel')
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment.tz(new Date(), timeZone))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">

              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoDarkSmUrl} alt="" height={logoSmHeight}/>
                  </span>
                  <span className="logo-lg">
                    <img src={logoDarkUrl} alt="" height={logoHeight}/>
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSmUrl} alt="" height={logoSmHeight}/>
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightUrl} alt="" height={logoHeight}/>
                  </span>
                </Link>
              </div>

              <button
                onClick={toggleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              {/*<SearchOption />*/}
            </div>

            <div className="d-flex align-items-center">
              {/*
              <Dropdown isOpen={search} toggle={toggleSearch} className="d-md-none topbar-head-dropdown header-item">
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>
              */}

              <div className="me-3">
                <span className="fs-16 font-monospace">
                  {currentTime.format('HH:mm:ss')} {gmtHours > 0 ? `GMT+${gmtHours}` : gmtHours === 0 ? 'UTC' : `GMT${gmtHours}`}
                </span>
              </div>

              {/* DocumentsDropdown */}
              <DocumentsDropdown/>

              {/* CalendarDropdown */}
              <CalendarDropdown/>

              {/* FullScreenDropdown */}
              <FullScreenDropdown/>

              {/* Dark/Light Mode set */}
              <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              />

              {/* NotificationDropdown */}
              <NotificationDropdown/>

              {/* ProfileDropdown */}
              <ProfileDropdown/>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
