import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export function getP2POrder(id) {
  return api.get(`/spa/p2p-orders/get-p2p-order/${id}`, {})
}

export function updateP2POrder(data, id) {
  return api.post(`/spa/p2p-orders/update/${id}`, data)
}

export function preview(data) {
  return api.post('/spa/p2p-orders/preview', {data: data})
}

export function deleteP2POrder(id) {
  return api.post(`/spa/p2p-orders/delete/${id}`, {})
}
