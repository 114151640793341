import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap"
import {useSelector} from "react-redux";
import FilterFields from "./FilterFields";

const BasicFilters = ({filterFields, filtersHandler, filterStorageKey}) => {
  const activeFilters = useSelector(s => s.TableFilters.filters)
  const [activeFiltersData, setActiveFiltersData] = useState({})

  const submitHandler = () => {
    filtersHandler(activeFiltersData)
  }

  useEffect(() => {
    if (activeFilters?.hasOwnProperty(filterStorageKey)) {
      setActiveFiltersData(activeFilters[filterStorageKey])
    } else {
      setActiveFiltersData({})
    }
  }, [activeFilters])

  return (
    <>
      <div className="d-flex flex-wrap mb-3">
        <FilterFields filterFields={filterFields} activeFiltersData={activeFiltersData} filterStorageKey={filterStorageKey} />
      </div>
      <div className="d-flex">
        {/* BEGIN Apply filters button */}
        <Button
          type="btn"
          className="btn btn-sm waves-effect waves-light"
          color="success"
          onClick={() => submitHandler()}
        >
          Apply filters
        </Button>
        {/* END Apply filters button */}
      </div>
    </>
  )
}

export default BasicFilters
