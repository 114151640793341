import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../../contexts"
import {Button, Form, Input, Label} from "reactstrap"
import Select from "react-select";
import {selectListData} from "../../../../api/general"
import {createPostback} from "../../../../api/postbacks"
import {toast} from "react-toastify"

const CreatePostback = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [postbackOptions, setPostbackOptions] = useState([])
  const [selectedPostbackOption, setSelectedPostbackOption] = useState([])

  const submitHandler = (e) => {
    e.preventDefault()

    let data = new FormData(e.target)

    createPostback(data).then(r => {
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
        closeModal()
      } else {
        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        } else {
          toast.error(message)
        }
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  useEffect( () => {
    selectListData('postbacks_types').then(r => setPostbackOptions(r))
  }, [])

  return(
    <Form onSubmit={submitHandler}>
      <div className="form-group mb-3">
        <Label className="form-label mb-0">Name</Label>
        <Input
          name="name"
          required={true}
          placeholder="Name"
          autoComplete="off"
        />
      </div>
      <div className="form-group mb-3">
        <Label className="form-label mb-0">URL</Label>
        <Input
          name="url"
          required={true}
          placeholder="URL"
          autoComplete="off"
        />
      </div>
      <div className="form-group mb-3">
        <Label htmlFor="choices-multiple-default" className="form-label mb-0">Type</Label>
        <Select
          placeholder="Choose..."
          name="type"
          onChange={(selected) => {
            setSelectedPostbackOption(selected)
          }}
          options={postbackOptions}
        />
      </div>
      <div className="hstack justify-content-end">
        <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
        <Button type="submit" color="primary" className="btn">Save</Button>
      </div>
    </Form>
  )
}

export default CreatePostback
