import {APIClient} from "../helpers/api_helper"

const api = new APIClient

export function getCalendarEvents() {
    return api.get('/spa/calendar/get-events', {})
}

export function getEmployeeEvents(id) {
  return api.get(`spa/calendar/get-employee-events/${id}`, {})
}

export function getAssignedCustomers(page, inputValue) {
  return api.get('/spa/calendar/assigned-customers', {page, inputValue})
}

export function getCalendarCategories() {
    return api.get('/spa/calendar/get-categories', {})
}

export function addCalendarEvent(data) {
    return api.post('/spa/calendar', data)
}

export function updateCalendarEvent(data) {
    return api.post('/spa/calendar/update', data)
}

export function deleteCalendarEvent(id) {
	return api.post(`/spa/calendar/delete/${id}`, {})
}
