import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'

//import images
import default_avatar from "../../assets/images/users/default_avatar.png"
import {useProfile, useUserCan} from "../Hooks/UserHooks"
import {getLoggedinUser} from "../../helpers/api_helper"
import {getProfile} from "../../api/employees"
import {setProfile} from "../../slices/auth/profile/reducer"
import {useDispatch} from "react-redux"
import {toast} from "react-toastify"

const ProfileDropdown = () => {
  const [userName, setUserName] = useState("User")
  const dispatch = useDispatch()
  let {userProfile} = useProfile()

  // Permissions
  const canLoginAsCustomer = useUserCan('login_as_customer')

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown)
  }

  const updateProfileInfo = () => {
    const user = getLoggedinUser()
    getProfile(user.user.id).then(r => {
      dispatch(setProfile(r))
      let data = {...r, access_token: user.access_token}
      localStorage.setItem('authUser', JSON.stringify(data))
      toast.success('Account data successfully synced!')
    })
  }

  useEffect(() => {
    setUserName(userProfile.name)
  }, [userName, userProfile])

  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown}
                className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img className="rounded-circle header-profile-user" src={default_avatar}
                 alt="Header Avatar"/>
            <span className="text-start ms-xl-2">
                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-start">
          <DropdownItem className='p-0'>
            {(canLoginAsCustomer && !import.meta.env.VITE_ONE_CLICK_AUTOLOGIN) &&
              <Link
                to={import.meta.env.VITE_CUSTOMER_APP_URL + '/login-as'}
                className="dropdown-item"
                target="_blank"
              >
                <i className="mdi mdi-login text-muted fs-16 align-middle me-1"></i>
                <span className="align-middle">Customer login</span>
              </Link>}
          </DropdownItem>
          <DropdownItem className='p-0'>
            <Link to={'#'} onClick={updateProfileInfo} className="dropdown-item">
              <i className="mdi mdi-cloud-refresh text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Sync data</span>
            </Link>
          </DropdownItem>
          <DropdownItem className='p-0'>
            <Link to={"/logout"} className="dropdown-item">
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle" data-key="t-logout">Logout</span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileDropdown
