import {combineReducers} from "redux"

// Front
import LayoutReducer from "./layouts/reducer"

// Authentication
import LoginReducer from "./auth/login/reducer"
import AccountReducer from "./auth/register/reducer"
import ProfileReducer from "./auth/profile/reducer"
// Calendar
import CalendarReducer from "./calendar/reducer"
// Notification
import NotificationReducer from "./notification/reducer"
// Customers
import CustomersReducer from "./customers/reducer"
// Transactions
import TransactionsReducer from "./transactions/reducer"
// Invoices
import InvoicesReducer from "./invoices/reducer"
// Tickets
import TicketsReducer from "./tickets/reducer"
// State
import StateReducer from './state/reducer'
//Crypto Rates
import CryptoRatesReducer from './crypto-rates/reducer'
//Table Filters
import TableFilters from "./table-filters/reducer"
//Profile Revisions
import ProfileRevisions from "./profile-revisions/reducer"

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  Calendar: CalendarReducer,
  Notification: NotificationReducer,
  State: StateReducer,
  Customers: CustomersReducer,
  Transactions: TransactionsReducer,
  Invoices: InvoicesReducer,
  Tickets: TicketsReducer,
  CryptoRates: CryptoRatesReducer,
  TableFilters: TableFilters,
  Revisions: ProfileRevisions,
})

export default rootReducer
