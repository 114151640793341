import React, {useEffect, useState} from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import classnames from "classnames"
import BankDetails from "./BankDetails"
import DefaultAssets from "./DefaultAssets"
import DepartmentsAndTeams from "./DepartmentsAndTeams"
import DocumentTypes from "./DocumentsTypes"
import Recipients from "./Recipients"
import {useUserCan} from "../../Components/Hooks/UserHooks"
import Brands from "./Brands"
import EmailConfigurations from "./EmailConfigurations"
import Templates from "./Templates"
import CustomerGroups from "./CustomerGroups"
import VoIP from "./VoIP"

const Settings = () => {
  const [verticalTab, setVerticalTab] = useState('bank-details')

  // Permissions
  const canViewBankDetails = useUserCan('view_bank_details')
  const canViewAssets = useUserCan('view_assets')
  const canViewDepartments = useUserCan('view_departments')
  const canViewDocumentTypes = useUserCan('view_document_types')
  const canViewRecipients = useUserCan('view_recipients')
  const canViewBrands = useUserCan('view_brands')
  const canViewConfigurations = useUserCan('view_configurations')
  const canViewTemplates = useUserCan('view_templates')
  const canViewStatusGroups = useUserCan('view_status_groups')

  const toggleTab = (tab) => {
    if (verticalTab !== tab) {
      setVerticalTab(tab)
      sessionStorage.setItem('activeSettingsTab', tab)
    }
  }

  useEffect(() => {
    let prevTab= sessionStorage.getItem('activeSettingsTab')

    if (prevTab) {
      setVerticalTab(prevTab)
    }
  }, [])

  document.title = 'Settings | ' + import.meta.env.VITE_APP_NAME
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Settings"/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                      <Nav pills className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center">
                        {canViewBankDetails &&
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "bank-details",
                            })}
                            onClick={() => {
                              toggleTab("bank-details")
                            }}
                            id="custom-v-pills-bank-details-tab"
                          >
                            <i className="mdi mdi-bank-plus d-block fs-24 mb-1"></i>
                            Bank Details
                          </NavLink>
                        </NavItem>}
                        {canViewAssets &&
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "default-assets",
                            })}
                            onClick={() => {
                              toggleTab("default-assets")
                            }}
                            id="custom-v-pills-default-assets-tab"
                          >
                            <i className="mdi mdi-microsoft-access d-block fs-24 mb-1"></i>
                            Default Assets
                          </NavLink>
                        </NavItem>}
                        {canViewDepartments &&
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "departments",
                            })}
                            onClick={() => {
                              toggleTab("departments")
                            }}
                            id="custom-v-pills-departments-tab"
                          >
                            <i className="mdi mdi-microsoft-teams d-block fs-24 mb-1"></i>
                            Departments and Teams
                          </NavLink>
                        </NavItem>}
                        {canViewDocumentTypes &&
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "document-types",
                            })}
                            onClick={() => {
                              toggleTab("document-types")
                            }}
                            id="custom-v-pills-document-types-tab"
                          >
                            <i className="mdi mdi-file-document-multiple-outline d-block fs-24 mb-1"></i>
                            Document Types
                          </NavLink>
                        </NavItem>}
                        {canViewRecipients &&
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "recipients",
                            })}
                            onClick={() => {
                              toggleTab("recipients")
                            }}
                            id="custom-v-pills-recipients-tab"
                          >
                            <i className="ri-user-received-line d-block fs-24 mb-1"></i>
                            Recipients
                          </NavLink>
                        </NavItem>}
                        {canViewBrands &&
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "brands",
                            })}
                            onClick={() => {
                              toggleTab("brands")
                            }}
                            id="custom-v-pills-recipients-tab"
                          >
                            <i className="mdi mdi-file-sign d-block fs-24 mb-1"></i>
                            Brands
                          </NavLink>
                        </NavItem>}
                        {canViewConfigurations &&
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "email-configurations",
                            })}
                            onClick={() => {
                              toggleTab("email-configurations")
                            }}
                            id="custom-v-pills-recipients-tab"
                          >
                            <i className="ri-mail-settings-line d-block fs-24 mb-1"></i>
                            Email Configurations
                          </NavLink>
                        </NavItem>}
                        {canViewTemplates &&
                          <NavItem>
                            <NavLink
                              style={{cursor: "pointer"}}
                              className={classnames({
                                "mb-2": true,
                                active: verticalTab === "templates",
                              })}
                              onClick={() => {
                                toggleTab("templates")
                              }}
                              id="custom-v-pills-recipients-tab"
                            >
                              <i className="mdi mdi-file-cog-outline d-block fs-24 mb-1"></i>
                              Templates
                            </NavLink>
                          </NavItem>
                        }
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "voip",
                            })}
                            onClick={() => {
                              toggleTab("voip")
                            }}
                            id="custom-v-pills-recipients-tab"
                          >
                            <i className="mdi mdi-card-account-phone-outline d-block fs-24 mb-1"></i>
                            VoIP
                          </NavLink>
                        </NavItem>
                        {canViewStatusGroups &&
                        <NavItem>
                          <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                              "mb-2": true,
                              active: verticalTab === "customer_groups",
                            })}
                            onClick={() => {
                              toggleTab("customer_groups")
                            }}
                            id="custom-v-pills-recipients-tab"
                          >
                            <i className="mdi mdi-file-cog-outline d-block fs-24 mb-1"></i>
                            Customer Status Groups
                          </NavLink>
                        </NavItem>}
                      </Nav>
                    </Col>
                    <Col lg={9}>
                      <TabContent activeTab={verticalTab} className="text-muted mt-3 mt-lg-0">
                        {canViewBankDetails &&
                        <TabPane tabId="bank-details" id="custom-v-pills-bank-details" className="m-2">
                          <BankDetails/>
                        </TabPane>}
                        {canViewAssets &&
                        <TabPane tabId="default-assets" id="custom-v-pills-default-assets" className="m-2">
                          <DefaultAssets/>
                        </TabPane>}
                        {canViewDepartments &&
                        <TabPane tabId="departments" id="custom-v-pills-departments" className="m-2">
                          <DepartmentsAndTeams/>
                        </TabPane>}
                        {canViewDocumentTypes &&
                        <TabPane tabId="document-types" id="custom-v-pills-document-types" className="m-2">
                          <DocumentTypes/>
                        </TabPane>}
                        {canViewRecipients &&
                        <TabPane tabId="recipients" id="custom-v-pills-recipients" className="m-2">
                          <Recipients/>
                        </TabPane>}
                        {canViewBrands &&
                        <TabPane tabId="brands" id="custom-v-pills-recipients" className="m-2">
                          <Brands/>
                        </TabPane>}
                        {canViewConfigurations &&
                        <TabPane tabId="email-configurations" id="custom-v-pills-recipients" className="m-2">
                          <EmailConfigurations/>
                        </TabPane>}
                        {canViewTemplates &&
                        <TabPane tabId="templates" id="custom-v-pills-recipients" className="m-2">
                          <Templates/>
                        </TabPane>}
                        {import.meta.env.VITE_VOIP_ENABLED === 'true' &&
                        <TabPane tabId="voip" id="custom-v-pills-recipients" className="m-2">
                          <VoIP />
                        </TabPane>}
                        {canViewStatusGroups &&
                        <TabPane tabId="customer_groups" id="custom-v-pills-recipients" className="m-2">
                          <CustomerGroups/>
                        </TabPane>}
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings
