import {APIClient} from "../helpers/api_helper"

const api = new APIClient();

export function check2fa(code) {
    return api.post('2fa-verify', {code})
}

export function checkPinCode(code) {
    return api.post('/spa/pin-code/check', {'pin-code': code})
}
