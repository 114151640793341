import React, {useEffect, useState} from 'react'
import {Card, CardBody, CardHeader, Table} from "reactstrap"
import {APIClient} from "../helpers/api_helper"
import {getLastTickets} from "../api/widget"
import {openLinkInNewTab, prettyDate} from "../helpers/utils"
import {Link} from "react-router-dom";
import {useUserCan} from "../Components/Hooks/UserHooks"

const LastTickets = () => {
  const api = new APIClient;
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const canViewTicket = useUserCan('view_ticket')

  useEffect(() => {
    getLastTickets().then(r => {
      setData(r)
      setLoading(false)
    })
  }, [])

  return (
    <>
      <Card>
        <CardHeader>Last 5 Tickets</CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Table className="table-striped table-nowrap align-middle mb-0">
              <thead>
              <tr>
                <th scope="col">Ticket ID</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th scope="col">Status</th>
                <th scope="col">Customer</th>
                <th scope="col">Last Update</th>
              </tr>
              </thead>
              <tbody>
              {loading ?
                <tr>
                  <td colSpan={11} className='text-center'>Loading...</td>
                </tr> :
                (data.length > 0 ? data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="fw-medium">
                        {canViewTicket ?
                          <span>
                            <Link to={`/tickets/list/show/${item.id}`}>
                              #{item.id}
                            </Link>
                            <Link to="#" onClick={() => openLinkInNewTab(`/tickets/list/show/${item.id}`)}>
                              <i className="ri-share-box-line text-muted fs-14 ms-1"></i>
                            </Link>
                          </span> :
                          <span>#{item.id}</span>
                        }
                      </td>
                      <td style={{maxWidth: '200px'}} className="text-truncate">{item.subject}</td>
                      <td style={{maxWidth: '300px'}} className="text-truncate">{item.message}</td>
                      <td><span className={'badge bg-' + item.status_color}>{item.status_name}</span></td>
                      <td>{item?.customer?.name}</td>
                      <td>{prettyDate(item.updated_at)}</td>
                    </tr>
                  )
                }) : (<tr>
                  <td colSpan={11} className='text-center'>No data</td>
                </tr>))
              }
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default LastTickets;
