import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  cryptoRates: [],
}

const CryptoRatesSlice = createSlice({
  name: 'crypto-rates',
  initialState,
  reducers: {
    setCryptoRates(state, action) {
      state.cryptoRates = action.payload
    }
  }
})

export const {setCryptoRates} = CryptoRatesSlice.actions

export default CryptoRatesSlice.reducer
