import {createAsyncThunk} from "@reduxjs/toolkit";

import {
	addCalendarEvent,
	deleteCalendarEvent,
	getCalendarCategories,
	getCalendarEvents,
	updateCalendarEvent
} from "../../api/calendar";

export const getEvents = createAsyncThunk("calendar/getEvents", async () => {
  try {
    return await getCalendarEvents()
  } catch (error) {
    return error;
  }
});

export const addNewEvent = createAsyncThunk("calendar/addNewEvent", async (payload) => {
  try {
    const res = await addCalendarEvent(payload)

    if (res.success) {
      return res.data
    }

    return []
  } catch (error) {
    return error;
  }
});

export const updateEvent = createAsyncThunk("calendar/updateEvent", async (payload) => {
  try {
    const res = await updateCalendarEvent(payload)

    if (res.success) {
      return res.data
    }

    return []
  } catch (error) {
    return error;
  }
});

export const deleteEvent = createAsyncThunk("calendar/deleteEvent", async (event) => {
  try {
	  try {
		  const res = await deleteCalendarEvent(event.id)

		  if (res.success) {
			  return res.data
		  }

		  return []
	  } catch (error) {
		  return error;
	  }
  } catch (error) {
    return error;
  }
});

export const getCategories = createAsyncThunk("calendar/getCategories", async () => {
  try {
    return await getCalendarCategories()
  } catch (error) {
    return error;
  }
})

export const getUpcomingEvent = createAsyncThunk("calendar/getUpcomingEvent", async () => {
  try {
    // const response = getUpCommingEventApi()
    // return response;
  } catch (error) {
    return error;
  }
})

export const resetCalendar = createAsyncThunk("calendar/resetCalendar", async () => {
  try {
    // const response = '';
    // return response;
  } catch (error) {
    return error;
  }
})
