import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  role: null,
  permissions: [],
  all_permissions: [],
  error: '',
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    setProfile(state, action) {
      state.user = action.payload?.user ?? {}
      state.role = action.payload?.role || ''
      state.permissions = action.payload?.permissions || []
      state.all_permissions = action.payload?.all_permissions.map(i => i.name) || []
    }
  },
});

export const {
  setProfile
} = ProfileSlice.actions

export default ProfileSlice.reducer;
