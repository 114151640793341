import {createSlice} from "@reduxjs/toolkit"

let defaultFilters
let storageName = 'table-filters'

try {
  defaultFilters = JSON.parse(localStorage.getItem(storageName))
} catch (err) {
  defaultFilters = {}
}

const initialState = {
  filters: defaultFilters,
}

const TableFiltersSlice = createSlice({
  name: 'table-filters',
  initialState,
  reducers: {
    insertFilterData(state, action) {
      let inputData = action.payload
      let filterStorageKey = action.payload.filterStorageKey
      let emptyValue = false

      if (inputData) {
        if (typeof inputData.value === 'object') {
          if (Array.isArray(inputData.value)) {
            emptyValue = !inputData.value.length
          } else {
            emptyValue = !Object.keys(inputData.value).length
          }
        } else {
          emptyValue = !inputData.value.length
        }
      }

      const newData = {};

      if (emptyValue) {
        newData[filterStorageKey] = {...Object.filter(state.filters[filterStorageKey], i => i.name !== inputData.name)}

        return (state = {
          ...state,
          filters: {
            ...state.filters,
            ...newData
          }
        })
      }

      const newKey = {};
      newKey[inputData.name] = inputData

      if (state.filters) {
        newData[filterStorageKey] = {
          ...state.filters[filterStorageKey],
          ...newKey
        }
      } else {
        newData[filterStorageKey] = {
          ...newKey
        }
      }

      return (state = {
        ...state,
        filters: {
          ...state.filters,
          ...newData
        }
      })
    },
    refreshFromStorage(state, action) {
      const newFilters = {}

      newFilters[action.payload.filterStorageKey] = action.payload.data

      return {...state, filters: {...state.filters, ...newFilters}}
    },
    clearTableFilters(state, action) {
      const newFilters = {}

      for (let i in state.filters) {
        if (i !== action.payload) {
          newFilters[i] = state.filters[i]
        }
      }

      if (Object.keys(newFilters).length) {
        return {...state, filters: {...newFilters}}
      }

      return {...state, filters: {}}
    }
  }
})

export default TableFiltersSlice.reducer
export const {
  insertFilterData,
  clearTableFilters,
  refreshFromStorage,
} = TableFiltersSlice.actions
