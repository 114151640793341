import {Card, CardBody} from "reactstrap";
import classnames from "classnames";
import default_avatar from "../../../assets/images/users/default_avatar.png";
import React, {useEffect, useState} from "react";
import {getActivityLog} from "../../../api/customers";

const Activities = ({customerId, activeTab}) => {
	const [activityLog, setActivityLog] = useState([])

	const getActivityLogData = () => {
		getActivityLog(customerId).then(r => {
			setActivityLog(r.data)
		})
	}

	useEffect(() => {
		if (activeTab === 'activities') {
			getActivityLogData()
		}
	}, [activeTab, customerId])

	return (
			<Card>
				<CardBody>
					<h5 className="card-title mb-3">Activities</h5>
					<div className="acitivity-timeline overflow-auto">
						{activityLog.length ? activityLog.map((log, idx) => {
							return <div
									className={classnames(
											'acitivity-item',
											'd-flex',
											{'py-3': ![0, activityLog.length - 1].includes(idx)}
									)}
									key={idx}
							>
								<div className="flex-shrink-0">
									<img src={default_avatar} alt="" className="avatar-xs rounded-circle acitivity-avatar"/>
								</div>
								<div className="flex-grow-1 ms-3">
									{log.meta.message && <h6 className="mb-1">{log?.meta?.message}</h6>}
									{log.before && <p className="text-muted mb-2">{JSON.stringify(log.before)}</p>}
									<p className="text-muted mb-2">{JSON.stringify(log.after)}</p>
									{log.user_id && <small className="mb-0 text-muted">User ID: {log.user_id}</small>}
								</div>
							</div>
						}) : <div className='text-center'>Loading...</div>
						}
					</div>
				</CardBody>
			</Card>
	)
}

export default Activities
