import React from "react"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import {Container} from "reactstrap"
import PostbacksHistoryTable from "./PostbacksHistoryTable"

const PostbacksHistory = () => {
  document.title = 'Postbacks History | ' + import.meta.env.VITE_APP_NAME

  return(
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Postbacks History"/>
          <PostbacksHistoryTable/>
        </Container>
      </div>
    </React.Fragment>
)
}

export default PostbacksHistory
