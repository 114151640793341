import {Button, Table} from "reactstrap"
import {Link} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import {toast} from "react-toastify"
import {ModalContext} from "../../contexts"
import {deleteStatusGroup, getStatusGroups} from "../../api/settings"
import Edit from "./Modals/Customers/StatusGroups/Edit"
import Add from "./Modals/Customers/StatusGroups/Add"
import Show from "./Modals/Customers/StatusGroups/Show"
import {useUserCan} from "../../Components/Hooks/UserHooks";

const CustomerGroups = () => {
  const {openModal} = useContext(ModalContext)
  const [statusGroups, setStatusGroups] = useState([])

  // Permissions
  const canCreateStatusGroup = useUserCan('create_status_group')
  const canUpdateStatusGroup = useUserCan('update_status_group')
  const canDeleteStatusGroup = useUserCan('delete_status_group')

  const getStatusGroupsData = () => {
    getStatusGroups().then(r => {
      setStatusGroups(r)
    })
  }

  const updateStatusGroup = () => {
    getStatusGroupsData()
  }

  // const deleteHandler = id => {
  //   if (confirm("Are you sure to delete this Status Group?")) {
  //     deleteStatusGroup(id).then(r => {
  //       if (r.success) {
  //         toast.success(r.message)
  //         getStatusGroupsData()
  //       } else {
  //         toast.error(r.message)
  //       }
  //     })
  //   }
  // }

  const editStatusGroupHandler = (statusGroup) => {
    if (statusGroup.id === 1) {
      openModal({
        title: "Show Status Customer Group",
        content: <Show statusGroup={statusGroup} />
      })
    } else {
      openModal({
        title: "Edit Status Customer Group",
        content: <Edit statusGroup={statusGroup} updateTable={updateStatusGroup} />,
        size: 'lg'
      })
    }
  }

  const addStatusGroupHandler = () => {
    openModal({
      title: "Add Status Group",
      content: <Add updateTable={updateStatusGroup}/>,
      size: 'lg'
    })
  }

  useEffect(() => {
    getStatusGroupsData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-end mt-1">
        {canCreateStatusGroup &&
          <Button color="primary" className="btn mb-3" onClick={addStatusGroupHandler}>
            Add Customer Status Group
          </Button>}
      </div>
      <div className="table-responsive">
        <Table className="table-borderless table-nowrap">
          <thead>
          <tr className="border-bottom">
            <th className="col-1" scope="col">ID</th>
            <th className="col-1" scope="col">NAME</th>
            <th className="col-10" scope="col">IS DEFAULT</th>
            {(canUpdateStatusGroup || canDeleteStatusGroup) &&
              <th className="col-10" scope="col">ACTIONS</th>}
          </tr>
          </thead>
          <tbody>
          {statusGroups.length ?
            statusGroups.map((statusGroup, idx) => {
              return (
                <tr className="border-bottom" key={idx}>
                  <td>{statusGroup.id}</td>
                  <td>{statusGroup.name}</td>
                  <td>{statusGroup.is_default === 1 ? 'Yes' : 'No'}</td>
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      {canUpdateStatusGroup &&
                        <Link
                          to="#"
                          className="link-muted fs-15"
                          onClick={() => editStatusGroupHandler(statusGroup)}
                        >
                          <i className="ri-settings-4-line "></i>
                        </Link>}

                      {/*{canDeleteStatusGroup &&*/}
                      {/*  <Link*/}
                      {/*    to="#"*/}
                      {/*    className="link-danger fs-15"*/}
                      {/*    onClick={() => deleteHandler(statusGroup.id)}*/}
                      {/*  >*/}
                      {/*    <i className="ri-delete-bin-line"></i>*/}
                      {/*  </Link>}*/}
                    </div>
                  </td>
                </tr>
              )
            }) :
            (<tr>
              <td colSpan={3} className='text-center'>No Data</td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default CustomerGroups
