import {createSlice} from "@reduxjs/toolkit";

// Store actual data timestamp
const initialState = {
  customersData: 0,
}

const StateSlice = createSlice({
  name: 'StateSlice',
  initialState,
  reducers: {
    setCustomerData(state, action) {
      state.customersData = Number(action.payload.id) || 0
    }
  }
})

export default StateSlice.reducer
export const {setCustomerData} = StateSlice.actions
