import React, {useContext, useEffect, useState} from "react"
import {Button, Table} from "reactstrap"
import {ModalContext} from "../../contexts"
import AddEmailConfig from "./Modals/AddEmailConfig"
import {Link} from "react-router-dom"
import EditEmailConfig from "./Modals/EditEmailConfig"
import {deleteConfig, getConfigurations} from "../../api/settings"
import {toast} from "react-toastify"
import {useUserCan} from "../../Components/Hooks/UserHooks"

const EmailConfigurations = () => {
  const {openModal} = useContext(ModalContext)
  const [configurations, setConfigurations] = useState([])

  // Permissions
  // const canViewConfigurations = useUserCan('view_configurations')
  const canCreateConfiguration = useUserCan('create_configuration')
  const canUpdateConfiguration = useUserCan('edit_configuration')
  const canDeleteConfiguration = useUserCan('delete_configuration')

  const addEmailConfigHandler = () => {
    openModal({
      title: "Add Email Configurations",
      content: <AddEmailConfig updateTable={getConfigurationsData}/>
    })
  }

  const editEmailConfigHandler = (id) => {
    openModal({
      title: "Edit Email Configurations",
      content: <EditEmailConfig id={id} updateTable={getConfigurationsData} />
    })
  }

  const deleteEmailConfigHandler = id => {
    if (confirm("Are you sure to delete this configuration?")) {
      deleteConfig(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          getConfigurationsData()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const getConfigurationsData = () => {
    getConfigurations().then(r => {
      setConfigurations(r)
    })
  }

  useEffect(() => {
    getConfigurationsData()
  }, [])

  return (
    <>
      {/* BEGIN Actions */}
      {canCreateConfiguration &&
        <div className="d-flex justify-content-end mt-1 mb-3">
          {canCreateConfiguration &&
            <Button color="primary" className="btn" onClick={addEmailConfigHandler}>
              Add Email Configurations
            </Button>}
        </div>}
      {/* END Actions */}

      <div className="table-responsive">
        <Table className="table-borderless table-nowrap">
          <thead>
          <tr className="border-bottom">
            <th>ID</th>
            <th>CONFIG NAME</th>
            <th>TRANSPORT</th>
            <th>ENCRYPTION</th>
            <th>HOST</th>
            <th>PORT</th>
            <th>USER NAME</th>
            {(canUpdateConfiguration || canDeleteConfiguration) &&
              <th className="col-1" scope="col">ACTIONS</th>}
          </tr>
          </thead>
          <tbody>
          {configurations.length > 0 ? configurations.map((config, idx) =>
            <tr key={idx}>
              <td>{config.id}</td>
              <td>{config.name}</td>
              <td>{config.data.transport ? config.data.transport : '-'}</td>
              <td>{config.data.encryption ? config.data.encryption : '-'}</td>
              <td>{config.data.host ? config.data.host : '-'}</td>
              <td>{config.data.port ? config.data.port : '-'}</td>
              <td>{config.data.username ? config.data.username : '-'}</td>
              {(canUpdateConfiguration || canDeleteConfiguration) &&
                <td>
                  <div className="hstack gap-3 flex-wrap">
                    {canUpdateConfiguration &&
                      <Link
                        to="#"
                        onClick={() => editEmailConfigHandler(config.id)}
                        className="link-success fs-15"
                      >
                        <i className="ri-edit-2-line"></i>
                      </Link>}
                    {canDeleteConfiguration &&
                      <Link
                        to="#"
                        onClick={() => deleteEmailConfigHandler(config.id)}
                        className="link-danger fs-15"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Link>}
                  </div>
                </td>}
            </tr>
          ) : (<tr>
            <td colSpan={8} className='text-center'>No Data</td>
          </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default EmailConfigurations
