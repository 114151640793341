import React from 'react'
import {Col, Container, Row} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import LastTransactions from "../../Widgets/LastTransactions"
import LastTickets from "../../Widgets/LastTickets"
import CryptoRates from "../../Widgets/CryptoRates"
import IncomeWidget from "../../Widgets/IncomeWidget"
import {useUserCan} from "../../Components/Hooks/UserHooks";

const DashboardCrypto = () => {
  const canSeeIncomeWidget = useUserCan('view_income_widget')
  const canSeeLastTransactionsWidget = useUserCan(['view_transactions', 'view_all_transactions'])
  const canSeeLastTicketsWidget = useUserCan(['view_all_tickets', 'view_tickets'])

  document.title = 'Dashboard | ' + import.meta.env.VITE_APP_NAME
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Dashboards"/>
          <Row>
            <Col>
              <CryptoRates />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex" xs={12}>
              {canSeeIncomeWidget && <IncomeWidget />}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {canSeeLastTransactionsWidget && <LastTransactions/>}
            </Col>
            <Col xs={12}>
              {canSeeLastTicketsWidget && <LastTickets/>}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardCrypto
