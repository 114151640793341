import {Button, Form, Input, Label} from "reactstrap"
import Select from "react-select"
import React, {useContext, useEffect, useState} from "react"
import {editPostback, getPostback} from "../../../../api/postbacks"
import {ModalContext} from "../../../../contexts"
import {selectListData} from "../../../../api/general"
import {toast} from "react-toastify";

const EditPostback = ({id, updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [loading, setLoading] = useState(true)
  const [postback, setPostback] = useState({})
  const [postbackOptions, setPostbackOptions] = useState([])
  const [selectedPostbackOption, setSelectedPostbackOption] = useState([])

  const getPostbackData = () => {
    getPostback(id).then(r => {
      if (r.success) {
        setPostback(r.postback)
        selectListData('postbacks_types').then(r => {
          setPostbackOptions(r)
        })
        setLoading(false)
      }
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let data = new FormData(e.target)

    editPostback(id, data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
      } else {
        toast.error(r.message)
      }
    }).finally(() => closeModal())
  }

  useEffect(() => {
    getPostbackData()
  }, [])

  useEffect(() => {
    if (postbackOptions && postbackOptions.length > 0) {
      setSelectedPostbackOption(postbackOptions.find(i => i.value === postback.type))
    }
  }, [postbackOptions])

  return (
    <>
      {!loading ?
        <Form onSubmit={submitHandler}>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Name</Label>
            <Input
              name="name"
              required={true}
              placeholder="Name"
              autoComplete="off"
              defaultValue={postback?.name ?? ''}
            />
          </div>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">URL</Label>
            <Input
              name="url"
              required={true}
              placeholder="URL"
              autoComplete="off"
              defaultValue={postback?.url ?? ''}
            />
          </div>
          <div className="form-group mb-3">
            <Label htmlFor="choices-multiple-default" className="form-label mb-0">Type</Label>
            <Select
              placeholder="Choose..."
              name="type"
              value={selectedPostbackOption}
              onChange={(selected) => {
                setSelectedPostbackOption(selected)
              }}
              options={postbackOptions}
            />
          </div>
          <div className="hstack justify-content-end">
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" color="primary" className="btn">Save</Button>
          </div>
        </Form> :
        <span>Loading...</span>
      }
    </>
  )
}

export default EditPostback
