import React from 'react'
import {Container} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import TicketsTable from "./TicketsTable"

const Tickets = () => {
  document.title = 'Tickets | ' + import.meta.env.VITE_APP_NAME
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Tickets" />
          <TicketsTable withFilters={true}/>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Tickets
