import {cryptoRates} from '../../api/widget'
import {setCryptoRates} from "./reducer"

export const getCryptoRatesData = () => async (dispatch) => {
  try {
    let response

    response = await cryptoRates()

    if (response) {
      // TODO: needs to be reworked to a different exclusion method
      dispatch(setCryptoRates(response.filter(i => i.currency.code !== 'USDT (ERC20)')))
    }
  } catch (error) {
    console.log(error)
  }
}
