import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import React, {useContext, useState} from "react"
import {ModalContext} from "../../../../contexts"
import {changePassword} from "../../../../api/customers"
import {toast} from "react-toastify"
import classnames from "classnames";

const ChangePassword = ({id}) => {
  const {closeModal} = useContext(ModalContext)
  const [isPassVisible, setIsPassVisible] = useState(false)
  const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false)

  const submitHandler = e => {
    e.preventDefault()

    const data = new FormData(e.target)
    data.append('customer_id', id)

    changePassword(data).then(r => {
        if (r.success) {
          toast.success(r.message)
          closeModal()
        } else {
          toast.error(r.message)
        }
      })
      .catch(r => {
        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        }
      })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12}>
            <div className="mb-3">
              <label className="form-label mb-0">New Password<span className="text-danger">*</span></label>
              <div className="position-relative auth-pass-inputgroup">
                <Input
                  type={isPassVisible ? "text" : "password"}
                  className="form-control pe-5"
                  name="new_password"
                  placeholder="Enter new password"
                  autoComplete="off"
                  id="newpasswordInput"
                />
                <Button
                  color="link"
                  onClick={() => setIsPassVisible(!isPassVisible)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                  type="button"
                >
                  <i className="ri-eye-fill align-middle"></i>
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="mb-3">
              <label className="form-label mb-0">Confirm Password<span className="text-danger">*</span></label>
              <div className="position-relative auth-pass-inputgroup">
                <Input
                  type={isConfirmPassVisible ? "text" : "password"}
                  className="form-control pe-5 password-input"
                  name="new_password_confirmation"
                  placeholder="Confirm password"
                  autoComplete="off"
                  id="confirmpasswordInput"
                />
                <Button
                  color="link"
                  onClick={() => setIsConfirmPassVisible(!isConfirmPassVisible)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                  type="button"
                >
                  <i className="ri-eye-fill align-middle"></i>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" color="primary" className="btn">Submit</Button>
          </div>
        </Row>
      </Form>
    </>
  )
}

export default ChangePassword
