import {useEffect, useState} from "react"
import {getLoggedinUser} from "../../helpers/api_helper"
import {useDispatch, useSelector} from "react-redux"
import {setProfile} from "../../slices/auth/profile/reducer"
import {checkIfSuperAdmin} from "../../helpers/user"

const useProfile = () => {
  const dispatch = useDispatch()
  const userProfileSession = getLoggedinUser()
  let token =
    userProfileSession &&
    userProfileSession["access_token"]
  const [loading, setLoading] = useState(!userProfileSession)
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession.user : null
  )

  useEffect(() => {
    const userProfileSession = getLoggedinUser()
    let token =
      userProfileSession &&
      userProfileSession["access_token"]
    setUserProfile(userProfileSession ? userProfileSession.user : null)
    dispatch(setProfile(userProfileSession))
    setLoading(!token)
  }, [])

  return {userProfile, loading, token}
}

const useLockScreen = () => {
  const lockScreen = localStorage.getItem('lockScreen')

  return {lockScreen}
}

function useUserCan(permissions, andCondition = false) {
  if (! Array.isArray(permissions)) permissions = [permissions]

  const userPermissions = useSelector(s => s.Profile.all_permissions)
  const userRole = useSelector(s => s.Profile.role)

  if (checkIfSuperAdmin(userRole)) {
    return true
  }

  if (andCondition) {
    for (let permission of permissions) {
      if (! userPermissions.includes(permission)) {
        return false
      }
    }

    return true
  } else {
    for (let permission of permissions) {
      if (userPermissions.includes(permission)) {
        return true
      }
    }

    return false
  }
}

export function useUserHasRole(roles, isSuperAdmin = true) {
  if (! Array.isArray(roles)) roles = [roles]

  const userRole = useSelector(state => state.Profile.role)

  if (checkIfSuperAdmin(userRole)) {
    return isSuperAdmin
  }

  return roles.includes(userRole)
}

export {useProfile, useLockScreen, useUserCan};
