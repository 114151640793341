import {Button, Col, Form, FormGroup, Input, Label} from "reactstrap"
import {useContext, useState} from "react"
import {ModalContext} from "../../../contexts"
import {editBankDetail} from "../../../api/settings"
import {toast} from "react-toastify"

const EditBankDetail = ({name, id, updateTable, fields}) => {
  const {closeModal} = useContext(ModalContext)
  const [bankDetailName, setBankDetailName] = useState(name)
  const [editedFields, setEditedFields] = useState(fields)
  const [isFieldVisible, setIsFieldVisible] = useState(false)

  const bankDetailNameChangeHandler = (e) => {
    setBankDetailName(e.target.value)
  }

  const fieldsChangeHandler = (index, fieldName, value) => {
    const updatedFields = [...editedFields]
    updatedFields[index][fieldName] = value
    setEditedFields(updatedFields)
  }

  const removeFieldHandler = (index) => {
    const updatedFields = [...editedFields]
    updatedFields.splice(index, 1)
    setEditedFields(updatedFields)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    const data = {
      bank_details_id: id,
      change_bank_details_name: bankDetailName,
      fields: editedFields
    }

    editBankDetail(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  const addFieldHandler = () => {
    if (!isFieldVisible) {
      setEditedFields([...editedFields, {key: '', value: ''}])
    }
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="d-flex flex-column">
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Name</Label>
            <Input
              placeholder='Enter name'
              autoComplete="off"
              value={bankDetailName}
              onChange={bankDetailNameChangeHandler}
            />
          </div>
          <div className="d-flex flex-column">
            {editedFields.length > 0 &&
              editedFields.map((field, index) => (
                <div className="d-flex align-items-center justify-content-between" key={index}>
                  <Col className="col-3">
                    <FormGroup>
                      <Input
                        name={`key${index}`}
                        value={field.key || ''}
                        onChange={e =>
                          fieldsChangeHandler(index, 'key', e.target.value)}
                        placeholder="Ex. IBAN"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="col-7">
                    <FormGroup>
                      <Input
                        name={`value${index}`}
                        value={field.value || ''}
                        onChange={e =>
                          fieldsChangeHandler(index, 'value', e.target.value)}
                        placeholder="GB29 NWBK 6016 1331 9268 19"
                      />
                    </FormGroup>
                  </Col>
                  <div className="mb-3">
                    <Button type="button" onClick={() => removeFieldHandler(index)} className="btn btn-danger">
                      X
                    </Button>
                  </div>
                </div>
              ))}
          </div>
          <div>
            <hr className="w-100 px-0 mt-0"/>
            <Button type="button" color="primary" onClick={addFieldHandler} className="btn mb-3">Add Field</Button>
          </div>
          <div className="hstack justify-content-end">
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" color="primary" className="btn">Save changes</Button>
          </div>
        </div>
      </Form>
    </>
  )
}

export default EditBankDetail
