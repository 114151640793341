import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import React, {useEffect, useState} from "react"
import {readAllNotification} from "../../api/notifications"
import NotificationsTable from "./NotificationsTable"
import {getNotifications} from "../../slices/notification/thunk"
import {useDispatch, useSelector} from "react-redux"
import {setNotifications} from "../../slices/notification/reducer"

function Notifications() {
  const dispatch = useDispatch()
  const notifications = useSelector(s => s.Notification.notifications);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

	const getNotificationsData = () => {
		getNotifications().then(r => dispatch(setNotifications(r)))
  }

  const togglePageButtons = (disabled = false) => {
    let buttons = document.querySelectorAll('.notifications-page button')
    buttons?.forEach(i => i.disabled = disabled)
  }

  const markAllAsReadHandler = (e) => {
    togglePageButtons(true)
    readAllNotification().then(() => getNotificationsData())
  }

  useEffect(() => {
    setUnreadNotificationsCount(notifications.reduce((a, i) => a + Number(!i.read_at), 0))
    togglePageButtons()
  }, [notifications]);

  return (
    <React.Fragment>
      <div className="page-content notifications-page">
        <Container fluid>
          <Row>
            <Col className="col-12">
              <BreadCrumb title="Crypto" pageTitle="Invoices"/>
              <Row>
                <Col lg={12}>
                  <Card id="orderList">
                    <CardHeader className="border-0">
                      <Row className="align-items-center gy-3">
                        <div className="col-sm">
                          <h5 className="card-title mb-0">Notifications</h5>
                        </div>
                        <div className="col-sm-auto">
                          <div className="d-flex gap-1 flex-wrap">
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={markAllAsReadHandler}
                            >
                              <i className="bx bx-list-check fs-20 align-bottom me-1"></i>{" "}
                              Mark all as read
                            </button>
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                    <NotificationsTable
                      withFilters={false}
                      updateBellNotifications={getNotificationsData}
                      togglePageButtons={togglePageButtons}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Notifications
