import {APIClient} from "../../../helpers/api_helper"
import React, {useContext, useEffect, useReducer, useRef, useState} from "react"
import {ModalContext} from "../../../contexts"
import {Button, Form, FormGroup, Input, Label} from "reactstrap"
import Select from "react-select"
import {createEmployee} from "../../../api/employees"
import {toast} from "react-toastify"

function formFieldsReducer(fields, action) {
  switch (action.type) {
    case 'add-invalid': {
      return fields.map(obj => {
        if (obj.name === action.name) {
          return {...obj, invalid: true}
        }

        return obj
      })
    }
    case 'remove-invalid': {
      return fields.map(obj => {
        return {...obj, invalid: false}
      })
    }
    case 'fill-teams': {
      return  fields.map(obj => {
        if (obj.name === 'teams') {
          return {...obj, options: action.data}
        }

        return obj
      })
    }
    case 'fill-roles': {
      return  fields.map(obj => {
        if (obj.name === 'role') {
          return {...obj, options: action.data}
        }

        return obj
      })
    }
  }
}

const initialFormFields = [
  {name: 'first_name', className: 'form-control', type: 'text', label: "First name", autoComplete: 'off'},
  {name: 'last_name', className: 'form-control', type: 'text', label: "Last name", autoComplete: 'off'},
  {name: 'email', autoComplete: 'off', className: 'form-control', type: 'text', label: "Email"},
  {name: 'password', autoComplete: 'off', className: 'form-control', type: 'password', label: "Password"},
  {name: 'role', type: 'select', label: "Role", options: [], isMulti: false},
  {name: 'teams', type: 'select', label: "Team", options: [], isMulti: true},
]

const CreateEmployee = (props) => {
  const api = new APIClient
  const {closeModal} = useContext(ModalContext)

  const selectedData = useRef({})
  const [isPassShow, setIsPassShow] = useState(false)
  const [formFields, reduceFormFields] = useReducer(formFieldsReducer, initialFormFields)

  const submitHandler = (e) => {
    e.preventDefault()
    reduceFormFields({
      type: 'remove-invalid',
    })

    let formData = new FormData(e.target)
    let data = Object.assign(Object.fromEntries(formData.entries()), selectedData.current)

    createEmployee({...data}).then((res) => {
      closeModal()
      let message = res?.message || 'Empty response message.'

      if (res.success) {
        toast.success(message)
        props.updateTable()
      } else {
        toast.error(message)
      }
    }).catch(res => {
      if (res.errors) {
        Object.entries(res.errors).forEach(entry => {
          const [key, value] = entry
          reduceFormFields({
            name: key,
            type: 'add-invalid',
            error: value,
          })
        })
      }
    })
  }

  const selectedDataHandler = (data, name, isMulti) => {
    let prevState = selectedData.current
    const currentData = {};

    if (name) {
      if (!prevState) prevState = {}

      if (isMulti) {
        currentData[name] = data.map(i => i.value)
      } else {
        currentData[name] = data.value
      }

      selectedData.current = {...prevState, ...currentData}
    }
  }

  useEffect(() => {
    api.get('/spa/select-list-data/', {name: 'departments_teams'}).then((res) => {
      reduceFormFields({
        type: 'fill-teams',
        data: res
      })
    })

    api.get('/spa/select-list-data/', {name: 'team_based_roles_list'}).then(res => {
      reduceFormFields({
        type: 'fill-roles',
        data: res
      })
    })
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        {formFields && formFields.map(field => {
          if (field.type === 'select') {
            return (
                <FormGroup key={field.name}>
                  <Label htmlFor="choices-multiple-default" className="form-label mb-0">{field.label}</Label>
                  <Select
                      placeholder="Choose..."
                      {...field}
                      onChange={(selected) => {
                        selectedDataHandler(selected, field.name, field.isMulti)
                      }}
                  />
                </FormGroup>
            )
          } else {
            return (
                <div className="form-group mb-3" key={field.name}>
                  <Label className="form-label mb-0">{field.label}</Label>
                  <div className="position-relative auth-pass-inputgroup">
                    <Input
                      {...field}
                      type={field.name === 'password' ? (isPassShow ? "text" : "password") : field.type}
                      placeholder={field.label}
                    />
                    {field.name === 'password' &&
                      <Button
                        color="link"
                        onClick={() => setIsPassShow(!isPassShow)}
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    }
                  </div>
                  {/*<FormFeedback type="invalid">{field?.error || 'Required'}</FormFeedback>*/}
                </div>)
          }
        })}
        <div className="hstack justify-content-end">
          <Button type="submit" color="primary" className="btn">Submit</Button>
        </div>
      </Form>
    </>
  )
}

export default CreateEmployee
