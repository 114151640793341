import {Card, CardBody} from "reactstrap"
import React from "react"
import TicketsTable from "../../Tickets/TicketsTable"

const Tickets = ({activeTab, customerId}) => {

  return (
    <Card>
      <CardBody>
        <div className="acitivity-timeline">
          {activeTab === 'tickets' ? <TicketsTable customerId={customerId}/> : ''}
        </div>
      </CardBody>
    </Card>
  )
}

export default Tickets
