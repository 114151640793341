import {Card, CardBody, Col, Row} from "reactstrap";
import React from "react";
import TransactionsTable from "../../Transactions/TransactionsTable";

const Transactions = ({walletId, activeTab, methodsRef, updateProfile}) => {
	return (
	<Card>
		<CardBody>
			<Row>
				<Col lg={12}>
					{activeTab === 'transactions'
						? <TransactionsTable walletId={walletId} methodsRef={methodsRef} updateProfile={updateProfile} /> :
						''
					}
				</Col>
			</Row>
		</CardBody>
	</Card>
	)
}

export default Transactions
