import {Button, Form, Input, Label} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {createDocument} from "../../../api/settings"
import {toast} from "react-toastify"
import {selectListData} from "../../../api/general"
import Select from "react-select"

const AddDocument = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [checked, setChecked] = useState(false)
  const [documentCategoriesOptions, setDocumentCategoriesOptions] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')

  const getDocumentCategoriesData = () => {
    selectListData('document_categories').then(r => {
      setDocumentCategoriesOptions(r)
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())
    data.hidden_from_customer = checked

    createDocument(data).then(r => {
      closeModal()
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  useEffect(() => {
    getDocumentCategoriesData()
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Document type name</Label>
            <Input
              name="type"
              placeholder="Document type name"
              autoComplete="off"
            />
          </div>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Document Category</Label>
            <Select
              name="document_category_id"
              options={documentCategoriesOptions}
              isClearable={true}
              onChange={selected => {
                if (selected) {
                  setSelectedCategory(selected.value)
                }
              }}
            />
          </div>
          <div className="form-check form-switch form-switch-left form-switch-sm mt-3 mb-3">
            <Input
              className="form-check-input"
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked)
              }}
              type="checkbox"
            />
            <Label className="form-check-label text-muted" for="hidden_from_customer">
              Hidden from customer
            </Label>
          </div>
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default AddDocument
