import {ModalContext} from "../../contexts"
import React, {useContext, useEffect, useState} from "react"
import {Button, Table} from "reactstrap"
import {Link} from "react-router-dom"
import {deleteAsset, getSettingsAssets} from "../../api/settings"
import AddAssets from "./Modals/AddAssets"
import EditAsset from "./Modals/EditAsset"
import {toast} from "react-toastify"
import {useUserCan} from "../../Components/Hooks/UserHooks"

const DefaultAssets = () => {
  const [assets, setAssets] = useState([])
  const {openModal} = useContext(ModalContext)

  // Permissions
  const canCreateAsset = useUserCan('create_asset')
  const canEditAsset = useUserCan('edit_asset')
  const canDeleteAsset = useUserCan('delete_asset')

  const getAssetsData = () => {
    getSettingsAssets().then((r) => {
      setAssets(r)
    })
  }

  const updateAsset = () => {
    getAssetsData()
  }

  const addAssetHandler = () => {
    openModal({
      title: "Add Asset",
      content: <AddAssets updateTable={getAssetsData}/>
    })
  }

  const editAssetHandler = (id, address) => {
    openModal({
      title: "Edit Asset",
      content: <EditAsset id={id} address={address} updateTable={updateAsset}/>
    })
  }

  const deleteAssetHandler = (id) => {
    if (confirm('Do you really want to disable asset?')) {
      deleteAsset(id).then((r) => {
        if (r.success) {
          toast.success(r.message)
          getAssetsData()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  useEffect(() => {
    getAssetsData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-end mt-1">
        {canCreateAsset &&
        <Button color="primary" className="btn mb-3" onClick={addAssetHandler}>
          Add Asset
        </Button>}
      </div>
      <div className="table-responsive">
        <Table className="table-borderless table-responsive table-nowrap">
          <thead>
          <tr className="border-bottom">
            <th className="col-1" scope="col">ID</th>
            <th className="col-2" scope="col">NAME</th>
            <th className="col-5" scope="col">DEFAULT ADDRESS</th>
            <th className="col-3" scope="col">CURRENCY CODE</th>
            {(canEditAsset || canDeleteAsset) &&
            <th className="col-1" scope="col">ACTIONS</th>}
          </tr>
          </thead>
          <tbody>
          {assets.length > 0 ?
            assets.map((asset, idx) => {
              return (
                <tr className="border-bottom" key={idx}>
                  <td>{asset.id}</td>
                  <td>{asset.name}</td>
                  <td>{asset.default_address}</td>
                  <td>{asset.currency.code}</td>
                  {(canCreateAsset || canDeleteAsset) &&
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      {canEditAsset &&
                      <Link
                        to="#"
                        onClick={() => {
                          editAssetHandler(asset.id, asset.default_address || '')
                        }}
                        className="link-success fs-15"
                      >
                        <i className="ri-edit-2-line"></i>
                      </Link>}
                      {canDeleteAsset &&
                      <Link
                        to="#"
                        onClick={() => {
                          deleteAssetHandler(asset.id)
                        }}
                        className="link-danger fs-15"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Link>}
                    </div>
                  </td>}
                </tr>
              )
            }) :
            (<tr>
              <td colSpan={5} className='text-center'>No Data</td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default DefaultAssets
