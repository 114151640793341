import React, {useEffect, useState} from 'react'
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  CardHeader,
  TabContent, TabPane, Spinner, Button
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {
  getPermissions,
  updatePermission,
  selectAllPermissions,
  unselectAllPermissions
} from "../../api/permissions"
import classnames from "classnames"
import PermissionsTable from "../../Components/Common/PermissionsTable"
import {toast} from "react-toastify"

const Roles = () => {
  const [roles, setRoles] = React.useState([])
  const [data, setData] = React.useState({})
  const [activeTab, setActiveTab] = useState(0)
  const [overlay, setOverlay] = useState(false)

  const getPermissionsData = () => {
    getPermissions().then(r => {
      let rolesData = Object.keys(r)
      setRoles(rolesData)
      setData(r)
      setActiveTab(rolesData[0])
      setOverlay(false)
    })
  }

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const selectAllPermissionsHandler = () => {
    if (confirm('Select all permissions for this role?')) {
      setOverlay(true)

      selectAllPermissions(activeTab).then(r => {
        if (r.success) {
          toast.success(r.message)
          getPermissionsData()
        } else {
          toast.error(r.message)
          setOverlay(false)
        }
      })
    }
  }

  const unselectAllPermissionsHandler = () => {
    if (confirm('Unselect all permissions for this role?')) {
      setOverlay(true)

      unselectAllPermissions(activeTab).then(r => {
        if (r.success) {
          toast.success(r.message)
          getPermissionsData()
        } else {
          toast.error(r.message)
          setOverlay(false)
        }
      })
    }
  }

  const checkPermissionHandler = (e, permission_id, entity) => {
    let el = e.target
    el.disabled = true

    const formData = {
      role: entity,
      permission_id,
      attach: el.checked
    }

    updatePermission(formData).then(r => {
      if (r.success) {
        toast.success(r.message)
        el.disabled = false
      }
    })
  }

  useEffect(() => {
    getPermissionsData()
  }, [])

  document.title = 'Roles | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="col-12">
              <BreadCrumb title="Crypto" pageTitle="Roles"/>
              <div className="mb-3">
                <Button type="button" color="primary" className="btn" onClick={selectAllPermissionsHandler}>
                  Select all
                </Button>
                <Button type="button" color="warning" className="btn ms-3" onClick={unselectAllPermissionsHandler}>
                  Unselect all
                </Button>
              </div>
              <Card className="overflow-hidden">
                <CardHeader className="d-flex justify-content-center">
                  <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    {roles.length ?
                      roles.map((role, idx) => (
                        <NavItem className="cursor-pointer fs-14 mx-3" key={idx}>
                          <NavLink
                            className={classnames({active: activeTab === role})}
                            onClick={() => {
                              tabChange(role)
                            }}>
                            <i className="fas fa-home"></i>
                            {role.capitalize()}
                          </NavLink>
                        </NavItem>
                      )) : (<Spinner color="primary" className="my-3">Loading ...</Spinner>)}
                  </Nav>
                </CardHeader>
                <CardBody>
                  {roles.map((role, idx) => (
                    <TabContent key={idx} activeTab={activeTab}>
                      <TabPane tabId={role}>
                        <div className="overflow-scroll">
                          <PermissionsTable
                            className="w-100"
                            data={data[role]}
                            entity={role}
                            overlay={overlay}
                            checkPermissionHandler={checkPermissionHandler}
                          />
                        </div>
                      </TabPane>
                    </TabContent>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Roles
