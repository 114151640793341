import React, {useContext, useEffect, useRef, useState} from "react"
import {Button, Container} from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import PostbacksTable from "./PostbacksTable"
import {ModalContext} from "../../../contexts"
import CreatePostback from "./Modals/CreatePostback"
import {getPostbacks} from "../../../api/postbacks"
import {useUserCan} from "../../../Components/Hooks/UserHooks";

const Postbacks = () => {
  const {openModal} = useContext(ModalContext)
  const [postbacks, setPostbacks] = useState([])
  const methodsRef = useRef(null)

  // Permissions
  const canCreatePostback = useUserCan('create_postback')

  const updateTable = () => {
    methodsRef.current.updateTable()
  }

  const getPostbacksData = () => {
    getPostbacks().then(r => {
      setPostbacks(r)
    })
  }

  const addPostbackHandler = () => {
    openModal({
      title: "Add Postback",
      content: <CreatePostback updateTable={updateTable}/>
    })
  }

  useEffect(() => {
    getPostbacksData()
  }, [])

  document.title = 'Postbacks | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Postbacks"/>
          {canCreatePostback &&
          <Button color="primary" type="button" className="btn btn-primary mb-3" onClick={addPostbackHandler}>
            Add Postback Configuration
          </Button>}
          <PostbacksTable methodsRef={methodsRef} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Postbacks
