import Select from "react-select"
import {useEffect, useState} from "react"

function FormSelect({name, label, value, extraAttrs}) {
  const [selectedValue, setSelectedValue] = useState(value)

  useEffect(() => {
    setSelectedValue(value)
  }, [value]);

  return (
    <Select
      name={name}
      placeholder={label}
      {...extraAttrs}
      value={selectedValue}
      onChange={(selected) => setSelectedValue(selected)}
    >
    </Select>
  )
}

export default FormSelect
