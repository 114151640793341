import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap"
import PermissionsTable from "../../../Components/Common/PermissionsTable"
import {
  getProfile,
  selectAllDirectPermissions,
  unselectAllDirectPermissions,
  updatePermission
} from "../../../api/employees"
import {toast} from "react-toastify"
import {getPermissions} from "../../../api/permissions"

const PermissionsTab = ({id, activeTab}) => {
  const [overlay, setOverlay] = useState(false)
  const [permissionsData, setPermissionsData] = useState([])
  const [profileData, setProfileData] = useState({})

  const getProfileData = () => {
    setOverlay(true)
    getProfile(id).then(r => {
      setProfileData(r)
    })
  }

  const getPermissionsData = (role = 'agent') => {
    getPermissions(role).then(r => {
      setPermissionsData(r)
      setOverlay(false)
    })
  }

  const checkPermissionHandler = (e, permission_id, employee_id) => {
    let el = e.target
    el.disabled = true

    const formData = {
      employee_id,
      permission_id,
      attach: el.checked
    }

    updatePermission(formData).then(r => {
      if (r.success) {
        toast.success(r.message)
        el.disabled = false
      }
    })
  }

  const selectAllDirectPermissionsHandler = () => {
    if (confirm(`Select all permissions for ${profileData.user.profile.first_name} ${profileData.user.profile.last_name} ?`)) {
      setOverlay(true)
      selectAllDirectPermissions({employee_id: id, role: profileData.role}).then(r => {
        if (r.success) {
          toast.success(r.message)
          getProfileData()
          setOverlay(false)
        } else {
          toast.error(r.message)
          setOverlay(false)
        }
      })
    }
  }

  const unselectAllDirectPermissionsHandler = () => {
    if (confirm(`Unselect all permissions for ${profileData.user.profile.first_name} ${profileData.user.profile.last_name} ?`)) {
      setOverlay(true)
      unselectAllDirectPermissions({employee_id: id, role: profileData.role}).then(r => {
        if (r.success) {
          toast.success(r.message)
          getProfileData()
          setOverlay(false)
        } else {
          toast.error(r.message)
          setOverlay(false)
        }
      })
    }
  }

  useEffect(() => {
    if (activeTab === 'permissions') {
      getProfileData()
    }
  }, [activeTab])

  useEffect(() => {
    getPermissionsData(profileData.role)
  }, [profileData])

  return (
    <React.Fragment>
      <div className="mb-3 d-flex justify-content-end">
        <Button
          type="button"
          color="primary"
          className="btn"
          onClick={selectAllDirectPermissionsHandler}
        >
          Select all
        </Button>
        <Button
          type="button"
          color="warning"
          className="btn ms-3"
          onClick={unselectAllDirectPermissionsHandler}
        >
          Unselect all
        </Button>
      </div>
      <div className="overflow-scroll">
        <PermissionsTable
          entity={id}
          overlay={overlay}
          data={permissionsData}
          checkPermissionHandler={checkPermissionHandler}
          protectedRolesPermissions={true}
          directPermissions={profileData.permissions || []}
        />
      </div>
    </React.Fragment>
  )
}

export default PermissionsTab
