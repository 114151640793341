import {APIClient} from "../helpers/api_helper";

const api = new APIClient()

export function getNotifications() {
	return api.get('/spa/notifications', {})
}

export function toggleNotification(notification_ids) {
	return api.get(`/spa/notifications/toggle`, {notification_ids})
}

export function readAllNotification() {
	return api.get(`/spa/notifications/read-all`, {})
}
