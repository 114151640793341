//Include Both Helper File with needed methods
import {APIClient, setAuthorization} from '../../../helpers/api_helper'

import {loginSuccess, logoutUserSuccess, apiError, reset_login_flag} from './reducer';
import {setProfile} from "../profile/reducer";

const api = new APIClient();

export const loginUser = (user, history) => async (dispatch) => {
  try {
    let response;

    response = api.post('/auth', {
      email: user.email,
      password: user.password,
      remember_me: user.remember_me,
    });

    let data = await response;

    if (data) {
      setAuthorization(data.access_token);
      dispatch(loginSuccess(data));
      dispatch(setProfile(data));
      localStorage.setItem('authUser', JSON.stringify(data));
      history('/dashboard');
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem("authUser");
    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const socialLogin = (type, history) => async (dispatch) => {
  try {
    let response;

    // response = postSocialLogin(data);

    const socialdata = await response;
    if (socialdata) {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      dispatch(loginSuccess(response));
      history('/dashboard')
    }

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
