import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  loading: false,
  isUserLogout: false,
  error: false, // while error appear
  errorMsg: '', // error message
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    loginInProgress(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true
      state.user = {}
    },
    apiError(state, action) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    reset_login_flag(state) {
      state.error = null
      state.loading = false;
      state.errorMsg = false;
    }
  },
});

export const {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag
} = loginSlice.actions

export default loginSlice.reducer;
