import BaseTable from "../../Components/Tables/BaseTable"
import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from "react"
import {Link} from "react-router-dom"
import {useUserCan, useUserHasRole} from "../../Components/Hooks/UserHooks"
import {closeTicket} from "../../api/tickets"
import {toast} from "react-toastify"
import {prettyDate} from "../../helpers/utils"
import {setColumnsSetting} from "../../slices/tickets/reducer"
import {useSelector} from "react-redux"
import {filtersList} from "../../api/general"

const TicketsTable = ({customerId, withFilters, methodsRef}) => {
  const baseTableMethodsRef = useRef(null)
  const columnsSetting = useSelector(s => s.Tickets.columnsSetting)
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [isGoToNextCustomer, setIsGoToNextCustomer] = useState(false)
  const [filters, setFilters] = useState([])
  const tableRowsPerPage = 'tickets_rows_per_page'
  let filterStorageKey = 'tickets_filter'

  const selectedRowsHandler = (rows) => {
    setSelectedRowIds(rows.map((row) => row.id))
  }

  // Permissions
  const canCloseTicket = useUserCan('close_ticket')
  const canViewTicket = useUserCan('view_ticket')
  const canViewCustomer = useUserCan('view_customer')
  const isAdmin = useUserHasRole(['admin'])
  const isSuperAdmin = useUserHasRole(['super_admin'])

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  useImperativeHandle(methodsRef, () => ({
    updateTable: () => updateTable()
  }))

  // When isGoToNextCustomer fired (first call fired from component BaseTable)
  useEffect(() => {
    if (customerId && isGoToNextCustomer) {
      updateTable()
      setIsGoToNextCustomer(false)
    }

    setIsGoToNextCustomer(true)
  }, [customerId])

  const closeTicketHandler = (id) => {
    if (confirm('Do you want to close ticket?')) {
      closeTicket(id).then((r) => {
        if (r.success) {
          toast.success(r.message)
          updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>Ticket ID</span>,
        selector: canViewTicket ?
          (row => <Link to={`/tickets/list/show/${row.id}`}>C-{String(row.id).padStart(4, '0')}</Link>) :
          (row => <>C-{String(row.id).padStart(4, '0')}</>),
        sortable: true,
        width: "110px",
        omit: false
      },
      {
        id: "subject",
        name: <span className='font-weight-bold fs-14'>Subject</span>,
        selector: row => row.subject,
        width: "190px",
        omit: false
      },
      {
        id: "message",
        name: <span className='font-weight-bold fs-14'>Message</span>,
        selector: row => row.message,
        width: "300px",
        omit: false
      },
      {
        id: "customer",
        name: <span className='font-weight-bold fs-14'>Customer</span>,
        selector: canViewCustomer ?
          (row => <Link to={`/customers/show/${row.customer_id}`}>{row?.customer?.name} #{row?.customer?.id}</Link>) :
          (row => `#${row?.customer?.id} ${row?.customer?.name}`),
        width: "160px",
        omit: false
      },
      {
        id: "assigned_employee",
        name: <span className='font-weight-bold fs-14'>Assigned Agent</span>,
        selector: row => `${row?.formatted_employee}`,
        width: "150px",
        omit: false
      },
      {
        id: "last_update",
        name: <span className='font-weight-bold fs-14'>Last Update</span>,
        selector: row => (prettyDate(row.formatted_date)
        ),
        width: "175px",
        omit: false
      },
      {
        id: "status",
        name: <span className='font-weight-bold fs-14'>Status</span>,
        selector: row => (<><span className={'badge bg-' + row.ticket_status}>{row.ticket_name}</span></>),
        width: "140px",
        sortable: true,
        omit: false
      },
      {
        id: "actions",
        name: <span className='font-weight-bold fs-14'>Actions</span>,
        width: "110px",
        selector: row => (
          <>
            <div className="hstack gap-3 flex-wrap">
              {canViewTicket  &&
                <Link to={`/tickets/list/show/${row.id}`}><i className="ri-eye-line"></i></Link>}
              {canCloseTicket &&
                <Link to="#" onClick={() => {closeTicketHandler(row.id)}} className="link-danger fs-15">
                  <i className="ri-close-line"></i>
                </Link>}
            </div>
          </>
        ),
        omit: false
      }
    ], [canViewTicket, canCloseTicket, canViewCustomer])

  useEffect(() => {
    if (withFilters) {
      filtersList(filterStorageKey).then(r => {
        setFilters(r)
      })
    }
  }, [withFilters])

  // const filters = useMemo(
  //   () => [
  //     {
  //       title: 'Ticket ID',
  //       name: 'ticket_id',
  //       type: 'text',
  //     },
  //     {
  //       title: 'Customer ID',
  //       name: 'customer_id',
  //       type: 'text',
  //     },
  //     {
  //       title: 'Status',
  //       name: 'ticket_status',
  //       type: 'select',
  //     },
  //     (isAdmin || isSuperAdmin) && (
  //       {
  //         title: 'Agents',
  //         name: 'agents',
  //         type: 'select',
  //         is_multiple: true,
  //       }
  //     ),
  //   ], [isAdmin, isSuperAdmin])

  return (
    <BaseTable
      columns={columns}
      title="Tickets"
      filterFields={withFilters ? filters : []}
      url="/spa/tickets/data"
      selectedRowsHandler={selectedRowsHandler}
      methodsRef={baseTableMethodsRef}
      additionalRequest={customerId ? {customer_id: customerId} : null}
      columnsSetting={columnsSetting}
      columnsSettingHandler={setColumnsSetting}
      conditionalRowStyles={[{
        when: (row) => selectedRowIds.includes(row.id),
        classNames: ['selected_Row']
      }
      ]}
      tableRowsPerPage={tableRowsPerPage}
      filterStorageKey={filterStorageKey}
    />
  )
}

export default TicketsTable
