import {APIClient} from "../helpers/api_helper"

const api = new APIClient()

export function customerImport(data) {
  return api.post('/spa/import/store', data)
}

export function exportData(id) {
  return api.post(`/spa/import/export/${id}`, {})
}
