import React, {useContext, useEffect, useReducer, useState} from "react";
import {ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, UncontrolledDropdown} from "reactstrap";
import {ModalContext} from "../../../../contexts";
import ChangeAction from "./Modals/ChangeAction";
import {applyActionsToCustomers} from "../../../../api/mass-actions";
import {toast} from "react-toastify";

function actionsListReducer(fields, action) {
  switch (action.type) {
    case 'insert': {
      if (fields.find(i => i.action === action.action)) {
        return fields.map(obj => {
          if (obj.action === action.action) {
            return {action: action.action, data: {...action.data}}
          }
          return obj
        })
      }

      return [...fields, {action: action.action, data: {...action.data}}]
    }
    case 'remove': {
      return [...fields.filter(field => field.action !== action.action)]
    }
    case 'clearAll': {
      return []
    }
  }
}

/**
 * ['change_status', 'change_department']
 *
 * [{action: 'change_status', data: 'call again'}, {...}]
 */
const initialActionsList = []

const MassActionsForm = ({filters, selectedRows = []}) => {
  const {openModal} = useContext(ModalContext)
  const [actionsList, reduceActionsList] = useReducer(actionsListReducer, initialActionsList)
  const [applyToSelectedCheckbox, setApplyToSelectedCheckbox] = useState(false)

  const addAction = (action, data) => {
    reduceActionsList({
      type: 'insert',
      action: action,
      data: data,
    })
  }

  const removeAction = (action) => {
    reduceActionsList({
      type: 'remove',
      action: action,
    })
  }

  const applyActions = () => {
    if (confirm()) {
      applyActionsToCustomers(actionsList, filters, applyToSelectedCheckbox, [...selectedRows.map(i => i.id)])
        .then(r => {
          if (r.success) {
            toast.success(r.message)
          } else {
            toast.error(r.message)
          }
        }).catch(r => {
        toast.error(r)
      })

      reduceActionsList({
        type: 'clearAll'
      })
    }
  }

  const openModalHandler = (meta) => {
    // Meta: title, label, selectListDataKey, action
    openModal({
      title: meta.title,
      content: <ChangeAction
        addAction={addAction}
        meta={meta}
      />
    })
  }

  return <>
    {actionsList.length > 0 &&
      <div className="mt-2">
        <ul>
          {actionsList.map((action, idx) => {
            return (
              <li key={idx}>
                {action.data.title}: <span
                className="badge text-info border border-info">{action.data.valueLabel}</span>
                <button className="btn btn-danger px-1 py-0 ms-1 btn-sm flex align-items-center"
                        onClick={() => removeAction(action.action)}>
                  <span className="mdi mdi-close-thick"></span>
                </button>
              </li>
            )
          })}
        </ul>
      </div>
    }

    <div className="mt-1">
      <ButtonGroup>
        <UncontrolledDropdown>
          <DropdownToggle tag="button" className="btn btn-outline-secondary ps-2 border-dashed">
            <i className="ri-add-line align-bottom me-1"></i>Add Action
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-xl-end dropdown-menu-end">

            {/* BEGIN Attach employee */}
            {/*<DropdownItem*/}
            {/*  className="d-flex justify-content-between align-items-center"*/}
            {/*  onClick={() => {*/}
            {/*    openModalHandler({*/}
            {/*      title: 'Attach employee',*/}
            {/*      label: 'Employee',*/}
            {/*      selectListDataKey: 'agents',*/}
            {/*      action: 'attach_employee',*/}
            {/*    })*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Attach employee*/}
            {/*</DropdownItem>*/}
            {/* END Attach employee */}

            {/* BEGIN Detach employee */}
            <DropdownItem
              className="d-flex justify-content-between align-items-center"
              onClick={() => addAction('detach_employee', {title: 'Detach employee', valueLabel: 'Filtered'})}
            >
              Detach employee
            </DropdownItem>
            {/* END Detach employee */}

            {/* BEGIN Change status */}
            <DropdownItem
              className="d-flex justify-content-between align-items-center"
              onClick={() => {
                openModalHandler({
                  title: 'Change status',
                  label: 'Status',
                  selectListDataKey: 'customer_status',
                  action: 'change_status',
                })
              }}
            >
              Change status
            </DropdownItem>
            {/* END Change status */}

            {/* BEGIN Change source */}
            <DropdownItem
              className="d-flex justify-content-between align-items-center"
              onClick={() => {
                openModalHandler({
                  title: 'Change source',
                  label: 'Source',
                  action: 'change_source',
                })
              }}
            >
              Change source
            </DropdownItem>
            {/* END Change source */}

            {/* BEGIN Change department */}
            <DropdownItem
              className="d-flex justify-content-between align-items-center"
              onClick={() => {
                openModalHandler({
                  title: 'Change department',
                  label: 'Department',
                  selectListDataKey: 'departments',
                  action: 'change_department',
                })
              }}
            >
              Change department
            </DropdownItem>
            {/* END Change department */}

            {/* BEGIN Export */}
            {/*<DropdownItem*/}
            {/*  className="d-flex justify-content-between align-items-center"*/}
            {/*  onClick={() => addAction('export', {title: 'Export', valueLabel: 'Filtered'})}*/}
            {/*>*/}
            {/*  Export*/}
            {/*</DropdownItem>*/}
            {/* END Export */}

            {/* BEGIN Delete customers */}
            <DropdownItem
              className="d-flex justify-content-between align-items-center"
              onClick={() => addAction('delete_customers', {title: 'Delete', valueLabel: 'Filtered'})}
            >
              Delete customers
            </DropdownItem>
            {/* END Delete customers */}

            {/* BEGIN Send To recipient */}
            <DropdownItem
              className="d-flex justify-content-between align-items-center"
              onClick={() => {
                openModalHandler({
                  title: 'Send to Recipient',
                  label: 'Recipient',
                  selectListDataKey: 'recipients',
                  action: 'send_to_recipient',
                })
              }}
            >
              Send to Recipient
            </DropdownItem>
            {/* END Send To recipient */}

          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonGroup>

      {actionsList.length > 0 &&
        <>
          <button
            type="button"
            className="btn btn-outline-success ms-2 ps-2"
            data-bs-toggle="button"
            onClick={() => applyActions()}
          >
            <i className="ri-check-double-line align-bottom me-1"></i>Apply Actions
          </button>
        </>
      }

      <div className="form-check form-switch mt-2">
        <Label htmlFor="apply_to_selected_rows_checkbox">Apply to selected rows</Label>
        <Input
          type="checkbox"
          role="switch"
          checked={applyToSelectedCheckbox}
          id="apply_to_selected_rows_checkbox"
          onChange={e => {
            setApplyToSelectedCheckbox(e.target.checked)
            // isDisablePinCodeHandler(e.target.checked)
          }}
        />
      </div>
    </div>
  </>
}

export default MassActionsForm
