import {Button, Form, FormGroup, Input, Label} from "reactstrap"
import {useContext} from "react"
import {ModalContext} from "../../../contexts"
import {createRecipient} from "../../../api/settings"
import {toast} from "react-toastify"

const AddRecipient = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    createRecipient(data).then(r => {
      closeModal()
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Recipient Name</Label>
          <Input
            name="recipient_name"
            placeholder="Name"
            autoComplete="off"
            required={true}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Settings</Label>
          <Input
            type="textarea"
            rows="4"
            name="recipient_settings"
            placeholder="Settings"
            required={true}
          />
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default AddRecipient
