import React, {useEffect, useReducer, useState} from 'react'
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import { getCalendarCategories, addCalendarEvent } from "../../../../api/calendar"
import {useFormik} from "formik"
import * as Yup from "yup"
import {toast} from "react-toastify"
import moment from "moment-timezone"
import Select from "react-select";
import {formatStringDate, timeZone} from "../../../../helpers/utils"

function dateRangeReducer(state, action) {
  switch (action.type) {
    case 'defaultDate': {
      return {...state, defaultDate: action.payload}
    }
    default: {
      return state
    }
  }
}

const dateRangeInitOptions = {
  enableTime: true,
  time_24hr: true,
  mode: "range",
  dateFormat: "Y-m-d H:i",
}

const AddCommentsToCalendar = ({ comment, closeModal }) => {
  const [categories, setCategories] = useState({})
  const [dateRangeOptions, dateRangeOptionsDispatch] = useReducer(dateRangeReducer, dateRangeInitOptions)
  const [eventTitle, setEventTitle] = useState('')
  const [eventDescription, setEventDescription] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(true)
  const [selectedEndDate, setSelectedEndDate] = useState('')
  const [selectedStartDate, setSelectedStartDate] = useState('')

  const currentDate = moment.tz(new Date(), timeZone)
  const tomorrowDate = moment.tz(currentDate, timeZone).add(1, 'days').toDate()

  const formik = useFormik({
    initialValues: {
      title: '',
      category: '',
      customers: '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Please enter a Event Name!'),
      category: Yup.string().required('Please select a Category!'),
    }),
    onSubmit: (data) => {
      data = {
        ...data,
        id: Date.now(),
        description: eventDescription,
        start: selectedStartDate ? new Date(formatStringDate(selectedStartDate)) : currentDate,
        end: selectedEndDate ? new Date(formatStringDate(selectedEndDate)) : tomorrowDate,
        customers: [comment.customer_id]
      }

      addCalendarEvent(data).then((r) => {
        if (r.success) {
          toast.success('Comment successfully added to calendar!')
          closeModal()
        } else {
          toast.error('Something went wrong!')
        }
      })
    }
  })

  useEffect(() => {
    getCalendarCategories().then(r => {
      setCategories(r)

      if (r.length > 0) {
        setSelectedCategory(r[0].category)
        formik.setFieldValue('category', r[0].category)
      }
    })

    if (comment.message.length > 40) {
      setEventDescription(comment.message)
    } else {
      setEventTitle(comment.message.replace(/\n/g, ' '))
    }
  }, [comment])

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      title: eventTitle,
      category: selectedCategory
    })
  }, [eventTitle, selectedCategory])

  return (
   <React.Fragment>
     <Modal isOpen={isModalOpen} toggle={closeModal} centered>
       <ModalHeader toggle={closeModal} className="p-3 bg-soft-info modal-title">Add Comments to Calendar</ModalHeader>
       <ModalBody>

         <Form
           className="needs-validation view-event"
           name="event-form"
           id="form-event"
           onSubmit={(e) => {
             e.preventDefault()

             if (formik.errors.category) {
               toast.error(formik.errors.category)
             } else if (formik.errors.title) {
               toast.error(formik.errors.title)
             }
             formik.handleSubmit(e)
           }}
         >
           <Row className="event-form">
             <Col xs={12}>
               <div className="mb-3">
                 <Label className="form-label">Type</Label>
                 <Input
                   className="form-group"
                   name="category"
                   id="event-category"
                   type="select"
                   value={selectedCategory}
                   onChange={(e) => {
                     setSelectedCategory(e.target.value)
                     formik.setFieldValue('category', e.target.value)
                   }}
                 >
                   {categories.length > 0 ? categories.map((category, idx) => {
                       return <option key={idx} value={category.category}>{category.title}</option>
                     }) :
                     <option value="">None</option>
                   }
                 </Input>
               </div>
             </Col>
             <Col xs={12}>
               <div className="mb-3">
                 <Label className="form-label">Event Name</Label>
                 <Input
                   className="form-control"
                   placeholder="Enter event name"
                   type="text"
                   name="title"
                   id="event-title"
                   value={eventTitle}
                   onChange={(e) => {
                     setEventTitle(e.target.value)
                     formik.setFieldValue('title', e.target.value)
                   }}
                 />
               </div>
             </Col>
             <Col xs={12}>
               <div className="mb-3">
                 <Label>Event Date</Label>
                 <div
                   className="input-group"
                 >
                   <Flatpickr
                     className="form-control"
                     id="event-start-date"
                     name="dateRange"
                     placeholder="Select Date"
                     options={{
                       ...dateRangeOptions,
                       defaultDate: [
                         moment.tz(tomorrowDate, timeZone).format('YYYY-MM-DDTHH:mm'),
                         moment.tz(tomorrowDate, timeZone).format('YYYY-MM-DDTHH:mm'),
                       ],
                     }}
                     onChange={(selectedDates) => {
                       setSelectedStartDate(selectedDates[0])
                       setSelectedEndDate(selectedDates[1])
                     }}
                   />
                   <span className="input-group-text">
                     <i className="ri-calendar-event-line"></i>
                   </span>
                 </div>
               </div>
             </Col>
             <Col xs={12}>
               <div className="mb-3">
                 <Label className="form-label">Description</Label>
                 <textarea
                   className="form-control"
                   id="event-description"
                   name="description"
                   placeholder="Enter a description"
                   rows="3"
                   value={eventDescription}
                   onChange={(e) => setEventDescription(e.target.value)}
                 ></textarea>
               </div>
             </Col>
           </Row>
           <div className="hstack justify-content-end">
             <button
               type="submit"
               className="btn btn-success"
               id="btn-save-event"
             >
               Save
             </button>
           </div>
         </Form>
       </ModalBody>
     </Modal>
   </React.Fragment>
  )
}

export default AddCommentsToCalendar
