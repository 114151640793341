import {Button, Table} from "reactstrap"
import {Link} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../contexts"
import {deleteDocument, getDocumentTypes} from "../../api/settings"
import AddDocument from "./Modals/AddDocument"
import EditDocument from "./Modals/EditDocument"
import {toast} from "react-toastify"
import {useUserCan} from "../../Components/Hooks/UserHooks"

const DocumentTypes = () => {
  const [documentTypes, setDocumentTypes] = useState([])
  const {openModal} = useContext(ModalContext)

  // Permissions
  const canCreateDocumentType = useUserCan('create_document_type')
  const canEditDocumentType = useUserCan('edit_document_type')
  const canDeleteDocumentType = useUserCan('delete_document_type')

  const getDocumentTypesData = () => {
    getDocumentTypes().then((r) => {
      setDocumentTypes(r)
    })
  }

  const updateDocument = (id, newType) => {
    const updatedDocuments = documentTypes.map((item) => {
      if (item.id === id) {
        return {...item, type: newType}
      }
      return item
    })
    setDocumentTypes(updatedDocuments)
    getDocumentTypesData()
  }

  const addDocumentHandler = () => {
    openModal({
      title: "Add Document Type",
      content: <AddDocument updateTable={getDocumentTypesData}/>
    })
  }

  const editDocumentHandler = (id, type, isHidden) => {
    openModal({
      title: "Edit Document Type",
      content: <EditDocument type={type} id={id} isHidden={isHidden} onSave={updateDocument}/>
    })
  }

  const deleteDocumentHandler = (id) => {
    if (confirm('Are you sure to delete this document type?')) {
      deleteDocument(id).then((r) => {
        if (r.success) {
          toast.success(r.message)
          getDocumentTypesData()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  useEffect(() => {
    getDocumentTypesData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-end mt-1 mb-3">
        {canCreateDocumentType &&
        <Button color="primary" className="btn" onClick={addDocumentHandler}>
          Add Document Type
        </Button>}
      </div>
      <div className="table-responsive">
        <Table className="table-borderless table-responsive table-nowrap">
          <thead className="border-bottom">
          <tr>
            <th className="col-1" scope="col">ID</th>
            <th className="col-1" scope="col">TYPE</th>
            <th className="col-1" scope="col">Category</th>
            <th className="col-9" scope="col">IS HIDDEN</th>
            {(canEditDocumentType || canDeleteDocumentType) &&
            <th className="col-1" scope="col">ACTIONS</th>}
          </tr>
          </thead>
          <tbody>
          {documentTypes.length > 0 ?
            documentTypes.map((document, idx) => {
              return (
                <tr className="border-bottom" key={idx}>
                  <td>{document.id}</td>
                  <td>{document.type}</td>
                  <td>{document.category.name}</td>
                  <td>{document?.settings?.hidden_from_customer ? 'Yes' : 'No'}</td>
                  {(canEditDocumentType || canDeleteDocumentType) &&
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      {canEditDocumentType &&
                      <Link
                        to="#"
                        onClick={() => {
                          editDocumentHandler(document.id, document.type, document?.settings?.hidden_from_customer)
                        }}
                        className="link-success fs-15"
                      >
                        <i className="ri-edit-2-line"></i>
                      </Link>}
                      {canDeleteDocumentType &&
                      <Link
                        to="#"
                        onClick={() => {deleteDocumentHandler(document.id)}}
                        className="link-danger fs-15"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Link>}
                    </div>
                  </td>}
                </tr>
              )
            }) :
            (<tr>
              <td colSpan={3} className='text-center'>No Data</td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default DocumentTypes
