import React, {useEffect, useState} from 'react'
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap"
import Select from "react-select"
import {ModalContext} from "../../../../contexts"
import {useContext} from "react"
import {createTransaction, getFiatAssets, getTransactionsStatuses} from "../../../../api/transactions"
import {selectListData} from "../../../../api/general"
import {toast} from "react-toastify"
import DatePickerWithParse from "../../../../Components/Common/DatePickerWithParse"
import moment from 'moment-timezone'
import {timeZone} from "../../../../helpers/utils"

const BankTransferModal = ({data, transactionTableMethodsRef}) => {
  const {closeModal} = useContext(ModalContext)
  const [countriesOptions, setCountriesOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [assetsOptions, setAssetsOptions] = useState([])
  const [selectedAsset, setSelectedAsset] = useState('')
  const [transactionStatusesOptions, setTransactionStatusesOptions] = useState([])
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState('')
  const [profileData, setProfileData] = useState({})

  const getAssetsData = () => {
    getFiatAssets().then(r => {
      setAssetsOptions(r)

      if (r.length) {
        setSelectedAsset(r[0])
      }
    })
  }

  const getCountriesData = (defaultCountry) => {
    selectListData('countries').then(r => {
      setCountriesOptions(r)
      if (defaultCountry) {
        setSelectedCountry(r.find(i => i.label === defaultCountry))
      }
    })
  }

  const getTransactionStatusesData = () => {
    getTransactionsStatuses().then(r => {
      setTransactionStatusesOptions(r)

      if (r.length) {
        setSelectedTransactionStatus(r[2])
      }
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    data.date = new Date(moment.tz(data.date, timeZone).format()).toUTCString()

    if (data.amount === '') {
      toast.error('Enter amount')
      btn.disabled = false
    } else {
      createTransaction(data).then(r => {
        closeModal()
        let message = r?.message || 'Empty response message.'

        if (r.success) {
          toast.success(message)
          btn.dasabled = false
          transactionTableMethodsRef?.current?.updateTable()
        } else {
          toast.error(message)
          btn.disabled = false
        }
      }).catch(r => {
        if (r.errors) {
          btn.disabled = false
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        }
      })
    }
  }

  useEffect(() => {
    getAssetsData()
    getTransactionStatusesData()

  }, [])

  useEffect(() => {
    setProfileData(data)
    getCountriesData(data.customerCountry)
  }, [data])

  return (
    <React.Fragment>
      <Form onSubmit={submitHandler}>
        <Input hidden={true} name="transaction_type" defaultValue="withdrawal" />
        <div className="mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Asset</Label>
          <Select
            name="asset_id"
            placeholder="Asset"
            options={assetsOptions}
            value={selectedAsset}
            onChange={selected => setSelectedAsset(selected)}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Amount</Label>
          <Input type="text" className="form-control" placeholder="0.00000000" name="amount" autoComplete="off" />
        </div>
        <div className="mb-3">
          <Label htmlFor="choices-multiple-default" className="form-label mb-0">Status</Label>
          <Select
            name="status"
            placeholder="Status"
            options={transactionStatusesOptions}
            value={selectedTransactionStatus}
            onChange={selected => setSelectedTransactionStatus(selected)}
          />
        </div>
        <div className="form-group mb-3">
          <Label htmlFor="inputDate" className="form-label mb-0">Date</Label>
          <DatePickerWithParse />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Fee(%)</Label>
          <Input type="text" list="fee-list" className="form-control" placeholder="Fee(%)" name="fee_value"/>
          <datalist id="fee-list">
            <option value="3.5">3.5</option>
            <option value="5">5</option>
          </datalist>
        </div>
        <div className="mb-3">
          <span className="text-muted fs-12">Customer Info</span>
        </div>
        <FormGroup className="form-group mb-3">
          <Label className="form-label mb-0">Country</Label>
          <Select
            placeholder="Country"
            id="inputCountry"
            name="country"
            value={selectedCountry}
            options={countriesOptions}
            onChange={selected => setSelectedCountry(selected)}
          />
        </FormGroup>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Canton</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="Canton"
            name="canton"
            defaultValue={profileData?.state}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Address</Label>
          <Input
            type="text"
            className="form-control"
            placeholder="Address"
            name="address"
            defaultValue={profileData.address}
          />
        </div>
        <Row className="d-flex">
          <Col xs={6}>
            <div className="form-group mb-3">
              <Label className="form-label mb-0">Postal/ZIP code</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Postal/ZIP code"
                name="postal_code"
                defaultValue={profileData.postal_code}
              />
            </div>
          </Col>
          <Col xs={6}>
            <div className="form-group mb-3">
              <Label className="form-label mb-0">City</Label>
              <Input
                type="text"
                className="form-control"
                placeholder="City"
                name="city"
                defaultValue={profileData.city}
              />
            </div>
          </Col>
        </Row>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Phone</Label>
          <Input
            type="tel"
            className="form-control"
            placeholder="Phone"
            name="phone"
            defaultValue={profileData.phone}
          />
        </div>
        <div className="mb-3">
          <span className="text-muted fs-12">Billing info</span>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Account holder</Label>
          <Input type="text" className="form-control" placeholder="Account holder" name="account_holder"/>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Bank</Label>
          <Input type="text" className="form-control" placeholder="Bank" name="bank_to_withdraw"/>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">City</Label>
          <Input type="text" className="form-control" placeholder="City" name="city_to_withdraw"/>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Account number</Label>
          <Input type="text" className="form-control" placeholder="Account number" name="account_number"/>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">IBAN</Label>
          <Input type="text" className="form-control" placeholder="IBAN" name="iban"/>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Swift code</Label>
          <Input type="text" className="form-control" placeholder="Swift code" name="swift_code"/>
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Payment reference number</Label>
          <Input
            type="text"
            className="form-control bg-soft-dark"
            readOnly
            placeholder="Payment reference number"
            name="customer_id"
            defaultValue={profileData.user_id}
          />
        </div>
        <div className="mb-4 border-bottom">
          <div className="d-flex">
            <div className="col-6 form-check form-switch mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                name="is_real_check"
                role="switch"
                id="check-box"
              />
              <Label className="form-check-label" for="check-box">Is real transaction</Label>
            </div>
            <div className="col-6 form-check form-switch mb-3">
              <Input
                className="form-check-input"
                type="checkbox"
                name="is_sync_check"
                role="switch"
                id="check-box"
              />
              <Label className="form-check-label" for="check-box">Is sync transaction</Label>
            </div>
          </div>
          <div className="col-6 form-check form-switch mb-3">
            <Input
              className="form-check-input"
              type="checkbox"
              name="is_hidden_transaction"
              role="switch"
              id="check-box"
            />
            <Label className="form-check-label" for="check-box">Hidden</Label>
          </div>
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Submit</Button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default BankTransferModal
