import React, {useContext, useRef} from 'react'
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import {ModalContext} from "../../../contexts"
import CreateSecurity from "./Modals/CreateSecurity"
import SecuritiesTable from "./SecuritiesTable"
import {useUserCan} from "../../../Components/Hooks/UserHooks";

const Securities = () => {
  document.title = 'Securities | ' + import.meta.env.VITE_APP_NAME
  const {openModal} = useContext(ModalContext)
  const tableMethodsRef = useRef(null)

  // Permissions
  const canManageSecurities = useUserCan('manage_securities')

  const updateTable = () => {
    if (tableMethodsRef.current) {
      tableMethodsRef.current.updateTable()
    }
  }

  const addNewSecurityHandler = () => {
    openModal({
      title: "Add Security",
      content: <CreateSecurity updateTable={updateTable}/>,
      size: 'lg'
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="col-12">
              <BreadCrumb title="Crypto" pageTitle="Securities"/>
            </Col>
            <Col>
              {canManageSecurities &&
                <div className="d-flex justify-content-end mt-1">
                  <Button color="primary" className="btn mb-3" onClick={addNewSecurityHandler}>
                    Add Security
                  </Button>
                </div>}
              <SecuritiesTable withFilters={true} methodsRef={tableMethodsRef}/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Securities
