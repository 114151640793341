import {Accordion, AccordionItem, Button, Col, Collapse, Form, Row, Table} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../../contexts"
import {capitalize, isEmpty} from "lodash"
import {changeRevisionStatus} from "../../../../api/customers"
import {toast} from "react-toastify"
import classnames from "classnames"
import {prettyDate} from "../../../../helpers/utils"
import {useSelector} from "react-redux"

const ProfileRevisionsModal = ({updateProfile}) => {
  const {closeModal} = useContext(ModalContext)
  const [revisionsData, setRevisionsData] = useState({})
  const [activeToggleTabs, setActiveToggleTabs] = useState([])
  const profileRevisions = useSelector((s) => s.Revisions.profileRevisions)

  const changeRevisionStatusHandler = (status, revision_id) => {
    changeRevisionStatus(status, revision_id).then(r => {
      if (r.success) {
        toast.success(r.message)
        // closeModal()
        // window.document.querySelectorAll('details').forEach(i => i.open = false)
        updateProfile()
        // setActiveToggleTabs([])
      } else {
        toast.error(r.message)
      }
    })
  }

  useEffect(() => {
    setRevisionsData(profileRevisions)
  }, [profileRevisions])

  const toggleAccordion = (idx) => {
    setActiveToggleTabs((prev) =>
      prev.includes(idx) ? prev.filter(i => i !== idx) : [...prev, idx]
    )
  }

  return (
    <>
      {revisionsData?.length ?
        Object.entries(revisionsData).map(([key, revision], idx) => (
          <Accordion
            className={classnames("custom-accordionwithicon",
              {'accordion-success': revision.status === 'was applied'},
              {'accordion-danger': revision.status === 'rejected'},
            )}
            id="default-accordion-example"
            open="false"
            key={idx}
            toggle={() => toggleAccordion(idx)}
          >
            <AccordionItem className="mt-2">
              <h2 className="accordion-header" id="accordionborderedExample2">
                <button
                  className={classnames(
                    "accordion-button cursor-pointer p-2",
                    {collapsed: !activeToggleTabs.includes(idx)}
                  )}
                  type="button"
                  onClick={() => {
                    toggleAccordion(idx)
                  }}
                >
                  <div className='d-flex justify-content-between align-items-center w-100'>
                    <div className='d-flex flex-column flex-lg-row'>
                      <span className='fs-14'>
                        {revision.type.capitalize()} revision
                        <span className='text-primary ms-1 me-1'>#{revision.id}</span>
                      </span>
                      <span className='me-1 d-none d-lg-block'>|</span>
                      <span className="fs-14 me-1">Date: {prettyDate(revision.updated_at)}</span>
                    </div>
                    {/*<span className="fs-14">Status:&nbsp;</span>*/}
                    <span style={activeToggleTabs.includes(idx) ? {marginRight: '3px'} : {}}>
                      <span
                        className={classnames('badge fs-10 me-2', {
                          'bg-warning': revision.status === 'pending',
                          'bg-success': revision.status === 'was applied',
                          'bg-danger': revision.status === 'rejected',
                        })}
                      >
                        {capitalize(revision.status)}
                      </span>
                    </span>
                  </div>
                </button>
              </h2>
              <Collapse
                isOpen={activeToggleTabs.includes(idx)}
                className="accordion-collapse"
                id={`collapse-${idx}`}
              >
                <div className="table-responsive">
                  <Table className="table-striped align-middle mb-0">
                  <tbody>
                    {revision.data &&
                      Object.entries(revision.data).map(([key, val], idx) => {
                        if (!isEmpty(val) || val === '') {
                          return (
                            <tr key={idx}>
                              <td style={{width: '200px'}} className='py-1'>{capitalize(key)}:</td>
                              <td className='py-1'>{val}</td>
                            </tr>
                          )
                        }
                      })
                    }
                    </tbody>
                  </Table>
                </div>
                {revision.status === 'pending' ?
                  <div className="my-2 px-2 d-flex justify-content-between">
                    <Button
                      type="button"
                      color="success"
                      onClick={() => changeRevisionStatusHandler('was applied', revision.id)}
                      className="btn btn-sm px-3 fw-bolder"
                    >
                      Approve
                    </Button>
                    <Button
                      type="button"
                      color="danger"
                      onClick={() => changeRevisionStatusHandler('rejected', revision.id)}
                      className="btn btn-sm px-3 fw-bolder"
                    >
                      Reject
                    </Button>
                  </div> : null
                }
              </Collapse>
            </AccordionItem>
          </Accordion>
        )) :
        <span className="fw-bolder">No available data</span>
      }

      <div className='mt-3'>
        <Button type="button" color="warning" onClick={closeModal} className="btn px-5 me-3">Close</Button>
      </div>
    </>
  )
}

export default ProfileRevisionsModal
