import React, {useContext, useEffect, useState} from "react"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Label, Row} from "reactstrap"
import {toast} from "react-toastify"
import {Link, useNavigate, useParams} from "react-router-dom"
import {closeTicket, commentTicket, deleteCommentTicket, getTicket, navUnreadTicket} from "../../api/tickets"
import {selectListData} from "../../api/general"
import Select from "react-select"
import {ModalContext} from "../../contexts"
import EditTicketComment from "./Modals/EditTicketComment"
import {useUserCan} from "../../Components/Hooks/UserHooks"
import {prettyDate} from "../../helpers/utils"

const SingleTicket = () => {
  const navigate = useNavigate()
  const {openModal} = useContext(ModalContext)
  const {id} = useParams()
  const [conversation, setConversation] = useState(null)
  const [conversationData, setConversationData] = useState([])
  const [fastRepliesOptions, setFastRepliesOptions] = useState([])
  const [selectedReply, setSelectedReply] = useState()
  const [replyTemplate, setReplyTemplate] = useState([])
  const [ticketStatus, setTicketStatus] = useState(4)
  const [disabledNextButton, setDisabledNextButton] = useState(false)
  const [disabledPrevButton, setDisabledPrevButton] = useState(false)

  // Permissions
  const canAnswerTicket = useUserCan('answer_ticket')
  const canCloseTicket = useUserCan('close_ticket')
  const canEditTicketComment = useUserCan('edit_ticket_comment')
  const canDeleteTicketComment = useUserCan('delete_ticket_comment')

  const getFastReplies = () => {
    selectListData('ticket_comments_template').then(r => {
      setFastRepliesOptions(r)
    })
  }

  const navigateToUnreadTicketHandler = (dir) => {
    navUnreadTicket({id, dir}).then(r => {
      if (r.success) {
        navigate(`/tickets/list/show/${r.next_ticket_id}`)
        getData()
        setDisabledNextButton(false)
        setDisabledPrevButton(false)
        resetForm()
      } else {
        toast.info(r.message)
        if (dir === 'next') {
          setDisabledNextButton(true)
        } else {
          setDisabledPrevButton(true)
        }
      }
    })
  }

  const resetForm = () => {
    setSelectedReply([])
    setReplyTemplate('')
  }

  const editTicketCommentHandler = (id, message) => {
    openModal({
      title: 'Edit Ticket Comment',
      content: <EditTicketComment id={id} message={message} updateTable={getData}/>,
    })
  }

  const deleteTicketCommentHandler = (id) => {
    if (confirm('Do you want to delete this comment?')) {
      deleteCommentTicket(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          getData()
        } else {
          toast.error(r.message)
        }
      }).catch(e => {
        toast.error(e)
      })
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    let data = new FormData(e.target)

    commentTicket(id, data).then(r => {
      btn.disabled = false

      if (r.success) {
        getData()
        toast.success(r.message)
        resetForm()
      } else {
        toast.error(r.message)
      }
    }).catch(e => {
      btn.disabled = false
      toast.error(e)
    })
  }

  const closeTicketHandler = (e) => {
    e.target.disabled = true;

    closeTicket(id).then(r => {
      if (r.success) {
        toast.success(r.message)
        navigate('/tickets/list')
      } else {
        toast.error(r.message)
      }
    }).catch(e => {
      toast.error(e)
    })
  }

  const getData = () => {
    getTicket(id).then(r => {
      setConversation(r)
    })
  }

  useEffect(() => {
    getFastReplies()
  }, [])

  useEffect(() => {
    getData()
  }, [id])

  useEffect(() => {
    let data = []

    if (conversation?.comments?.length > 0) {
      data = conversation.comments.map((item, index) => {
        return <div key={index} className="d-flex align-items-start text-muted mb-3">
          <div className="flex-grow-1">
            <hr className="w-100 mt-0"/>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="fs-14">
                {conversation?.customer?.id === item.user_id ? (
                  <span>
                    <Link to={`/customers/show/${item.user_id}`}>{item.user.name}</Link>
                    <Link to={`/customers/show/${item.user_id}`} target="_blank" className="ms-1">
                      <i className="ri-share-box-line text-muted fs-12"></i>
                    </Link>
                    <span className="text-muted ps-2 fs-12">{prettyDate(item.created_at)}</span>
                  </span>) : (
                    <span>
                      {item?.user?.name ? item.user.name : "Support Team"}
                      <span className="text-muted ps-2 fs-12">{prettyDate(item.created_at)}</span>
                    </span>
                  )}
              </h5>
              <ButtonGroup>
                {canEditTicketComment &&
                  <Link
                    to="#"
                    className="link-success fs-15"
                    onClick={() => editTicketCommentHandler(item.id, item.message)}>
                    <i className="ri-edit-2-line"></i>
                  </Link>}
                {canDeleteTicketComment &&
                  <Link to="#" className="link-danger fs-15 ms-3" onClick={() => deleteTicketCommentHandler(item.id)}>
                    <i className="ri-delete-bin-line"></i>
                  </Link>
                }
              </ButtonGroup>
            </div>
            <p className="mb-0 keep-lines">{item.message}</p>
          </div>
        </div>
      })
    }

    if (conversation) {
      setTicketStatus(Number(conversation.status))
    }

    setConversationData(data)
  }, [conversation, canEditTicketComment, canDeleteTicketComment])

  document.title = 'Ticket | '  + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Support" pageTitle={`Ticket #${id}`}/>
          <Row>
            <Col xs={12} className="col-12 offset-xl-1 col-xl-10 offset-xxl-2 col-xxl-8 mb-4">
              <div className="hstack justify-content-between">
                <Button onClick={() => navigate(-1)} color="primary">Back</Button>
                {ticketStatus !== 4 &&
                  <>
                    {canCloseTicket &&
                      <Button color="danger" onClick={closeTicketHandler}>Close ticket</Button>}
                  </>
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 offset-xl-1 col-xl-10 offset-xxl-2 col-xxl-8 mb-4">
              <Card>
                {conversation ?
                  <>
                    <CardHeader><h5>Subject: {conversation.subject}</h5></CardHeader>
                    <CardBody>
                      <div className="d-flex align-items-start text-muted mb-4">
                        <div className="flex-grow-1">
                          <h5 className="fs-14">
                            <Link to={`/customers/show/${conversation.customer.id}`}>{conversation.customer.name}</Link>
                            <Link to={`/customers/show/${conversation.customer.id}`} target="_blank" className="ms-1">
                              <i className="ri-share-box-line text-muted fs-12"></i>
                            </Link>
                            <span className="text-muted ps-2 fs-12">Created: {prettyDate(conversation.created_at)}</span>
                          </h5>
                          <p className="mb-0 keep-lines">{conversation.message} </p>
                        </div>
                      </div>
                      {conversationData && conversationData}
                      <Row className="pt-1 fs-12">
                        <Col className="text-end col-12">
                          <span className="text-muted">
                            Status: <span className={'fs-12 badge bg-' + conversation.status_color}>
                              {conversation.status_name}
                            </span>
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                  </>
                  : <CardHeader><h5>Loading...</h5></CardHeader>
                }
              </Card>
            </Col>
          </Row>
          {ticketStatus !== 4 &&
            <>
              {canAnswerTicket &&
                <Row>
                  <Col className="col-12 offset-xl-1 col-xl-10 offset-xxl-2 col-xxl-8 mb-4">
                    <Form onSubmit={submitHandler}>
                      <FormGroup className="mb-1">
                        <Label htmlFor="ticketComment" className="form-label">Message</Label>
                        <textarea
                          name="comment"
                          className="form-control"
                          id="ticketComment"
                          rows="8"
                          placeholder="Enter your message"
                          value={selectedReply}
                          onChange={(e) => setSelectedReply(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="mb-1">
                        <Label className="form-label">Fast Answer</Label>
                        <Select
                          name="transactions_comments"
                          options={fastRepliesOptions}
                          value={replyTemplate}
                          className="form-control bg-light border-light"
                          onChange={selected => {
                            setSelectedReply(selected.data)
                            setReplyTemplate(selected)
                          }}
                        />
                      </FormGroup>
                      <Button type="submit" color="primary" className="btn w-100 mb-5">SEND</Button>
                    </Form>
                  </Col>
                </Row>}
            </>
          }
          <Row>
            <Col className="col-12 offset-xl-1 col-xl-10 offset-xxl-2 col-xxl-8 mb-4">
              <div className="d-flex justify-content-between pt-3 mb-2">
                <Button
                  color="success"
                  className="btn-label"
                  onClick={() => navigateToUnreadTicketHandler('previous')}
                >
                  <i className="ri-arrow-left-circle-line label-icon align-middle fs-16 me-2"></i>
                  Previous unread
                </Button>
                <Button
                  color="success"
                  className="btn-label right"
                  onClick={() => navigateToUnreadTicketHandler('next')}
                >
                  <i className="ri-arrow-right-circle-line label-icon align-middle fs-16 ms-2"></i>
                  Next unread
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SingleTicket
