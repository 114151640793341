import {APIClient} from "../helpers/api_helper"

const api = new APIClient();

export function changeCryptoFee(data, id) {
  return api.post('/spa/fees/change-crypto-fee', {data: data, customer_id: id})
}

export function changeFiatFee(data, id) {
  return api.post('/spa/fees/change-fiat-fee', {data: data, customer_id: id})
}

export function changeExchangeFee(fee_amount, id) {
  return api.post('/spa/fees/change-exchange-fee', {fee_amount, customer_id: id})
}

export function getFee(id) {
  return api.get(`/spa/fees/get-fees/${id}`, {})
}
