import React, {useContext, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown
} from 'reactstrap'
import classnames from 'classnames'
import SwiperCore, {Autoplay} from "swiper"
import {disable2Fa, generateTempPassword, getProfile, loginAs} from "../../../api/customers"
import {copyToClipboard, isTwoPrecision} from "../../../helpers/utils"
import {ModalContext} from "../../../contexts"
import {useUserCan, useUserHasRole} from "../../../Components/Hooks/UserHooks"
import {toast} from "react-toastify"
import {capitalize} from "lodash"

// Components
import Overview from "./Overview"
import Activities from "./Activities"
import Documents from "./Documents"
import Invoices from "./Invoices"
import Transactions from "./Transactions"
import CreditCards from "./CreditCards"
import AdditionalMenu from "./Components/AdditionalMenu"
import Tickets from "./Tickets"
import Securities from "./Securities"

// Modals
import DepositTransactionModal from "./Modals/DepositTransactionModal"
import WithdrawalTransactionModal from "./Modals/WithdrawalTransactionModal"
import BankTransferModal from "./Modals/BankTransferModal"
import SendEmailModal from "./Modals/SendEmailModal"
import FeesConfigModal from "./Modals/FeesConfigModal"
import ExchangeFeesConfigModal from "./Modals/Components/ExchangeFeesConfigModal"
import VoIPModal from "../Modals/VoIPModal"

//Images
import profileBg from '../../../assets/images/bg-image.webp'
import default_avatar from "../../../assets/images/users/default_avatar.webp"
import P2POrders from "./P2POrders"

// Redux
import {useDispatch} from "react-redux"
import {setProfileRevisions} from "../../../slices/profile-revisions/reducer"

export const Profile = () => {
  const {openModal} = useContext(ModalContext)
  const {id} = useParams()
  const navigate = useNavigate()
  SwiperCore.use([Autoplay])
  const defaultTab = useRef('overview')
  const transactionTableMethodsRef = useRef()
  const securitiesTableTableMethodsRef = useRef()

  const [activeTab, setActiveTab] = useState(defaultTab.current)
  const [profileData, setProfileData] = useState({})
  const [profileBalance, setProfileBalance] = useState({})
  const [allRealDeposits, setAllRealDeposits] = useState([])
  const [assignedEmployee, setAssignedEmployee] = useState({})
  const [customerDepartment, setCustomerDepartment] = useState({})
  const [creditCards, setCreditCards] = useState([])
  const [fees, setFees] = useState([])
  const dispatch = useDispatch()

  let hashValue = window.location.hash
  const crmVersion = import.meta.env.VITE_CRM_VERSION

  // Permissions
  const canLoginAs = useUserCan('login_as_customer')
  const canSendMail = useUserCan('send_mail')
  const canViewTransactions = useUserCan(['view_transactions', 'view_all_transactions'])
  const canViewInvoices = useUserCan('view_invoices')
  const canViewActivityLogs = useUserCan('view_activity_logs')
  const canViewCreditCards = useUserCan('view_credit_cards')
  const canCreateTransaction = useUserCan('create_transaction')
  const canEditInvoice = useUserCan('edit_invoice')
  const canViewDocuments = useUserCan('view_documents')
  const canViewTickets = useUserCan('view_tickets')
  const canChangeFees = useUserCan('change_customer_fee')
  const canDisable2Fa = useUserCan('disable_2fa')
  const isAffiliate = useUserHasRole(['affiliate'], false)

  const getProfileData = () => {
    return getProfile(id).then(r => {
      const {
        data,
        balance,
        all_real_deposits,
        assigned_employee,
        customer_department,
        credit_cards,
        fees,
        profile_revisions,
      } = r

      setProfileData(data)
      setCustomerDepartment(customer_department)
      setProfileBalance(balance)
      setAllRealDeposits(all_real_deposits)
      setAssignedEmployee(assigned_employee)
      setCreditCards(credit_cards)
      setFees(fees)
      dispatch(setProfileRevisions(profile_revisions))
    })
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const loginAsHandler = () => {
    loginAs({id: id}).then(r => {
      if (r.success) {
        window.open(r.url, '_blank').focus()
      } else {
        toast.error('Error')
      }
    })
  }

  const disable2FaHandler = (id) => {
    if(confirm('Do you want to disable 2fa?')) {
      disable2Fa(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          getProfileData()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const generatePassword = () => {
    generateTempPassword({customer_id: id}).then(r => {
      if (r.success) {
        copyToClipboard(r.password, 'Temp password successfully copied!')
      } else {
        toast.error('Cannot generate password!')
      }
    })
  }

  const openDepositTransactionModal = () => {
    openModal({
      title: 'Deposit Transaction',
      content: <DepositTransactionModal
        id={id}
        transactionTableMethodsRef={transactionTableMethodsRef}
      />
    })
  }

  const openWithdrawalTransactionModal = () => {
    openModal({
      title: 'Withdrawal Transaction',
      content: <WithdrawalTransactionModal
        id={id}
        transactionTableMethodsRef={transactionTableMethodsRef}
      />
    })
  }

  const openBankTransferModal = () => {
    openModal({
      title: 'Bank Transfer',
      content: <BankTransferModal
        data={profileData}
        transactionTableMethodsRef={transactionTableMethodsRef}
      />
    })
  }

  const openVoIPHandler = (id) => {
    openModal({
      title: `Call to Customer #${id}`,
      content: <VoIPModal customerId={id}/>
    })
  }

  const sendEmailModal = () => {
    openModal({
      title: 'Send Email',
      content: <SendEmailModal id={id} />,
      size: 'lg'
    })
  }

  const openFeesConfigModal = (type, id) => {
    openModal({
      title: `${capitalize(type)} Fees Config`,
      content: <FeesConfigModal type={type} customerId={id} fees={fees} updateProfile={getProfileData}/>,
      size: 'lg'
    })
  }

  const openExchangeFeesConfigModal = (customer_id) => {
    openModal({
      title: 'Exchange Fees Config',
      content: <ExchangeFeesConfigModal customer_id={customer_id} updateProfile={getProfileData} />
    })
  }

  useEffect(() => {
    getProfileData()
  }, [id])

  useEffect(() => {
    if (window.location.hash) {
      const tab = window.location.hash.substring(1)
      toggleTab(tab)
    }
  }, [hashValue])

  document.title = '#' + `${!profileData.user_id ? '...' : profileData.user_id}` + ' | Profile | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img src={profileBg} alt="" className="profile-wid-img"/>
            </div>
          </div>

          {/* BEGIN Header section */}
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              <div className="col-auto">
                <div className="avatar-lg position-relative">
                  <img
                    src={profileData.avatar_url ? profileData.avatar_url : default_avatar}
                    alt="user-img"
                    className="img-thumbnail rounded-circle avatar-lg object-cover"
                  />
                  {profileData?.is_online ?
                    <span
                      className="position-absolute translate-middle badge rounded-circle bg-success"
                      style={{bottom: '-10px', padding: '11px', right: '-11px'}}
                          >
                      <span className="visually-hidden">unread messages</span>
                    </span> : null
                  }
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <div className="d-flex align-items-center">
                    <h3 className="text-white mb-1">
                      {profileData.first_name} {profileData.last_name} #{profileData.user_id}
                      {import.meta.env.VITE_VOIP_ENABLED === 'true' &&
                        <i className="bx bx-phone-call text-white-75 fs-20 ms-2 cursor-pointer"
                           onClick={() => openVoIPHandler(profileData.user_id)}
                        ></i>}
                    </h3>
                    {canDisable2Fa &&
                    <>
                      {profileData.security?.google2fa_enable ?
                        <span className="text-white ">
                    <i className="bx bx-lock text-white-75 fs-20 ms-2 cursor-pointer"
                       onClick={() => disable2FaHandler(profileData.user_id)}
                    ></i>
                    </span> : ''}
                    </>}
                  </div>
                  <p className="text-white-50">{profileData.email}</p>
                  <div className="hstack text-white-50 gap-1">
                    {profileData?.country ?
                      <div className="me-2">
                        <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>
                        {profileData.customerCountry}
                      </div>
                      : ''}
                    {profileData?.city ?
                      <div>
                        <i className="ri-building-line me-1 text-white-75 fs-16 align-middle"></i>
                        {profileData.city}
                      </div>
                      : ''}
                  </div>
                </div>
              </Col>

              <Col xs={12}  className="col-lg-auto order-last order-lg-0 text-nowrap">
                <Row className="text text-white-50 text-end">
                  <Col md={4} xs={12}>
                    <div className="p-2">
                      <h5 className="text-white mb-1">Locked Balance</h5>
                      <ul className='list-unstyled'>
                        {profileBalance?.locked_balance &&
                          profileBalance.locked_balance.map((asset, idx) => {
                            return <li key={idx} className='hstack justify-content-between'>
                              <span className='fw-bold'>{asset.code}:</span>
                              <span>{isTwoPrecision(asset.code, asset.balance)}</span>
                            </li>
                          })
                        }
                        <>
                          <li>
                            <hr className='my-1 w-100'/>
                          </li>
                          <li className='hstack justify-content-between'>
                            <span className='fw-bold'>Total $:</span>
                            <span>
                              {isTwoPrecision('usd', profileBalance
                                ?.locked_balance
                                ?.reduce((a, v) => a + Number(v?.balance * v?.price) || 0, 0).toFixed(2) || 0)
                              }
                            </span>
                          </li>
                        </>
                      </ul>
                    </div>
                  </Col>
                  <Col md={4} xs={12}>
                    <div className="p-2">
                      <h5 className="text-white mb-1">Available Balance</h5>
                      <ul className='list-unstyled'>
                        {profileBalance?.unlocked_balance &&
                          profileBalance.unlocked_balance.map((asset, idx) => {
                            return <li key={idx} className='hstack justify-content-between'>
                              <span className='fw-bold'>{asset.code}:</span>
                              <span>{isTwoPrecision(asset.code, asset.balance)}</span>
                            </li>
                          })
                        }
                        <>
                          <li>
                            <hr className='my-1 w-100'/>
                          </li>
                          <li className='hstack justify-content-between'>
                            <span className='fw-bold'>Total $:</span>
                            <span>
                              {isTwoPrecision('usd', profileBalance
                                ?.unlocked_balance
                                ?.reduce((a, v) => a + Number(v?.balance * v?.price) || 0, 0).toFixed(2) || 0)
                              }
                            </span>
                          </li>
                        </>
                      </ul>
                    </div>
                  </Col>
                  <Col md={4} xs={12}>
                    <div className="p-2">
                      <h5 className="text-white mb-1">Total Real Deposits</h5>
                      <ul className='list-unstyled'>
                        {allRealDeposits &&
                          allRealDeposits.map((asset, idx) => {
                            return <li key={idx} className='hstack justify-content-between'>
                              <span className='fw-bold'>{asset.asset.currency.code}:</span>
                              <span>{isTwoPrecision(asset.asset.currency.code, asset.amount)}</span>
                            </li>
                          })
                        }
                        {allRealDeposits &&
                          <>
                            <li>
                              <hr className='my-1 w-100'/>
                            </li>
                            <li className='hstack justify-content-between'>
                              <span className='fw-bold'>Total $:</span>
                              <span>{allRealDeposits.reduce((a, v) => a + Number(v?.usd_amount) || 0, 0).toFixed(2)}</span>
                            </li>
                          </>
                        }
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {/* END Header section */}

          {/* BEGIN Body section */}
          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  {/* BEGIN Tab navigation */}
                  <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                    <NavItem>
                      <NavLink
                        href="#overview"
                        className={classnames({active: activeTab === defaultTab.current})}
                        onClick={() => toggleTab(defaultTab.current)}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>
                        <span className="d-none d-md-inline-block">Overview</span>
                      </NavLink>
                    </NavItem>

                    {canViewTransactions &&
                      <NavItem>
                        <NavLink
                          href="#transactions"
                          className={classnames({active: activeTab === 'transactions'})}
                          onClick={() => toggleTab('transactions')}
                        >
                          <i className="ri-exchange-dollar-fill d-inline-block d-md-none"></i>
                          <span className="d-none d-md-inline-block">Transactions</span>
                        </NavLink>
                      </NavItem>
                    }

                    {(Number(crmVersion) > 1) &&
                      <NavItem>
                        <NavLink
                          href="#securities"
                          className={classnames({active: activeTab === 'securities'})}
                          onClick={() => toggleTab('securities')}
                        >
                          <i className="ri-exchange-dollar-fill d-inline-block d-md-none"></i>
                          <span className="d-none d-md-inline-block">Securities</span>
                        </NavLink>
                      </NavItem>
                    }

                    {(Number(crmVersion) > 1) &&
                    <NavItem>
                      <NavLink
                        href="#p2p_orders"
                        className={classnames({active: activeTab === 'p2p_orders'})}
                        onClick={() => toggleTab('p2p_orders')}
                      >
                        <i className="mdi mdi-card-account-details-outline d-inline-block d-md-none"></i>
                        <span className="d-none d-md-inline-block">P2P Orders</span>
                      </NavLink>
                    </NavItem>
                    }

                    {canViewDocuments &&
                      <NavItem>
                        <NavLink
                          href="#documents"
                          className={classnames({active: activeTab === 'documents'})}
                          onClick={() => toggleTab('documents')}
                        >
                          <i className="ri-folder-4-line d-inline-block d-md-none"></i>
                          <span className="d-none d-md-inline-block">Documents</span>
                        </NavLink>
                      </NavItem>}

                    {canViewInvoices &&
                      <NavItem>
                        <NavLink
                          href="#invoices"
                          className={classnames({active: activeTab === 'invoices'})}
                          onClick={() => toggleTab('invoices')}
                        >
                          <i className="ri-currency-line d-inline-block d-md-none"></i>
                          <span className="d-none d-md-inline-block">Invoices</span>
                        </NavLink>
                      </NavItem>
                    }

                    {canViewTickets &&
                      <NavItem>
                        <NavLink
                          href="#tickets"
                          className={classnames({active: activeTab === 'tickets'})}
                          onClick={() => toggleTab('tickets')}
                        >
                          <i className="ri-file-unknow-line d-inline-block d-md-none"></i>
                          <span className="d-none d-md-inline-block">Tickets</span>
                        </NavLink>
                      </NavItem>
                    }

                    {canViewActivityLogs &&
                      <NavItem>
                        <NavLink
                          href="#activities"
                          className={classnames({active: activeTab === 'activities'})}
                          onClick={() => toggleTab('activities')}
                        >
                          <i className="mdi mdi-account-reactivate-outline d-inline-block d-md-none"></i>
                          <span className="d-none d-md-inline-block">Activities</span>
                        </NavLink>
                      </NavItem>
                    }

                    {canViewCreditCards &&
                      <NavItem>
                        <NavLink
                          href="#credit_cards"
                          className={classnames({active: activeTab === 'credit_cards'})}
                          onClick={() => toggleTab('credit_cards')}
                        >
                          <i className="mdi mdi-card-account-details-outline d-inline-block d-md-none"></i>
                          <span className="d-none d-md-inline-block">Credit Cards</span>
                        </NavLink>
                      </NavItem>
                    }
                  </Nav>
                  {/* END Tab navigation */}

                  {/* BEGIN Config button */}
                  {canChangeFees &&
                    <div className="flex-shrink-0 me-3">
                      <ButtonGroup>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="button" className="btn btn-warning">
                            Config <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-lg-end" end={true}>
                            <DropdownItem onClick={() => openFeesConfigModal('crypto', profileData.user_id)}>
                              Crypto Fees
                            </DropdownItem>
                            <DropdownItem onClick={() => openFeesConfigModal('fiat', profileData.user_id)}>
                              Bank Fees
                            </DropdownItem>
                            <DropdownItem onClick={() => openExchangeFeesConfigModal(profileData.user_id)}>
                              Exchange Fees
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </ButtonGroup>
                    </div>
                  }
                  {/* END Config button */}

                  {/* BEGIN Actions button */}
                  {(import.meta.env.VITE_ONE_CLICK_AUTOLOGIN && !isAffiliate) ?
                    <div className="flex-shrink-0 me-3">
                      <ButtonGroup>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="button" className="btn btn-info">
                            Actions <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-lg-end" end={true}>
                            {canSendMail &&
                              <DropdownItem onClick={sendEmailModal}>Send Email</DropdownItem>
                            }
                            {canLoginAs && <DropdownItem onClick={loginAsHandler}>Login as</DropdownItem>}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </ButtonGroup>
                    </div> :
                    <>
                      {canSendMail &&
                        <div className="flex-shrink-0 me-3">
                          <div className="flex-shrink-0">
                            <Button color="success" className="btn btn-info" onClick={sendEmailModal}>Send Email</Button>
                          </div>
                        </div>}
                    </>
                  }
                  {/* END Actions button */}

                  {/* BEGIN Create action button */}
                  {(canCreateTransaction || canEditInvoice || canLoginAs) &&
                    <div className="flex-shrink-0">
                      <ButtonGroup>
                        <UncontrolledDropdown>
                          <DropdownToggle tag="button" className="btn btn-success">
                            Create <i className="mdi mdi-chevron-down"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-lg-end" end={true}>
                            {canCreateTransaction &&
                              <DropdownItem onClick={openDepositTransactionModal}>Deposit Transaction</DropdownItem>}
                            {canCreateTransaction &&
                              <DropdownItem onClick={openWithdrawalTransactionModal}>Withdrawal
                                Transaction</DropdownItem>}
                            {canCreateTransaction &&
                              <DropdownItem onClick={openBankTransferModal}>Bank Transfer Transaction</DropdownItem>}
                            {canEditInvoice &&
                              <DropdownItem onClick={() => {
                                navigate(`/invoices/create/${id}`)
                              }}>
                                Invoice
                              </DropdownItem>}
                            {canLoginAs && <DropdownItem onClick={generatePassword}>Temporary password</DropdownItem>}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </ButtonGroup>
                    </div>}
                  {/* END Create action button */}
                </div>

                {/* BEGIN Tab content */}
                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId={defaultTab.current}>
                    <Overview
                      profileData={profileData}
                      assignedEmployee={assignedEmployee}
                      customerDepartment={customerDepartment}
                      activeTab={activeTab}
                      updateProfile={getProfileData}
                    />
                  </TabPane>

                  <TabPane tabId="transactions">
                    <Transactions
                      updateProfile={getProfileData}
                      walletId={profileData.wallet_id}
                      activeTab={activeTab}
                      methodsRef={transactionTableMethodsRef}
                    />
                  </TabPane>

                  <TabPane tabId="securities">
                    <Securities
                      customerId={id}
                      activeTab={activeTab}
                      methodsRef={securitiesTableTableMethodsRef}
                    />
                  </TabPane>

                  <TabPane tabId="p2p_orders">
                    <P2POrders
                      customerId={id}
                      activeTab={activeTab}
                      methodsRef={securitiesTableTableMethodsRef}
                    />
                  </TabPane>

                  <TabPane tabId="documents">
                    <Documents customerId={id} activeTab={activeTab}/>
                  </TabPane>

                  <TabPane tabId="invoices">
                    <Invoices customerId={id} activeTab={activeTab}/>
                  </TabPane>

                  <TabPane tabId="tickets">
                    <Tickets customerId={id} activeTab={activeTab}/>
                  </TabPane>

                  <TabPane tabId="activities">
                    <Activities customerId={id} activeTab={activeTab}/>
                  </TabPane>

                  <TabPane tabId="credit_cards">
                    <CreditCards
                      customerId={id}
                      key={creditCards.length}
                      creditCards={creditCards}
                      updateTable={getProfileData}
                      activeTab={activeTab}
                    />
                  </TabPane>
                </TabContent>
                {/* END Tab content */}
              </div>
            </Col>
          </Row>
          {/* END Body section */}

          <AdditionalMenu customerId={id} />

        </Container>
      </div>
    </React.Fragment>
  )
}
