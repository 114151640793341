import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import BaseTable from '../../Components/Tables/BaseTable'
import {deleteInvoice} from "../../api/invoices"
import {toast} from "react-toastify"
import {Link} from "react-router-dom"
import {ModalContext} from "../../contexts"
import EditInvoice from "./Modals/EditInvoice"
import {useNavigate} from "react-router-dom"
import {useUserCan} from "../../Components/Hooks/UserHooks"
import {openLinkInNewTab, prettyDate} from "../../helpers/utils"
import {setColumnsSetting} from "../../slices/invoices/reducer"
import {useSelector} from "react-redux"
import {filtersList, selectListData} from "../../api/general";

const InvoiceTable = ({customerId, withFilters}) => {
	const {openModal} = useContext(ModalContext)
	const [selectedRows, setSelectedRows] = useState([])
	const baseTableMethodsRef = useRef(null)
	const navigate = useNavigate()
  const columnsSetting = useSelector(s => s.Invoices.columnsSetting)
	const [selectedRowIds, setSelectedRowIds] = useState([])
  const [isGoToNextCustomer, setIsGoToNextCustomer] = useState(false)
  const [filters, setFilters] = useState([])
  const tableRowsPerPage = 'invoices_rows_per_page'
  const filterStorageKey = 'invoices_filter'

  // Permissions
  const canEditInvoice  = useUserCan('edit_invoice')
  const canDeleteInvoice = useUserCan('delete_invoice')
  const canViewCustomer = useUserCan('view_customer')

	const selectedRowsHandler = (row) => {
		setSelectedRows(row)
		setSelectedRowIds(row.map((row) => row.id))
	}

	const columns = useMemo(
		() => [
			{
				id: "id",
				name: <span className='font-weight-bold fs-14'>Invoice ID</span>,
				selector: row => canEditInvoice ? (
					<a onClick={() => {navigate(`/invoices/show/${row.id}`)}}
					   className="cursor-pointer">{row.uniq_id}</a>
				) : row.uniq_id,
				sortable: true,
        minWidth: '120px',
        maxWidth: '300px',
        omit: false,
			},
			{
				id: "status",
				name: <span className='font-weight-bold fs-14'>Status</span>,
				selector: row => (<span className={'badge badge-soft-' + row.status_color}>{row.status_upper}</span>),
        minWidth: '75px',
        maxWidth: '300px',
        omit: false,
			},
			{
				id: "customer",
				name: <span className='font-weight-bold fs-14'>Customer</span>,
        selector: canViewCustomer ? (row => <div className="hstack justify-content-center">
          <Link className="text-truncate" to={`/customers/show/${row.customer.id}`}>#{row.customer.id} {row.customer.name}</Link>
        </div>) : (row => <span className="text-truncate">{`# ${row.customer.id} ${row.customer.name}`}</span>),
        minWidth: '180px',
        maxWidth: '300px',
        omit: false,
			},
			{
				id: "invoicing_date",
				name: <span className='font-weight-bold fs-14'>Invoicing Date</span>,
				selector: row => (prettyDate(row.formatted_invoicing_date, false)),
        minWidth: '100px',
        maxWidth: '300px',
        omit: false,
			},
			{
				id: "due_date",
				name: <span className='font-weight-bold fs-14'>Due Date</span>,
				selector: row => (prettyDate(row.formatted_due_date, false)),
        minWidth: '100px',
        maxWidth: '300px',
        omit: false,
			},
			{
				id: "payout_asset",
				name: <span className='font-weight-bold fs-14'>Payout Asset</span>,
				selector: row => row.payout_asset.currency.code,
        minWidth: '100px',
        maxWidth: '300px',
        omit: false,
			},
			{
				id: "amount",
				name: <span className='font-weight-bold fs-14'>Amount</span>,
				selector: row => row.formatted_total_amount,
        minWidth: '110px',
        maxWidth: '300px',
        omit: false,
			},
			{
				id: "actions",
				name: <span className='font-weight-bold fs-14'>Actions</span>,
				selector: row => (
					<>
						<div className="hstack gap-3 flex-wrap">
              {canEditInvoice &&
							<Link to="#" onClick={() => {editInvoiceHandler(row.id, row.status)}} className="link-success fs-15">
								<i className="ri-edit-2-line"></i>
							</Link>}
              {canDeleteInvoice &&
							<Link to="#" onClick={() => {deleteInvoiceHandler(row.id, row.status_upper)}} className="link-danger fs-15">
								<i className="ri-delete-bin-line"></i>
							</Link>}
						</div>
					</>
				),
        minWidth: '75px',
        maxWidth: '300px',
        omit: false,
			}
		], [canEditInvoice, canDeleteInvoice, canViewCustomer])

  useEffect(() => {
    if (withFilters) {
      filtersList('invoices_filter').then(r => {
        setFilters(r)
      })
    }
  }, [withFilters])

	// const filters = useMemo(
	// 	() => [
	// 		{
	// 			title: 'ID',
	// 			name: 'invoice_id',
	// 			type: 'text',
	// 		},
	// 		{
	// 			title: 'Customer ID',
	// 			name: 'customer_id',
	// 			type: 'text',
	// 		},
	// 		{
	// 			title: 'Status',
	// 			name: 'invoice_status',
	// 			type: 'select',
	// 		},
	// 	], [])

	const updateTable = () => {
		baseTableMethodsRef.current.updateTable()
	}

	const editInvoiceHandler = (id, status) => {
		openModal({
			title: 'Edit Invoice',
			content: <EditInvoice id={id} status={status.toUpperCase()} updateTable={updateTable}/>,
		})
	}

	const deleteInvoiceHandler = (id) => {
		if (confirm('Do you want to delete this invoice?')) {
			deleteInvoice(id).then(r => {
				if (r.success) {
					toast.success(r.message)
					updateTable()
				} else {
					toast.error(r.message)
				}
			})
		}
	}

  // When isGoToNextCustomer fired (first call fired from component BaseTable)
  useEffect(() => {
    if (customerId && isGoToNextCustomer) {
      updateTable()
      setIsGoToNextCustomer(false)
    }

    setIsGoToNextCustomer(true)
  }, [customerId])

  return (
		<BaseTable
				columns={columns}
				title="Invoices"
				filterFields={withFilters ? filters : []}
				url="/spa/invoices/data"
				selectedRowsHandler={selectedRowsHandler}
				methodsRef={baseTableMethodsRef}
				additionalRequest={customerId ? {customer_id: customerId} : null}
        columnsSetting={columnsSetting}
        columnsSettingHandler={setColumnsSetting}
				conditionalRowStyles={[{
					when: (row) => selectedRowIds.includes(row.id),
					classNames: ['selected_Row']
				}
				]}
        tableRowsPerPage={tableRowsPerPage}
        filterStorageKey={filterStorageKey}
		/>
	)
}

export default InvoiceTable
