import Select from "react-select"
import React, {useEffect, useState} from "react"
import {Label} from "reactstrap"
import {selectListData} from "../../api/general"

function CustomSelect({label, value, options, selectListDataName, relatedIds, attrs, params}) {
  const [selectedValue, setSelectedValue] = useState(value ?? '')
  const [selectOptions, setSelectOptions] = useState(options ?? [])

  const {
    isMulti,
  } = params

  useEffect(() => {
    if (selectListDataName) {
      selectListData(selectListDataName).then(r => {
        if (relatedIds?.length) {
          if (!!isMulti) {
            setSelectedValue(r.filter(i => relatedIds.map(i => i.id).includes(i.value)))
          } else {
            setSelectedValue(r.find(i => i.value === relatedIds[0]?.id))
          }
        }

        setSelectOptions(r)
      })
    }
  }, [selectListDataName])

  return (
    <div className="form-group mb-3">
      <Label className="form-label mb-0">{label}</Label>
      <Select
        placeholder="Select..."
        autoComplete="off"
        required={true}
        isMulti={!!isMulti}
        options={selectOptions}
        value={selectedValue}
        onChange={(selected) => setSelectedValue(selected)}
        {...attrs}
      />
    </div>
  )
}

export default CustomSelect
