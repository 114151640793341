import BaseTable from "../../../Components/Tables/BaseTable"
import React, {useMemo, useState} from "react"
import {prettyDate} from "../../../helpers/utils"

const PostbacksHistoryTable = () => {
  const columns = useMemo(() => [
    {
      id: "id",
      name: <span className='font-weight-bold fs-14'>Id</span>,
      selector: row => row.id,
      width: "190px",
      omit: false
    },
    {
      id: 'postback_id',
      name: <span className='font-weight-bold fs-14'>Postback Id</span>,
      selector: row => row.postback_id,
      width: "190px",
      omit: false
    },
    {
      id: 'url',
      name: <span className='font-weight-bold fs-14'>URL</span>,
      selector: row => row.url,
      width: "300px",
      omit: false
    },
    {
      id: 'response',
      name: <span className='font-weight-bold fs-14'>Response</span>,
      selector: row => row.response,
      width: "190px",
      omit: false
    },
    {
      id: 'created_at',
      name: <span className='font-weight-bold fs-14'>Created At</span>,
      selector: row => (prettyDate(row.created_at)),
      width: "190px",
      omit: false
    },
  ], [])
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowIds, setSelectedRowIds] = useState([])

  const selectedRowsHandler = (rows) => {
    setSelectedRows(rows)
    setSelectedRowIds(rows.map((row) => row.id))
  }

  return(
    <BaseTable
      title="Postbacks History"
      columns={columns}
      url="spa/postbacks-history/data"
      selectedRowsHandler={selectedRowsHandler}
      filterFields={[]}
    />
  )
}

export default PostbacksHistoryTable
