import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
  profileRevisions: [],
};

const ProfileRevisionsSlice = createSlice({
  name: "ProfileRevisions",
  initialState,
  reducers: {
    setProfileRevisions(state, action) {
      state.profileRevisions = action.payload
    }
  }
});

export const {
  setProfileRevisions
} = ProfileRevisionsSlice.actions

export default ProfileRevisionsSlice.reducer;
