import {Button, Form, FormGroup, Input, Label} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {createTeam} from "../../../api/settings"
import {toast} from "react-toastify"
import Select from "react-select"
import {selectListData} from "../../../api/general"

const AddTeam = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [statusGroupsOptions, setStatusGroupsOptions] = useState([])
  const [selectedStatusGroup, setSelectedStatusGroup] = useState('')

  const getDepartmentsData = () => {
    selectListData('departments').then(r => {
      setDepartmentOptions(r)
    })
  }

  const getCustomerStatusGroupsData = () => {
    selectListData('customer_status_groups').then(r => {
      setStatusGroupsOptions(r)
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())
    data.department_id = selectedDepartment
    data.customer_status_group_id = selectedStatusGroup

    createTeam(data).then(r => {
      closeModal()
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  useEffect(() => {
    getDepartmentsData()
    getCustomerStatusGroupsData()
  }, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Team name</Label>
          <Input
            name="name"
            placeholder="Team name"
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Department</Label>
          <Select
            name="department_id"
            required
            options={departmentOptions}
            onChange={selected => setSelectedDepartment(selected.value)}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Customer Status Group</Label>
          <Select
            name="status_group_id"
            options={statusGroupsOptions}
            isClearable={true}
            onChange={selected => {
              if (selected) {
                setSelectedStatusGroup(selected.value)
              }
            }}
          />
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default AddTeam
