import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Card, CardBody, Col, Container, Form, Row} from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";

const TwoFA = () => {
  const submitHandler = e => {
    e.preventDefault()

    console.log('TwoFA');
  }

  document.title = import.meta.env.VITE_APP_NAME + ' | 2FA'
  return (
    <React.Fragment>
      <div className="auth-page-content">
        <div className="auth-page-wrapper">
          <ParticlesAuth>
            <div className="auth-page-content">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="text-center mt-sm-5 mb-5 text-white-50">
                      <div>
                        <Link to="/dashboard" className="d-inline-block auth-logo">
                          <img src={logoLight} alt="" height="20"/>
                        </Link>
                      </div>
                      <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>
                    </div>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card className="mt-4">
                      <CardBody className="p-4">
                        <div className="text-center mt-2">
                          <h5 className="text-primary">Lock Screen</h5>
                          <p className="text-muted">Enter your 2FA code to unlock the screen!</p>
                        </div>
                        <div className="user-thumb text-center">
                          {/*<img src={avatar1} className="rounded-circle img-thumbnail avatar-lg" alt="thumbnail"/>*/}
                          {/*<h5 className="font-size-15 mt-3">Anna Adame</h5>*/}
                        </div>
                        <div className="p-2 mt-4">
                          <Form onSubmit={submitHandler}>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="2fa_code">2FA Code</label>
                              <input
                                name="one_time_password"
                                type="2fa_code"
                                className="form-control"
                                id="2fa_code"
                                placeholder="Enter password"
                                required
                              />
                            </div>
                            <div className="mb-2 mt-4">
                              <Button color="success" className="w-100" type="submit">Unlock</Button>
                            </div>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </ParticlesAuth>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TwoFA;
