import React, {useContext, useEffect, useState} from 'react'
import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import Select from "react-select"
import {ModalContext} from "../../../contexts"
import {clickToCall} from "../../../api/customers"
import {selectListData, selectListDataWithParam} from "../../../api/general"
import {toast} from "react-toastify"

const VoIPModal = ({customerId}) => {
  const {closeModal} = useContext(ModalContext)
  const [selectedProvider, setSelectedProvider] = useState()
  const [providersList, setProvidersList] = useState([])
  const [c2cFields, setC2cFields] = useState([])
  const [selectedAccount, setSelectedAccount] = useState()
  const [userAccountsList, setUserAccountsList] = useState([])

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    clickToCall(customerId, data)
      .then(r => {
        if (r.success) {
          toast.success(r.message)
          closeModal()
        } else {
          toast.error(message)
        }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  useEffect(() => {
    selectListData('voip_providers').then(r => {
      setProvidersList(r)
    })
  }, [])

  useEffect(() => {
    if (selectedProvider) {
      selectListDataWithParam('voip_own_accounts', {provider_id: selectedProvider.value}).then(r => {
        setUserAccountsList(r)
      })
    }
  }, [selectedProvider])

  useEffect(() => {
    if (providersList?.length > 0) {
      setSelectedProvider(providersList[0])
    }
  }, [providersList])

  useEffect(() => {
    if (userAccountsList?.length > 0 && selectedProvider) {
      setSelectedAccount(userAccountsList[0])
    }
  }, [selectedProvider, userAccountsList])

  useEffect(() => {
    let fields = []

    if (selectedProvider?.settings && selectedProvider?.settings?.c2c_fields?.length > 0) {
      fields = selectedProvider.settings.c2c_fields.map((item, idx) => {
        return <div className="form-group border-0 mt-3" key={idx}>
          <Label className="form-label mb-0">{item.title}</Label>
          <Input
            type={item.type}
            name={item.name}
            placeholder={item.placeholder}
            autoComplete="false"
          />
        </div>
      })
    }

    setC2cFields(fields)
  }, [selectedProvider])

  return (
    <Form onSubmit={submitHandler}>
      <Row>
        <Col xs={12}>
          <Label className="form-label mb-0">Select Provider</Label>
          <Select
            placeholder="Select provider"
            name='provider_id'
            value={selectedProvider}
            onChange={(selected) => setSelectedProvider(selected)}
            options={providersList}
          />
        </Col>
        {selectedProvider &&
          <Col xs={12} className="mt-3">
            <Label className="form-label mb-0">Select Account</Label>
            <Select
              placeholder="Select account"
              name='voip_account_id'
              value={selectedAccount}
              onChange={(selected) => setSelectedAccount(selected)}
              options={userAccountsList}
            />
          </Col>
        }
        {(c2cFields && userAccountsList?.length > 0) && c2cFields}
        <Col xs={12} className="hstack justify-content-between">
          <Button type="btn" color="warning" className="btn mt-4" onClick={closeModal}>Close</Button>
          <Button type="submit" color="primary" className="btn mt-4">Call</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default VoIPModal
