import {Button, Table} from "reactstrap"
import {Link} from "react-router-dom"
import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../contexts"
import {deleteRecipient, getRecipients} from "../../api/settings"
import AddRecipient from "./Modals/AddRecipient"
import EditRecipient from "./Modals/EditRecipient"
import {toast} from "react-toastify"
import {useUserCan} from "../../Components/Hooks/UserHooks"

const Recipients = () => {
  const [recipients, setRecipients] = useState([])
  const {openModal} = useContext(ModalContext)

  // Permissions
  const canCreateRecipient = useUserCan('create_recipient')
  const canEditRecipient = useUserCan('edit_recipient')
  const canDeleteRecipient = useUserCan('delete_recipient')

  const getRecipientsData = () => {
    getRecipients().then((r) => {
      setRecipients(r)
    })
  }

  const updateRecipient = (id, name) => {
    const updatedRecipients = recipients.map((item) => {
      if (item.id === id) {
        return {...item, name: name}
      }
      return item
    })
    setRecipients(updatedRecipients)
    getRecipientsData()
  }

  const addRecipientHandler = () => {
    openModal({
      title: "Add Recipient",
      content: <AddRecipient updateTable={getRecipientsData}/>
    })
  }

  const editRecipientHandler = (id, name, settings) => {
    openModal({
      title: `Edit Recipient (${name})`,
      content: <EditRecipient name={name} id={id} settings={settings} onSave={updateRecipient}/>
    })
  }

  const deleteRecipientHandler = (id) => {
    if (confirm("Are you sure to delete this recipient?")) {
      deleteRecipient(id).then((r) => {
        if (r.success) {
          toast.success(r.message)
          getRecipientsData()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  useEffect(() => {
    getRecipientsData()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-end mt-1 mb-3">
        {canCreateRecipient &&
        <Button color="primary" className="btn" onClick={addRecipientHandler}>
          Add Recipient
        </Button>}
      </div>
      <div className="table-responsive">
        <Table className="table-borderless table-responsive table-nowrap">
          <thead className="border-bottom">
          <tr>
            <th className="col-1" scope="col">ID</th>
            <th className="col-9" scope="col">NAME</th>
            {(canEditRecipient || canDeleteRecipient) &&
            <th className="col-1" scope="col">ACTIONS</th>}
          </tr>
          </thead>
          <tbody>
          {recipients.length > 0 ?
            recipients.map((recipient, idx) => {
              return (
                <tr className="border-bottom" key={idx}>
                  <td>{recipient.id}</td>
                  <td>{recipient.name}</td>
                  {(canEditRecipient || canDeleteRecipient) &&
                  <td>
                    <div className="hstack gap-3 flex-wrap">
                      {canEditRecipient &&
                      <Link
                        to="#"
                        onClick={() => {editRecipientHandler(recipient.id, recipient.name, recipient.settings)}}
                        className="link-success fs-15"
                      >
                        <i className="ri-edit-2-line"></i>
                      </Link>}
                      {canDeleteRecipient &&
                      <Link
                        to="#"
                        onClick={() => {deleteRecipientHandler(recipient.id)}}
                        className="link-danger fs-15"
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Link>}
                    </div>
                  </td>}
                </tr>
              )
            }) :
            (<tr>
              <td colSpan={3} className='text-center'>No Data</td>
            </tr>)
          }
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default Recipients
