import Flatpickr from "react-flatpickr"
import React, {useEffect, useState} from "react"
import {formatStringDate} from "../../../helpers/utils"
import {insertFilterData} from "../../../slices/table-filters/reducer";
import {useDispatch} from "react-redux";
import moment from "moment";

const DateInput = (props) => {
  const {fieldData, rowValues, withoutPlaceholder = false, filterStorageKey} = props
  const [selectedValue, setSelectedValue] = useState('')
  const {type, name, uuid} = fieldData
  const dispatch = useDispatch()

  const addToActiveFilters = (value) => {
    dispatch(insertFilterData({
      value: value,
      type: type,
      name: name,
      uuid: uuid,
      filterStorageKey: filterStorageKey,
    }))
  }

  const clearSelectedValuesHandler =() => {
    setSelectedValue('')
    addToActiveFilters('')
  }

  useEffect(() => {
    if (rowValues && rowValues.length > 0) {
      setSelectedValue(rowValues)
    } else if (!rowValues && name?.length) {
      setSelectedValue('')
    } else {
      setSelectedValue('')
    }
  }, [rowValues])

  return (
    <div className="position-relative">
      {selectedValue &&
        <i
          className="mdi mdi-close font-weight-bold text-muted fs-18 position-absolute cursor-pointer"
          style={{right: '10px', top: '50%', transform: 'translateY(-50%)'}}
          onClick={clearSelectedValuesHandler}
        ></i>
      }
      <Flatpickr
        className="form-control"
        id={`${filterStorageKey}_${name}`}
        placeholder={!withoutPlaceholder ? 'Enter the value' : ''}
        value={selectedValue}
        onChange={date => {
          let dates = date.map(i => moment(i).format('YYYY-MM-DD'))
          setSelectedValue(dates)
          addToActiveFilters(dates)
        }}
        onClose={(selectedDates, dateStr) => {
          if (!dateStr) {
            setSelectedValue('')
            addToActiveFilters('')
          }
        }}
        options={{
          allowInput: true,
          time_24hr: true,
          mode: "range",
          dateFormat: "Y-m-d",
        }}
      />
    </div>
  )
}

export default DateInput
