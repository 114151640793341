import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import {Button, Container, DropdownItem} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import BaseTable from '../../Components/Tables/BaseTable'
import {ModalContext} from "../../contexts"
import BaseActionDropdown from "../../Components/Tables/Actions/BaseActionDropdown"
import AttachEmployee from "./Modals/AttachEmployee"
import ChangeStatus from "./Modals/ChangeStatus"
import ChangeSource from "./Modals/ChangeSource"
import ChangeCustomerDepartment from "./Modals/ChangeCustomerDeparment"
import {toast} from "react-toastify"
import CreateCustomer from "./Modals/CreateCustomer"
import {detachEmployee, deleteCustomer} from "../../api/customers"
import {Link} from "react-router-dom"
import {useUserCan, useUserHasRole} from "../../Components/Hooks/UserHooks"
import {copyToClipboard, openLinkInNewTab, prettyDate} from "../../helpers/utils"
import {setColumnsSetting} from "../../slices/customers/reducer"
import {useSelector} from "react-redux"
import SendToRecipient from "./Modals/SendToRecipient"
import VoIPModal from "./Modals/VoIPModal"
import {filtersList} from "../../api/general"

const storageName = 'customers_columns_order'
const tableRowsPerPage = 'customers_rows_per_page'
const filterStorageKey = 'customers_filter'

const Customers = () => {
  const {openModal} = useContext(ModalContext)
  const customersOnlineData = useSelector(s => s.State.customersData)
  const [selectedRows, setSelectedRows] = useState([])
  const baseTableMethodsRef = useRef(null)
  const columnsSetting = useSelector(s => s.Customers.columnsSetting)
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [isDeleteDone, setIsDeleteDone] = useState(false)
  const [filters, setFilters] = useState([])

  // Permissions
  const canCreateCustomer = useUserCan('create_customer')
  const canAssignEmployee = useUserCan('assign_employee')
  const canChangeSource = useUserCan('change_source')
  const canChangeDepartment = useUserHasRole(['admin'])
  const isAdmin = useUserHasRole(['admin'])
  const isSuperAdmin = useUserHasRole(['super_admin'])
  const canViewAllCustomers = useUserCan('view_all_customers')
  const canViewCustomer = useUserCan('view_customer')
  const isManager = useUserHasRole(['admin', 'manager', 'support'])
  const canFilterByDepartment = useUserCan('filter_by_department')
  const canViewDocuments = useUserCan('view_documents')
  const canSendCustomer = useUserCan('send_customer')
  const canDeleteCustomer = useUserCan('delete_customer')
  const canChangeStatus = useUserCan('change_status')

  const selectedRowsHandler = (rows) => {
    setSelectedRows(rows)
    setSelectedRowIds(rows.map((row) => row.id))
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='font-weight-bold fs-14'>ID</span>,
        selector: canViewCustomer ? (row => <div className="hstack justify-content-center">
          <Link to={`/customers/show/${row.id}`}>#{row.id}</Link>
          <Link to="#" onClick={() => openLinkInNewTab(`/customers/show/${row.id}`)}>
            <i className="ri-share-box-line text-muted fs-14 ms-1"></i>
          </Link>
        </div>) : (row => <>#{row.id}</>),
        sortable: true,
        reorder: true,
        customVisible: true,
        minWidth: '88px',
        maxWidth: '300px',
        omit: false
      },
      {
        id: "name",
        name: <span className='font-weight-bold fs-14'>Name</span>,
        selector: row => (!row.is_online ? row.name :
            <div className="position-relative pe-2 pt-2">
            <span className="bg-transparent text-reset">
                {row.name}
            </span>
              <span
                className="position-absolute top-10 start-90 translate-middle badge bg-success me-1 p-1">
            <span className="visually-hidden">unread messages</span></span>
            </div>
        ),
        reorder: true,
        minWidth: '180px',
        maxWidth: '300px',
        omit: false,
        customVisible: true,
      },
      {
        id: "phone",
        name: <span className='font-weight-bold fs-14'>Phone</span>,
        selector: row => <>
          {import.meta.env.VITE_VOIP_ENABLED === 'true' &&
            <span>
            <i
              className="bx bx-phone-call text-primary fs-14 me-2 cursor-pointer"
              onClick={() => openVoIPHandler(row.id)}
            ></i>
          </span>}
          <span
            className="cursor-pointer"
            onClick={() => copyToClipboard(row.profile?.phone, 'Phone successfully copied to clipboard!')}
          >
            {row.phone_formatted}
          </span>
        </>
        ,
        reorder: true,
        customVisible: true,
        minWidth: "110px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "source",
        name: <span className='font-weight-bold fs-14'>Source</span>,
        selector: row => row.profile.source,
        reorder: true,
        minWidth: "215px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "country",
        name: <span className='font-weight-bold fs-14'>Country</span>,
        selector: row => row.formatted_country,
        reorder: true,
        minWidth: "120px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "email",
        name: <span className='font-weight-bold fs-14'>Email</span>,
        selector: row => <span className="cursor-pointer" onClick={() => copyToClipboard(row.email)}>
          {row.email}
        </span>,
        reorder: true,
        minWidth: "200px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "status_id",
        name: <span className='font-weight-bold fs-14'>Status</span>,
        selector: row =>
          row.profile.status_data?.status_color ?
            <span className="badge fs-12" style={{background: `${row.profile.status_data.status_color}`}}>
          {row.profile.status_data.name.capitalize()}
        </span>
            :
            <span>
          {row.profile.status_data.name.capitalize()}
        </span>,
        reorder: true,
        sortable: true,
        minWidth: "120px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "is_sync",
        name: <span className='font-weight-bold fs-14'>Is Sync</span>,
        selector: row => row.is_sync.length > 1 ? (prettyDate(row.is_sync)) : '-',
        reorder: true,
        minWidth: "175px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "is_real",
        name: <span className='font-weight-bold fs-14'>Is Real</span>,
        selector: row => row.is_real,
        reorder: true,
        minWidth: "50px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "document_verified",
        name: <span className='font-weight-bold fs-14'>Document Verified</span>,
        selector: row => row.document_verified,
        reorder: true,
        minWidth: "95px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "agent",
        name: <span className='font-weight-bold fs-14'>Agent</span>,
        selector: row => {
          if (typeof row.attached_employee[0] !== 'undefined') {
            return row.attached_employee[0].name
          } else {
            return '-'
          }
        },
        reorder: true,
        omit: !(isAdmin || isSuperAdmin || canViewAllCustomers),
        minWidth: "150px",
        maxWidth: "300px",
      },
      {
        id: "affiliate",
        name: <span className='font-weight-bold fs-14'>Affiliate</span>,
        selector: row => {
          if (typeof row.profile?.affiliate?.name !== 'undefined') {
            return row.profile?.affiliate?.name
          } else {
            return '-'
          }
        },
        reorder: true,
        omit: !(isAdmin || isSuperAdmin || canViewAllCustomers),
        minWidth: "150px",
        maxWidth: "300px",
      },
      {
        id: "department",
        name: <span className='font-weight-bold fs-14'>Department</span>,
        selector: row => row.departments[0].name,
        reorder: true,
        omit: !(isAdmin || isSuperAdmin || canViewAllCustomers),
        minWidth: "150px",
        maxWidth: "300px",
      },
      {
        id: "last_comment_date",
        name: <span className='font-weight-bold fs-14'>Last Comment</span>,
        selector: row => (row.last_comment_date ? prettyDate(row.last_comment_date) : '-'),
        sortable: true,
        reorder: true,
        minWidth: "155px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "last_visit_date",
        name: <span className='font-weight-bold fs-14'>Last Visit Date</span>,
        selector: row => (row.last_visit_date ? prettyDate(row.last_visit_date) : '-'),
        sortable: true,
        reorder: true,
        minWidth: "155px",
        maxWidth: "300px",
        omit: false
      },
      {
        id: "registered_at",
        name: <span className='font-weight-bold fs-14'>Registered At</span>,
        selector: row => (prettyDate(row.formatted_date)),
        reorder: true,
        minWidth: "155px",
        maxWidth: "300px",
        omit: false
      },
    ], [isManager, canViewCustomer])

  const getFilterData = () => {
    filtersList(filterStorageKey).then(r => {

      // if (!canViewDocuments) {
      //   filtredFiltersArr = filtredFiltersArr.filter(i => i.name !== 'document_verified')
      // }
      //
      // if (!canFilterByDepartment) {
      //     filtredFiltersArr = filtredFiltersArr.filter(i => i.name !== 'departments')
      // }
      //
      // if (!isManager) {
      //     filtredFiltersArr = filtredFiltersArr.filter(i => i.name !== 'affiliates').filter(i => i.name !== 'agents')
      // }

      setFilters(r)
    })
  }

  useEffect(() => {
    getFilterData()
  }, [canViewDocuments, canFilterByDepartment, isManager]);

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  const clearingSelectedRowsList = () => {
    setSelectedRowIds([])
  }

  const attachEmployeeHandler = () => {
    openModal({
      title: 'Attach Employee',
      content: <AttachEmployee selectedRows={selectedRows} updateTable={updateTable}/>
    })
  }

  const detachEmployeeHandler = () => {
    if (confirm('Are you sure to detach employee?')) {
      detachEmployee(selectedRows.map(i => i.id)).then(res => {
        toast.success(res.message)
        updateTable()
      })
    }
  }

  const deleteCustomerHandler = () => {
    if (confirm('Are you sure you want to remove this user(s)?')) {
      setIsDeleteDone(true)

      deleteCustomer(selectedRows.map(i => i.id)).then(r => {
        if (r.success) {
          toast.success(r.message)
          updateTable()
          setSelectedRows([])
          setSelectedRowIds([])
          setIsDeleteDone(false)
        } else {
          toast.error(r.error)
          setIsDeleteDone(false)
        }
      }).catch(r => {
        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
          setIsDeleteDone(false)
        }
      })
    }
  }

  const openVoIPHandler = (id) => {
    openModal({
      title: `Call to Customer #${id}`,
      content: <VoIPModal customerId={id}/>
    })
  }

  const changeStatusHandler = () => {
    openModal({
      title: 'Change Status',
      content: <ChangeStatus selectedRows={selectedRows} updateTable={updateTable}/>
    })
  }

  const createCustomerHandler = () => {
    openModal({
      title: 'Create Customer',
      content: <CreateCustomer updateTable={updateTable}/>
    })
  }

  const changeCustomerSource = () => {
    openModal({
      title: 'Change Source',
      content: <ChangeSource selectedRows={selectedRows} updateTable={updateTable}/>
    })
  }

  const changeCustomerDepartment = () => {
    openModal({
      title: 'Change Department',
      content: <ChangeCustomerDepartment selectedRows={selectedRows} updateTable={updateTable}/>
    })
  }

  const sendToRecipient = () => {
    openModal({
      title: 'Send to recipient',
      content: <SendToRecipient selectedRows={selectedRows} updateTable={updateTable}/>
    })
  }

  const contextActions = (
    <BaseActionDropdown key="actionsDropdown">
      {canAssignEmployee &&
        <>
          <DropdownItem onClick={attachEmployeeHandler}>Attach Employee</DropdownItem>
          <DropdownItem onClick={detachEmployeeHandler}>Detach Employee</DropdownItem>
        </>
      }
      {canChangeStatus && <DropdownItem onClick={changeStatusHandler}>Change Status</DropdownItem>}
      {canChangeSource && <DropdownItem onClick={changeCustomerSource}>Change Source</DropdownItem>}
      {canChangeDepartment && <DropdownItem onClick={changeCustomerDepartment}>Change Department</DropdownItem>}
      {canSendCustomer && <DropdownItem onClick={sendToRecipient}>Send to recipient</DropdownItem>}
      {canDeleteCustomer &&
        <DropdownItem disabled={isDeleteDone} onClick={deleteCustomerHandler}>Delete customer</DropdownItem>}
    </BaseActionDropdown>
  )

  useEffect(() => {
    if (customersOnlineData) {
      updateTable()
    }
  }, [customersOnlineData])

  document.title = 'Customers | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Customers"/>
          {canCreateCustomer &&
            <Button color="primary" type="button" className="btn btn-primary mb-3" onClick={createCustomerHandler}>
              Create customer
            </Button>
          }
          <BaseTable
            columns={columns}
            title="Customers"
            filterFields={filters}
            url="/spa/customer/data"
            actions={contextActions}
            selectedRowsHandler={selectedRowsHandler}
            methodsRef={baseTableMethodsRef}
            columnsSetting={columnsSetting}
            columnsSettingHandler={setColumnsSetting}
            conditionalRowStyles={[{
              when: (row) => selectedRowIds.includes(row.id),
              classNames: ['selected_Row']
            }
            ]}
            storageName={storageName}
            tableRowsPerPage={tableRowsPerPage}
            clearingSelectedRowsList={clearingSelectedRowsList}
            filterStorageKey={filterStorageKey}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Customers
