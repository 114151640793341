import Flatpickr from "react-flatpickr"
import React, {useRef, useState} from "react"
import moment from "moment-timezone"
import {toast} from "react-toastify"
import {timeZone} from "../../helpers/utils"

const DatePickerWithParse = () => {
  const [transactionDate, setTransactionDate] = useState(
    moment.tz(new Date(), timeZone).format('YYYY-MM-DD HH:mm')
  )
  const flatpickrRef = useRef(null)

  const parseValue = () => {
    const target = document.querySelector("#inputDate")

    target.addEventListener("paste", function(event)  {
      event.preventDefault()

      let paste = (event.clipboardData || window.clipboardData).getData('text').trim()
      let parsedDate = moment.utc(paste)

      if (parsedDate.isValid()) {
        flatpickrRef.current.set(parsedDate.format('YYYY-MM-DD HH:mm'))
      } else {
        toast.error('Invalid date format. Available formats: YYYY-MM-DD')
        setTransactionDate(moment.utc().format('YYYY-MM-DD HH:mm'))
      }
    })
  }

  return (
    <React.Fragment>
      <Flatpickr
        className="form-control"
        id="inputDate"
        name="date"
        ref={flatpickrRef}
        value={transactionDate}
        options={{
          enableTime: true,
          dateFormat: "Y-m-d H:i",
          time_24hr: true,
          allowInput: true,
        }}
      />
    </React.Fragment>
  )
}

export default DatePickerWithParse
