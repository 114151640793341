import React, {useContext, useEffect, useReducer, useState} from 'react'
import {Button, Card, CardBody, Input, Label} from "reactstrap"
import Select from "react-select"
import {toast} from "react-toastify"
import {ModalContext} from "../../../../contexts"
import {updateTab} from "../../../../api/Tabs"
import {Tooltip} from "react-tooltip";

const blocksListData = [
  {
    value: 'description',
    label: 'Description',
    fields:
    [
      {name: 'title', label: 'Block title', type: 'input'},
      {name: 'text', label: 'About', type: 'textarea'},
      {name: 'video_url', label: 'Video URL', type: 'input'},
    ]
  },
  {
    value: 'popular_list',
    label: 'Popular list',
    fields: [
      {name: 'title', label: 'Block title', type: 'input'},
      {name: 'is_calculator_visible', label: 'Is calculator visible', type: 'checkbox', range: [
          {name: 'calculator_range_min', label: 'Minimum value', type: 'input'},
          {name: 'calculator_range_max', label: 'Maximum value', type: 'input'},
        ]
      },
    ]
  },
  {
    value: 'how_buy',
    label: 'How to buy',
    fields: [
      {name: 'title', label: 'Block title', type: 'input'},
      {name: 'step_tab_list', label: 'Tabs list', steps_tabs_list: [
          {name: 'tab_title', label: 'Tab name', type: 'input'},
          {name: 'description_title', label: 'Description title', type: 'input'},
          {name: 'description', label: 'Description text', type: 'textarea'},
          {name: 'img', label: 'Step image', type: 'input'},
        ]
      },
    ]
  },
  {
    value: 'documents_and_info',
    label: 'Documents and info',
    fields: [
      {name: 'title', label: 'Block title', type: 'input'},
      {name: 'documents_list', label: 'Added documents links', documents_list: [
          {name: 'title', label: 'Document Title', type: 'input'},
          {name: 'link', label: 'Document Link', type: 'input'},
        ]
      },
      {name: 'information', label: 'Information', type: 'textarea'},
    ]
  },
  {
    value: 'securities_table',
    label: 'Table',
    fields: [
      {name: 'title', label: 'Table title', type: 'input'},
      {
        name: 'is_user_list',
        label: 'Display the list of purchased securities. (When this option is selected, ' +
          'only those securities that have been purchased by the user will be displayed in the table)',
        type: 'checkbox'
      },
    ]
  },
]

function blocksDataReducer(blocks, action) {
  switch (action.type) {
    case 'addBlock': {
      return [...blocks, {uuid: action.uuid, name: '', select_label: ''}]
    }

    case 'editBlock': {
      let blockFields = blocksListData.find(el => el.value === action.block.value)?.fields

      blockFields = blockFields.reduce((acc, field) => {
        acc[field.name] = ''
        return acc
      }, {})

      return blocks.map(block =>
        block.uuid === action.uuid
          ? { uuid: block.uuid, name: action.block.value, select_label: action.block.label, ...blockFields}
          : block
      )
    }

    case 'addData': {
      return action.data
    }

    case 'editFields': {
      let newBlocks

      if (action.field_type && action.field_type === 'checkbox') {
        newBlocks = blocks.map(block =>
          block.uuid === action.uuid ? {...block, [action.name]: action.value} : block
        )
      } else if (action.field_type && action.field_type === 'range') {
        newBlocks = blocks.map(block => {

          if (block.uuid === action.uuid) {
            let valueRange = block.value_range || ["", ""]
            valueRange[action.idx] = action.value

            return { ...block, value_range: valueRange }
          }

          return block
        })
      } else if (action.field_type && action.field_type === 'step') {
        newBlocks = blocks.map(block => {

          if (block.uuid === action.uuid) {
            let stepsTabsList = block.steps_tabs_list || []
            stepsTabsList[action.idx] = { ...stepsTabsList[action.idx], [action.name]: action.value }
            return { ...block, steps_tabs_list: stepsTabsList }
          }

          return block
        })
      } else if (action.field_type && action.field_type === 'document') {
        newBlocks = blocks.map(block => {

          if (block.uuid === action.uuid) {
            let documentsList = block.documents_list || []
            documentsList[action.idx] = { ...documentsList[action.idx], [action.name]: action.value }
            return { ...block, documents_list: documentsList }
          }

          return block
        })
      } else {
        newBlocks = blocks.map(block =>
          block.uuid === action.uuid ? {...block, [action.name]: action.value} : block
        )
      }

      return newBlocks
    }

    case 'deleteBlock': {
      return blocks.filter(block => block.uuid !== action.uuid)
    }

    case 'createStep': {
      let fieldsList = { uuid: Date.now(), tab_title: '', description_title: '', description: '', img: '' }
      let newBlocks

      newBlocks = blocks.map(block => {
        if (block.uuid === action.data.block_uuid) {
          if (!block.steps_tabs_list) {
            return {...block, steps_tabs_list: [fieldsList]}
          } else if (block.steps_tabs_list) {
            return {...block, steps_tabs_list: [...block.steps_tabs_list, fieldsList]}
          }
        }

        return block
      })

      return newBlocks
    }

    case 'deleteStep': {
      return blocks.map(block => {
        if (block.uuid === action.data.block_uuid) {
          return {...block, steps_tabs_list: block.steps_tabs_list.filter(step => step.uuid !== action.data.uuid)}
        }

        return block
      })
    }

    case 'createDocument': {
      let fieldsList = { uuid: Date.now(), title: '', link: '' }
      let newBlocks

      newBlocks = blocks.map(block => {
        if (block.uuid === action.data.block_uuid) {
          if (!block.documents_list) {
            return {...block, documents_list: [fieldsList]}
          } else if (block.documents_list) {
            return {...block, documents_list: [...block.documents_list, fieldsList]}
          }
        }

        return block
      })

      return newBlocks
    }

    case 'deleteDocument': {
      return blocks.map(block => {
        if (block.uuid === action.data.block_uuid) {
          return {...block, documents_list: block.documents_list.filter(document => document.uuid !== action.data.uuid)}
        }

        return block
      })
    }

    default:
      return blocks
  }
}

const initialBlocksData = []

const EditTab = (props) => {
  const {uuid, name, page_id, data = [], updateData} = props
  const {closeModal} = useContext(ModalContext)
  const [tabTitle, setTabTitle] = useState('')
  const [blockListOptions, setBlockListOptions] = useState([])
  const [blocksData, dispatchBlocksData] = useReducer(blocksDataReducer, initialBlocksData)

  const addBlockHandler = () => {
    dispatchBlocksData({
      type: 'addBlock',
      uuid: Date.now(),
    })
  }

  const addData = (data) => {
    dispatchBlocksData({
      type: 'addData',
      data: data
    })

    addBlockHandler()
  }

  const editBlockHandler = (selected, uuid, idx) => {
    dispatchBlocksData({
      type: 'editBlock',
      uuid: uuid,
      block: selected
    })

    if (idx === blocksData.length - 1) {
      addBlockHandler()
    }
  }

  const editBlockFields = (name, value, uuid, field_type, idx) => {
    dispatchBlocksData({
      type: 'editFields',
      uuid: uuid || '',
      name: name || '',
      value: value || '',
      field_type: field_type || '',
      idx: idx !== undefined ? String(idx) : ''
    })
  }

  const deleteBlockHandler = (uuid) => {
    dispatchBlocksData({
      type: 'deleteBlock',
      uuid: uuid
    })
  }

  const createStep = (data) => {
    dispatchBlocksData({
      type: 'createStep',
      data: data
    })
  }

  const deleteStep = (data) => {
    dispatchBlocksData({
      type: 'deleteStep',
      data: data
    })
  }

  const createDocument = (data) => {
    dispatchBlocksData({
      type: 'createDocument',
      data: data
    })
  }

  const deleteDocument = (data) => {
    dispatchBlocksData({
      type: 'deleteDocument',
      data: data
    })
  }

  const checkBoxFields = (block, field) => {
    return (
      <div className="d-flex align-items-start">
        <div className="form-check form-switch form-switch-left form-switch-sm mt-1">
          <Input
            className="form-check-input"
            name={field.name}
            type="checkbox"
            checked={!!block?.[field.name]}
            onChange={e =>
              editBlockFields(field.name, e.target.checked, block.uuid, field.type)
            }
          />
          <Label className="form-check-label text-muted me-3">
            {field.label}
          </Label>
        </div>
        {(field.range?.length) ?
          field.range.map((i, idx) => (
            <div className={`form-group ${idx === 0 && 'me-3'}`} key={idx}>
              <label className="custom-label">{i.label}</label>
              <Input
                type='text'
                name={i.name}
                placeholder='Enter the value'
                disabled={!block?.is_calculator_visible}
                value={block.value_range ? block.value_range[idx] : ''}
                onChange={(e) =>
                  editBlockFields(field.name, e.target.value, block.uuid, 'range', idx)
                }
              />
            </div>
          ))
          : ''}
      </div>
    )
  }

  const inputFields = (field, block) => {
    return (
      <Input
        type={field.type}
        name={field.name}
        placeholder='Enter the value'
        value={block[field.name] || ''}
        onChange={(e) =>
          editBlockFields(field.name, e.target.value, block.uuid, field.type)
        }
      />
    )
  }

  const stepsFields = (steps_tabs_list, block) => {
    return steps_tabs_list.map((step, stepIdx) => (
      <Card key={stepIdx} className="mb-0">
        {stepIdx > 0 ?
          <div className="d-flex justify-content-center">
            <div className="border-bottom w-75"></div>
          </div> : ''}
        <CardBody className="pb-0">
          <div className="mb-3">
            {blocksListData
              .find(i => i.value === 'how_buy')
              ?.fields.find(i => i.name === 'step_tab_list')
              ?.steps_tabs_list.map((field, fieldIdx) => (
                <div key={fieldIdx} className="d-flex w-100 align-items-end">
                  <div className="form-group mt-1 w-100 position-relative">
                    <label className={`custom-label ${field.name === 'img' ? 'pe-4' : ''}`}>{field.label}</label>
                    <Input
                      type={field.type}
                      value={step[field.name] || ''}
                      onChange={(e) =>
                        editBlockFields(field.name, e.target.value, block.uuid, 'step', stepIdx)
                      }
                    />
                    {field.name === 'img' ?
                      <div className="position-absolute" style={{top: '-4px', left: '88px', zIndex: '2'}}>
                        <i
                          className="ri-question-line align-middle fs-20 ms-1 text-premium"
                          style={{cursor: 'help', color: '#405189'}}
                          data-tooltip-id={'img_helper'}
                          data-tooltip-content={
                            'The image should have a minimum height of 350 pixels, and its width should not exceed ' +
                            'the height. The optimal aspect ratio is 1:1. Failure to follow these guidelines may ' +
                            'result in the image displaying incorrectly on the website.'
                          }
                        ></i>
                        <Tooltip id={'img_helper'} style={{maxWidth: '400px'}}/>
                      </div> : null
                    }
                  </div>
                  {fieldIdx === 0 ?
                    <button
                      type="button"
                      className={`btn btn-${stepIdx === steps_tabs_list.length - 1 ? 'primary' : 'danger'} px-3 btn-icon waves-effect waves-light ms-3`}
                      onClick={() => stepIdx === steps_tabs_list.length - 1 ?
                        createStep({block_uuid: block.uuid}) :
                        deleteStep({block_uuid: block.uuid, uuid: step.uuid})}
                    >
                      {stepIdx === steps_tabs_list.length - 1 ?
                        <i className="mdi mdi-layers-plus fs-20"></i> :
                        <i className="mdi mdi-archive-remove-outline fs-20"></i>
                      }
                    </button> : ''
                  }
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    ))
  }

  const documentsFields = (documents_list, block) => {
    return documents_list.map((document, docIdx) => (
      <Card key={docIdx} className="mb-0">
        {docIdx > 0 ?
          <div className="d-flex justify-content-center">
            <div className="border-bottom w-75"></div>
          </div> : ''}
        <CardBody className="pb-0">
          <div className="mb-3">
            {blocksListData
              .find(i => i.value === 'documents_and_info')
              ?.fields.find(i => i.name === 'documents_list')
              ?.documents_list.map((field, fieldIdx) => (
                <div key={fieldIdx} className="d-flex w-100 align-items-end">
                  <div className="form-group mt-1 w-100">
                    <label className="custom-label">{field.label}</label>
                    <Input
                      type={field.type}
                      value={document[field.name] || ''}
                      onChange={(e) =>
                        editBlockFields(field.name, e.target.value, block.uuid, 'document', docIdx)
                      }
                    />
                  </div>
                  {fieldIdx === 0 ?
                    <button
                      type="button"
                      className={`btn btn-${docIdx === documents_list.length - 1 ? 'primary' : 'danger'} px-3 btn-icon waves-effect waves-light ms-3`}
                      onClick={() => docIdx === documents_list.length - 1 ?
                        createDocument({block_uuid: block.uuid}) :
                        deleteDocument({block_uuid: block.uuid, uuid: document.uuid})}
                    >
                      {docIdx === documents_list.length - 1 ?
                        <i className="mdi mdi-layers-plus fs-20"></i> :
                        <i className="mdi mdi-archive-remove-outline fs-20"></i>
                      }
                    </button> : ''
                  }
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    ))
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let newBlocksData = blocksData.filter(i => i.name)

    const data = {
      uuid: uuid,
      blocks: newBlocksData
    }

    updateTab(page_id, data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateData()
        closeModal()
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })

  }

  useEffect(() => {
    setTabTitle(name)
  }, [name])

  useEffect(() => {
    addBlockHandler()
  }, [])

  useEffect(() => {
    setBlockListOptions(blocksListData.map(i => {
      return {value: i.value, label: i.label}
    }))
  }, [])

  useEffect(() => {
    if (data && data.length) {
      addData(data)
    }
  }, [data])

  return (
    <>
      <div className="form-group d-flex align-items-center">
        <Label className="form-label mb-0 text-nowrap me-3">Tab name:</Label>
        <Input
          type="text"
          placeholder="Tab name"
          autoComplete="off"
          disabled
          defaultValue={tabTitle}
        />
      </div>

      <div className="border-bottom w-100 mb-2 mt-3"></div>

      {(blocksData && blocksData?.length) ?
        blocksData.map((block, idx) => {
          const selectValue = {value: block?.name, label: block?.select_label}
          const fieldsData = blocksListData.find(i => i.value === block?.name)?.fields

          return (
            <div className="form-group" key={idx}>
              <Label className="form-label mb-0 text-nowrap">Block</Label>
              <div className="d-flex">
                <Select
                  name="block"
                  className="w-100"
                  placeholder="Select block"
                  autoComplete="off"
                  required
                  options={blockListOptions}
                  value={selectValue}
                  onChange={(selected) => {
                    editBlockHandler(selected, block.uuid, idx)

                    if (selected.value === 'how_buy') {
                      createStep({block_uuid: block.uuid})
                    } else if (selected.value === 'documents_and_info') {
                      createDocument({block_uuid: block.uuid})
                    }
                  }}
                />
                <button
                  type="button"
                  className="btn btn-danger px-3 btn-icon waves-effect waves-light ms-3"
                  onClick={() => deleteBlockHandler(block.uuid)}
                  disabled={idx === blocksData.length - 1}
                >
                  <i className="mdi mdi-archive-remove-outline fs-20"></i>
                </button>
              </div>

              {(selectValue.value && fieldsData.length) ?
                <Card>
                  <CardBody>
                    {fieldsData.map((field, idx) => {
                      return (
                        <div key={idx}>
                          <div className="form-group mt-1">
                            {(field.type !== 'checkbox' && field.name !== 'step_tab_list' && field.name !== 'documents_list') ?
                              <label className="custom-label">{field.label}</label> : ''
                            }
                            {(field.type === 'input' || field.type === 'textarea') ?
                              inputFields(field, block) :
                              (field.type === 'checkbox') ?
                                checkBoxFields(block, field) :
                                (block.name === 'how_buy') ?
                                  stepsFields(block.steps_tabs_list, block) :
                                  (block.name === 'documents_and_info') ?
                                    documentsFields(block.documents_list, block) : ''
                            }
                          </div>
                        </div>
                      )
                    })}
                  </CardBody>
                </Card> : ''
              }
            </div>
          )
        }) : ''
      }

      <div className="mt-3 d-flex justify-content-between">
        <Button type="button" className="btn btn-warning me-3 py-1" onClick={closeModal}>Cancel</Button>
        <Button type="button" onClick={e => submitHandler(e)} className="btn btn-success py-1">Save</Button>
      </div>
    </>
  )
}

export default EditTab
