import {useContext, useEffect, useState} from "react";
import {ModalContext} from "../../../../../contexts";
import {Button, Col, Form, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import {selectListData} from "../../../../../api/general";

const ChangeAction = ({addAction, meta}) => { // addAction, title, label, selectListDataKey, action
  const {closeModal} = useContext(ModalContext)
  const [selectedValue, setSelectedValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (meta.selectListDataKey) {
      selectListData(meta.selectListDataKey).then(r => {
        setOptions(r)
      })
    }
  }, [meta])

  const submitHandler = (e) => {
    e.preventDefault()

    const data = {title: meta.title}

    if (meta.selectListDataKey) {
      data.value = selectedValue.value
      data.valueLabel = selectedValue.label
    } else {
      data.value = inputValue
      data.valueLabel = inputValue
    }

    addAction(meta.action, data)
    closeModal()
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12}>
            <Label className="form-label mb-0">{meta.label}</Label>
            {meta.selectListDataKey ?
              <Select
                placeholder="Choose..."
                value={selectedValue}
                onChange={(selected) => {
                  setSelectedValue(selected);
                }}
                options={options}
              /> :
              <Input
                placeholder="Enter new value..."
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
              />
            }
          </Col>
          <Col xs={12} className="hstack justify-content-end">
            <Button type="submit" color="primary" className="btn ms-auto mt-4">Apply</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ChangeAction;
