import React, {useEffect, useState} from 'react'
import {Badge, Card, CardBody, CardHeader, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap"
import {Link} from "react-router-dom"
import {getCalendarEvents} from "../../api/calendar"
import SimpleBar from "simplebar-react"
import moment from "moment-timezone"
import {prettyDate} from "../../helpers/utils"

let calendarInterval

const CalendarDropdown = () => {
  const [isCalendarDropdown, setIsCalendarDropdown] = useState(false)
  const [calendarEvents, setCalendarEvents] = useState([])

  const toggleCalendarDropdown = () => {
    setIsCalendarDropdown(!isCalendarDropdown)
  }

  const badgeColorMap = {
    "phone call" : "success",
    "other" : "primary",
    "email" : "info",
    "meeting" : "warning",
  }

  // const currentDate = new Date()
  // const tomorrowDate = new Date()
  // tomorrowDate.setDate(currentDate.getDate() + 1)
  // tomorrowDate.setHours(0, 0, 0, 0)

  const upcomingEvents = calendarEvents
    .filter(event => moment.tz(event.start, 'UTC') >= moment.tz(new Date(), 'UTC'))
    // .filter(event => new Date(event.start) >= tomorrowDate)

  const updateCalendarEvents = () => {
    clearInterval(calendarInterval)

    getCalendarEvents().then((r) => {
      r.forEach((event) => {
        event.startDate = new Date(event.start)
      })
      r.sort((a, b) => a.startDate - b.startDate)

      setCalendarEvents(r)
    }).finally(() => {
      calendarInterval = setTimeout(() => {
        updateCalendarEvents()
      }, 60000)
    })
  }

  useEffect(() => {
    updateCalendarEvents()
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isCalendarDropdown}
        toggle={toggleCalendarDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="ri-calendar-event-fill fs-22"></i>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-lg p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Link to="/calendar"><h6 className="m-0 fs-16 fw-semibold text-white"> Upcoming Events </h6></Link>
            </div>
          </div>
          <SimpleBar className="p-2" style={{maxHeight: "300px"}}>
            {upcomingEvents.length > 0 ? (
              upcomingEvents.map((event, idx) => (
                <Card key={idx}>
                  <CardBody className="my-1 py-1">
                    {event &&
                      <div className="event-details mb-0">
                       <div className="mb-2">
                         {(() => {
                           const classNameColor = badgeColorMap[event.category]
                           return (
                             <Badge
                               color={classNameColor}
                               className="badge-dot me-3 fs-10"
                             >
                               {event.category.toUpperCase()}
                             </Badge>
                           )
                         })()}
                       </div>
                        <div className="d-flex mb-1">
                          <div className="flex-grow-1 d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <i className=" ri-profile-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <h6
                                className="d-block fw-semibold mb-0"
                                id="event-title-tag"
                              >
                                {event.title}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="flex-grow-1 d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-calendar-event-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <h6
                                className="d-block mb-0"
                                id="event-start-date-tag"
                              >
                                {event ?
                                  prettyDate(event.start, true, true) : ""
                                  // moment(event.start).format('YYYY-MM-DD HH:mm') : ""
                                }
                              </h6>
                            </div>
                          </div>
                        </div>
                        {event.description &&
                          <div className="d-flex mt-1">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-discuss-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <p
                                className="d-block text-muted mb-0"
                                id="event-description-tag"
                              >
                                {event ? event.description : "No Description"}
                              </p>
                            </div>
                          </div>
                        }
                        {event?.users?.length > 0 &&
                          <div className="d-flex mt-3">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-user-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <p
                                className="d-block text-muted mb-0"
                                id="event-description-tag"
                              >
                                {event.users.map((user, idx) => {
                                  return <span key={idx} className="hstack justify-content-start">
                                    <Link to={`/customers/show/${user.id}`}>#{user.id} {user.name}</Link>
                                    <Link to={`/customers/show/${user.id}`} target="_blank" className="ms-1">
                                      <i className="ri-share-box-line text-muted fs-12"></i>
                                    </Link>
                                  </span>
                                })}
                              </p>
                            </div>
                          </div>
                        }
                        {event?.employee &&
                            <div className="d-flex mt-1">
                              <div className="flex-shrink-0 me-3">
                                <i className="ri-user-2-line text-muted fs-16"></i>
                              </div>
                              <div className="flex-grow-1">
                                <p
                                    className="d-block text-muted mb-0"
                                    id="event-description-tag"
                                >
                                  <span className="hstack justify-content-start">
                                    <Link to={`/employees/show/${event.employee.id}`}>
                                      #{event.employee.id} {event.employee.name}
                                    </Link>
                                    <Link to={`/employees/show/${event.employee.id}`} target="_blank" className="ms-1">
                                      <i className="ri-share-box-line text-muted fs-12"></i>
                                    </Link>
                                  </span>
                                </p>
                              </div>
                            </div>
                        }
                      </div>
                    }
                  </CardBody>
                </Card>
              ))
            ) : <div className="fw-bold text-center mb-1 mt-2">There are no upcoming events!</div>}
          </SimpleBar>
          <div className="mt-2 mb-3 text-center">
            <Link
              to="/calendar"
              onClick={toggleCalendarDropdown}
              type="button"
              className="btn btn-soft-success waves-effect waves-light"
            >
              Manage all events
              <i className="ri-arrow-right-line align-middle"></i>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default CalendarDropdown
