import {APIClient} from "../helpers/api_helper"

const api = new APIClient();

export function getPostbacks() {
  return api.get('/spa/postbacks/data', {})
}

export function getPostback(id) {
  return api.get(`/spa/postbacks/get-postback/${id}`, {id})
}

export function createPostback(data) {
  return api.post('/spa/postbacks/create', data)
}

export function editPostback(id, data) {
  return api.post(`/spa/postbacks/edit/${id}`, data)
}

export function deletePostback(id) {
  return api.post(`/spa/postbacks/delete/${id}`, {})
}
