import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import React, {useState} from "react"
import {toast} from "react-toastify"
import {changePassword} from "../../../api/employees"

const ChangePasswordForm = ({id}) => {
  const [isPassVisible, setIsPassVisible] = useState(false)
  const [isConfirmPassVisible, setIsConfirmPassVisible] = useState(false)

  const submitHandler = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData.entries())
    data.employee_id = id

    changePassword(data)
      .then(r => {
        if (r.success) {
          toast.success(r.message)
          e.target.reset()
        } else {
          toast.error(r.message)
        }
      })
      .catch(r => {
        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        }
      })
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row className="g-2">
          <Col lg={6}>
            <div>
              <Label htmlFor="newpasswordInput" className="form-label">
                New Password
                <span className="text-danger">*</span>
              </Label>
              <div className="position-relative auth-pass-inputgroup">
                <Input
                  name="new_password"
                  type={isPassVisible ? "text" : "password"}
                  className="form-control"
                  id="newpasswordInput"
                  placeholder="Enter new password"
                />
                <Button
                  color="link"
                  onClick={() => setIsPassVisible(!isPassVisible)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                  type="button"
                >
                  <i className="ri-eye-fill align-middle"></i>
                </Button>
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <div>
              <Label htmlFor="confirmpasswordInput" className="form-label">
                Confirm Password
                <span className="text-danger">*</span>
              </Label>
              <div className="position-relative auth-pass-inputgroup">
                <Input
                  name="new_password_confirmation"
                  type={isConfirmPassVisible ? "text" : "password"}
                  className="form-control"
                  id="confirmpasswordInput"
                  placeholder="Confirm password"
                />
                <Button
                  color="link"
                  onClick={() => setIsConfirmPassVisible(!isConfirmPassVisible)}
                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                  type="button"
                >
                  <i className="ri-eye-fill align-middle"></i>
                </Button>
              </div>
            </div>
          </Col>

          <Col lg={12}>
            <div className="text-end mt-3">
              <button type="submit" className="btn btn-success">Change Password</button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ChangePasswordForm
