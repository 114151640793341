import React from 'react'
import {Container} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import TransactionsTable from "./TransactionsTable"

const Transactions = () => {
  document.title = 'Transactions | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Transactions"/>
          <TransactionsTable withFilters={true}/>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Transactions
