import React, {useContext, useRef, useState, useEffect, useReducer} from "react"
import {ModalContext} from "../../../../../contexts"
import {Button, Card, CardBody, Form, FormGroup, Input, Label} from "reactstrap"
import {editStatusGroup} from "../../../../../api/settings"
import {toast} from "react-toastify"
import {Tooltip} from "react-tooltip"
import classnames from "classnames"
import {SketchPicker} from "react-color"

function statusesReducer(state, action) {
  switch (action.type) {
    case 'add-item': {
      return [...state, action.payload]
    }
    case 'delete-item': {
      let newState = state
      newState.splice(action.idx, 1)

      return [...newState]
    }
    case 'add-new-item': {
      return [...state, {id: action.id, name: action.name}]
    }
    case 'edit-item-name': {
      let newState = state
      newState[action.idx] = {...newState[action.idx], name: action.name}

      return [...newState]
    }
    case 'edit-item-visible-to-api': {
      let newState = state
      newState[action.idx] = {...newState[action.idx], visible_to_api: action.visible_to_api}

      return [...newState]
    }
    case 'edit-item-color': {
      let newState = state
      newState[action.idx] = {...newState[action.idx], status_color: action.status_color}

      return [...newState]
    }
    // case 'edit-item-fallback': {
    //   let newState = state
    //   newState = state.map((item, idx) => ({...item, fallback: (idx === action.idx)}))
    //
    //   return [...newState]
    // }
    default: {
      return state
    }
  }
}

const statusesInitOptions = []

const Edit = ({statusGroup, updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const {id, name, statuses} = statusGroup
  const [isFieldVisible, setIsFieldVisible] = useState(false)
  const [editedFields, setEditedFields] = useState(statuses)
  const [statusGroupName, setStatusGroupName] = useState(name)
  // const [selectedFallback, setSelectedFallback] = useState(null)
  const [visibleToApiList, setVisibleToApiList] = useState(new Map())
  const updatedStatuses = useRef(new Map())
  const newStatusesIndex = useRef(1)
  const [isColorPicker, setIsColorPicker] = useState({})
  const [fieldColors, setFieldColors] = useState({})
  const [initialFieldColor, setInitialFieldColor] = useState({})

  const [statusesData, statusesDataOptionsDispatch] = useReducer(statusesReducer, statusesInitOptions)

  const submitHandler = (e) => {
    e.preventDefault()

    const data = {
      status_group_id: id,
      statuses_group_name: statusGroupName,
      fields: statusesData,
      // fields_to_delete: statusesToDelete.current,
      // is_default: isDefaultCheck,
    }

    editStatusGroup(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  const handleColorChange = (color, fieldId, index) => {
    setFieldColors((prevColors) => ({
      ...prevColors,
      [fieldId]: color
    }))

    statusesDataOptionsDispatch({type: 'edit-item-color', idx: index, status_color: color})
    setInitialFieldColor('')
  }

  const toggleColorPicker = (fieldId) => {
    const pickerToClose = { ...isColorPicker }
    Object.keys(pickerToClose).forEach((key) => {
      pickerToClose[key] = false
    })

    pickerToClose[fieldId] = !pickerToClose[fieldId]
    setIsColorPicker(pickerToClose)
  }

  const statusGroupNameChangeHandler = (e) => {
    setStatusGroupName(e.target.value)
  }

  const fieldsChangeHandler = (index, value) => {
    statusesDataOptionsDispatch({type: 'edit-item-name', idx: index, name: value})
  }

  const editVisibleToApiHandler = (index, value) => {
    statusesDataOptionsDispatch({type: 'edit-item-visible-to-api', idx: index, visible_to_api: value})
  }

  // const changeFallbackHandler = (index, checked) => {
  //   statusesDataOptionsDispatch({type: 'edit-item-fallback', idx: index, fallback: checked})
  // }

  const removeFieldHandler = (index) => {
    statusesDataOptionsDispatch({type: 'delete-item', idx: index})
  }

  const addFieldHandler = () => {
    if (!isFieldVisible) {
      setEditedFields([...editedFields, {id: `new_${newStatusesIndex.current}`, name: ''}])

      statusesDataOptionsDispatch({type: 'add-new-item', id: `new_${newStatusesIndex.current}`, name: ''})
      newStatusesIndex.current = newStatusesIndex.current + 1
    }
  }

  useEffect(() => {
    if(statuses?.length) {
      statuses.forEach(i => statusesDataOptionsDispatch({type: 'add-item', payload: i}))

      // let fallbackItem = statuses.find(i =>  i.fallback === 1)
      //
      // if (fallbackItem) {
      //   setSelectedFallback(fallbackItem.id)
      // }

      const newStatuses = []
      statuses.forEach(i => {
        if (i.status_color) {
          newStatuses.push({ id: i.id, color: i.status_color })
        }
      })

      newStatuses.forEach(({ id, color }) => {
        setFieldColors(prevColors => ({
          ...prevColors,
          [id]: color
        }))
      })
    }

  }, [statuses])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="d-flex flex-column">
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Name</Label>
            <Input
              placeholder='Enter name'
              autoComplete="off"
              value={statusGroupName}
              onChange={statusGroupNameChangeHandler}
              tabIndex={1}
            />
          </div>
          <div className="d-flex flex-column border-top border-top-dashed pt-3">
            <Label className="form-label mb-0">Statuses list :</Label>
            {statusesData.length > 0 &&
              statusesData.map((field, idx) => (
                <div className="d-flex align-items-center justify-content-between" key={idx}>
                  {idx === 0 ? null : (
                    isNaN(field.id) ? (
                      <div className="mb-3 me-3">
                        <Button type="button" onClick={() => removeFieldHandler(idx)} className="btn btn-danger">
                          X
                        </Button>
                      </div>
                    ) : null
                  )}
                  <div className="w-100">
                    <FormGroup>
                      <Input
                        name={field.id}
                        value={field.name}
                        onChange={e => {
                          fieldsChangeHandler(idx, e.target.value)
                          updatedStatuses.current = updatedStatuses.current.set(String(e.target.name), e.target.value)
                        }}
                        tabIndex={idx + 2}
                      />
                    </FormGroup>
                  </div>

                  {/*   Color picker   */}
                  <div className="mb-3 ms-3 position-relative">
                    <button
                      type="button"
                      className={classnames("btn btn-sm btn-icon waves-effect waves-light rounded-circle", {
                        "border-muted border-3": isColorPicker[field.id],
                        "border": !fieldColors[field.id]
                      })}
                      onClick={() => toggleColorPicker(field.id)}
                      style={{background: ((isColorPicker[field.id] && initialFieldColor) ? initialFieldColor : fieldColors[field.id])}}
                      data-tooltip-id={field.id}
                      data-tooltip-content={"Status color"}
                    >
                      {!fieldColors[field.id] && <i
                        className={`bx bx-color-fill ${(isColorPicker[field.id] && initialFieldColor) && "text-white"}`}></i>}
                    </button>
                    <Tooltip id={field.id}/>

                    <Card className={classnames("position-absolute border", {
                      "d-block": isColorPicker[field.id],
                      "d-none": !isColorPicker[field.id]
                    })}
                          style={{left: "-260px", top: "0px", zIndex: "100"}}
                    >
                      <CardBody>
                        <div className="pb-2 d-flex justify-content-between">
                          <div>
                            <button
                              className="text-success fs-16 btn btn-sm p-0 m-0"
                              onClick={(e) => {
                                e.preventDefault()
                                handleColorChange(initialFieldColor, field.id, idx)
                                setIsColorPicker({...isColorPicker, [field.id]: !isColorPicker[field.id]})
                              }}
                            >
                              <i className="ri-checkbox-circle-line"></i>
                            </button>
                            <button
                              className="text-danger fs-16 ms-2 btn btn-sm p-0 m-0"
                              onClick={(e) => {
                                e.preventDefault()
                                setIsColorPicker({...isColorPicker, [field.id]: !isColorPicker[field.id]})
                                setInitialFieldColor('')
                              }}
                            >
                              <i className="ri-close-circle-line"></i>
                            </button>
                          </div>
                          {fieldColors[field.id] &&
                            <button
                              className="text-danger fs-16 ms-2 btn btn-sm p-0 m-0"
                              onClick={(e) => {
                                e.preventDefault()
                                handleColorChange("transparent", field.id, idx)
                                setInitialFieldColor('')
                              }}
                            >
                              <i className="ri-login-circle-line "></i>
                            </button>
                          }
                        </div>
                        <SketchPicker
                          color={initialFieldColor || "#ffffff"}
                          width="200px"
                          disableAlpha
                          onChangeComplete={(color) => {
                            setInitialFieldColor(color.hex)
                          }}
                        />
                      </CardBody>
                    </Card>
                  </div>


                  <div className="mb-3 d-flex ps-3">
                    <div className="d-flex align-items-center">
                      {/*<div className="form-check form-radio-primary">*/}
                      {/*  <Input*/}
                      {/*    className="form-check-input"*/}
                      {/*    checked={selectedFallback === field.id}*/}
                      {/*    onChange={() => {*/}
                      {/*      setSelectedFallback(field.id)*/}
                      {/*      changeFallbackHandler(idx, selectedFallback)*/}
                      {/*    }}*/}
                      {/*    type="radio"*/}
                      {/*    data-tooltip-id={field.id}*/}
                      {/*    data-tooltip-content={"Fallback"}*/}
                      {/*  />*/}
                      {/*  <Tooltip id={field.id}/>*/}
                      {/*</div>*/}
                      <div className="form-check form-check-outline form-check-primary ms-3">
                        <Input
                          className="form-check-input"
                          checked={field.visible_to_api}
                          onChange={e => {
                            editVisibleToApiHandler(idx, e.target.checked)
                            setVisibleToApiList(s => s.set(field.id, e.target.checked))
                          }}
                          type="checkbox"
                          data-tooltip-id={field.id}
                          data-tooltip-content={"Visible to API"}
                        />
                        <Tooltip id={field.id}/>
                      </div>
                    </div>
                  </div>

                </div>
              ))}

            <div>
              <hr className="w-100 px-0 mt-0"/>
              <Button type="button" color="primary" onClick={addFieldHandler} className="btn mb-3">Add Field</Button>
            </div>

          </div>

          {/*<div className="form-check form-switch form-switch-left form-switch-sm mt-3 mb-3">
            <Input
              className="form-check-input"
              checked={isDefaultCheck ?? ''}
              name="is_default"
              onChange={e => {
                setIsDefaultCheck(e.target.checked)
              }}
              type="checkbox"
            />
            <Label className="form-check-label text-muted">
              Default
            </Label>
          </div>*/}
          <div className="hstack justify-content-end">
            <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
            <Button type="submit" color="primary" className="btn">Save changes</Button>
          </div>
        </div>
      </Form>
    </>
  )
}

export default Edit
