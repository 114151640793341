import React, {useContext, useEffect, useState} from 'react'
import {ModalContext} from "../../../../contexts"
import {
  Button,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import {selectListData, selectListDataWithParam} from "../../../../api/general"
import {attachVoipAccounts} from "../../../../api/employees"
import {toast} from "react-toastify"

const VoipEditModal = ({employeeId, voipAccount, updateProfile}) => {
  const {closeModal} = useContext(ModalContext)
  const [selectedProvider, setSelectedProvider] = useState()
  const [selectedAccounts, setSelectedAccounts] = useState(null)
  const [accountsList, setAccountsList] = useState(null)
  // const [extensionsList, setExtensionsList] = useState({})
  const [providersList, setProvidersList] = useState(null)

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())
    data = {...data, employee_id: Number(employeeId)}

    data.voip_account_id = selectedAccounts.map(i => i.value)

    attachVoipAccounts(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateProfile()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  useEffect(() => {
    // setExtensionsList([
    //   {value: '11', label: 'extension_1', checked: false},
    //   {value: '22', label: 'extension_2', checked: false},
    //   {value: '33', label: 'extension_3', checked: false},
    //   {value: '44', label: 'extension_4', checked: false},
    // ])

    selectListData('voip_providers').then(r => setProvidersList(r))
  }, [])

  useEffect(() => {
    if (selectedProvider) {
      selectListDataWithParam('voip_accounts', {provider_id: selectedProvider.value}).then(r => {
        setAccountsList(r)
      })
    }
  }, [selectedProvider])

  useEffect(() => {
    if (accountsList && voipAccount) {
      setSelectedAccounts(accountsList.filter(i => voipAccount.some(account => account.id === i.value)))
    }
  }, [accountsList, voipAccount])

  return (
    <React.Fragment>
      <Form onSubmit={submitHandler}>
        <div className="mb-3 w-100">
          <div className="mb-4 form-control border-0 p-0">
            <Label className="form-label mb-0">VoIP Providers</Label>
            <Select
              name="provider_id"
              placeholder="VoIP Providers"
              options={providersList}
              value={selectedProvider}
              required
              onChange={(selected) => setSelectedProvider(selected)}
            >
            </Select>
          </div>

          {selectedProvider &&
            <Row className="border-2 border-top border-top-dotted pt-3">
              <div className="form-control border-0">
                <Label className="form-label mb-0">VoIP Accounts</Label>
                <Select
                  name="voip_account_id"
                  placeholder="Select Accounts"
                  options={accountsList}
                  value={selectedAccounts}
                  isMulti
                  onChange={(selected) => setSelectedAccounts(selected)}
                >
                </Select>
                {/*{selectUser &&
                  <div className="mt-3">
                    {(extensionsList && extensionsList.length > 0) ?
                      extensionsList.map((item, idx) => (
                        <div
                          key={idx}
                          className="form-check form-check-outline text-nowrap form-check-primary mb-2"
                        >
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name={item.label}
                            defaultChecked={item.checked}
                          />
                          <Label
                            className="form-check-label ms-1"
                          >
                            {item.label}
                          </Label>
                        </div>
                      ))
                      :
                      <div className="pt-3 text-center">
                        Extensions list is empty!
                      </div>
                    }
                  </div>
                }*/}
              </div>
            </Row>
          }
        </div>

        <div className="hstack justify-content-between">
          <Button type="button" color="warning" onClick={closeModal} className="btn">Cancel</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </React.Fragment>
  )
}

export default VoipEditModal
