import React, {useEffect, useState} from 'react'
import {Button, Form, FormGroup, Input, Label} from "reactstrap"
import {selectListData} from "../../../../api/general"
import Select from "react-select"
import {sendEmail} from "../../../../api/customers"
import {toast} from "react-toastify"
import {useUserCan} from "../../../../Components/Hooks/UserHooks"
import {useSelector} from "react-redux"

const sendEmailModal = ({id}) => {
  const user = useSelector(s => s.Profile.user)
  const [agentOptions, setAgentOptions] = useState([])
  const [selectedAgent, setSelectedAgent] = useState('')
  const [brandOptions, setBrandOptions] = useState([])
  const [selectedTemplate, setSelectedMailTemplate] = useState('')
  const [mailTemplateSubject, setMailTemplateSubject] = useState('')
  const [mailTemplateMessage, setMailTemplateMessage] = useState('')
  const [sendOrSpinnerButton, setSendOrSpinnerButton] = useState(false)
  const [selectedBrand, setSelectedBrands] = useState()
  const [emailTemplates, setEmailTemplates] = useState([])
  const [isForceSendMode, setIsForceSendMode] = useState(false)

  // Permissions
  const canSendMail = useUserCan('send_mail')
  const canSelectFrom = useUserCan('select_from')
  const canEditMailMessage = useUserCan('edit_mail_message')

  const getAgentsData = (sendFromEmployee) => {
    if (sendFromEmployee) {
      selectListData('send_email_agents').then(r => {
        if (!canSelectFrom) {
          r = r.filter(i => Number(i.value) === Number(user.id))
        }
        r.unshift({value: 0, label: 'Brand'})
        setAgentOptions(r)
      })
    } else {
      setAgentOptions([{value: 0, label: 'Brand'}])
    }
  }

  const changeSelectedBrandsHandler = (brand) => {
    getAgentsData(brand.send_from_employee === 'on')
    setSelectedAgent('')
  }

  const getMailTemplatesHandler = (selected) => {
    setMailTemplateSubject(selected.label)
    setMailTemplateMessage(selected.value)
  }

  const resetForm = () => {
    setSelectedMailTemplate('')
    setSelectedAgent('')
    setMailTemplateSubject('')
    setMailTemplateMessage('')
    setIsForceSendMode(false)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    setSendOrSpinnerButton(true)

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())
    data.customer_id = id

    data.employee_id = selectedAgent.value

    data['force_send'] = !!isForceSendMode

    if (mailTemplateSubject === '') {
      toast.error('Subject is a required field')
      setSendOrSpinnerButton(false)
    } else if (mailTemplateMessage === '') {
      toast.error('Message is a required field')
      setSendOrSpinnerButton(false)
    } else {
      sendEmail(data).then(r => {
        if (r.success) {
          toast.success(r.message)
          setSendOrSpinnerButton(false)
          resetForm()
        } else {
          setSendOrSpinnerButton(false)
          toast.error(r?.message ?? 'Validation error!')

          if (r?.set_force_send) {
            setIsForceSendMode(true)
          }

          if (r.errors) {
            Object.entries(r.errors).forEach(entry => {
              const [key, value] = entry
              value.forEach(i => toast.error(i))
            })
          }
        }
      }).catch(r => {
        toast.error("ERROR! Can't connect to the server!")
        setSendOrSpinnerButton(false)
      })
    }
  }

  useEffect(() => {
    selectListData('brands').then(r => setBrandOptions(r))
  }, []);

  return (
    <React.Fragment>
      <Form onSubmit={submitHandler}>
        <FormGroup>
          <Label>From Brand</Label>
          <Select
            options={brandOptions}
            name="brand_id"
            required={true}
            onChange={(selected) => {
              changeSelectedBrandsHandler(selected)
              let emailTemplatesData = selected?.templates?.map(i => ({label: i.data.subject, value: i.data.template}))

              setEmailTemplates(emailTemplatesData ?? [])
              setMailTemplateSubject('')
              setMailTemplateMessage('')
              setSelectedMailTemplate({})
              setSelectedBrands(selected)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>From who</Label>
          <Select
            options={agentOptions}
            value={selectedAgent}
            onChange={(selected) => setSelectedAgent(selected)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Subject</Label>
          <Input
            className='form-control'
            placeholder='Subject'
            name="mail_subject"
            readOnly={!(canSendMail && canEditMailMessage)}
            value={mailTemplateSubject}
            onChange={(e) => setMailTemplateSubject(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Message</Label>
          <textarea
            className='form-control'
            rows={10}
            placeholder='Message'
            name="mail_message"
            readOnly={!(canSendMail && canEditMailMessage)}
            value={mailTemplateMessage}
            onChange={(e) => setMailTemplateMessage(e.target.value)}
          />
        </FormGroup>
        {selectedBrand !== undefined ?
          <FormGroup>
            <Label>Mail template</Label>
            <Select
              options={emailTemplates}
              value={selectedTemplate}
              onChange={(selected) => {
                setSelectedMailTemplate(selected)
                getMailTemplatesHandler(selected)
              }}
            />
          </FormGroup> : null
        }

        <div className="hstack justify-content-end">

          {isForceSendMode ?
            <div className="text-center px-2 py-1 bg-soft-danger fs-12 me-3 rounded-3">
              <b>Note:</b> Sending emails to customers who have unsubscribed may not be the best practice. Sending
              unwanted emails can
              lead to an increase in complaints or spam reports. This can negatively affect the deliverability of
              future emails.
            </div> : null
          }

          <Button type="submit" color={`${isForceSendMode ? 'danger' : 'primary'}`}
                  className={`btn ${sendOrSpinnerButton ? 'd-none' : null}`}>
            <span className="d-flex align-items-center">
              <div className="me-2 text-nowrap">{isForceSendMode ? 'Force send' : 'Send'}</div>  <i
              className="ri-mail-send-line"></i>
            </span>
          </Button>

          <button
            type="button"
            className={`btn btn-${isForceSendMode ? 'danger' : 'primary'} btn-load ${!sendOrSpinnerButton ? 'd-none' : null}`}
            disabled
          >
            <span className="d-flex align-items-center">
                <span className="flex-grow-1 me-2">
                    Sending...
                </span>
                <span className="spinner-border flex-shrink-0" role="status">
                    <span className="visually-hidden">Loading...</span>
                </span>
            </span>
          </button>

        </div>
      </Form>
    </React.Fragment>
  )
}

export default sendEmailModal
