import {Input} from "reactstrap"
import {useEffect, useState} from "react"
import {insertFilterData} from "../../../slices/table-filters/reducer";
import {useDispatch} from "react-redux";

const TextInput = (props) => {
  const {fieldData, disabled, rowValue, withoutPlaceholder = false, filterStorageKey} = props
  const [selectedValue, setSelectedValue] = useState('')
  const dispatch = useDispatch()
  const {type, name, uuid} = fieldData ?? []

  const addToActiveFilters = (value) => {
    dispatch(insertFilterData({
      value: value,
      type: type,
      name: name,
      uuid: uuid,
      filterStorageKey: filterStorageKey,
    }))
  }

  useEffect(() => {
    setSelectedValue(rowValue ? rowValue : '')
  }, [rowValue])

  return (
    <Input
      id={`${filterStorageKey}_${name}`}
      type="text"
      className="form-control"
      placeholder={!withoutPlaceholder ? 'Enter the value' : ''}
      disabled={disabled}
      value={selectedValue}
      onChange={e => {
        addToActiveFilters(e.target.value)
        setSelectedValue(e.target.value)
      }}
    />
  )
}

export default TextInput
