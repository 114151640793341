import React, {useEffect, useState} from 'react'
import {Col, Form, Input, Label, Row, Spinner} from "reactstrap"
import Select from "react-select"
import {useUserCan} from "../../../Components/Hooks/UserHooks"
import {updateEmployee} from "../../../api/employees"
import {changeApiToken} from "../../../api/affiliates"
import {selectListData} from "../../../api/general"
import {toast} from "react-toastify"

const PersonalDetails = ({id, response, updateProfile}) => {
  const [profileData, setProfileData] = useState({})
  const [rolesData, setRolesData] = useState([])
  const [selectedRole, setSelectedRole] = useState([])
  const [teamsOptions, setTeamsOptions] = useState([])
  const [selectedTeams, setSelectedTeams] = useState('')
  const [departmentsOptions, setDepartmentsOptions] = useState([])
  const [selectedDepartments, setSelectedDepartments] = useState('')
  const [emailSignature, setEmailSignature] = useState()

  //Permissions
  const canEditUser = useUserCan('edit_user')
  const isAdmin = profileData.role === 'admin'
  const isAffiliate = profileData.role === 'affiliate'

  const getRolesData = () => {
    selectListData('team_roles_list').then(r => {
      setRolesData(r)
    })
  }

  const getTeamsData = () => {
    selectListData('departments_teams').then(r => {
      setTeamsOptions(r)
    })
  }

  const getDepartmentsData = () => {
    selectListData('departments').then(r => {
      setDepartmentsOptions(r)
    })
  }

  const getProfileData = () => {
    setProfileData(response)
  }

  const changeApiTokenHandler = () => {
    if (confirm('Do you really want to create new token?')) {
      changeApiToken(id).then(r => {
        if (r.success) {
          toast(r.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  const submitHandler = e => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())
    data.employee_id = id

    if (!isAdmin && !isAffiliate) {
      data.teams = selectedTeams.map(i => i.value)
    }

    data.departments = selectedDepartments.map(i => i.value)

    updateEmployee(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateProfile()
      } else {
        toast.error(r.message)
        if (Object.keys(r.errors).length) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        }
      }
    })
  }

  useEffect(() => {
    setSelectedRole(rolesData.find(i => String(i.label).toLowerCase() === profileData.role))
  }, [rolesData, profileData])

  useEffect(() => {
    if (profileData?.teams?.length && teamsOptions.length) {
      setSelectedTeams(teamsOptions.filter(i => profileData.teams.map(i => i.id).includes(i.value)))
    }
  }, [teamsOptions, profileData])

  useEffect(() => {
    if (profileData?.departments?.length && departmentsOptions.length) {
      setSelectedDepartments(departmentsOptions.filter(i => profileData.departments.map(i => i.id).includes(i.value)))
    }

    if (profileData?.user?.profile?.settings?.email_signature) {
      setEmailSignature(profileData.user.profile.settings.email_signature)
    }
  }, [departmentsOptions, profileData])

  useEffect(() => {
      getRolesData()
      getTeamsData()
      getDepartmentsData()
  }, [])

  useEffect(() => {
    if (response) {
      getProfileData()
    }
  }, [response])

  return (
    <React.Fragment>
      {Object.keys(profileData).length > 0 ?
        <Form onSubmit={submitHandler}>
          <Row>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="firstnameInput" className="form-label mb-0">First Name</Label>
                <Input
                  name="first_name"
                  type="text"
                  className="form-control"
                  id="firstnameInput"
                  placeholder="Enter your firstname"
                  defaultValue={profileData.user.profile.first_name}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="lastnameInput" className="form-label mb-0">Last Name</Label>
                <Input
                  name="last_name"
                  type="text"
                  className="form-control"
                  id="lastnameInput"
                  placeholder="Enter your lastname"
                  defaultValue={profileData.user.profile.last_name}
                />
              </div>
            </Col>
            {profileData.role !== 'affiliate' &&
              <Col lg={6}>
                <div className="mb-3">
                  <Label className="form-label mb-0">Role</Label>
                  <Select
                    name="change_role_id"
                    placeholder="Choose..."
                    value={selectedRole}
                    onChange={(selected) => setSelectedRole(selected)}
                    options={rolesData}
                  />
                </div>
              </Col>}
            <Col lg={6}>
              <div className="mb-3">
                <Label htmlFor="emailInput" className="form-label mb-0">Email Address</Label>
                <Input
                  name="email"
                  type="email"
                  className="form-control"
                  id="emailInput"
                  placeholder="Enter your email"
                  defaultValue={profileData.user.email}
                />
              </div>
            </Col>
            {(!isAdmin && !isAffiliate) &&
              <Col lg={12}>
                <Label htmlFor="teams" className="form-label mb-0">Team</Label>
                <Select
                  id="teams"
                  placeholder="Choose..."
                  value={selectedTeams}
                  isMulti
                  onChange={(selected) => setSelectedTeams(selected)}
                  options={teamsOptions}
                />
              </Col>
            }
            {(isAdmin || isAffiliate) &&
              <Col lg={12}>
                <Label htmlFor="teams" className="form-label mb-0">Department</Label>
                <Select
                  id="departments"
                  placeholder="Choose..."
                  value={selectedDepartments}
                  isMulti
                  onChange={(selected) => setSelectedDepartments(selected)}
                  options={departmentsOptions}
                />
              </Col>
            }
            {profileData.role !== 'affiliate' &&
              <Col lg={12}>
                <Label className="form-label mt-3 mb-0">Email Signature</Label>
                <Input
                  name="email_signature"
                  type="textarea"
                  rows="5"
                  placeholder="Enter email signature"
                  value={emailSignature}
                  onChange={(e) => setEmailSignature(e.target.value)}
                />
              </Col>}
            {canEditUser &&
              <Col lg={12} className="mt-3 d-flex justify-content-end">
                {isAffiliate &&
                  <div className="hstack gap-2 justify-content-end me-2">
                    <button type="button" onClick={changeApiTokenHandler} className="btn btn-primary">Change API
                      token
                    </button>
                  </div>}
                <div className="hstack gap-2 justify-content-end">
                  <button type="submit" className="btn btn-primary">Update</button>
                </div>
              </Col>}
          </Row>
        </Form>
        :
        <div className="w-100 text-center">
          <Spinner color="primary" className="my-3">Loading ...</Spinner>
        </div>
      }
    </React.Fragment>
  )
}

export default PersonalDetails
