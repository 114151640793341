import {Button, Form, FormGroup, Input, Label} from "reactstrap"
import {useContext, useState} from "react"
import {ModalContext} from "../../../contexts"
import {createDepartment} from "../../../api/settings"
import {toast} from "react-toastify"

const AddDepartment = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [department, setDepartment] = useState("")

  const submitHandler = (e) => {
    e.preventDefault()

    createDepartment({name: department}).then(r => {
      closeModal()
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  const changeDepartmentHandler = (e) => {
    setDepartment(e.target.value)
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Department name</Label>
          <Input
            name="name"
            placeholder="Department name"
            autoComplete="off"
            value={department}
            onChange={changeDepartmentHandler}
          />
        </div>
        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default AddDepartment
