import {Button, Form, Input, Label} from "reactstrap"
import React, {useContext, useState} from "react"
import CustomSelect from '../../../Components/Form/CustomSelect'
import {ModalContext} from "../../../contexts"
import {createTemplate} from "../../../api/settings"
import {toast} from "react-toastify"
import Select from "react-select";
import CustomInput from "../../../Components/Form/CustomInput"

const AddTemplate = ({updateTable, templateTypes}) => {
  const {closeModal} = useContext(ModalContext)
  const [renderedFields, setRenderedFields] = useState('')

  const renderFields = (type) => {
    let template = ''

    if (type) {
      template = templateTypes
        .find(i => i.type === type)
        .fields
        .map((field, idx) => {
          return (
            <div key={idx}>
              {field.type === 'select' &&
                <CustomSelect
                  params={field}
                  label={field.label}
                  selectListDataName={field.selectListDataName}
                  attrs={field.attrs}
                />
              }

              {field.type === 'input' &&
                <CustomInput
                  label={field.label}
                  attrs={field.attrs}
                />
              }
            </div>
          )
      })
    }

    setRenderedFields(template)
  }

  const submitHandler = (e) => {
    e.preventDefault()

    let data = new FormData(e.target)

    createTemplate(data).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
      } else {
        toast.error(r.message)
      }
    }).finally(() => closeModal())
  }

  return (
    <>
      <Form onSubmit={submitHandler}>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Type</Label>
          <Select
            name="type"
            placeholder="Select..."
            autoComplete="off"
            required={true}
            options={templateTypes.map(i => ({label: i.title, value: i.type}))}
            onChange={selected => renderFields(selected.value)}
          />
        </div>
        <div className="form-group mb-3">
          <Label className="form-label mb-0">Name</Label>
          <Input
            name="name"
            required={true}
            placeholder="Enter name"
            autoComplete="off"
          />
        </div>

        {renderedFields}

        <div className="hstack justify-content-end">
          <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
          <Button type="submit" color="primary" className="btn">Save</Button>
        </div>
      </Form>
    </>
  )
}

export default AddTemplate
