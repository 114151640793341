import React, {useEffect, useState} from "react"
import {Input, Label} from "reactstrap"
import {Tooltip} from "react-tooltip"

function CustomCheckbox({label, tooltip, namedId, checked, disabled, onUpdate, updateTable}) {
  const [checkedState, setCheckedState] = useState(checked ?? false)

  useEffect(() => {
    setCheckedState(checked)
  }, [checked, updateTable])

  return (
    <div className="form-check form-check-outline text-nowrap form-check-primary mb-3">
      <Input
        className="form-check-input"
        type="checkbox"
        id={namedId}
        checked={checkedState}
        onChange={e => {
          setCheckedState(e.target.checked)
          onUpdate(e)
        }}
        disabled={disabled}
      />
      <Label
        className="form-check-label ms-1"
        for={namedId}
        data-tooltip-id={namedId}
        data-tooltip-content={tooltip ?? ''}
      >
        {label}
      </Label>
      {!!tooltip && <Tooltip id={namedId} style={{zIndex: 100}}/>}
    </div>
  )
}

export default CustomCheckbox
