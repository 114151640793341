import React, {useEffect, useState} from 'react'
import {Button, Dropdown, DropdownMenu, DropdownToggle} from "reactstrap"
import {Link} from "react-router-dom"
import SimpleBar from "simplebar-react"
import classnames from "classnames"
import {prettyDate} from "../../helpers/utils"
import ResizableModal from "./ResizableModal"
import {getTemplatesByType} from "../../api/settings"

const DocumentsDropdown = () => {
  const [isDropdown, setIsDropdown] = useState(false)
  const [documentId, setDocumentId] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [documentsData, setDocumentsData] = useState([])

  const toggleDropdown = () => setIsDropdown(!isDropdown)

  const openDocumentHandler = (document) => {
    setSelectedDocument(document)
    setIsModalOpen(true)
  }

  const closeModalHandler = () => setIsModalOpen(false)

  useEffect(() => {
    getTemplatesByType('employee_documents').then(r => {
      if (r.success) {
        setDocumentsData(r.templates)
      }
    })
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isDropdown}
        toggle={toggleDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="ri-survey-line fs-22"></i>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-lg p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3 d-flex justify-content-between align-items-center">
              <Link to="#"><h6 className="m-0 fs-16 fw-semibold text-white curs"> Documents </h6></Link>
              <Button
                type="button"
                className="btn btn-sm m-0 fs-10 fw-semibold btn-info"
                onClick={closeModalHandler}
              >
                Close active window
              </Button>
            </div>
          </div>
          <SimpleBar style={{maxHeight: "300px"}} className='bg-gradient'>
            {(documentsData && documentsData.length) ?
              documentsData.map((item, idx) => {

                return (
                  <div
                    className={classnames('fs-14 p-1 m-2 cursor-pointer rounded-2 ' +
                      'd-flex align-items-center justify-content-between', {
                      'ps-2 fw-semibold bg-soft-secondary': documentId === String(idx)
                    })}
                    key={idx}
                    style={{transition: 'ease-in-out 0.2s'}}
                    onMouseEnter={() => setDocumentId(String(idx))}
                    onMouseLeave={() => setDocumentId('')}
                    onClick={() => openDocumentHandler(item)}
                  >
                    <div className="d-flex text-truncate d-flex align-items-center">
                      <span className="me-2 rounded-circle">
                      <i
                        className={`bx bxs-file-${documentId === String(idx) ? 'find' : 'doc'} text-success fs-22`}></i>
                      </span>
                      <div className="text-nowrap text-truncate">
                        {item.name}
                        <div className="fs-12 text-dark">
                          {prettyDate(item.created_at)}
                        </div>
                      </div>
                    </div>
                    <div className={`rounded-circle d-${documentId === String(idx) ? 'block' : 'none'}`}>
                      <i className="bx bx-chevrons-right text-primary fs-22"></i>
                    </div>

                  </div>
                )
              }) :
              <div className="fw-bold text-center mb-1 mt-2 py-3">You have no available documents</div>
            }
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>

      {(isModalOpen && selectedDocument) && (
        <ResizableModal
          title={selectedDocument.name}
          content={selectedDocument.data.template}
          onClose={closeModalHandler}
        />
      )}
    </React.Fragment>
  )
}

export default DocumentsDropdown
