import React, {useEffect, useState} from "react"
import {Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Row} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import {selectListData} from "../../api/general"
import Select from "react-select"
import {customerImport} from "../../api/import"
import {toast} from "react-toastify"
import ImportTable from "./ImportTable"

const Import = () => {
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [departments, setDepartments] = useState([])

  const getDepartmentsData = () => {
    selectListData('departments').then(r => {
      setDepartments(r)
    })
  }

  const submitHandler = (e) => {
    e.preventDefault()
    toast.warning('Importing... Please wait.')

    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true;

    let data = new FormData(e.target)
    data.append('department_id', selectedDepartment.value)

    customerImport(data).then(r => {
      btn.disabled = false;

      if (r.success) {
        toast.success(r.message)
      } else {
        toast.error(r.message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    }).finally(() => {
      btn.disabled = false;
    })
  }

  useEffect(() => {
    getDepartmentsData()
  }, [])

  document.title = 'Import | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Import"/>
          <Row>
            <Col className='col-12'>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Import</h4>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={submitHandler}>
                    <Row>
                      <Col xs={12}>
                        <Label htmlFor="department" className="form-label">Select destination department</Label>
                        <Select
                          id="department"
                          options={departments}
                          value={selectedDepartment}
                          onChange={(selected) => setSelectedDepartment(selected)}
                          required
                        />
                      </Col>
                      <Col className="mt-3" xs={12}>
                        <Label htmlFor="import_file" className="form-label">File to import</Label>
                        <Input
                          name="file"
                          className="form-control"
                          aria-label="form-control-lg"
                          id="import_file"
                          type="file"
                          required
                        />
                      </Col>
                    </Row>
                    <Button className="mt-3" color="primary" type="submit">Import</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <ImportTable/>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Import
