import React, {useEffect, useReducer, useState} from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"
import {formatStringDate, openLinkInNewTab, prettyDate, timeZone} from "../../helpers/utils"

import {
  Card,
  CardBody,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col, Button
} from "reactstrap"

import * as Yup from "yup"
import {useFormik} from "formik"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, {Draggable} from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import Flatpickr from "react-flatpickr"

//redux
import {useSelector, useDispatch} from "react-redux"

import BreadCrumb from "../../Components/Common/BreadCrumb"
import DeleteModal from "../../Components/Common/DeleteModal"

//Simple bar
import SimpleBar from "simplebar-react"
import UpcomingEvents from './UpcomingEventCard'
import listPlugin from '@fullcalendar/list'

import {
  getEvents as onGetEvents,
  getCategories as onGetCategories,
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  updateEvent as onUpdateEvent,
} from "../../slices/calendar/thunk"
import moment from "moment-timezone"
import classnames from "classnames"
import {selectListData} from "../../api/general"
import LazyLoadSelect from "../../Components/Form/LazyLoadSelect"

function eventDateReducer(state, action) {
  switch (action.type) {
    case 'defaultDate': {
      return {...state, defaultDate: action.payload}
    }
    default: {
      return state
    }
  }
}

const eventDateInitOptions = {
  // minDate: 'today',
  enableTime: true,
  time_24hr: true,
  // mode: "range",
  mode: "single",
  dateFormat: "Y-m-d H:i",
}

const Calendar = () => {
  const dispatch = useDispatch()
  const [event, setEvent] = useState({})
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedEventDates, setSelectedEventDates] = useState(new Date().setHours(12, 0))
  const [isEdit, setIsEdit] = useState(false)
  const [isEditButton, setIsEditButton] = useState(true)
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [eventDateOptions, eventDateOptionsDispatch] = useReducer(eventDateReducer, eventDateInitOptions)
  const [customers, setCustomers] = useState([])
  const authUser = useSelector(s => s.Profile.user)

  const {events, categories} = useSelector((state) => ({
    events: state.Calendar.events,
    categories: state.Calendar.categories,
    // isEventUpdated: state.Calendar.isEventUpdated,
  }))

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      category: (event && event.category) || "",
      description: (event && event.description) || "",
      eventDate: (event && event.eventDate) || "",
      customers: (event && event.customers) || "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
    }),
    onSubmit: (values) => {
      const eventDate = new Date(selectedEventDates || new Date())
      let formatEventTime = ''

      if (typeof selectedEventDates === 'string') {
        let formatDate = moment.tz(selectedEventDates, 'UTC').format()
        formatEventTime = moment.tz(formatDate, timeZone).format()
      } else {
        formatEventTime = formatStringDate(eventDate)
      }

      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          category: values.category,
          start: formatEventTime,
          end: formatEventTime,
          description: values.description,
          customers: (values['customers'] || []).map(i => i.value),
        }
        // update event
        dispatch(onUpdateEvent(updateEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          title: values['title'],
          start: formatEventTime,
          end: formatEventTime,
          category: values.category,
          description: values['description'],
          customers: (values['customers'] || []).map(i => i.value),
        }
        // save new event
        dispatch(onAddNewEvent(newEvent))
        validation.resetForm()
      }

      setSelectedEventDates(null)
      toggle()
    },
  })

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false)
      setEvent(null)
      setIsEdit(false)
      setIsEditButton(true)
    } else {
      setModal(true)
    }
  }

  /**
   * On category drag event
   */
  const onDrag = (e) => {
    e.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = (e) => {
    const date = e["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currentDate = new Date()
    const currentHour = currentDate.getHours()
    const currentMin = currentDate.getMinutes()
    const currentSec = currentDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )

    const draggedEl = e.draggedEl
    const draggedElClass = draggedEl.className
    const draggedElCategory = draggedEl.dataset['category'] || 'none'
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElClass.indexOf("fc-event-draggable") === -1
    ) {
      const modifiedData = {
        title: draggedEl.innerText,
        category: draggedElCategory,
        start: modifiedDate,
        end: modifiedDate,
      }

      setSelectedEventDates(date)
      dispatch(onAddNewEvent(modifiedData))
    }
  }

  /**
   * Handling date click on calendar
   */
  const dateClickHandler = (arg) => {
    const date = arg["date"]

    eventDateOptionsDispatch({
      type: 'defaultDate',
      payload: date
    })

    setSelectedEventDates(date.setHours(12, 0))
    validation.setFieldValue('category', categories[0]?.category || '')
    toggle()
  }

  /**
   * Handling click on event on calendar
   */
  const eventClickHandler = (arg) => {
    const event = arg.event

    const selectedEvent = events.find(i => Number(i.id) === Number(event._def.publicId))

    const customerIds = selectedEvent.users.map(i => i.id)
    const selectedEventCustomers = customers.filter(i => customerIds.includes(i.value))
    const employee = (selectedEvent.employee && selectedEvent.employee.id !== authUser.id) ?
      selectedEvent.employee :
      null

    setEvent({
      id: selectedEvent.id,
      title: selectedEvent.title,
      start: selectedEvent.start,
      end: selectedEvent.end,
      category: selectedEvent.category,
      description: selectedEvent.description,
      customers: selectedEventCustomers,
      employee: employee,
    })

    eventDateOptionsDispatch({
      type: 'defaultDate',
      payload: selectedEvent
    })

    setSelectedEventDates(selectedEvent.start)
    setIsEdit(true)
    setIsEditButton(false)
    toggle()
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event))
    setDeleteModal(false)
    toggle()
  }

  const flatpickrValue = (typeof selectedEventDates === 'string' && timeZone !== 'UTC') ?
    moment.tz(moment.tz(selectedEventDates, "UTC"), timeZone).format() :
    (typeof selectedEventDates === 'string' && timeZone === 'UTC') ?
      selectedEventDates : typeof selectedEventDates === 'number' ?
        moment.tz(selectedEventDates, timeZone).toDate() : selectedEventDates

  const submitOtherEvent = () => {
    document.getElementById("form-event").classList.remove("view-event")

    document
      .getElementById("event-title")
      ?.classList?.replace("d-none", "d-block")
    document
      .getElementById("event-category")
      ?.classList?.replace("d-none", "d-block")
    document
      .getElementById("event-start-date")
      ?.parentNode?.classList?.remove("d-none")
    document
      .getElementById("event-start-date")
      ?.classList?.replace("d-none", "d-block")
    document
      .getElementById("event-description")
      ?.classList?.replace("d-none", "d-block")

    document
      .getElementById("event-title-tag")
      ?.classList?.replace("d-block", "d-none")
    document
      .getElementById("event-start-date-tag")
      ?.classList?.replace("d-block", "d-none")
    document
      .getElementById("event-description-tag")
      ?.classList?.replace("d-block", "d-none")

    setIsEditButton(true)
  }

  useEffect(() => {
    dispatch(onGetEvents())
    dispatch(onGetCategories())

    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [dispatch])

  useEffect(() => {
    validation.setFieldValue('category', categories[0]?.category || '')
  }, [categories])

  useEffect(() => {
    if (events?.length) {
      setUpcomingEvents(events)
    }
  }, [events])

  useEffect(() => {
    let usersInEvents = upcomingEvents.map(i => i.users?.map(j => ({
      'value': j.id,
      'label': `ID: ${j.id} | ${j.name}`
    }))).flat()

    selectListData('assigned_customers').then(r => {
      let newCustomers = [...r, ...usersInEvents]
      const unique = [...new Map(newCustomers.map(item => [item['value'], item])).values()]

      setCustomers(unique)
    })

  }, [upcomingEvents])

  document.title = 'Calendar | ' + import.meta.env.VITE_APP_NAME

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Crypto" pageTitle="Calendar"/>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <Button
                        color="primary"
                        className="btn w-100"
                        id="btn-new-event"
                        onClick={toggle}
                      >
                        <i className="mdi mdi-plus"></i> Create New Event
                      </Button>

                      <div id="external-events">
                        <br/>
                        <p className="text-muted">
                          Drag and drop your event or click in the calendar
                        </p>
                        {categories &&
                          categories.map((category, idx) => (
                            <div
                              className={`bg-soft-${category.color} external-event fc-event text-${category.color}`}
                              key={idx}
                              data-category={category.category}
                              draggable
                              onDrag={(e) => {
                                onDrag(e, category)
                              }}
                            >
                              <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2"/>
                              {category.title}
                            </div>
                          ))}
                      </div>
                    </CardBody>
                  </Card>
                  <div>
                    <h5 className="mb-1">Upcoming Events</h5>
                    <p className="text-muted">Don't miss scheduled events</p>
                    <SimpleBar
                      className="pe-2 me-n1 mb-3"
                      style={{maxHeight: "400px"}}
                    >
                      <div id="upcoming-event-list">
                        {upcomingEvents.length > 0 ?
                          upcomingEvents.slice()
                            .filter(i => moment.tz(i.start, 'UTC') >= moment.tz(new Date(), 'UTC'))
                            .sort((o1, o2) => new Date(o1.start) - new Date(o2.start))
                            .map((event, key) => (
                              <React.Fragment key={key}>
                                <UpcomingEvents
                                  event={event}
                                  color={categories.find(i => i.category === event.category)?.color}
                                />
                              </React.Fragment>
                            )) : (
                            <span className="text-info fs-12">There no upcoming events</span>
                          )
                        }
                      </div>
                    </SimpleBar>
                  </div>
                </Col>

                <Col xl={9}>
                  <Card className="card-h-100">
                    <CardBody>
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin
                        ]}
                        initialView="dayGridMonth"
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                        }}
                        events={events}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={dateClickHandler}
                        eventClick={eventClickHandler}
                        drop={onDrop}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div style={{clear: "both"}}></div>

              <Modal isOpen={modal} id="event-modal" centered>
                <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-soft-info modal-title">
                  {!!isEdit ? "Edit Event" : "Add Event"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    className={
                      !!isEdit
                        ? "needs-validation view-event"
                        : "needs-validation"
                    }
                    name="event-form"
                    id="form-event"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    {!!isEdit ? (
                        <div className="text-end">
                          <Link
                            to="#"
                            // className="btn btn-sm btn-soft-primary"
                            className={classnames('btn', 'btn-sm', 'btn-soft-primary', {
                              'd-none': !!isEditButton
                            })}
                            id="edit-event-btn"
                            onClick={(e) => {
                              e.preventDefault()
                              submitOtherEvent()
                              return false
                            }}
                          >
                            Edit
                          </Link>
                        </div>
                      ) :
                      null
                    }

                    {/* BEGIN Event Details */}
                    {event &&
                      <div className="event-details">
                        <div className="d-flex mb-2">
                          <div className="flex-grow-1 d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <i className=" ri-profile-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <h6
                                className="d-block fw-semibold mb-0"
                                id="event-title-tag"
                              >
                                {event.title}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mb-2">
                          <div className="flex-grow-1 d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-calendar-event-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <h6
                                className="d-block mb-0"
                                id="event-start-date-tag"
                              >
                                {event ?
                                  <>
                                    {prettyDate(event.start, true, true)} :: {prettyDate(event.end, true, true)}
                                  </> :
                                  ""
                                }
                              </h6>
                            </div>
                          </div>
                        </div>
                        {event.description &&
                          <div className="d-flex mb-3">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-discuss-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <p
                                className="d-block text-muted mb-0"
                                id="event-description-tag"
                              >
                                {event ? event.description : "No Description"}
                              </p>
                            </div>
                          </div>
                        }
                        {event?.customers?.length > 0 &&
                          <div className="d-flex mb-3">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-user-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <p
                                className="d-block text-muted mb-0"
                                id="event-description-tag"
                              >
                                {event.customers.map((customer, idx) => (
                                  <span key={idx} className="hstack justify-content-start">
                                    <Link to={`/customers/show/${customer.value}`}>{customer.label}</Link>
                                    <Link to="#"
                                          onClick={() => openLinkInNewTab(`/customers/show/${customer.value}`)}
                                          className="ms-1"
                                    >
                                      <i className="ri-share-box-line text-muted fs-16"></i>
                                    </Link>
                                </span>))}
                              </p>
                            </div>
                          </div>
                        }
                        {event?.employee &&
                          <div className="d-flex mt-3">
                            <div className="flex-shrink-0 me-3">
                              <i className="ri-user-2-line text-muted fs-16"></i>
                            </div>
                            <div className="flex-grow-1">
                              <p
                                className="d-block text-muted mb-0"
                                id="event-description-tag"
                              >
                                <span className="hstack justify-content-start">
                                  <Link to={`/employees/show/${event.employee.id}`}>
                                    #{event.employee.id} {event.employee.name}
                                  </Link>
                                  <Link to={`/employees/show/${event.employee.id}`} target="_blank" className="ms-1">
                                    <i className="ri-share-box-line text-muted fs-12"></i>
                                  </Link>
                                </span>
                              </p>
                            </div>
                          </div>
                        }
                      </div>
                    }
                    {/* END Event Details */}

                    {/* BEGIN Event form */}
                    <Row className="event-form">
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Type</Label>
                          <Input
                            className={
                              !!isEdit
                                ? "form-select d-none"
                                : "form-select d-block"
                            }
                            name="category"
                            id="event-category"
                            type="select"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.category || ""}
                          >
                            {categories.length > 0 ? categories.map((category, idx) => {
                                return <option
                                  key={idx}
                                  value={category.category}
                                >
                                  {category.title}
                                </option>
                              }) :
                              <option value="">None</option>
                            }
                          </Input>
                          {validation.touched.category &&
                          validation.errors.category ? (
                            <FormFeedback type="invalid">
                              {validation.errors.category}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Event Name</Label>
                          <Input
                            className={
                              !!isEdit
                                ? "form-control d-none"
                                : "form-control d-block"
                            }
                            placeholder="Enter event name"
                            type="text"
                            name="title"
                            id="event-title"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.title || ""}
                            // invalid={
                            //   validation.touched.title &&
                            //   validation.errors.title
                            //     ? true
                            //     : false
                            // }
                          />
                          {validation.errors.title ? (
                            <FormFeedback valid={true}>
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label>Event Date</Label>
                          <div
                            className={
                              !!isEdit ? "input-group d-none" : "input-group"
                            }
                          >
                            <Flatpickr
                              className="form-control"
                              id="event-start-date"
                              name="eventDate"
                              placeholder="Select Date"
                              // value={validation.values.eventDate || ""}
                              value={flatpickrValue}
                              options={eventDateOptions}
                              onChange={(date) => {
                                setSelectedEventDates(date)
                              }}
                            />
                            <span className="input-group-text">
                              <i className="ri-calendar-event-line"></i>
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Description</Label>
                          <textarea
                            className={
                              !!isEdit
                                ? "form-control d-none"
                                : "form-control d-block"
                            }
                            id="event-description"
                            name="description"
                            placeholder="Enter a description"
                            rows="3"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            // invalid={
                            //   validation.touched.description &&
                            //   validation.errors.description
                            //     ? true
                            //     : false
                            // }
                          ></textarea>
                          {validation.errors.description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.description}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={12}>
                        <LazyLoadSelect
                          label='Customers'
                          customOnChange={(values) => {
                            validation.setFieldValue('customers', values)
                          }}
                          customValue={(event && event.customers) || ""}
                        />
                      </Col>
                    </Row>
                    {/* END Event form */}

                    <div className="hstack gap-2 justify-content-end">
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-soft-danger"
                          id="btn-delete-event"
                          onClick={() => setDeleteModal(true)}
                        >
                          <i className="ri-close-line align-bottom"></i> Delete
                        </button>
                      )}
                      {isEditButton && <button
                        type="submit"
                        className="btn btn-success"
                        id="btn-save-event"
                      >
                        {!!isEdit ? "Edit Event" : "Add Event"}
                      </button>}
                    </div>
                  </Form>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calendar.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Calendar
