import {Card, CardBody} from "reactstrap";
import React from "react";
import InvoiceTable from "../../Invoices/InvoiceTable";

const Invoices = ({activeTab, customerId}) => {
	return (
			<Card>
				<CardBody>
					<div className="acitivity-timeline">
						{activeTab === 'invoices' ? <InvoiceTable customerId={customerId} /> : ''}
					</div>
				</CardBody>
			</Card>
	)
}

export default Invoices
