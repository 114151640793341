import {createSlice} from "@reduxjs/toolkit"

let defaultTicketsColumnsSetting
// First part of key depends on title of table title (lowercase)
let storageName = 'tickets_columns_setting'

try {
  defaultTicketsColumnsSetting = JSON.parse(localStorage.getItem(storageName) ?? {})
} catch (err) {
  defaultTicketsColumnsSetting = {}
}

const initialState = {
  columnsSetting: defaultTicketsColumnsSetting,
  unreadTickets: [],
}

const TicketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setColumnsSetting(state, action) {
      const data = {...state.columnsSetting}
      data[action.payload.id] = {...action.payload.params}

      state.columnsSetting = data
      localStorage.setItem(storageName, JSON.stringify({...data}))
    },
    setUnreadTickets(state, action) {
      const ticketId = Number(action.payload)

      if (ticketId) {
        state.unreadTickets = [...state.unreadTickets, ticketId]
      }
    }
  }
})

export default TicketsSlice.reducer
export const {
  setColumnsSetting,
  setUnreadTickets
} = TicketsSlice.actions
