import React, {useContext, useEffect, useState} from "react"
import {ModalContext} from "../../../contexts"
import {toast} from "react-toastify"
import {Button, Col, Form, Input, Label, Row, Table} from "reactstrap"
import Select from "react-select"
import {selectListData} from "../../../api/general"
import {
  changeIsReal,
  changeIsSync,
  changeIsVisible,
  changeStatus,
  getTransaction,
  updateTransaction
} from "../../../api/transactions"
import {copyToClipboard, cryptoIconsMapper, prettyDate, toTwoPrecision} from "../../../helpers/utils"
import _ from "lodash"
import classnames from "classnames"

const EditTransfer = (props) => {
  const {transferId, security, securitiesLogoUrlValidList, updateTable} = props
  const {closeModal} = useContext(ModalContext)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')
  const [isReal, setIsReal] = useState(false)
  const [isSync, setIsSync] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [statusOptions, setStatusOptions] = useState([])
  const [commentOptions, setCommentOptions] = useState({})
  const [transactionsStatus, setTransactionsStatus] = useState([])
  const [transactionIds, setTransactionIds] = useState(0)
  const [transfer, setTransfer] = useState(null)
  const [securityPrice, setSecurityPrice] = useState('')

  const getData = () => {
    getTransaction(transferId).then(r => {
      setTransfer(r)
      setTransactionIds(r?.transactions?.map(i => i.id) || 0)
    })
  }

  const changeIsRealHandler = (type, status) => {
    let req;

    if (type === 'real') {
      req = changeIsReal(status, transferId)
    } else {
      req = changeIsSync(status, transferId)
    }

    req.then(r => {
      if (r.success) {
        getData()
        updateTable()
        toast.success(r.message)
      } else {
        getData()
        toast.error(r.message)
      }
    })
  }

  const changeIsVisibleHandler = (is_visible) => {
    changeIsVisible(is_visible, transferId).then(r => {
      if (r.success) {
        getData()
        updateTable()
        toast.success(r.message)
      } else {
        getData()
        toast.error(r.message)
      }
    })
  }

  const changeStatusHandler = (status) => {
    changeStatus(status, [transferId]).then(r => {
      if (r.success) {
        getData()
        updateTable()
        toast.success(r.message)
      } else {
        getData()
        toast.error(r.message)
      }
    })
  }

	const submitHandler = (e) => {
		e.preventDefault()

		const data = {
      transfer_id: transferId,
      transaction_ids: transactionIds,
			statuses: transactionsStatus,
			comment: message,
		}

		updateTransaction(data)
			.then((r) => {
				// closeModal()
				let message = r?.message || 'Empty response message.'

				if (r.success) {
					toast.success(r.message)
          getData()
					updateTable()
				} else {
					toast.error(message)
				}
			})
	}

	useEffect(() => {
		if (transferId) {
      getData()
		}

    selectListData('transaction_statuses').then(r => {
      setStatusOptions(r)
    })

		selectListData('transactions_comments_template').then(r => {
			setCommentOptions(r)
		})
	}, [])

	useEffect(() => {
		if (transfer) {
			setStatus(statusOptions.find(i => i.value === transfer.status))
			setMessage(transfer?.meta?.comment)
			setIsReal(transfer.is_real)
			setIsSync(transfer.is_sync)
      setIsVisible(transfer.is_visible)
		}
	}, [transfer])

  useEffect(() => {
    if (!_.isEmpty(transfer) && transfer.meta?.quantity) {
      let averagePrice = _.divide(Number(transfer.usd_amount), Number(transfer.meta?.quantity)).toFixed(2)

      if (Number(averagePrice) < 0) {
        averagePrice = -Number(averagePrice)
      }

      setSecurityPrice(`$${averagePrice}`)
    }
  }, [transfer])

  return (
		<>
			<Form onSubmit={submitHandler}>
				<Row>
					{transfer &&
						<Col xs={12}>
							<div>
								<Table className="table-striped table-nowrap align-middle">
									<thead>
									<tr>
										<th scope="col">ID</th>
										<th scope="col">Amount</th>
										<th scope="col">USD Amount</th>
										<th scope="col">Fee Pct</th>
										<th style={{minWidth: '150px'}} scope="col">Status</th>
										<th scope="col">Is Visible</th>
										<th scope="col">Is Sync</th>
										<th scope="col">Is Real</th>
									</tr>
									</thead>
									<tbody>
                  <tr className="align-middle">
                    <td className="fw-medium">#{transfer.id}{transfer.is_real ? '*' : null}</td>
                    <td>
                      {
                        transfer.quote_amount &&
                        <>
                          {transfer.quote_amount * 1} {transfer.quote_asset.currency.code}<b> -> </b>
                        </>
                      }
                      {transfer.base_amount.replace(/\.?0+$/, '')} {transfer.base_asset.currency.code}
                    </td>
                    <td>${toTwoPrecision(transfer.usd_amount)}</td>
                    <td>{Number(transfer.fee_pct)}%</td>
                    <td>
                      <Select
                        placeholder="Choose..."
                        value={status}
                        onChange={(selected) => {
                          setStatus(selected)
                          changeStatusHandler(selected.value)
                        }}
                        options={statusOptions}
                      />
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <Input
                          className="form-check-input form-switch-md"
                          type="checkbox"
                          role="switch"
                          id="is_visible_checkbox"
                          checked={isVisible}
                          onChange={e => {
                            setIsVisible(e.target.checked)
                            changeIsVisibleHandler(e.target.checked)
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <Input
                          className="form-check-input form-switch-md"
                          type="checkbox"
                          role="switch"
                          id="is_sync_checkbox"
                          checked={isSync}
                          onChange={e => {
                            setIsSync(e.target.checked)
                            changeIsRealHandler('sync', e.target.checked)
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-check form-switch">
                        <Input
                          className="form-check-input form-switch-md"
                          type="checkbox"
                          role="switch"
                          id="is_real_checkbox"
                          checked={isReal}
                          onChange={e => {
                            setIsReal(e.target.checked)
                            changeIsRealHandler('real', e.target.checked)
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          }
          <Col xs={12}>
            <hr className="my-2"/>
          </Col>
          <Col xs={12} lg={6}>
            <div className="form-group mb-3">
              <Label htmlFor="message-text" className="col-form-label pb-0">Comment:</Label>
              <textarea
                className="form-control "
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <Label className="form-label mb-0">Comments templates</Label>
              <Select
                placeholder="Choose..."
                onChange={(selected) => {
                  setMessage(selected.data)
								}}
                options={commentOptions}
              />
            </div>
					</Col>

					{transfer && transfer.meta && transfer.meta.additional_data &&
						transfer.meta.additional_data.AccountHolder && (
              <Col xs={12} lg={6}>
                <div className="mt-3 pt-2">
                  <Label className="form-label mb-3 text-muted">Billing info</Label>
                  <Table responsive className="table table-striped">
                    <tbody>
                    <tr className="text-nowrap">
                      <td className="fw-bold">Account holder:</td>
                      <td>{transfer.meta.additional_data.AccountHolder}</td>
                    </tr>
                    <tr className="text-nowrap">
                      <td className="fw-bold">Account number:</td>
                      <td>{transfer.meta.additional_data.AccountNumber}</td>
                    </tr>
                    <tr className="text-nowrap">
                      <td className="fw-bold">Bank:</td>
                      <td>{transfer.meta.additional_data.Bank}</td>
                    </tr>
                    <tr className="text-nowrap">
                      <td className="fw-bold">City:</td>
                      <td>{transfer.meta.additional_data.City}</td>
                    </tr>
                    <tr className="text-nowrap">
                      <td className="fw-bold">IBAN:</td>
                      <td>{transfer.meta.additional_data.IBAN}</td>
                    </tr>
                    <tr className="text-nowrap">
                      <td className="fw-bold">Phone:</td>
                      <td>{transfer.meta.additional_data.Phone}</td>
                    </tr>
                    <tr className="text-nowrap">
                      <td className="fw-bold">SWIFT:</td>
                      <td>{transfer.meta.additional_data.SwiftCode}</td>
                    </tr>
                    <tr className="text-nowrap">
                      <td className="fw-bold">User ID:</td>
                      <td>{transfer.meta.additional_data.UserId}</td>
                    </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
						)}

          {transfer && transfer.meta && transfer.meta.quantity && security &&
            <Col xs={12} lg={6}>
              <div>
                <Label className="form-label mt-2 mb-0 border-bottom fw-semibold w-100">Security details:</Label>
                <Table responsive className="table">
                  <tbody>
                  <tr className="text-nowrap">
                    <td className="w-25  ps-0 py-1">Name:</td>
                    <td className='fw-semibold text-muted ps-0 py-1'>
                      <div className="d-flex align-items-center">
                        <div className="avatar-xxs me-2">
                          {!securitiesLogoUrlValidList.includes(String(transferId)) ?
                            <img
                              src={security?.logo}
                              className="img-fluid rounded-circle h-100"
                              alt={security?.name}
                            />
                            :
                            <div className="avatar-title bg-soft-primary rounded-circle text-primary">
                              {_.capitalize(security?.name.slice(0, 1))}
                            </div>
                          }
                        </div>
                        <div className="text-truncate">
                          #{security.security_id} | {security?.name}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="text-nowrap">
                    <td className="w-25  ps-0 py-1">Ticker:</td>
                    <td className='fw-semibold text-muted ps-0 py-1'>
                      {security.type !== 'bond' ? security.ticker : '-'}
                    </td>
                  </tr>
                  <tr className="text-nowrap">
                    <td className="w-25  ps-0 py-1">Type:</td>
                    <td className='fw-semibold text-muted ps-0 py-1'>{security.type.capitalize()}</td>
                  </tr>
                  <tr className="text-nowrap">
                    <td className="w-25  ps-0 py-1">Quantity:</td>
                    <td className='fw-semibold text-muted ps-0 py-1'>{transfer.meta.quantity}</td>
                  </tr>
                  <tr className="text-nowrap">
                    <td className="w-25  ps-0 py-1">
                      {transfer.type === 'deduction' ? 'Purchase USD price' : 'Selling USD price'}:
                    </td>
                    <td className='fw-semibold text-muted ps-0 py-1'>
                      {securityPrice}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          }

          {transfer && transfer?.address_to || transfer?.transactions[0]?.address_to ?
            <Col xs={12} lg={6}>
            <div className="mt-3 pt-2">
                <Table responsive className="table table-striped">
                  <tbody>
                  <tr className="text-nowrap">
                    <td className="fw-bold">Address</td>
                    <td className='cursor-pointer' onClick={() => {
                      copyToClipboard(`${transfer.address_to || transfer?.transactions[0]?.address_to}`,
												`Address was copied to clipboard`)
                    }}>
                      {transfer.address_to || transfer?.transactions[0]?.address_to}</td>
                  </tr>
                  </tbody>
                </Table>
              </div>
            </Col> : null
					}

          {transfer?.transactions.length &&
            <Col xl={12} className={classnames("", {'mt-4': !security})}>
              <div>
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Type</th>
                    <th scope="col">Created date</th>
                    <th scope="col">Asset</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Usd Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">New Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  {transfer?.transactions?.length > 0 ? transfer?.transactions?.map((item, index) => {
                    return (
                      <tr key={index} className="align-middle">
                        <td className="fw-medium">#{item.id}{item.is_real ? '*' : null}</td>
                        <td className="text-nowrap">
                          {/*<div className="avatar-xs">
                            <div className={
                              `avatar-title rounded-circle fs-16 ${item.type === 'withdrawal' ?
                                'bg-soft-danger' : ( item.type === 'deposit' ?
                                  'bg-soft-success' : ( item.type === 'sell' || item.type === 'buy' || item.type === 'fee' ?
                                    'bg-soft-info' : null))}`
                            }>
                              {
                                item.type === 'withdrawal' ? <i className="ri-arrow-right-up-fill text-danger"></i> :
                                (item.type === 'deposit' ? <i className="ri-arrow-left-down-fill text-success"></i> :
                                  (item.type === 'sell' || item.type === 'buy' || item.type === 'fee' ?
                                    <i
                                      className="ri-arrow-left-right-fill text-info"
                                      style={{transform: 'rotate(-45deg)'}}
                                    >
                                    </i>
                                    :
                                    null
                                  )
                                )
                              }
                            </div>
                          </div>*/}
                          {item.type.capitalize()}
                        </td>
                        <td>{prettyDate(item.created_at)}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="avatar-xxs me-2">
                              <img
                                src={cryptoIconsMapper[item.asset.currency.code]}
                                className="img-fluid"
                                alt="3"
                              />
                            </div>
                            <span>{item.asset.currency.code}</span>
                          </div>
                        </td>
                        <td>{item.amount}</td>
                        <td>{item.usd_amount}</td>
                        <td>
                          <span className={'badge badge-soft-' + item.status_color}>
                            {item.status === 'pending' ? (
                              <i className="ri-time-line align-bottom me-1"></i>
                            ) : ( item.status === 'executed' ? (
                              <i className="ri-checkbox-circle-line align-bottom me-1"></i>
                            ) : ( item.status === 'declined' ?
                                <i className="ri-close-circle-line align-bottom me-1"></i> : null
                            ))}
                            {item.status.capitalize()}
                          </span>
                        </td>
                        <td>
                          <Select
                            placeholder="Choose..."
                            // value={statusOptions.find(i => i.value === item.status)}
                            value={statusOptions.find(i => i.value === transactionsStatus[item.id]) || statusOptions.find(i => i.value === item.status)}
                            // value={statusOptions.find(i => i.value === transactionsStatusSelected[item.id])}
                            onChange={(selected) => {
                              setTransactionsStatus(state => {
                                let newState = {...state}
                                newState[item.id] = selected.value

                                return newState
                              })
                            }}
                            options={statusOptions}
                          />
                        </td>
                      </tr>
                    )
                    }) :
                    <tr>
                      <td colSpan={13} className='text-center'>No data</td>
                    </tr>
                  }
                  </tbody>
                </Table>
              </div>
            </Col>
          }

					<Col xs={12} className="hstack justify-content-end">
						<Button type="submit" color="primary" className="btn ms-auto mt-4">Submit</Button>
					</Col>
				</Row>
			</Form>
		</>
	)
}

export default EditTransfer
