import React, {useContext, useEffect, useState} from 'react'
import {Button, Col, Row, Table} from "reactstrap"
import Select from "react-select"
import {selectListData, selectListDataWithParam} from "../../api/general"
import {Link} from "react-router-dom"
import {ModalContext} from "../../contexts"
import EditProviderAccount from "./Modals/EditProviderAccount"
import AddProviderAccount from "./Modals/AddProviderAccount"
import {deleteVoipAccount} from "../../api/settings";
import {toast} from "react-toastify";

const VoIP = () => {
  const {openModal} = useContext(ModalContext)
  const [providersList, setProvidersList] = useState([])
  const [selectProvider, setSelectProvider] = useState()
  const [usersList, setUsersList] = useState([])
  const [loading, setLoading] = useState(true)

  const getProviderAccountsList = () => {
    if (selectProvider) {
      selectListDataWithParam('voip_accounts', {provider_id: selectProvider.value})
        .then(r => {
          setUsersList(r)
          setLoading(false)
        })
    }
  }

  const updateTable = () => {
    getProviderAccountsList()
  }

  const createProviderAccountHandler = () => {
    openModal({
      title: 'Create Account',
      content: <AddProviderAccount providersList={providersList} updateTable={updateTable}/>
    })
  }

  const editProviderAccountHandler = (account) => {
    openModal({
      title: 'Edit Account',
      content: <EditProviderAccount account={account} updateTable={updateTable}/>
    })
  }

  const deleteProviderAccountHandler = (id) => {
    if (confirm('Do you really want delete this provider account?')) {
      deleteVoipAccount(id).then(r => {
        if (r.success) {
          toast.success(r.message)
          updateTable()
        } else {
          toast.error(r.message)
        }
      })
    }
  }

  useEffect(() => {
    selectListData('voip_providers').then(r => setProvidersList(r))
  }, [])

  useEffect(() => {
    if (providersList?.length > 0) setSelectProvider(providersList[0])
  }, [providersList])

  useEffect(() => {
    getProviderAccountsList()
  }, [selectProvider])

  return (
    <>
      <Row className="d-flex justify-content-between mt-1 mb-3">
        <Col className="col-4">
          <div className="form-control border-0 p-0">
            <Select
              name="provider_id"
              placeholder="Select VoIP Provider"
              value={selectProvider}
              options={providersList}
              onChange={select => setSelectProvider(select)}
            />
          </div>
        </Col>
        {selectProvider &&
         <Col className="col-4 d-flex justify-content-end">
           <Button color="primary" className="btn" onClick={createProviderAccountHandler}>
             Create Account
           </Button>
         </Col>
        }
      </Row>
      {selectProvider &&
        <div className="table-responsive">
          <Table className="table-borderless table-responsive table-nowrap">
          <thead className="border-bottom">
            <tr>
              <th className="col-10" scope="col">NAME</th>
              <th className="col-2" scope="col">ACTIONS</th>
            </tr>
            </thead>
            <tbody>
            {usersList?.length > 0 ? usersList.map((account, idx) =>
              <tr key={idx}>
                <td>{account.label}</td>
                <td>
                  <div className="hstack gap-3 flex-wrap">
                    <Link
                      to="#"
                      onClick={() => editProviderAccountHandler(account)}
                      className="link-success fs-15"
                    >
                      <i className="ri-edit-2-line"></i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => deleteProviderAccountHandler(account.value)}
                      className="link-danger fs-15"
                    >
                      <i className="ri-delete-bin-line"></i>
                    </Link>
                  </div>
                </td>
              </tr>)
              :
              <tr>
                <td colSpan={6} className='text-center'>{loading ? 'Loading...' : 'No Data'}</td>
              </tr>
            }
            </tbody>
          </Table>
        </div>
      }
    </>
  )
}

export default VoIP
